import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { formatMoney } from "@/helpers/formatMoney";
import { format, parse } from "date-fns";
import { Bar, BarChart, CartesianGrid, Label, ReferenceLine, XAxis } from "recharts";

export const IncomeChartWidget = ({ data }) => {
  const { income_by_month } = data;

  const max = { value: 0, index: 0 };
  let avg = 0;
  const chartData = Object.entries(income_by_month)
    .map(([month, value], index) => {
      avg += value;
      if (income_by_month[month] > max.value) {
        max.index = index;
        max.value = value;
      }
      return {
        month: format(parse(month, "yyyy-MM", new Date()), "LLLL yyyy"),
        value,
      };
    })
    .sort(
      (a, b) => parse(a.month, "LLLL yyyy", new Date()).getTime() - parse(b.month, "LLLL yyyy", new Date()).getTime(),
    );

  const chartConfig = {
    value: {
      label: "Przychód",
      color: "hsl(var(--chart-1))",
    },
  };

  return (
    <ChartContainerWrapper data={chartData} config={chartConfig} className="h-[300px] w-full">
      <BarChart
        accessibilityLayer
        data={chartData}
        margin={{
          top: 20,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          tickFormatter={(value) => value.slice(0, 3)}
          dataKey="month"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        />
        <ChartTooltip content={<ChartTooltipContent className="w-[180px]" nameKey="value" />} />
        <Bar dataKey="value" fill="var(--color-value)" radius={8}>
          <Label offset={12} className="fill-foreground" fontSize={12} />
        </Bar>
        {chartData.length && (
          <ReferenceLine
            y={avg / chartData.length}
            stroke="hsl(var(--muted-foreground))"
            strokeDasharray="3 3"
            strokeWidth={1}
          >
            <Label position="insideBottomLeft" value="Średnia" offset={10} fill="hsl(var(--foreground))" />
            <Label
              position="insideTopLeft"
              value={formatMoney((avg / chartData.length).toFixed(0))}
              className="text-lg"
              fill="hsl(var(--foreground))"
              offset={10}
              startOffset={100}
            />
          </ReferenceLine>
        )}
      </BarChart>
    </ChartContainerWrapper>
  );
};
