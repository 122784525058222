import { getPermissions } from "@/features/auth/api/authApi";
import { PermissionContext } from "@/features/auth/context/PermissionContext";
import { useContext } from "react";

export const usePermissions = () => {
  const { setPermissions } = useContext(PermissionContext);

  const fetchPermissions = async () => {
    const response = await getPermissions();
    if (response.ok) {
      setPermissions(response.data.map((perm) => perm.name));
    } else {
      setPermissions([]);
    }
  };

  return { fetchPermissions };
};
