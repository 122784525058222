import api from "@/api/api";
import {
  ACTIONS_ENDPOINT,
  CREDITS_ENDPOINT,
  CREDITS_STATUSES_ENDPOINT,
  FILES_ENDPOINT,
  NOTES_ENDPOINT,
  PROCESSES_ENDPOINT,
  USERS_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDateTime } from "@/helpers/formatDate";

export const assignCreditsToUser = ({ userId, creditIds }) =>
  api.post(USERS_ENDPOINT + userId + "/assignCredits", { credit_ids: creditIds });

export const getCreditProcesses = (creditId) => api.get(CREDITS_ENDPOINT + creditId + PROCESSES_ENDPOINT);

export const updateCreditProcessProgress = ({ creditId, processId, data }) =>
  api.put(CREDITS_ENDPOINT + creditId + PROCESSES_ENDPOINT + processId, data);

export const getCreditStatuses = () => api.get(CREDITS_STATUSES_ENDPOINT);

export const createCreditStatus = (data) => api.post(CREDITS_STATUSES_ENDPOINT, data);

export const updateCreditStatus = ({ creditStatusId, data }) =>
  api.patch(CREDITS_STATUSES_ENDPOINT + creditStatusId, data);

export const updateCreditStatusOrder = (data) => api.put(CREDITS_STATUSES_ENDPOINT + "order", data);

export const deleteCreditStatus = ({ creditStatusId }) => api.delete(CREDITS_STATUSES_ENDPOINT + creditStatusId);

export const getCredits = (filters = null) => api.get(CREDITS_ENDPOINT, transformQueryFilters(filters));

export const getCredit = (creditId) => api.get(CREDITS_ENDPOINT + creditId);

export const createCredit = ({ data }) => api.post(CREDITS_ENDPOINT, data);

export const updateCredit = ({ creditId, data }) => api.patch(CREDITS_ENDPOINT + creditId, data);

export const updateCreditsStatus = ({ creditId, data }) => api.put(CREDITS_ENDPOINT + creditId + "/status", data);

export const deleteCredit = (creditId) => api.delete(CREDITS_ENDPOINT + creditId);

export const bulkEditStatusCredits = ({ creditIds, creditStatusId }) =>
  api.post(CREDITS_ENDPOINT + "bulkEditStatus", { credit_ids: creditIds, credit_status_id: creditStatusId });

export const getCreditFiles = ({ creditId, filters }) =>
  api.get(CREDITS_ENDPOINT + creditId + "/files", transformQueryFilters(filters));

export const createCreditFiles = ({ creditId, data }) =>
  api.post(CREDITS_ENDPOINT + creditId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteCreditFile = ({ creditId, fileId }) =>
  api.delete(CREDITS_ENDPOINT + creditId + FILES_ENDPOINT + fileId);

export const getCreditNotes = (creditId) => api.get(CREDITS_ENDPOINT + creditId + NOTES_ENDPOINT);

export const createCreditNote = ({ creditId, note }) =>
  api.post(CREDITS_ENDPOINT + creditId + NOTES_ENDPOINT, { note });

export const updateCreditNote = ({ creditId, noteId, note }) =>
  api.put(CREDITS_ENDPOINT + creditId + NOTES_ENDPOINT + noteId, note);

export const deleteCreditNote = ({ creditId, noteId }) =>
  api.delete(CREDITS_ENDPOINT + creditId + NOTES_ENDPOINT + noteId);

export const getCreditActions = (creditId) => api.get(CREDITS_ENDPOINT + creditId + ACTIONS_ENDPOINT);

export const createCreditAction = ({ creditId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(CREDITS_ENDPOINT + creditId + ACTIONS_ENDPOINT, data);
};

export const updateCreditAction = ({ creditId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(CREDITS_ENDPOINT + creditId + ACTIONS_ENDPOINT + actionId, data);
};

export const deleteCreditAction = ({ creditId, actionId }) =>
  api.delete(CREDITS_ENDPOINT + creditId + ACTIONS_ENDPOINT + actionId);
