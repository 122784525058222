import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { updateInstallationsStatus } from "@/features/installations/api/installationApi";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const InstallationInformationCard = ({ installation }) => {
  const installationPolicy = useInstallationPolicy();
  const contractPolicy = useContractPolicy();
  const userPolicy = useUserPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(InstallationStatusContext);
  const installationContract = installation.contract;

  const { id: installationId } = useParams();
  const queryClient = useQueryClient();

  const handleStatusClick = (value) => {
    if (value) {
      const data = { installation_status_id: value };
      updateInstallationStatusMutation.mutate({ installationId, data });
    }
  };

  const updateInstallationStatusMutation = useMutation({
    mutationFn: updateInstallationsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installations"] });
      queryClient.invalidateQueries({ queryKey: ["installation", installationId] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      }
    },
  });

  return (
    <div className="flex-1 flex gap-5 flex-col">
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Informacje")}</CardTitle>
          <CardDescription>{i18n.t("Informacje o montażu")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa")}</p>
            <p className="text-right">{installation.name}</p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Typ")}</p>
            <ColorBadge color={installation.type.color}>{installation.type.name}</ColorBadge>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Termin montażu")}</p>
            <p>
              {installation.date
                ? format(parse(installation.date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
                : i18n.t("Nie ustalono")}
            </p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia montażu")}</p>
            <p title={installation.created_at}>
              {format(parse(installation.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
            </p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Status")}</p>
            {installationPolicy.updateStatus() || installation.amount_of_processes === 0 ? (
              <Select
                className="w-fit"
                style={{ backgroundColor: installation.status.color }}
                options={statusOptions}
                setValue={handleStatusClick}
                value={installation.status.id}
                isLoading={isLoadingStatuses || updateInstallationStatusMutation.isPending}
              />
            ) : (
              <ColorBadge color={installation.status.color}>{installation.status.name}</ColorBadge>
            )}
          </CardRow>
          {userPolicy.viewAny() && (
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
              <UserTooltip user={installation.user} />
            </CardRow>
          )}
          {installation.description && (
            <CardRow className="flex lg:flex-col lg:items-start gap-1">
              <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
              <p className="text-muted-foreground">{installation.description}</p>
            </CardRow>
          )}
        </CardContent>
      </Card>
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Umowa")}</CardTitle>
          <CardDescription>{i18n.t("Informacje o umowie")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Umowa")}</p>
            {contractPolicy.view() ? (
              <Link to={`${routes.contracts}/${installationContract.id}`}>
                <Button variant="link" size="xs">
                  {installationContract.identifier}
                </Button>
              </Link>
            ) : (
              <p className="text-right">{installationContract.identifier}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko klienta")}</p>
            <p className="text-right">{installationContract.name}</p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu klienta")}</p>
            {installationContract.phone_number ? (
              <a className="font-semibold" href={`tel:${installationContract.phone_number}`}>
                {formatPhoneNumber(installationContract.phone_number)}
              </a>
            ) : (
              <p className="text-right">{i18n.t("none")}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Email klienta")}</p>
            {installationContract.email ? (
              <a className="font-semibold" href={`mailto:${installationContract.email}`}>
                {installationContract.email}
              </a>
            ) : (
              <p className="text-right">{i18n.t("none")}</p>
            )}
          </CardRow>
          <AddressCardRow model={installation?.contract} modelName="Client" />
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data podpisania umowy")}</p>
            <p className="text-right">
              {format(parse(installationContract.signed_at, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}
            </p>
          </CardRow>
          {installationContract.calculation && (
            <>
              <CardRow>
                <p className="text-xs sm:text-base font-medium">{i18n.t("Kalkulacja")}</p>
                <Link
                  to={`${routes.contracts}/${installationContract.id}/kalkulacja`}
                  state={{ contract: installationContract }}
                >
                  <Button variant="link" size="xs">
                    {installationContract.calculation.name}
                  </Button>
                </Link>
              </CardRow>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
