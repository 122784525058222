import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useTabs = (initialTab = "table") => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return { activeTab, setActiveTab };
};
