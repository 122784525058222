import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateCustomField } from "@/features/customFields/api/customFieldApi";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  required: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const CustomFieldEditDialog = ({ customField, trigger }) => {
  const queryClient = useQueryClient();
  const customFieldPolicy = useCustomFieldPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    updateCustomFieldMutation.mutate({ customFieldId: customField.id, data });
  };

  const defaultValues = {
    required: !!customField.required,
    name: customField.name,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateCustomFieldMutation = useMutation({
    mutationFn: updateCustomField,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["customFields"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pole dodatkowe zostało dodane" /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!customFieldPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj pole dodatkowe")}</DialogTitle>
          <DialogDescription>{i18n.t("Nowe pole które będzie widoczne na rekordzie.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Nazwa" placeholder="Nazwa pola" name="name" autoComplete="off" />
            <FormCheckbox
              label="Pole jest wymagane"
              name="required"
              description="Jeśli pole będzie oznaczone jako wymagane będzie trzeba je podać podczas tworzenia rekordu"
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateCustomFieldMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
