import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormMultipleSelect } from "@/components/forms/FormMultipleSelect";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { PermissionEnum } from "@/features/auth/enums/PermissionEnum";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { getBillingSettings } from "@/features/billings/api/billingApi";
import { useBillingSettingPolicy } from "@/features/billings/policies/useBillingSettingPolicy";
import { getRoles } from "@/features/roles/api/roleApi";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import { getUserLevels, updateUser } from "@/features/users/api/userApi";
import { useUserLevelPolicy } from "@/features/users/policies/useUserLevelPolicy";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

export const UserEditForm = ({ user }) => {
  const { hasPermission } = useAuth();
  const billingSettingPolicy = useBillingSettingPolicy();
  const userLevelPolicy = useUserLevelPolicy();
  const rolePolicy = useRolePolicy();
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [billingSettingOptions, setBillingSettingOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const validationSchema = Yup.object({
    roles: Yup.array()
      .of(Yup.string().required(i18n.t("Pole jest wymagane")))
      .required(i18n.t("Pole jest wymagane")),
    level_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    billing_setting_id: Yup.string().optional().nullable(),
  });

  const defaultValues = {
    roles: user.roles?.map((role) => role.name) ?? [],
    level_id: user.level ? user.level.id : "null",
    billing_setting_id: user.billingSetting ? user.billingSetting.id : "null",
  };

  const onSubmit = (data) => {
    editUserMutation.mutate({ id: userId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const editUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["user", userId] });
      if (res.ok) {
        return navigate(`${routes.users}/${user.id}`);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading: isLoadingLevels,
    data: levels,
    error: errorLevels,
  } = useQuery({
    queryKey: ["userLevels"],
    queryFn: getUserLevels,
    staleTime: 1000 * 60 * 5,
    enabled: userLevelPolicy.create(),
  });

  const {
    isLoading: isLoadingBillingSettings,
    data: billingSettings,
    error: errorBillingSettings,
  } = useQuery({
    queryKey: ["billingSettings"],
    queryFn: getBillingSettings,
    staleTime: 1000 * 60 * 5,
    enabled: billingSettingPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingRoles,
    data: roles,
    error: errorRoles,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
    staleTime: 1000 * 60 * 5,
    enabled: rolePolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingBillingSettings && !errorBillingSettings && billingSettings) {
      setBillingSettingOptions(billingSettings.data.map((setting) => ({ name: setting.name, value: setting.id })));
    }
  }, [isLoadingBillingSettings, billingSettings, errorBillingSettings]);

  useEffect(() => {
    if (!isLoadingLevels && !errorLevels && levels) {
      setLevelOptions(levels.data.map((level) => ({ name: level.name, value: level.id })));
    }
  }, [isLoadingLevels, levels, errorLevels]);

  useEffect(() => {
    if (!isLoadingRoles && !errorRoles && roles) {
      setRoleOptions(roles.data.map((role) => ({ name: role.name, value: role.name })));
    }
  }, [isLoadingRoles, roles, errorRoles]);

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            {hasPermission(PermissionEnum.ACCESS_ALL_USERS) && (
              <FormMultipleSelect isLoading={isLoadingRoles} name="roles" label="Rola" options={roleOptions} />
            )}
            {userLevelPolicy.create() && (
              <FormSelect
                name="level_id"
                label="Poziom"
                options={appendEmptyOption(levelOptions)}
                isLoading={isLoadingLevels}
              />
            )}
            {billingSettingPolicy.viewAny() && (
              <FormCombobox
                name="billing_setting_id"
                label="Model rozliczeń"
                options={appendEmptyOption(billingSettingOptions)}
                isLoading={isLoadingBillingSettings}
              />
            )}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button type="submit" title="Zapisz" leftIcon={<Check size={20} />} isLoading={editUserMutation.isPending} />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(-1)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
