import { useAuth } from "@/features/auth/hooks/useAuth";
import { createModelView } from "@/features/modelViews/api/modelViewApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export const useTrackModelView = (modelType, model, modelUser) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const createModelViewMutation = useMutation({
    mutationFn: createModelView,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: [modelType.toLowerCase(), model.id] });
      }
    },
  });

  useEffect(() => {
    if (user && model && modelUser && modelType) {
      if (user.id === modelUser.id && model.viewed_at === null) {
        createModelViewMutation.mutate({ model_id: model.id, model_type: modelType });
      }
    }
  }, [model, modelUser, user, modelType]);
};
