import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { ButtonContext } from "@/context/ButtonContext";
import { FileFilters } from "@/features/files/components/FileFilters";
import { deleteOrderFile, getOrderFiles } from "@/features/orders/api/orderApi";
import { useOrderFilesTableColumns } from "@/features/orders/hooks/useOrderFilesTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const OrderFilesTable = () => {
  const { id: orderId } = useParams();
  const queryClient = useQueryClient();
  const { showButton, hideButton } = useContext(ButtonContext);

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "original_name",
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["order", orderId, "files", pagination, sorting, columnFilters],
    queryFn: () => getOrderFiles({ orderId, filters: { pagination, sorting, columnFilters } }),
  });

  const deleteOrderFileMutation = useMutation({
    mutationFn: deleteOrderFile,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["order", orderId], exact: true });
      queryClient.invalidateQueries({ queryKey: ["order", orderId, "files"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas usuwania." /> });
      }
    },
  });

  const handleDelete = async (fileId) => {
    deleteOrderFileMutation.mutate({ orderId, fileId });
  };

  const { columns } = useOrderFilesTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  useEffect(() => {
    showButton("addOrderFiles");

    return () => {
      hideButton("addOrderFiles");
    };
  }, []);

  return (
    <Table table={table} Filters={FileFilters} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} />
  );
};
