import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { downloadFile } from "@/features/files/api/fileApi";
import { saveAs } from "file-saver";

export const downloadFileHelper = async ({ id, original_name }) => {
  const response = await downloadFile(id);
  if (response.ok) {
    saveAs(response.data, original_name);
    toast({ title: <SuccessToast title="Plik został pobrany" /> });
  } else {
    toast({ title: <ErrorToast title="Błąd podczas pobierania pliku" /> });
  }
};
