export const Settings = Object.freeze({
  CONTRACT_POINTS_STATUS: "contract_points_status",
  CONTRACT_ORDER_STATUS: "contract_order_status",

  APPLICATION_CONTRACT_FILE_CATEGORIES: "application_contract_file_categories",
  INSTALLATION_CONTRACT_FILE_CATEGORIES: "installation_contract_file_categories",
  AUDIT_LEAD_FILE_CATEGORIES: "audit_lead_file_categories",
  CREDIT_LEAD_FILE_CATEGORIES: "credit_lead_file_categories",

  USER_DEFAULT_ROLE: "user_default_role",
  USER_POINTS_CASCADE: "user_points_cascade",
  USER_POINTS_RESET_DAY: "user_points_reset_day",
});
