export const Role = Object.freeze({
  SUPERADMIN: "Superadmin",
  ADMIN: "Administrator",
  MANAGER: "Manager",
  SALES: "Sprzedawca",
  OFFICE: "Biuro",

  cases() {
    return [this.SUPERADMIN, this.ADMIN, this.OFFICE, this.SALES, this.MANAGER];
  },

  displayRoles(user) {
    if (!user.roles || user.roles?.length === 0) return "";
    return this.getRoleNames(user)
      .filter((role) => role !== this.SUPERADMIN)
      .join(", ");
  },

  getRoleNames(user) {
    return user.roles.map((role) => role.name);
  },
});
