import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CalculationSurvey } from "@/features/calculations/components/Surveys/CalculationSurvey";
import { useTabs } from "@/hooks/useTabs";

export const CalculationSurveys = ({ surveys, calculation }) => {
  const { activeTab, setActiveTab } = useTabs(surveys?.length > 0 ? surveys[0]?.id : 0);

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-5">
        {surveys?.map((survey) => (
          <TabsTrigger key={survey.id} value={survey.id}>
            {survey.name}
          </TabsTrigger>
        ))}
      </TabsList>
      {surveys?.map((survey) => (
        <TabsContent key={survey.id} value={survey.id}>
          <CalculationSurvey survey={survey} calculation={calculation} />
        </TabsContent>
      ))}
    </Tabs>
  );
};
