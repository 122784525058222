import { useAuth } from "@/features/auth/hooks/useAuth";
import { getFileCategories } from "@/features/files/api/fileApi";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const FileCategoryContext = createContext();

export function FileCategoryProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const { user } = useAuth();

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["fileCategories"],
    queryFn: getFileCategories,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setCategories(response.data);
      setCategoryOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <FileCategoryContext.Provider value={{ categories, isLoading, categoryOptions }}>
      {children}
    </FileCategoryContext.Provider>
  );
}
