import api from "@/api/api";
import { ROLES_ENDPOINT } from "@/api/endpoints";

export const getRoles = () => api.get(ROLES_ENDPOINT);

export const createRole = (data) => api.post(ROLES_ENDPOINT, data);

export const getRole = (roleId) => api.get(ROLES_ENDPOINT + roleId);

export const updateRole = ({ roleId, data }) => api.patch(ROLES_ENDPOINT + roleId, data);

export const deleteRole = (roleId) => api.delete(ROLES_ENDPOINT + roleId);
