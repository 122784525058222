import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { LeadActionsCard } from "@/features/leads/components/Actions/LeadActionsCard";
import { LeadFilesTable } from "@/features/leads/components/Files/LeadFilesTable";
import { LeadCalculationsTable } from "@/features/leads/components/LeadCalculationsTable";
import { LeadContractsTable } from "@/features/leads/components/LeadContractsTable";
import { LeadInformationCard } from "@/features/leads/components/LeadInformationCard";
import { LeadNotesCard } from "@/features/leads/components/LeadNotesCard";
import { LeadProcessesCard } from "@/features/leads/components/LeadProcessesCard";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import { useLeadFilePolicy } from "@/features/leads/policies/useLeadFilePolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const LeadDetails = ({ lead }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const contractPolicy = useContractPolicy();
  const leadFilePolicy = useLeadFilePolicy();
  const leadCalculationPolicy = useLeadCalculationPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {(leadCalculationPolicy.viewAny() || leadFilePolicy.viewAny() || contractPolicy.viewAny()) && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {leadFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
          {leadCalculationPolicy.viewAny() && <TabsTrigger value="calculations">{i18n.t("Kalkulacje")}</TabsTrigger>}
          {contractPolicy.viewAny() && <TabsTrigger value="contracts">{i18n.t("Umowy")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <LeadInformationCard lead={lead} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <LeadActionsCard lead={lead} />
            <LeadNotesCard />
          </div>
          <LeadProcessesCard />
        </div>
      </TabsContent>
      {leadFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <LeadFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
      {leadCalculationPolicy.viewAny() && (
        <TabsContent value="calculations">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <LeadCalculationsTable key="calculations_list" />
          </div>
        </TabsContent>
      )}
      {contractPolicy.viewAny() && (
        <TabsContent value="contracts">
          <div className="flex flex-col gap-5">
            <LeadContractsTable lead={lead} />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
