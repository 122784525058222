import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ButtonContext } from "@/context/ButtonContext";
import { deleteApplication, getApplication } from "@/features/applications/api/applicationApi";
import { ApplicationDetails } from "@/features/applications/components/ApplicationDetails";
import { ApplicationEditDialog } from "@/features/applications/components/ApplicationEditDialog";
import { ApplicationFileCreateDialog } from "@/features/applications/components/Files/ApplicationFileCreateDialog";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useTrackModelView } from "@/features/modelViews/hooks/useTrackModelView";
import { TaskCreateDialog } from "@/features/tasks/components/TaskCreateDialog";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ApplicationDetailsPage = () => {
  const { id: applicationId } = useParams();
  const navigate = useNavigate();
  const { buttons } = useContext(ButtonContext);
  const applicationPolicy = useApplicationPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["application", applicationId],
    queryFn: () => getApplication(applicationId),
  });
  useTrackModelView("Application", response?.data, response?.data?.user);
  useDocumentTitle(response?.data ? `Wniosek ${response?.data?.lead?.name}` : "Wniosek");

  const deleteApplicationMutation = useMutation({
    mutationFn: deleteApplication,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      if (res.ok) {
        return navigate(routes.applications);
      }
    },
  });

  const handleDelete = () => {
    deleteApplicationMutation.mutate(applicationId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.lead?.name !== " " ? response?.data?.lead?.name : "Brak"} />
        <div
          id="application_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          {buttons["addApplicationFiles"] && <ApplicationFileCreateDialog />}
          <TaskCreateDialog
            taskableId={applicationId}
            taskableType="Application"
            withUser={true}
            buttonVariant="outline"
          />
          <ApplicationEditDialog
            application={response?.data}
            trigger={<Button variant="outline" title="Edytuj wniosek" leftIcon={<Pencil size={20} />} />}
          />
          {applicationPolicy.destroy() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń wniosek"
                  isLoading={deleteApplicationMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten wniosek? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <ApplicationDetails application={response?.data} />
    </Layout>
  );
};
