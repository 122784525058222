import logoDark from "@/assets/logo/logo_dark.png";
import logoLight from "@/assets/logo/logo_light.png";
import { useTheme } from "@/hooks/useTheme";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

export const SiteLogo = ({ className, linkTo = null }) => {
  const { theme } = useTheme();

  return linkTo ? (
    <Link to={linkTo}>
      <img src={theme === "dark" ? logoDark : logoLight} className={cn("cursor-pointer", className)} alt="logo" />
    </Link>
  ) : (
    <img src={theme === "dark" ? logoDark : logoLight} className={className} alt="logo" />
  );
};
