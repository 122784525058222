import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormRangesField } from "@/components/forms/FormRangesField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { createCalculatorDiscount } from "@/features/calculators/api/calculatorApi";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  range_conditions: Yup.array()
    .of(
      Yup.object({
        from: Yup.number().required(i18n.t("Wartość początkowa zakresu jest wymagana")),
        to: Yup.number().required(i18n.t("Wartość końcowa zakresu jest wymagana")),
        value: Yup.number().required(i18n.t("Wartość dla zakresu jest wymagana")),
      }),
    )
    .min(1, i18n.t("Wymagane jest dodanie co najmniej jednego zakresu"))
    .required(i18n.t("Pole jest wymagane")),
  field: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const CalculatorDiscountCreateDialog = () => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();
  const calculatorPolicy = useCalculatorPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const createCalculatorDiscountMutation = useMutation({
    mutationFn: createCalculatorDiscount,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      if (res.ok) {
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const onSubmit = (values) => {
    let data = {
      range_conditions: {
        field: values.field,
        ranges: values.range_conditions.map((range) => ({
          from: range.from,
          to: range.to,
          amount: range.value,
        })),
      },
      name: values.name,
    };
    createCalculatorDiscountMutation.mutate({ calculatorId, data });
  };

  const defaultValues = {
    name: undefined,
    range_conditions: [],
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  if (!calculatorPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj rabat" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj rabat")}</DialogTitle>
          <DialogDescription>
            {i18n.t("Rabaty służą do pomniejszania ceny końcowej dla klienta w zależności od jakiegoś z pól.")}
          </DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <ScrollArea className="h-[40vh] pr-4">
            <div className="flex flex-col gap-4 mx-1">
              <FormField label="Nazwa" placeholder="Nazwa rabatu" name="name" autoComplete="off" />
              <FormField label="Jeśli" placeholder="identyfikator_pola" name="field" autoComplete="off" />
              <FormRangesField name="range_conditions" label="Zakresy i wartości" actionTitle="Odlicz" />
              <DialogFooter className="mt-5">
                <Button
                  type="submit"
                  title="Dodaj"
                  leftIcon={<Plus size={20} />}
                  isLoading={createCalculatorDiscountMutation.isPending}
                />
                <Button
                  type="button"
                  title="Anuluj"
                  leftIcon={<X size={20} />}
                  variant="destructive"
                  onClick={() => setIsOpen(false)}
                />
              </DialogFooter>
            </div>
          </ScrollArea>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
