export const TaskDueDateUnit = Object.freeze({
  day: {
    value: "day",
    name: "Dni",
  },
  week: {
    value: "week",
    name: "Tygodni",
  },
  month: {
    value: "month",
    name: "Miesięcy",
  },
  year: {
    value: "year",
    name: "Lat",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
