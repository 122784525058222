export const AutomationLogStatus = Object.freeze({
  FAILED: {
    value: "FAILED",
    name: "Niewykonana",
    color: "bg-destructive",
  },
  SUCCESS: {
    value: "SUCCESS",
    name: "Wykonana",
    color: "bg-green-500",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },
});
