import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteProductBundle, getProductBundles } from "@/features/products/api/productApi";
import { ProductBundleCreateDialog } from "@/features/products/components/Bundles/ProductBundleCreateDialog";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export const ProductBundledProductsCard = ({ product }) => {
  const { id: productId } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, data: productBundles } = useQuery({
    queryKey: ["product", productId, "bundles"],
    queryFn: () => getProductBundles(productId),
    staleTime: 1000 * 60 * 3,
  });

  const deleteProductBundleMutation = useMutation({
    mutationFn: deleteProductBundle,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["product", productId], exact: true });
      queryClient.invalidateQueries({ queryKey: ["product", productId, "bundles"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = (productBundleId) => {
    deleteProductBundleMutation.mutate({ productId, productBundleId });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card className="flex-1 shadow-md h-fit">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5 w-2/3">
          <CardTitle>{i18n.t("Produkty w zestawie")}</CardTitle>
          <CardDescription>
            {i18n.t("Produkty które są doliczane w kalkulacji po wybraniu tego produktu")}
          </CardDescription>
        </div>
        <ProductBundleCreateDialog product={product} />
      </CardHeader>
      <CardContent>
        {productBundles?.data?.length === 0 && <EmptyState title="Brak produktów w zestawie" />}
        <div className="flex flex-col gap-3">
          {productBundles?.data?.map((productBundle) => {
            return (
              <div key={productBundle.id} className="flex flex-row justify-between items-center">
                <Link to={`${routes.products}/${productBundle.bundledProduct.id}`} className="font-medium">
                  <Button
                    variant="link"
                    className="h-fit max-w-[500px] truncate p-0"
                    title={`${productBundle.quantity} x ${productBundle.bundledProduct.name} (${productBundle.bundledProduct.sku})`}
                  />
                </Link>
                <DeleteAlertDialog
                  trigger={
                    <Button
                      variant="link"
                      className="text-destructive p-0"
                      title="Usuń"
                      leftIcon={<Trash size={16} />}
                    />
                  }
                  message="Czy na pewno chcesz usunąć ten product z zestawu?"
                  onConfirm={() => handleDelete(productBundle.id)}
                />
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};
