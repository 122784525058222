import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { getLead } from "@/features/leads/api/leadApi";
import { LeadEditForm } from "@/features/leads/components/LeadEditForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const LeadEditPage = () => {
  const { id: leadId } = useParams();

  const { isLoading, data: response } = useQuery({
    queryKey: ["lead", leadId],
    queryFn: () => getLead(leadId),
  });
  useDocumentTitle(response?.data ? `Edycja kontaktu ${response?.data?.name}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs idAlias={response?.data?.name} />
      </div>
      <LeadEditForm lead={response?.data} />
    </Layout>
  );
};
