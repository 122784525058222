import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ContractTemplateCalculationInformationDialog } from "@/features/contracts/components/Settings/Templates/ContractTemplateCalculationInformationDialog";
import { useContractTemplatePolicy } from "@/features/contracts/policies/useContractTemplatePolicy";
import i18n from "@/i18n";
import { ArrowDown, ArrowUp, ArrowUpDown, Download, MoreHorizontal, Trash } from "lucide-react";

export const useContractTemplatesTableColumns = (handleDownload, handleDelete) => {
  const contractTemplatePolicy = useContractTemplatePolicy();
  const columns = [
    {
      id: "calculator",
      name: "Kalkulator",
      size: 10,
      accessorKey: "calculator",
      header: i18n.t("Kalkulator"),
      cell: ({ row }) => {
        const contractTemplate = row.original;
        return (
          <span className="flex flex-row items-center gap-1">
            <ContractTemplateCalculationInformationDialog calculator={contractTemplate.calculator} />
            {contractTemplate.calculator.name}
          </span>
        );
      },
    },
    {
      id: "template",
      name: "Szablon",
      accessorKey: "template",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Szablon")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contractTemplate = row.original;

        return (
          <a
            className="font-bold flex flex-row gap-1 items-center cursor-pointer hover:underline"
            onClick={() => handleDownload(contractTemplate)}
          >
            <Download className="text-primary" size={20} />
            {contractTemplate.filename}
          </a>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const contractTemplate = row.original;
        if (!contractTemplatePolicy.destroy()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {contractTemplatePolicy.destroy() && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(contractTemplate.id)}
                  message="Czy na pewno chcesz usunąć ten szablon? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
