import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateCalculation } from "@/features/calculations/api/calculationApi";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const CalculationEditDialog = ({ trigger, calculation }) => {
  const queryClient = useQueryClient();
  const leadCalculationPolicy = useLeadCalculationPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    net_price: Yup.string().required(i18n.t("Pole jest wymagane")),
    margin: Yup.string().required(i18n.t("Pole jest wymagane")),
    comission: Yup.string().required(i18n.t("Pole jest wymagane")),
  });

  const onSubmit = (data) => {
    updateCalculationMutation.mutate({ calculationId: calculation.id, data });
  };

  const defaultValues = {
    net_price: calculation.net_price - calculation.margin,
    margin: calculation.margin,
    comission: calculation.comission,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateCalculationMutation = useMutation({
    mutationFn: updateCalculation,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["lead", calculation?.lead?.id, "calculations"] });
      queryClient.invalidateQueries({ queryKey: ["calculation", calculation.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!leadCalculationPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja kalkulacji")}</DialogTitle>
          <DialogDescription>{calculation?.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormNumberField name="net_price" label="Wartość netto" positiveOnly={true} />
            <FormNumberField name="margin" label="Marża" positiveOnly={true} />
            <FormNumberField name="comission" label="Prowizja" positiveOnly={true} />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateCalculationMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
