import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { AuditInformationCard } from "@/features/audits/components/AuditInformationCard";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { ClientActionsCard } from "@/features/clients/components/Actions/ClientActionsCard";
import { ClientApplicationsTab } from "@/features/clients/components/ClientApplicationsTab";
import { ClientCalculationsTable } from "@/features/clients/components/ClientCalculationsTable";
import { ClientContractsCard } from "@/features/clients/components/ClientContractsCard";
import { ClientInformationCard } from "@/features/clients/components/ClientInformationCard";
import { ClientInstallationServicesCard } from "@/features/clients/components/ClientInstallationServicesCard";
import { ClientInstallationsTab } from "@/features/clients/components/ClientInstallationsTab";
import { ClientNotesCard } from "@/features/clients/components/ClientNotesCard";
import { ClientSurveyEntriesTab } from "@/features/clients/components/ClientSurveyEntriesTab";
import { ClientFilesTable } from "@/features/clients/components/Files/ClientFilesTable";
import { CreditInformationCard } from "@/features/credits/components/CreditInformationCard";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import { useLeadFilePolicy } from "@/features/leads/policies/useLeadFilePolicy";
import { useSurveyEntryPolicy } from "@/features/surveys/policies/useSurveyEntryPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const ClientDetails = ({ client }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const clientFilePolicy = useLeadFilePolicy();
  const clientCalculationPolicy = useLeadCalculationPolicy();
  const auditPolicy = useAuditPolicy();
  const applicationPolicy = useApplicationPolicy();
  const installationPolicy = useInstallationPolicy();
  const surveyEntryPolicy = useSurveyEntryPolicy();
  const creditPolicy = useCreditPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {(clientCalculationPolicy.viewAny() || clientFilePolicy.viewAny()) && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {clientFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
          {clientCalculationPolicy.viewAny() && <TabsTrigger value="calculations">{i18n.t("Kalkulacje")}</TabsTrigger>}
          {auditPolicy.view() && client?.audit && <TabsTrigger value="audit">{i18n.t("Audyt")}</TabsTrigger>}
          {creditPolicy.view() && client?.credit && <TabsTrigger value="credit">{i18n.t("Kredyt")}</TabsTrigger>}
          {installationPolicy.viewAny() && <TabsTrigger value="installations">{i18n.t("Montaże")}</TabsTrigger>}
          {applicationPolicy.viewAny() && <TabsTrigger value="applications">{i18n.t("Wnioski")}</TabsTrigger>}
          {surveyEntryPolicy.viewAny() && <TabsTrigger value="surveys">{i18n.t("Ankiety")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <ClientInformationCard client={client} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <ClientActionsCard client={client} />
            <ClientNotesCard />
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <ClientContractsCard client={client} contracts={client.contracts} />
            <ClientInstallationServicesCard installationServices={client.installationServices} />
          </div>
        </div>
      </TabsContent>
      {clientFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
      {clientCalculationPolicy.viewAny() && (
        <TabsContent value="calculations">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientCalculationsTable key="calculations_list" />
          </div>
        </TabsContent>
      )}
      {auditPolicy.view() && client.audit && (
        <TabsContent value="audit">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditInformationCard audit={client.audit} client={client} />
            <div className="flex-1"></div>
          </div>
        </TabsContent>
      )}
      {creditPolicy.view() && client.credit && (
        <TabsContent value="credit">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <CreditInformationCard credit={client.credit} client={client} />
            <div className="flex-1"></div>
          </div>
        </TabsContent>
      )}
      {installationPolicy.viewAny() && (
        <TabsContent value="installations">
          <ClientInstallationsTab />
        </TabsContent>
      )}
      {applicationPolicy.viewAny() && (
        <TabsContent value="applications">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientApplicationsTab />
          </div>
        </TabsContent>
      )}
      {surveyEntryPolicy.viewAny() && (
        <TabsContent value="surveys">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientSurveyEntriesTab />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
