import api from "@/api/api";
import {
  BILLINGS_ENDPOINT,
  CHANGES_ENDPOINT,
  CONTRACT_FILE_CATEGORIES_ENDPOINT,
  CONTRACT_TEMPLATES_ENDPOINT,
  CONTRACTS_ENDPOINT,
  CONTRACTS_PRODUCTS_ENDPOINT,
  CONTRACTS_STATUSES_ENDPOINT,
  CONTRACTS_TYPES_ENDPOINT,
  FILES_ENDPOINT,
  MESSAGES_ENDPOINT,
  NOTES_ENDPOINT,
  PAYMENTS_ENDPOINT,
  PROCESSES_ENDPOINT,
  TASKS_ENDPOINT,
  USERS_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDate } from "@/helpers/formatDate";

export const getContracts = (filters = null) => api.get(CONTRACTS_ENDPOINT, transformQueryFilters(filters));

export const getContract = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId);

export const createContract = ({ data: contract }) => {
  const data = {
    ...contract,
    signed_at: contract?.signed_at && formatDate(contract.signed_at),
  };
  return api.post(CONTRACTS_ENDPOINT, data);
};

export const getContractFiles = ({ contractId, filters }) =>
  api.get(CONTRACTS_ENDPOINT + contractId + "/files", transformQueryFilters(filters));

export const createContractFiles = ({ contractId, data }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteContractFile = ({ contractId, fileId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + FILES_ENDPOINT + fileId);

export const updateContract = ({ contractId, data: contract }) => {
  const data = {
    ...contract,
    signed_at: contract?.signed_at && formatDate(contract.signed_at),
  };
  return api.patch(CONTRACTS_ENDPOINT + contractId, data);
};

export const deleteContract = (contractId) => api.delete(CONTRACTS_ENDPOINT + contractId);

export const getContractBillings = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + BILLINGS_ENDPOINT);

export const getContractStatuses = () => api.get(CONTRACTS_STATUSES_ENDPOINT);

export const updateContractStatus = ({ contractStatusId, data }) =>
  api.patch(CONTRACTS_STATUSES_ENDPOINT + contractStatusId, data);

export const deleteContractStatus = ({ contractStatusId }) =>
  api.delete(CONTRACTS_STATUSES_ENDPOINT + contractStatusId);

export const createContractStatus = (data) => api.post(CONTRACTS_STATUSES_ENDPOINT, data);

export const updateContractStatusOrder = (data) => api.put(CONTRACTS_STATUSES_ENDPOINT + "order", data);

export const getContractTypes = () => api.get(CONTRACTS_TYPES_ENDPOINT);

export const createContractType = (data) => api.post(CONTRACTS_TYPES_ENDPOINT, data);

export const updateContractType = ({ contractTypeId, data }) =>
  api.patch(CONTRACTS_TYPES_ENDPOINT + contractTypeId, data);

export const deleteContractType = ({ contractTypeId }) => api.delete(CONTRACTS_TYPES_ENDPOINT + contractTypeId);

export const getContractProducts = () => api.get(CONTRACTS_PRODUCTS_ENDPOINT);

export const updateContractProduct = ({ contractProductId, data }) =>
  api.patch(CONTRACTS_PRODUCTS_ENDPOINT + contractProductId, data);

export const deleteContractProduct = ({ contractProductId }) =>
  api.delete(CONTRACTS_PRODUCTS_ENDPOINT + contractProductId);

export const createContractProduct = (data) => api.post(CONTRACTS_ENDPOINT + "products", data);

export const updateContractsStatus = ({ contractId, data }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + "/status", data);

export const getContractNotes = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT);

export const createContractNote = ({ contractId, note }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT, { note });

export const updateContractNote = ({ contractId, noteId, note }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT + noteId, note);

export const deleteContractNote = ({ contractId, noteId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT + noteId);

export const getContractTasks = ({ contractId, filters }) =>
  api.get(CONTRACTS_ENDPOINT + contractId + TASKS_ENDPOINT, transformQueryFilters(filters));

export const getContractPayments = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT);

export const createContractPayment = ({ contractId, contractPayment }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT, {
    ...contractPayment,
    date: contractPayment.date ? formatDate(contractPayment.date) : undefined,
  });

export const updateContractPayment = ({ contractId, contractPaymentId, contractPayment }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT + contractPaymentId, {
    ...contractPayment,
    date: contractPayment.date ? formatDate(contractPayment.date) : undefined,
  });

export const deleteContractPayment = ({ contractId, contractPaymentId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT + contractPaymentId);

export const getContractChanges = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + CHANGES_ENDPOINT);

export const createContractPaymentFiles = ({ contractId, contractPaymentId, data }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT + contractPaymentId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getContractCalculation = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + "/calculation");

export const getContractProcesses = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + PROCESSES_ENDPOINT);

export const updateContractProcessProgress = ({ contractId, processId, data }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + PROCESSES_ENDPOINT + processId, data);

export const getContractMessages = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT);

export const createContractMessage = ({ contractId, content }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT, { content });

export const updateContractMessage = ({ contractId, contractMessageId, content }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT + contractMessageId, { content });

export const deleteContractMessage = ({ contractId, contractMessageId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT + contractMessageId);

export const getContractUsers = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + USERS_ENDPOINT);

export const createContractUser = ({ contractId, data }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + USERS_ENDPOINT, data);

export const deleteContractUser = ({ contractId, userId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + USERS_ENDPOINT + userId);

export const getContractsStats = (filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(CONTRACTS_ENDPOINT + "stats", queryFilters);
};

export const getContractFileCategories = (filters = null) =>
  api.get(CONTRACT_FILE_CATEGORIES_ENDPOINT, transformQueryFilters(filters));

export const createContractFileCategory = ({ data }) => {
  return api.post(CONTRACT_FILE_CATEGORIES_ENDPOINT, data);
};

export const deleteContractFileCategory = ({ contractFileCategoryId }) =>
  api.delete(CONTRACT_FILE_CATEGORIES_ENDPOINT + contractFileCategoryId);

export const getContractTemplates = () => api.get(CONTRACT_TEMPLATES_ENDPOINT);

export const createContractTemplate = (data) =>
  api.post(CONTRACT_TEMPLATES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteContractTemplate = ({ contractTemplateId }) =>
  api.delete(CONTRACT_TEMPLATES_ENDPOINT + contractTemplateId);

export const downloadContractTemplate = (contractTemplateId) =>
  api.get(CONTRACT_TEMPLATES_ENDPOINT + contractTemplateId + "/download", {}, { responseType: "blob" });
