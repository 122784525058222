import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { getLeadProcesses, updateLeadProcessProgress } from "@/features/leads/api/leadApi";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { ProcessesCard } from "@/features/processes/components/ProcessCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const LeadProcessesCard = () => {
  const { id: leadId } = useParams();
  const leadPolicy = useLeadPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["lead", leadId, "processes"],
    queryFn: () => getLeadProcesses(leadId),
    enabled: leadPolicy.viewProcesses(),
  });

  const updateLeadProcessProgressMutation = useMutation({
    mutationFn: updateLeadProcessProgress,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["lead", leadId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["lead", leadId, "processes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleUpdateLeadProcess = (processId, step, completed) => {
    updateLeadProcessProgressMutation.mutate({ leadId, processId, data: { step_id: step.id, completed } });
  };

  return (
    <ProcessesCard
      policy={leadPolicy}
      handleCheck={handleUpdateLeadProcess}
      isLoading={isLoading}
      processes={response?.data}
    />
  );
};
