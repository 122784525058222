import { Progress } from "@/components/Progress";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { getUserLevels } from "@/features/users/api/userApi";
import i18n from "@/i18n";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const UserLevelCard = ({ user }) => {
  const [nextLevel, setNextLevel] = useState(null);
  const [maxLevel, setMaxLevel] = useState(null);

  const {
    isLoading,
    data: userLevels,
    error,
  } = useQuery({
    queryKey: ["userLevels"],
    queryFn: getUserLevels,
  });

  useEffect(() => {
    if (!isLoading && !error && userLevels) {
      setMaxLevel(userLevels.data[userLevels.data.length - 1]);
      setNextLevel(userLevels.data.find((level) => level.required_points > user.total_points));
    }
  }, [isLoading, error, userLevels]);

  if (!user.level) return null;

  return (
    <Card className="flex-1 h-fit">
      <CardHeader>
        <CardTitle className="flex flex-row gap-3 items-center">
          {i18n.t("Poziom")}
          <Badge className="py-1 text-sm">{user.level.name}</Badge>
        </CardTitle>
        <CardDescription>{i18n.t("Aktualny poziom użytkownika i jego postęp")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-border overflow-x-auto gap-3 flex flex-col">
        <div className="flex flex-col gap-1 w-full text-sm">
          <div className="flex flex-row gap-3 items-center mb-1">
            <p>{i18n.t("Liczba punktów")}</p>
            <p className="text-xs text-muted-foreground">
              {user.total_points}
              {" / "}
              {nextLevel ? nextLevel?.required_points : maxLevel?.required_points}
            </p>
          </div>
          <Progress
            current={user.total_points}
            total={nextLevel ? nextLevel?.required_points : maxLevel?.required_points}
            formatter={null}
          />
          <div className="flex flex-row gap-3 items-center justify-between">
            <p className="w-fit text-muted-foreground text-xs">{user.level.name}</p>
            {nextLevel && <p className="w-fit text-muted-foreground text-xs">{nextLevel?.name}</p>}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
