import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ButtonContext } from "@/context/ButtonContext";
import { deleteCredit, getCredit } from "@/features/credits/api/creditApi";
import { CreditDetails } from "@/features/credits/components/CreditDetails";
import { CreditEditDialog } from "@/features/credits/components/CreditEditDialog";
import { CreditFileCreateDialog } from "@/features/credits/components/Files/CreditFileCreateDialog";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useTrackModelView } from "@/features/modelViews/hooks/useTrackModelView";
import { TaskCreateDialog } from "@/features/tasks/components/TaskCreateDialog";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const CreditDetailsPage = () => {
  const { id: creditId } = useParams();
  const navigate = useNavigate();
  const { buttons } = useContext(ButtonContext);
  const creditPolicy = useCreditPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["credit", creditId],
    queryFn: () => getCredit(creditId),
  });
  useTrackModelView("Credit", response?.data, response?.data?.user);
  useDocumentTitle(response?.data ? `Kredyt ${response?.data?.lead?.name}` : "Kredyt");

  const deleteCreditMutation = useMutation({
    mutationFn: deleteCredit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["credits"] });
      if (res.ok) {
        return navigate(routes.credits);
      }
    },
  });

  const handleDelete = () => {
    deleteCreditMutation.mutate(creditId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.lead.name !== " " ? response?.data?.lead.name : "Brak"} />
        <div
          id="credit_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          {buttons["addCreditFiles"] && <CreditFileCreateDialog />}
          <TaskCreateDialog taskableId={creditId} taskableType="Credit" withUser={true} buttonVariant="outline" />
          <CreditEditDialog
            credit={response?.data}
            trigger={<Button variant="outline" title="Edytuj kredyt" leftIcon={<Pencil size={20} />} />}
          />
          {creditPolicy.destroy() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń kredyt"
                  isLoading={deleteCreditMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten kredyt? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <CreditDetails credit={response?.data} />
    </Layout>
  );
};
