import api from "@/api/api";
import {
  BUNDLES_ENDPOINT,
  FILES_ENDPOINT,
  METADATA_ENDPOINT,
  PRODUCTS_ENDPOINT,
  PRODUCTS_TYPES_ENDPOINT,
  RELATIONS_ENDPOINT,
  WAREHOUSES_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getProductsPotentialDemand = (filters) =>
  api.get(PRODUCTS_ENDPOINT + "potentialDemand", transformQueryFilters(filters));

export const getProductsActualDemand = (filters) =>
  api.get(PRODUCTS_ENDPOINT + "actualDemand", transformQueryFilters(filters));

export const exportProducts = () => api.get(PRODUCTS_ENDPOINT + "export", {}, { responseType: "blob" });

export const importProducts = ({ data }) =>
  api.post(PRODUCTS_ENDPOINT + "import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getProducts = (filters) => api.get(PRODUCTS_ENDPOINT, transformQueryFilters(filters));

export const getProduct = (productId) => api.get(PRODUCTS_ENDPOINT + productId);

export const createProduct = ({ data }) => api.post(PRODUCTS_ENDPOINT, data);

export const deleteProduct = (productId) => api.delete(PRODUCTS_ENDPOINT + productId);

export const updateProduct = ({ productId, data }) => api.patch(PRODUCTS_ENDPOINT + productId, data);

export const getProductRelations = (productId) => api.get(PRODUCTS_ENDPOINT + productId + RELATIONS_ENDPOINT);

export const createProductRelation = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + RELATIONS_ENDPOINT, data);

export const getProductBundles = (productId) => api.get(PRODUCTS_ENDPOINT + productId + BUNDLES_ENDPOINT);

export const createProductBundle = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + BUNDLES_ENDPOINT, data);

export const deleteProductBundle = ({ productId, productBundleId }) =>
  api.delete(PRODUCTS_ENDPOINT + productId + BUNDLES_ENDPOINT + productBundleId);

export const getProductTypes = () => api.get(PRODUCTS_TYPES_ENDPOINT);

export const updateProductType = ({ productTypeId, data }) => api.patch(PRODUCTS_TYPES_ENDPOINT + productTypeId, data);

export const createProductType = (data) => api.post(PRODUCTS_TYPES_ENDPOINT, data);

export const deleteProductType = ({ productTypeId }) => api.delete(PRODUCTS_TYPES_ENDPOINT + productTypeId);

export const getMetadata = () => api.get(METADATA_ENDPOINT);

export const createMetadata = ({ data }) => api.post(METADATA_ENDPOINT, data);

export const createProductMetadata = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + METADATA_ENDPOINT, data);

export const updateProductMetadata = ({ productId, metadataId, data }) =>
  api.put(PRODUCTS_ENDPOINT + productId + METADATA_ENDPOINT + metadataId, data);

export const deleteProductMetadata = ({ productId, metadataId }) =>
  api.delete(PRODUCTS_ENDPOINT + productId + METADATA_ENDPOINT + metadataId);

export const getProductFiles = ({ productId, filters }) => api.get(PRODUCTS_ENDPOINT + productId + "/files", filters);

export const createProductFiles = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteProductFile = ({ productId, fileId }) =>
  api.delete(PRODUCTS_ENDPOINT + productId + FILES_ENDPOINT + fileId);

export const getProductWarehouses = ({ productId, filters = {} }) =>
  api.get(PRODUCTS_ENDPOINT + productId + WAREHOUSES_ENDPOINT, transformQueryFilters(filters));
