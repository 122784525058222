import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateOrderItem } from "@/features/orders/api/orderApi";
import { useOrderPolicy } from "@/features/orders/policies/useOrderPolicy";
import { getProductWarehouses } from "@/features/products/api/productApi";
import { ProductsContext } from "@/features/products/context/ProductsContext";
import { useWarehouseProductPolicy } from "@/features/warehouses/policies/useWarehouseProductPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

export const OrderItemEditDialog = ({ item, trigger }) => {
  const { id: orderId } = useParams();
  const queryClient = useQueryClient();
  const orderPolicy = useOrderPolicy();
  const { productOptions } = useContext(ProductsContext);
  const warehouseProductPolicy = useWarehouseProductPolicy();

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    isLoading: isLoadingWarehouses,
    data: warehouses,
    error: errorWarehouses,
  } = useQuery({
    queryKey: ["product", item.product.id, "warehouses"],
    queryFn: () => getProductWarehouses({ productId: item.product.id }),
    enabled: warehouseProductPolicy.viewAny() && isOpen,
  });

  const onSubmit = (data) => {
    updateOrderItemMutation.mutate({ orderId: orderId, itemId: item.id, data });
  };

  const validationSchema = Yup.object({
    product_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    quantity: Yup.number(i18n.t("Pole musi być liczbą"))
      .transform((value, originalValue) => (originalValue === "" ? 0 : value))
      .min(1, i18n.t("Minimalna ilość to 1"))
      .required(i18n.t("Pole jest wymagane"))
      .test("max-quantity", i18n.t("Ilość przekracza dostępną ilość w wybranym magazynie"), function (value) {
        const { warehouse_id } = this.parent;
        if (!warehouse_id) return true;
        const availableQuantity = warehouses?.data?.find((w) => w.id === warehouse_id)?.quantity;

        if (availableQuantity === null) return true;
        else return value <= availableQuantity;
      }),
    warehouse_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  });

  const defaultValues = {
    product_id: item.product.id,
    quantity: item.quantity,
    warehouse_id: item.warehouse?.id ?? undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });

  const updateOrderItemMutation = useMutation({
    mutationFn: updateOrderItem,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["order", orderId, "items"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingWarehouses && !errorWarehouses && warehouses) {
      setWarehouseOptions(
        warehouses.data.map((warehouse) => ({
          name: warehouse.external
            ? `${warehouse.name} (Zewnętrzny)`
            : `${warehouse.name} (${warehouse.quantity} szt. dostępnych)`,
          value: warehouse.id,
        })),
      );
    }
  }, [isLoadingWarehouses, warehouses, errorWarehouses]);

  if (!orderPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja pozycji")}</DialogTitle>
          <DialogDescription>{item.product.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-5 mb-5">
            <FormCombobox name="product_id" label="Produkt" options={productOptions} disabled={true} />
            <FormNumberField name="quantity" label="Ilość" positiveOnly={true} />
            <FormCombobox options={warehouseOptions} name="warehouse_id" label="Magazyn" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateOrderItemMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
