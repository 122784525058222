import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { LeadCreateForm } from "@/features/leads/components/LeadCreateForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const LeadCreatePage = () => {
  useDocumentTitle("Dodawanie kontaktu");
  return (
    <Layout>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      <LeadCreateForm />
    </Layout>
  );
};
