import { useAuth } from "@/features/auth/hooks/useAuth";
import { getProducts } from "@/features/products/api/productApi";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ProductsContext = createContext();

export function ProductsProvider({ children }) {
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productOptionsGrouped, setProductOptionsGrouped] = useState({});

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["products"],
    queryFn: getProducts,
    enabled: user !== null,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setProducts(response.data);
      setProductOptions(
        response.data.map((product) => ({ name: `${product.name} (${product.sku})`, value: product.id })),
      );
      const grouped = response.data.reduce((acc, product) => {
        const groupName = product.type.name;
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push({ name: `${product.name} (${product.sku})`, value: product.id });
        return acc;
      }, {});

      setProductOptionsGrouped(grouped);
    }
  }, [response, isLoading, error]);

  return (
    <ProductsContext.Provider value={{ products, isLoading, productOptions, productOptionsGrouped }}>
      {children}
    </ProductsContext.Provider>
  );
}
