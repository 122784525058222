import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { OrdersTable } from "@/features/orders/components/OrdersTable";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const OrdersPage = () => {
  useDocumentTitle("Zamówienia");

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"></div>
      </div>
      <OrdersTable />
    </Layout>
  );
};
