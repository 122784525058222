import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { downloadCalculationOffer } from "@/features/calculations/api/calculationApi";
import { saveAs } from "file-saver";
import { useState } from "react";

export const useDownloadCalculationOffer = () => {
  const [offerState, setOfferState] = useState("idle");

  const handleDownloadPdf = async (calculation) => {
    setOfferState(() => "loading");
    const response = await downloadCalculationOffer(calculation.id);
    if (response.ok) {
      saveAs(response?.data, calculation.name.replace("Kalkulacja", "Oferta") + ".pdf");
      toast({ title: <SuccessToast title="Oferta została pobrana" /> });
    } else {
      toast({ title: <ErrorToast title="Błąd podczas pobierania oferty." /> });
    }
    setOfferState(() => "idle");
  };

  return { handleDownloadPdf, offerState };
};
