import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { routes } from "@/routes";
import { Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom";

export const InstallationGroupedViewItem = ({ installation }) => {
  const installationPolicy = useInstallationPolicy();
  const contractPolicy = useContractPolicy();
  return (
    <div
      className="flex flex-col gap-3 items-start py-2 pb-5 px-3 border rounded-md"
      style={{ borderColor: installation.status.color + "80" }}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-1/2">
          {installationPolicy.view() ? (
            <Link
              to={`${routes.installations}/${installation.id}`}
              className="flex flex-row items-center gap-3 font-semibold text-base hover:underline"
            >
              {installation.name}
            </Link>
          ) : (
            <span className="font-semibold text-base">{installation.name}</span>
          )}
        </div>
        <div className="w-1/2 flex flex-row justify-end px-1">
          {installation.email && (
            <a href={`mailto:${installation.email}`}>
              <Button size="icon" className="m-0 p-0" variant="ghost" leftIcon={<Mail size={16} />} type="button" />
            </a>
          )}
          {installation.phone_number && (
            <a href={`tel:${installation.phone_number}`}>
              <Button size="icon" className="m-0 p-0" variant="ghost" leftIcon={<Phone size={16} />} type="button" />
            </a>
          )}
        </div>
      </div>
      <ColorBadge color={installation.type.color}>{installation.type.name}</ColorBadge>
      <div className="flex flex-col text-sm text-muted-foreground">
        {contractPolicy.view() ? (
          <Link
            to={`${routes.contracts}/${installation.contract.id}`}
            className="flex flex-row items-center gap-3 font-medium hover:underline"
          >
            {installation.contract.identifier}
          </Link>
        ) : (
          <span>{installation.contract.identifier}</span>
        )}
      </div>
      <div className="flex flex-col text-sm text-muted-foreground">
        <UserAvatarLink user={installation.user} fallback="Nieprzypisany" />
      </div>
    </div>
  );
};
