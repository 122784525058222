import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Label } from "@/components/ui/label";
import { CalculatorSelectField } from "@/features/calculators/components/Form/CalculatorSelectField";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormCalculatorSelectField = ({ name, options, parent, label, className, defaultValue, ...rest }) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getFieldState,
  } = useFormContext();

  const parentValue = watch(parent);
  let disabled = parent && !parentValue;

  useEffect(() => {
    if (parent && getFieldState(parent)?.isDirty) {
      setValue(name, null);
    }
  }, [parentValue]);

  useEffect(() => {
    const value = watch(name);
    if (!value && defaultValue !== undefined) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="grid gap-2">
          <Label htmlFor={name} className={errors[name] && "text-destructive"}>
            {i18n.t(label)}
          </Label>
          <CalculatorSelectField
            id={name}
            name={name}
            setValue={field.onChange}
            options={options}
            disabled={disabled}
            className={cn("truncate", className)}
            {...field}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
