import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";

export const ProductInformationCard = ({ product }) => {
  const productPolicy = useProductPolicy();

  if (!productPolicy.view()) return null;

  return (
    <Card className="flex-1 h-fit">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje na temat produktu")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("SKU")}</p>
          <p className="font-medium">{product.sku}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa produktu")}</p>
          <p className="text-right">{product.name}</p>
        </CardRow>
        {product.display_name && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa na ofercie")}</p>
            <p className="text-right">{product.display_name}</p>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Typ produktu")}</p>
          <p className="text-right">{product.type.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Cena")}</p>
          <p className="text-right">{formatMoney(product.price)}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Produkt widoczny")}</p>
          <p className="text-right">{product.available ? i18n.t("Tak") : i18n.t("Nie")}</p>
        </CardRow>
        {product.total_quantity > 0 && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Aktualna dostępna ilość produktu")}</p>
            <p className="text-right">{product.total_quantity + " szt."}</p>
          </CardRow>
        )}
        {product.warehouses.length > 0 && (
          <CardRow className="sm:items-start">
            <p className="text-xs sm:text-base font-medium">{i18n.t("W magazynach:")}</p>
            <div className="flex flex-col justify-end sm:items-end">
              {product.warehouses.map((warehouse) => (
                <span className="flex gap-1 items-center" key={warehouse.id}>
                  <p className="font-medium">{warehouse.name}</p>
                  {warehouse.quantity === null
                    ? `- ${i18n.t("Magazyn zewnętrzny")}`
                    : `- ${warehouse.quantity} ${i18n.t("szt.")}`}
                </span>
              ))}
            </div>
          </CardRow>
        )}
        {product.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{product.description}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
