import { useAuth } from "@/features/auth/hooks/useAuth";

export const useSettingPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_settings");
  };

  const update = () => {
    return hasPermission("update_setting");
  };

  return {
    viewAny,
    update,
  };
};
