import { Loader } from "@/components/Loader/Loader";
import { config } from "@/config";
import { getInstallations, getInstallationServices } from "@/features/installations/api/installationApi";
import { InstallationPopup } from "@/features/installations/components/Map/InstallationPopup";
import { InstallationServicePopup } from "@/features/installations/components/Map/InstallationServicePopup";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import { getMapPinIconForModel } from "@/helpers/getMapPinIconForModel";
import { useQuery } from "@tanstack/react-query";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const InstallationsServicesMap = () => {
  const installationPolicy = useInstallationPolicy();
  const installationServicePolicy = useInstallationServicePolicy();
  const mapStartingPoint = config("map.startingPoint");
  const mapZoom = config("map.zoom");

  const { isLoading, data: response } = useQuery({
    queryKey: ["installations"],
    queryFn: getInstallations,
    enabled: installationPolicy.viewAny(),
  });

  const { isLoading: isLoadingInstallationServices, data: responseInstallationServices } = useQuery({
    queryKey: ["installationServices"],
    queryFn: getInstallationServices,
    enabled: installationServicePolicy.viewAny(),
  });

  const installations = response?.data ?? [];

  const installationServices = responseInstallationServices?.data ?? [];

  return isLoading || isLoadingInstallationServices ? (
    <Loader />
  ) : (
    <div className="w-full">
      <MapContainer center={mapStartingPoint} zoom={mapZoom}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunkedLoading>
          {installations.map((installation) => {
            if (installation?.contract?.address) {
              const { latitude, longitude } = installation.contract.address;
              if (!latitude || !longitude) return null;
              return (
                <Marker
                  key={installation.id}
                  position={[parseFloat(latitude), parseFloat(longitude)]}
                  icon={getMapPinIconForModel("Installation")}
                >
                  <Popup maxWidth={500}>
                    <InstallationPopup installation={installation} />
                  </Popup>
                </Marker>
              );
            }
          })}
          {installationServices.map((installationService) => {
            if (installationService?.contract?.address) {
              const { latitude, longitude } = installationService.contract.address;
              if (!latitude || !longitude) return null;
              return (
                <Marker
                  key={installationService.id}
                  position={[parseFloat(latitude), parseFloat(longitude)]}
                  icon={getMapPinIconForModel("InstallationService")}
                >
                  <Popup maxWidth={500}>
                    <InstallationServicePopup installationService={installationService} />
                  </Popup>
                </Marker>
              );
            }
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};
