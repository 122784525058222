import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { EmptyState } from "@/components/EmptyState";
import { FullPageLoader } from "@/components/Loader/FullPageLoader";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CardRow } from "@/components/ui/CardRow";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { getClientApplications } from "@/features/clients/api/clientApi";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { ArrowRight } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export const ClientApplicationsTab = () => {
  const { id: clientId } = useParams();
  const applicationPolicy = useApplicationPolicy();
  const userPolicy = useUserPolicy();

  const { isLoading, data: applications } = useQuery({
    queryKey: ["client", clientId, "applications"],
    queryFn: () => getClientApplications(clientId),
  });

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
      {applications?.data?.length === 0 && <EmptyState title="Brak wniosków" />}
      {applications &&
        applications?.data?.map((application) => (
          <Card className="shadow-md" key={application.id}>
            <CardHeader className="flex flex-row justify-between items-center">
              <div className="flex flex-col gap-1.5">
                <CardTitle>{application.type.name}</CardTitle>
                <CardDescription>{i18n.t("Informacje o wniosku")}</CardDescription>
              </div>
              {applicationPolicy.view() && (
                <Link to={`${routes.applications}/${application.id}`}>
                  <Button variant="outline" title="Przejdź do wniosku" rightIcon={<ArrowRight size={16} />} />
                </Link>
              )}
            </CardHeader>
            <CardContent className="divide-y divide-y-border">
              <CardRow>
                <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia wniosku")}</p>
                <p title={application.created_at}>
                  {format(parse(application.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
                </p>
              </CardRow>
              <CardRow>
                <p className="text-xs sm:text-base font-medium">{i18n.t("Status")}</p>
                <ColorBadge color={application.status.color}>{application.status.name}</ColorBadge>
              </CardRow>
              {userPolicy.viewAny() && (
                <CardRow>
                  <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
                  <UserTooltip user={application.user} />
                </CardRow>
              )}
              {application.description && (
                <CardRow className="flex lg:flex-col lg:items-start gap-1">
                  <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
                  <p className="text-muted-foreground">{application.description}</p>
                </CardRow>
              )}
            </CardContent>
          </Card>
        ))}
    </div>
  );
};
