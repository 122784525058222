import { Label } from "@/components/ui/label";
import { Textarea as ShadTextarea } from "@/components/ui/textarea";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Asterisk } from "lucide-react";
import { forwardRef } from "react";

const Textarea = forwardRef(
  ({ name, label, description, isError, placeholder = null, requiredIndicator = false, ...rest }, ref) => {
    return (
      <div>
        <div className="grid gap-2">
          {label && (
            <Label htmlFor={name} className={cn("flex flex-row gap-1 items-center", isError && "text-destructive")}>
              {typeof label === "string" ? i18n.t(label) : label}
              {requiredIndicator ? <Asterisk size={16} className="text-destructive" /> : null}
            </Label>
          )}
          <ShadTextarea
            ref={ref}
            name={name}
            id={name}
            placeholder={placeholder ? i18n.t(placeholder) : i18n.t(label)}
            {...rest}
          />
        </div>
        {description && (
          <span className="text-sm text-muted-foreground mt-1">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
      </div>
    );
  },
);

Textarea.displayName = "Textarea";

export { Textarea };
