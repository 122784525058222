import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { getCalculation, getCalculationSurveys } from "@/features/calculations/api/calculationApi";
import { CalculationSurveys } from "@/features/calculations/components/Surveys/CalculationSurveys";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { FilePlus2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const CalculationSurveysPage = () => {
  const { id: calculationId } = useParams();
  const navigate = useNavigate();
  const [requiredSurveysFilled, setRequiredSurveysFilled] = useState(false);

  const { isLoading: isLoadingCalculation, data: calculation } = useQuery({
    queryKey: ["calculation", calculationId],
    queryFn: () => getCalculation(calculationId),
  });

  const {
    isLoading: isLoadingSurveys,
    data: surveys,
    error: errorSurveys,
  } = useQuery({
    queryKey: ["calculation", calculationId, "surveys"],
    queryFn: () => getCalculationSurveys(calculationId),
  });
  useDocumentTitle(calculation?.data ? `Ankiety ${calculation?.data?.name}` : null);

  const handleAddContract = () => {
    return navigate(`${routes.contracts}/dodaj`, { state: { lead: calculation?.data?.lead } });
  };

  useEffect(() => {
    if (!isLoadingSurveys && surveys && !errorSurveys) {
      const allRequiredFilled = surveys.data
        .filter(({ required }) => required)
        .every((survey) => {
          return survey.entries && survey.entries.length > 0;
        });
      setRequiredSurveysFilled(allRequiredFilled);
    }
  }, [isLoadingSurveys, surveys, errorSurveys]);

  return (
    <Layout isLoading={isLoadingCalculation || isLoadingSurveys}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-8">
        <Breadcrumbs idAlias={calculation?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {requiredSurveysFilled && (
            <Button title="Dodaj umowę" leftIcon={<FilePlus2 size={20} />} onClick={handleAddContract} />
          )}
        </div>
      </div>
      <CalculationSurveys surveys={surveys?.data} calculation={calculation} />
    </Layout>
  );
};
