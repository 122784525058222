import { Button } from "@/components/Button";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { WarehouseOperationType } from "@/features/warehouses/enums/WarehouseOperationTypeEnum";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { Link } from "react-router-dom";

export const useWarehouseOperationsTableColumns = () => {
  const productPolicy = useProductPolicy();
  const userPolicy = useUserPolicy();
  const { user } = useAuth();

  const columns = [
    {
      id: "type",
      name: "Typ operacji",
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ operacji")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const operation = row.original;
        return <span>{i18n.t(WarehouseOperationType[operation.type]?.name)}</span>;
      },
    },
    {
      id: "user_id",
      name: "Użytkownik",
      accessorKey: "user_id",
      header: i18n.t("Użytkownik"),
      cell: ({ row }) => {
        const creator = row.original.user;
        if (creator) {
          if (creator.id !== user.id && userPolicy.view(creator)) {
            return (
              <Link
                to={`${routes.users}/${creator.id}`}
                className="flex flex-row items-center gap-3 font-medium hover:underline"
              >
                <UserAvatar user={creator} />
                {creator.name}
              </Link>
            );
          } else {
            return (
              <div className="flex flex-row items-center gap-3 font-medium">
                <UserAvatar user={creator} />
                {creator.name}
              </div>
            );
          }
        } else {
          return i18n.t("Użytkownik usunięty");
        }
      },
    },
    {
      id: "product",
      name: "Produkt",
      accessorKey: "product",
      header: i18n.t("Produkt"),
      cell: ({ row }) => {
        const product = row.original.product;
        if (productPolicy.view()) {
          return (
            <Link
              to={`${routes.products}/${product.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline truncate"
            >
              {product.name}
            </Link>
          );
        } else {
          return <span>{product.name}</span>;
        }
      },
    },
    {
      id: "quantity",
      name: "Ilość",
      accessorKey: "quantity",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Ilość")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => row.getValue("quantity") ?? "-",
    },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data utworzenia")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => row.getValue("created_at"),
    },
  ];
  return { columns };
};
