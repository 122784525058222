import { CardRow } from "@/components/ui/CardRow";
import { displayAddress } from "@/helpers/displayAddress";
import { getMapPinIconForModel } from "@/helpers/getMapPinIconForModel";
import { isAddressNotEmpty } from "@/helpers/isAddressNotEmpty";
import i18n from "@/i18n";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const AddressCardRow = ({ model, modelName }) => {
  if (!model || !isAddressNotEmpty(model))
    return (
      <CardRow className="flex lg:flex-col lg:items-start gap-2">
        <p className="text-xs sm:text-base font-medium">{i18n.t("Adres")}</p>
        {i18n.t("Brak")}
      </CardRow>
    );

  if (!model.address.latitude || !model.address.longitude) {
    return (
      <CardRow>
        <p className="text-xs sm:text-base font-medium">{i18n.t("Adres")}</p>
        <p className="text-sm font-medium text-right w-1/2">{displayAddress(model)}</p>
      </CardRow>
    );
  }
  return (
    <CardRow className="flex lg:flex-col lg:items-start gap-2">
      <p className="text-xs sm:text-base font-medium">{i18n.t("Adres")}</p>
      <p className="text-sm font-medium">{displayAddress(model)}</p>
      <MapContainer
        className="leaflet-container-address-card"
        center={[model?.address?.latitude, model?.address?.longitude]}
        zoom={10}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunkedLoading>
          <Marker
            key={model.id}
            position={[parseFloat(model?.address?.latitude), parseFloat(model?.address?.longitude)]}
            icon={getMapPinIconForModel(modelName)}
          />
        </MarkerClusterGroup>
      </MapContainer>
    </CardRow>
  );
};
