import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Checkbox } from "@/components/forms/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { CreditEditDialog } from "@/features/credits/components/CreditEditDialog";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useCreditsTableColumns = (handleDelete) => {
  const { hasAnyPermission } = useAuth();
  const creditPolicy = useCreditPolicy();

  const columns = [
    hasAnyPermission(["bulk_edit_credit_status", "assign_user_credits"])
      ? {
          id: "select",
          header: ({ table }) => (
            <Checkbox
              checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
          ),
          enableSorting: false,
          enableHiding: false,
        }
      : { id: "select" },
    {
      id: "name",
      name: "Imię i nazwisko kontaktu",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Imię i nazwisko kontaktu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const credit = row.original;
        if (creditPolicy.view()) {
          return (
            <Link
              to={`${routes.credits}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {credit.lead.name}
            </Link>
          );
        } else {
          return <span>{credit.lead.name}</span>;
        }
      },
    },
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Status")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const credit = row.original;
        return <ColorBadge color={credit.status.color}>{credit.status.name}</ColorBadge>;
      },
    },
    creditPolicy.viewAll()
      ? {
          id: "user_id",
          name: "Osoba odpowiedzialna",
          accessorKey: "user_id",
          header: i18n.t("Osoba odpowiedzialna"),
          cell: ({ row }) => {
            const user = row.original.user;
            return user ? <UserAvatarLink user={user} /> : i18n.t("Nieprzypisany");
          },
        }
      : { id: "user_id" },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data utworzenia")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const credit = row.original;
        if (!creditPolicy.destroy() && !creditPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <CreditEditDialog
                credit={credit}
                trigger={
                  <DropdownMenuItem className="flex flex-row items-center" onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
              {creditPolicy.destroy() && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(credit.id)}
                    message="Czy na pewno chcesz usunąć ten kredyt? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
