import { Button } from "@/components/Button";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Plus, X } from "lucide-react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

export const FormRangesField = ({ name, label, actionTitle = "Dolicz", actionDescription = null }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const addRange = () => {
    append({ from: "", to: "", price: "" });
  };

  return (
    <div className="flex flex-col gap-4">
      <Label>{label}</Label>
      <p className="text-xs text-muted-foreground">
        {`${i18n.t("W przypadku wartości procentowej w polu")} "${actionTitle}" ${i18n.t(
          "wpisz w formie ułamka dziesiętnego np. zamiast 12% to 0.12",
        )}`}
      </p>
      {fields.map((field, index) => (
        <div key={field.id} className="flex items-end gap-4">
          <Controller
            name={`${name}[${index}].from`}
            control={control}
            defaultValue={field.from}
            render={({ field }) => (
              <FormNumberField
                {...field}
                label={i18n.t("Wartość od (>)")}
                placeholder={i18n.t("Wartość od (>)")}
                className="flex-1"
              />
            )}
          />
          <Controller
            name={`${name}[${index}].to`}
            control={control}
            defaultValue={field.to}
            render={({ field }) => (
              <FormNumberField
                {...field}
                label={i18n.t("Wartość do (<=)")}
                placeholder={i18n.t("Wartość do (<=)")}
                className="flex-1"
              />
            )}
          />
          <Controller
            name={`${name}[${index}].value`}
            control={control}
            defaultValue={field.price}
            render={({ field }) => (
              <FormField
                {...field}
                label={actionTitle}
                placeholder={actionTitle}
                className="flex-1"
                description={actionDescription}
              />
            )}
          />
          {fields.length > 1 && (
            <Button
              type="button"
              variant="destructive"
              onClick={() => remove(index)}
              leftIcon={<X size={16} className="shrink-0" />}
            />
          )}
        </div>
      ))}
      <Button
        type="button"
        variant="outline"
        title={i18n.t("Dodaj zakres")}
        onClick={addRange}
        leftIcon={<Plus size={16} />}
      />
      <ErrorMessage name={name} />
    </div>
  );
};
