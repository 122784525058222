import { ImpersonationBanner } from "@/components/ImpersonationBanner";
import { Container } from "@/components/layout/Container";
import { Sidebar } from "@/components/layout/Sidebar";
import { FullPageLoader } from "@/components/Loader/FullPageLoader";

export const Layout = ({ children, isLoading = false }) => {
  return (
    <>
      <ImpersonationBanner />
      <Sidebar />
      <Container>{isLoading ? <FullPageLoader /> : children}</Container>
    </>
  );
};
