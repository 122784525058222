import api from "@/api/api";
import {
  ACTIONS_ENDPOINT,
  FILES_ENDPOINT,
  INSTALLATION_CREWS_ENDPOINT,
  INSTALLATION_SERVICES_ENDPOINT,
  INSTALLATIONS_ENDPOINT,
  INSTALLATIONS_STATUSES_ENDPOINT,
  INSTALLATIONS_TYPES_ENDPOINT,
  NOTES_ENDPOINT,
  PROCESSES_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDate, formatDateTime } from "@/helpers/formatDate";

export const getInstallationProcesses = (installationId) =>
  api.get(INSTALLATIONS_ENDPOINT + installationId + PROCESSES_ENDPOINT);

export const updateInstallationProcessProgress = ({ installationId, processId, data }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + PROCESSES_ENDPOINT + processId, data);

export const getInstallationStatuses = () => api.get(INSTALLATIONS_STATUSES_ENDPOINT);

export const createInstallationStatus = (data) => api.post(INSTALLATIONS_STATUSES_ENDPOINT, data);

export const updateInstallationStatus = ({ installationStatusId, data }) =>
  api.patch(INSTALLATIONS_STATUSES_ENDPOINT + installationStatusId, data);

export const updateInstallationStatusOrder = (data) => api.put(INSTALLATIONS_STATUSES_ENDPOINT + "order", data);

export const deleteInstallationStatus = ({ installationStatusId }) =>
  api.delete(INSTALLATIONS_STATUSES_ENDPOINT + installationStatusId);

export const getInstallationTypes = () => api.get(INSTALLATIONS_TYPES_ENDPOINT);

export const createInstallationType = (data) => api.post(INSTALLATIONS_TYPES_ENDPOINT, data);

export const updateInstallationType = ({ installationTypeId, data }) =>
  api.patch(INSTALLATIONS_TYPES_ENDPOINT + installationTypeId, data);

export const deleteInstallationType = ({ installationTypeId }) =>
  api.delete(INSTALLATIONS_TYPES_ENDPOINT + installationTypeId);

export const getInstallations = (filters = null) => api.get(INSTALLATIONS_ENDPOINT, transformQueryFilters(filters));

export const getInstallation = (installationId) => api.get(INSTALLATIONS_ENDPOINT + installationId);

export const createInstallation = ({ data: installation }) => {
  const data = {
    ...installation,
    date: installation?.date && formatDate(installation.date),
  };
  return api.post(INSTALLATIONS_ENDPOINT, data);
};

export const updateInstallation = ({ installationId, data: installation }) => {
  const data = {
    ...installation,
    date: installation?.date && formatDate(installation.date),
  };
  return api.patch(INSTALLATIONS_ENDPOINT + installationId, data);
};

export const updateInstallationsStatus = ({ installationId, data }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + "/status", data);

export const deleteInstallation = (installationId) => api.delete(INSTALLATIONS_ENDPOINT + installationId);

export const getInstallationServices = (filters = null) =>
  api.get(INSTALLATION_SERVICES_ENDPOINT, transformQueryFilters(filters));

export const getInstallationService = (installationServiceId) =>
  api.get(INSTALLATION_SERVICES_ENDPOINT + installationServiceId);

export const createInstallationService = ({ data: installationService }) => {
  const data = {
    ...installationService,
    service_date: installationService?.service_date && formatDate(installationService.service_date),
  };
  return api.post(INSTALLATION_SERVICES_ENDPOINT, data);
};

export const updateInstallationService = ({ installationServiceId, data: installationService }) => {
  const data = {
    ...installationService,
    service_date: installationService?.service_date && formatDate(installationService.service_date),
  };
  return api.patch(INSTALLATION_SERVICES_ENDPOINT + installationServiceId, data);
};

export const deleteInstallationService = (installationServiceId) =>
  api.delete(INSTALLATION_SERVICES_ENDPOINT + installationServiceId);

export const getInstallationServiceNotes = (installationServiceId) =>
  api.get(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT);

export const createInstallationServiceNote = ({ installationServiceId, note }) =>
  api.post(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT, { note });

export const updateInstallationServiceNote = ({ installationServiceId, noteId, note }) =>
  api.put(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT + noteId, note);

export const deleteInstallationServiceNote = ({ installationServiceId, noteId }) =>
  api.delete(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT + noteId);

export const getInstallationFiles = ({ installationId, filters }) =>
  api.get(INSTALLATIONS_ENDPOINT + installationId + "/files", transformQueryFilters(filters));

export const createInstallationFiles = ({ installationId, data }) =>
  api.post(INSTALLATIONS_ENDPOINT + installationId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteInstallationFile = ({ installationId, fileId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + FILES_ENDPOINT + fileId);

export const getInstallationNotes = (installationId) =>
  api.get(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT);

export const createInstallationNote = ({ installationId, note }) =>
  api.post(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT, { note });

export const updateInstallationNote = ({ installationId, noteId, note }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT + noteId, note);

export const deleteInstallationNote = ({ installationId, noteId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT + noteId);

export const getInstallationActions = (installationId) =>
  api.get(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT);

export const createInstallationAction = ({ installationId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT, data);
};

export const updateInstallationAction = ({ installationId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT + actionId, data);
};

export const deleteInstallationAction = ({ installationId, actionId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT + actionId);

export const getInstallationCrews = (filters = null) =>
  api.get(INSTALLATION_CREWS_ENDPOINT, transformQueryFilters(filters));

export const getInstallationCrew = (installationCrewId) => api.get(INSTALLATION_CREWS_ENDPOINT + installationCrewId);

export const createInstallationCrew = ({ data }) => {
  return api.post(INSTALLATION_CREWS_ENDPOINT, data);
};

export const updateInstallationCrew = ({ installationCrewId, data }) => {
  return api.patch(INSTALLATION_CREWS_ENDPOINT + installationCrewId, data);
};

export const deleteInstallationCrew = (installationCrewId) =>
  api.delete(INSTALLATION_CREWS_ENDPOINT + installationCrewId);

export const createInstallationInstallationCrew = ({ installationId, installationCrewId }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + INSTALLATION_CREWS_ENDPOINT + installationCrewId);

export const deleteInstallationInstallationCrew = ({ installationId, installationCrewId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + INSTALLATION_CREWS_ENDPOINT + installationCrewId);
