export const routes = Object.freeze({
  notFound: "/404",
  login: "/",
  forgotPassword: "/odzyskiwanie-hasla",
  resetPassword: "/resetowanie-hasla",
  dashboard: "/pulpit",

  account: "/konto",

  users: "/uzytkownicy",
  user: "/uzytkownicy/:id",
  userCreate: "/uzytkownicy/dodaj",
  userEdit: "/uzytkownicy/:id/edytuj",

  leads: "/kontakty",
  lead: "/kontakty/:id",
  leadCreate: "/kontakty/dodaj",
  leadImport: "/kontakty/import",
  leadEdit: "/kontakty/:id/edytuj",

  calendar: "/kalendarz",

  tasks: "/zadania",

  files: "/pliki",

  contracts: "/umowy",
  contractCreate: "/umowy/dodaj",
  contract: "/umowy/:id",
  contractEdit: "/umowy/:id/edytuj",
  contractCalculation: "/umowy/kalkulacje/:id",

  contractPayments: "/umowy/:id/platnosci",
  contractPaymentCreate: "/umowy/:id/platnosci/dodaj",
  contractPaymentEdit: "/umowy/:id/platnosci/:paymentId/edytuj",

  calculators: "/kalkulatory",
  calculator: "/kalkulatory/:id",
  calculatorsSettings: "/kalkulatory/ustawienia",
  calculatorConfiguration: "/kalkulatory/:id/konfiguracja",

  calculations: "/kalkulacje",
  calculation: "/kalkulacje/:id",
  calculationSurveys: "/kalkulacje/:id/ankiety",

  notifications: "/powiadomienia",

  processes: "/procesy",

  erp: "/erp",
  warehouses: "/erp/magazyny",
  warehouse: "/erp/magazyny/:id",
  warehouseProductCreate: "/erp/magazyny/:id/dodaj-produkty",

  products: "/erp/produkty",
  product: "/erp/produkty/:id",
  productCreate: "/erp/produkty/dodaj",
  productEdit: "/erp/produkty/:id/edytuj",
  productImport: "/erp/produkty/import",

  orders: "/erp/zamowienia",
  order: "/erp/zamowienia/:id",

  billings: "/rozliczenia",
  billingCreate: "/prowizje/dodaj",

  billingGroupCreate: "/rozliczenia/dodaj",

  billingSettings: "/rozliczenia/modele",
  billingSetting: "/rozliczenia/modele/:id",

  installationsAndServices: "/montaze-i-serwisy",

  installations: "/montaze",
  installation: "/montaze/:id",
  installationCrews: "/montaze/ekipy",
  installationCrew: "/montaze/ekipy/:id",

  installationServices: "/serwisy",
  installationService: "/serwisy/:id",

  audits: "/audyty",
  audit: "/audyty/:id",

  applications: "/wnioski",
  application: "/wnioski/:id",

  clients: "/klienci",
  client: "/klienci/:id",
  clientCreate: "/klienci/dodaj",
  clientEdit: "/klienci/:id/edytuj",

  surveys: "/ankiety",
  survey: "/ankiety/:id",

  roles: "/role-uzytkownikow",
  role: "/role-uzytkownikow/:id",

  credits: "/kredyty",
  credit: "/kredyty/:id",

  automations: "/automatyzacje",
  automation: "/automatyzacje/:id",
});
