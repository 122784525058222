import { ColorBadge } from "@/components/ColorBadge";
import { Loader } from "@/components/Loader/Loader";
import { ContractStatusContext } from "@/features/contracts/context/ContractStatusContext";
import { FileCategoryContext } from "@/features/files/context/FileCategoryContext";
import { getRoles } from "@/features/roles/api/roleApi";
import { Settings } from "@/features/settings/enums/SettingsEnum";
import i18n from "@/i18n";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export const useSettingValueTableColum = (setting) => {
  const { statuses: contractStatuses } = useContext(ContractStatusContext);
  const { categories: fileCategories } = useContext(FileCategoryContext);
  const { isLoading: isLoadingRoles, data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const key = setting.key;
  if (key === Settings.CONTRACT_POINTS_STATUS) {
    const status = contractStatuses.find((s) => s.id === setting.value);
    return <ColorBadge color={status.color}>{status.name}</ColorBadge>;
  } else if (key === Settings.CONTRACT_ORDER_STATUS) {
    const status = contractStatuses.find((s) => s.id === setting.value);
    return <ColorBadge color={status.color}>{status.name}</ColorBadge>;
  } else if (
    [
      Settings.APPLICATION_CONTRACT_FILE_CATEGORIES,
      Settings.INSTALLATION_CONTRACT_FILE_CATEGORIES,
      Settings.AUDIT_LEAD_FILE_CATEGORIES,
      Settings.CREDIT_LEAD_FILE_CATEGORIES,
    ].includes(key)
  ) {
    return setting.value.length > 0 ? (
      <div className="flex flex-col gap-1">
        {setting.value.map((v, index) => (
          <span key={`file_category_${index}`}>{fileCategories.find((category) => category.id === v)?.name}</span>
        ))}
      </div>
    ) : (
      i18n.t("Brak")
    );
  } else if (key === Settings.USER_DEFAULT_ROLE) {
    if (isLoadingRoles) {
      return <Loader />;
    }
    const role = roles.data.find((r) => r.id === setting?.value);
    return role ? <div className="flex flex-col gap-1">{role?.name}</div> : i18n.t("Brak");
  } else if (key === Settings.USER_POINTS_CASCADE) {
    return setting.value ? (
      <div className="flex flex-col gap-1">
        {setting.value === "true" ? i18n.t("Tak") : setting.value === "false" ? i18n.t("Nie") : i18n.t("Brak")}
      </div>
    ) : (
      i18n.t("Brak")
    );
  } else if (key === Settings.USER_POINTS_RESET_DAY) {
    return setting.value !== "0" ? (
      <div className="flex flex-col gap-1">{`${setting.value} dzień miesiąca`}</div>
    ) : (
      i18n.t("Poziomy i punkty nie resetują się")
    );
  } else return i18n.t("Nieobsługiwane ustawienie, skontaktuj się z administratorem");
};
