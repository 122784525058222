import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ButtonContext } from "@/context/ButtonContext";
import { deleteAudit, getAudit } from "@/features/audits/api/auditApi";
import { AuditDetails } from "@/features/audits/components/AuditDetails";
import { AuditEditDialog } from "@/features/audits/components/AuditEditDialog";
import { AuditFileCreateDialog } from "@/features/audits/components/Files/AuditFileCreateDialog";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { useTrackModelView } from "@/features/modelViews/hooks/useTrackModelView";
import { TaskCreateDialog } from "@/features/tasks/components/TaskCreateDialog";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const AuditDetailsPage = () => {
  const { id: auditId } = useParams();
  const navigate = useNavigate();
  const { buttons } = useContext(ButtonContext);
  const auditPolicy = useAuditPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["audit", auditId],
    queryFn: () => getAudit(auditId),
  });
  useTrackModelView("Audit", response?.data, response?.data?.user);
  useDocumentTitle(response?.data ? `Audyt ${response?.data?.lead?.name}` : "Audyt");

  const deleteAuditMutation = useMutation({
    mutationFn: deleteAudit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      if (res.ok) {
        return navigate(routes.audits);
      }
    },
  });

  const handleDelete = () => {
    deleteAuditMutation.mutate(auditId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.lead.name !== " " ? response?.data?.lead.name : "Brak"} />
        <div
          id="audit_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          {buttons["addAuditFiles"] && <AuditFileCreateDialog />}
          <TaskCreateDialog taskableId={auditId} taskableType="Audit" withUser={true} buttonVariant="outline" />
          <AuditEditDialog
            audit={response?.data}
            trigger={<Button variant="outline" title="Edytuj audyt" leftIcon={<Pencil size={20} />} />}
          />
          {auditPolicy.destroy() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń audyt"
                  isLoading={deleteAuditMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten audyt? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <AuditDetails audit={response?.data} />
    </Layout>
  );
};
