import { useAuth } from "@/features/auth/hooks/useAuth";

export const useProductPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_products");
  };

  const view = () => {
    return hasPermission("show_product");
  };

  const create = () => {
    return hasPermission("create_product");
  };

  const update = () => {
    return hasPermission("update_product");
  };

  const destroy = () => {
    return hasPermission("delete_product");
  };

  const importProducts = () => {
    return hasPermission("import_products");
  };

  const exportProducts = () => {
    return hasPermission("export_products");
  };

  return {
    viewAny,
    view,
    create,
    update,
    destroy,
    importProducts,
    exportProducts,
  };
};
