import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { ColorBadge } from "@/components/ColorBadge";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { AuditCreateDialog } from "@/features/audits/components/AuditCreateDialog";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { CreditCreateDialog } from "@/features/credits/components/CreditCreateDialog";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { renderCustomFields } from "@/features/customFields/helpers/renderCustomFields";
import { updateLeadsStatus } from "@/features/leads/api/leadApi";
import { LeadStatusContext } from "@/features/leads/context/LeadStatusContext";
import { LeadStatus } from "@/features/leads/enums/LeadStatusEnum";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { useLeadSourcePolicy } from "@/features/leads/policies/useLeadSourcePolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const LeadInformationCard = ({ lead }) => {
  const leadPolicy = useLeadPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const auditPolicy = useAuditPolicy();
  const creditPolicy = useCreditPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(LeadStatusContext);

  const { id: leadId } = useParams();
  const queryClient = useQueryClient();

  const handleStatusClick = (value) => {
    if (value) {
      const data = { lead_status_id: value };
      updateLeadStatusMutation.mutate({ id: leadId, data });
    }
  };

  const updateLeadStatusMutation = useMutation({
    mutationFn: updateLeadsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      queryClient.invalidateQueries({ queryKey: ["lead", leadId] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      }
    },
  });

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o kontakcie")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko")}</p>
          <p className="text-right">{lead.name}</p>
        </CardRow>
        {leadSourcePolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Źródło")}</p>
            <p className="text-right">{lead?.source?.name ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
        {leadPolicy.viewAll() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
            <p title={lead.created_at}>
              {format(parse(lead.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
            </p>
          </CardRow>
        )}
        {lead.assigned_at && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data przypisania")}</p>
            <p className={lead.status.name === LeadStatus.EXPIRED ? "text-red-500" : ""}>
              {lead.assigned_at
                ? format(parse(lead.assigned_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")
                : i18n.t("Nieprzypisany")}
            </p>
          </CardRow>
        )}
        {leadPolicy.assignUserLeads() && lead.created_by && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Utworzony przez")}</p>
            <UserTooltip user={lead.created_by} />
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Status kontaktu")}</p>
          {leadPolicy.updateStatus() || lead.amount_of_processes === 0 ? (
            <Select
              className="w-fit font-medium"
              style={{ backgroundColor: lead.status.color }}
              options={statusOptions}
              setValue={handleStatusClick}
              value={lead.status.id}
              isLoading={isLoadingStatuses || updateLeadStatusMutation.isPending}
            />
          ) : (
            <ColorBadge color={lead.status.color}>{lead.status.name}</ColorBadge>
          )}
        </CardRow>
        {auditPolicy.create() && (
          <CardRow className="hover:bg-background">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Status audytu")}</p>
            {lead.audit ? (
              <>
                {auditPolicy.view() ? (
                  <Link to={`${routes.audits}/${lead.audit.id}`}>
                    <ColorBadge color={lead.audit.status.color}>{lead.audit.status.name}</ColorBadge>
                  </Link>
                ) : (
                  <ColorBadge color={lead.audit.status.color}>{lead.audit.status.name}</ColorBadge>
                )}
              </>
            ) : (
              <AuditCreateDialog lead={lead} />
            )}
          </CardRow>
        )}
        {creditPolicy.create() && (
          <CardRow className="hover:bg-background">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Status kredytu")}</p>
            {lead.credit ? (
              <>
                {creditPolicy.view() ? (
                  <Link to={`${routes.credits}/${lead.credit.id}`}>
                    <ColorBadge color={lead.credit.status.color}>{lead.credit.status.name}</ColorBadge>
                  </Link>
                ) : (
                  <ColorBadge color={lead.credit.status.color}>{lead.credit.status.name}</ColorBadge>
                )}
              </>
            ) : (
              <CreditCreateDialog lead={lead} />
            )}
          </CardRow>
        )}
        <AddressCardRow model={lead} modelName="Lead" />
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {lead.phone_number ? (
            <a className="font-semibold" href={`tel:${lead.phone_number}`}>
              {formatPhoneNumber(lead.phone_number)}
            </a>
          ) : (
            <p className="text-right">{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres e-mail")}</p>
          {lead.email ? (
            <a className="font-semibold" href={`mailto:${lead.email}`}>
              {lead.email}
            </a>
          ) : (
            <p className="text-right">{i18n.t("Brak")}</p>
          )}
        </CardRow>
        {leadPolicy.assignUserLeads() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Użytkownik odpowiedzialny")}</p>
            {lead.user ? <UserTooltip user={lead.user} linkClassName="break-all text-right" /> : i18n.t("Brak")}
          </CardRow>
        )}
        {lead.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{lead.description ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
        {renderCustomFields(lead.customFields)}
      </CardContent>
    </Card>
  );
};
