import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { getAutomation } from "@/features/automations/api/automationApi";
import { AutomationDetails } from "@/features/automations/components/AutomationDetails";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const AutomationDetailsPage = () => {
  const { id: automationId } = useParams();

  const { isLoading, data: automation } = useQuery({
    queryKey: ["automation", automationId],
    queryFn: () => getAutomation(automationId),
    staleTime: 1000 * 60 * 5,
  });
  useDocumentTitle(automation?.data ? `Automatyzacja ${automation?.data?.name}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={automation?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"></div>
      </div>
      <AutomationDetails automation={automation?.data} />
    </Layout>
  );
};
