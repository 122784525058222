import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { useLeadSourcePolicy } from "@/features/leads/policies/useLeadSourcePolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const useLeadsTableColumns = (handleDelete) => {
  const { hasAnyPermission } = useAuth();
  const navigate = useNavigate();
  const leadPolicy = useLeadPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();

  const columns = [
    hasAnyPermission(["bulk_delete_leads", "bulk_unassign_lead_user", "bulk_edit_lead_status", "assign_user_leads"])
      ? {
          id: "select",
          header: ({ table }) => (
            <Checkbox
              checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
          ),
          enableSorting: false,
          enableHiding: false,
        }
      : { id: "select" },
    {
      id: "name",
      name: "Imię i nazwisko",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Imię i nazwisko")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (leadPolicy.view()) {
          return (
            <Link
              to={`${routes.leads}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "phone_number",
      name: "Numer telefonu",
      accessorKey: "phone_number",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Numer telefonu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const lead = row.original;
        return <a href={`tel:${lead.phone_number}`}>{formatPhoneNumber(lead.phone_number)}</a>;
      },
    },
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Status")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const lead = row.original;
        return <ColorBadge color={lead.status.color}>{lead.status.name}</ColorBadge>;
      },
    },
    {
      id: "postal_code",
      name: "Kod pocztowy",
      accessorKey: "postal_code",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kod pocztowy")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const lead = row.original;
        return (
          <span>
            {lead.address?.postal_code ? lead.address.postal_code : "-"}
            <br />
            {lead.address?.city}
          </span>
        );
      },
    },
    leadSourcePolicy.viewAny()
      ? {
          id: "source",
          name: "Źródło",
          accessorKey: "source",
          header: ({ column }) => {
            return (
              <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                {i18n.t("Źródło")}
                {column.getIsSorted() === "asc" ? (
                  <ArrowDown className="h-4 w-4" />
                ) : column.getIsSorted() === "desc" ? (
                  <ArrowUp className="h-4 w-4" />
                ) : (
                  <ArrowUpDown className="h-4 w-4" />
                )}
              </Button>
            );
          },
          cell: ({ row }) => {
            const source = row.original?.source;
            return <span>{source?.name ?? i18n.t("Brak")}</span>;
          },
        }
      : { id: "source" },
    leadPolicy.assignUserLeads()
      ? {
          id: "user",
          name: "Użytkownik odpowiedzialny",
          accessorKey: "user",
          header: i18n.t("Użytkownik odpowiedzialny"),
          cell: ({ row }) => {
            const user = row.original.user;
            return <UserAvatarLink user={user} fallback="-" />;
          },
        }
      : { id: "user" },
    {
      id: "assigned_at",
      name: "Data przypisania",
      accessorKey: "assigned_at",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data przypisania")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const lead = row.original;
        return (
          <span className="">
            {lead.assigned_at
              ? format(parse(lead.assigned_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")
              : i18n.t("Nieprzypisany")}
          </span>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const lead = row.original;
        if (!leadPolicy.update() && !leadPolicy.destroy()) return null;
        const handleEdit = () => {
          return navigate(`${routes.leads}/${lead.id}/edytuj`);
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {leadPolicy.update() && (
                <DropdownMenuItem onClick={handleEdit} className="flex flex-row items-center">
                  <Pencil size={16} className="mr-1 text-primary" />
                  <span className="font-semibold">{i18n.t("Edytuj")}</span>
                </DropdownMenuItem>
              )}
              {leadPolicy.destroy() && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(lead.id)}
                    message="Czy na pewno chcesz usunąć ten kontakt? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
