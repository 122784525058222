import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Input } from "@/components/forms/Input";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { createContractMessage, getContractMessages } from "@/features/contracts/api/contractApi";
import { ContractMessage } from "@/features/contracts/components/Messages/ContractMessage";
import { useContractMessagePolicy } from "@/features/contracts/policies/useContractMessagePolicy";
import i18n from "@/i18n";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Send } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export const ContractMessagesCard = () => {
  const { id: contractId } = useParams();
  const messagesContainerRef = useRef(null);
  const queryClient = useQueryClient();
  const contractMessagePolicy = useContractMessagePolicy();

  const [content, setContent] = useState("");

  const { isLoading, data: response } = useQuery({
    queryKey: ["contract", contractId, "messages"],
    queryFn: () => getContractMessages(contractId),
  });

  const createContractMessageMutation = useMutation({
    mutationFn: createContractMessage,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "messages"] });
        setContent("");
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleCreate = (e) => {
    e.preventDefault();
    if (content.length > 0) createContractMessageMutation.mutate({ contractId, content });
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (response?.data) {
      scrollToBottom();
    }
  }, [response?.data]);

  if (!contractMessagePolicy.viewAny()) return null;

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Konwersacja")}</CardTitle>
          <CardDescription>{i18n.t("Konwersacja na temat tej umowy")}</CardDescription>
        </div>
      </CardHeader>
      <div ref={messagesContainerRef} className="w-full max-h-96 overflow-y-auto px-4">
        <CardContent>
          {isLoading && !response ? (
            <Loader />
          ) : (
            <>
              {!response?.data?.length && <EmptyState title="Brak wiadomości" />}
              <div className="space-y-4">
                {response?.data.map((message) => (
                  <ContractMessage message={message} key={message.id} />
                ))}
              </div>
            </>
          )}
        </CardContent>
      </div>
      <CardFooter>
        <form onSubmit={handleCreate} className="flex w-full items-center space-x-2">
          <div className="w-full">
            <Input
              name="message"
              placeholder="Napisz wiadomość..."
              autoComplete="off"
              value={content}
              onChange={(event) => setContent(event.target.value)}
            />
          </div>
          <Button type="submit" size="icon" disabled={content.length === 0} leftIcon={<Send className="h-4 w-4" />} />
        </form>
      </CardFooter>
    </Card>
  );
};
