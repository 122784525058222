import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { getContract } from "@/features/contracts/api/contractApi";
import { ContractEditForm } from "@/features/contracts/components/ContractEditForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ContractEditPage = () => {
  const { id: contractId } = useParams();

  const { isLoading, data: response } = useQuery({
    queryKey: ["contract", contractId],
    queryFn: () => getContract(contractId),
  });
  useDocumentTitle(response?.data ? `Edycja umowy ${response?.data?.identifier}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs idAlias={response?.data?.identifier} />
      </div>
      <ContractEditForm contract={response?.data} />
    </Layout>
  );
};
