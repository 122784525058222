import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { CalculatorFieldType } from "@/features/calculators/enums/CalculatorFieldTypeEnum";
import { createOrderItem } from "@/features/orders/api/orderApi";
import { OrderStatus } from "@/features/orders/enums/OrderStatusEnum";
import { useOrderPolicy } from "@/features/orders/policies/useOrderPolicy";
import { getProductWarehouses } from "@/features/products/api/productApi";
import { ProductsContext } from "@/features/products/context/ProductsContext";
import { useWarehouseProductPolicy } from "@/features/warehouses/policies/useWarehouseProductPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const OrderItemCreateDialog = ({ order, mergedProducts }) => {
  const queryClient = useQueryClient();
  const orderPolicy = useOrderPolicy();
  const { products } = useContext(ProductsContext);
  const warehouseProductPolicy = useWarehouseProductPolicy();

  const [productOptions, setProductOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const validationSchema = Yup.object({
    product_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    quantity: Yup.number(i18n.t("Pole musi być liczbą"))
      .transform((value, originalValue) => (originalValue === "" ? 0 : value))
      .min(1, i18n.t("Minimalna ilość to 1"))
      .required(i18n.t("Pole jest wymagane"))
      .test("max-quantity", i18n.t("Ilość przekracza dostępną ilość w wybranym magazynie"), function (value) {
        const { warehouse_id } = this.parent;
        if (!warehouse_id) return true;
        const availableQuantity = warehouses?.data?.find((w) => w.id === warehouse_id)?.quantity;

        if (availableQuantity === null) return true;
        else return value <= availableQuantity;
      }),
    warehouse_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  });

  const defaultValues = {
    product_id: undefined,
    quantity: undefined,
    warehouse_id: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });
  const productId = form.watch("product_id");

  const {
    isLoading: isLoadingWarehouses,
    data: warehouses,
    error: errorWarehouses,
  } = useQuery({
    queryKey: ["product", productId, "warehouses"],
    queryFn: () => getProductWarehouses({ productId }),
    enabled: !!productId && warehouseProductPolicy.viewAny() && isOpen,
  });

  const onSubmit = (data) => {
    createOrderItemMutation.mutate({ orderId: order.id, data });
  };

  const createOrderItemMutation = useMutation({
    mutationFn: createOrderItem,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["order", order.id, "items"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pozycja dodana." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingWarehouses && !errorWarehouses && warehouses) {
      setWarehouseOptions(
        warehouses.data.map((warehouse) => ({
          name: warehouse.external
            ? `${warehouse.name} (Zewnętrzny)`
            : `${warehouse.name} (${warehouse.quantity} szt. dostępnych)`,
          value: warehouse.id,
        })),
      );
    }
  }, [isLoadingWarehouses, warehouses, errorWarehouses]);

  useEffect(() => {
    const availableProducts = new Set(
      order.contract.calculation.calculator.sections
        .flatMap((section) => section.fields)
        .filter((field) => field.type === CalculatorFieldType.PRODUCT.value)
        .flatMap((field) => {
          const productIds = [field.value];
          if (field.bundledProducts?.length) {
            productIds.push(...field.bundledProducts.map((bundled) => bundled.value));
          }
          return productIds;
        }),
    );

    setProductOptions(
      products
        .filter((product) => availableProducts.has(product.id) || mergedProducts[product.id])
        .map((product) => ({
          name: `${product.name} (${product.sku})`,
          value: product.id,
        })),
    );
  }, [mergedProducts, products]);

  if (!orderPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          title="Dodaj pozycję"
          leftIcon={<Plus size={20} />}
          disabled={order.status !== OrderStatus.NEW.value}
        />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj pozycję")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowej pozycji do zamówienia.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-5 mb-5">
            <FormCombobox name="product_id" label="Produkt" options={productOptions} />
            <FormNumberField name="quantity" label="Ilość" positiveOnly={true} />
            <FormCombobox options={warehouseOptions} name="warehouse_id" label="Magazyn" disabled={!productId} />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createOrderItemMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
