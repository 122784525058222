import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ChangeAccountInformation } from "@/features/account/components/ChangeAccountInformation";
import { ChangePassword } from "@/features/account/components/ChangePassword";
import { ChangeTheme } from "@/features/account/components/ChangeTheme";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import { useTheme } from "@/hooks/useTheme";
import i18n from "@/i18n";
import { LockKeyhole, Moon, Sun, User } from "lucide-react";

export const AccountPage = () => {
  useDocumentTitle("Konto");
  const { theme } = useTheme();
  const { activeTab, setActiveTab } = useTabs("information");

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="mb-5">
          <TabsTrigger value="information" className="flex flex-row gap-2 items-center">
            <User size={16} />
            {i18n.t("Informacje")}
          </TabsTrigger>
          <TabsTrigger value="password" className="flex flex-row gap-2 items-center">
            <LockKeyhole size={16} />
            {i18n.t("Hasło")}
          </TabsTrigger>
          <TabsTrigger value="theme" className="flex flex-row gap-2 items-center">
            {theme === "dark" ? <Moon size={16} /> : <Sun size={16} />}
            {i18n.t("Motyw")}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="information">
          <ChangeAccountInformation />
        </TabsContent>
        <TabsContent value="password">
          <ChangePassword />
        </TabsContent>
        <TabsContent value="theme">
          <ChangeTheme />
        </TabsContent>
      </Tabs>
    </Layout>
  );
};
