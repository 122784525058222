import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormSimpleOptions } from "@/components/forms/FormSimpleOptions";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { ContractTypeContext } from "@/features/contracts/context/ContractTypeContext";
import { createCustomField } from "@/features/customFields/api/customFieldApi";
import { CustomFieldType } from "@/features/customFields/enums/CustomFieldTypeEnum";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  applies_to: Yup.string().required(i18n.t("Pole jest wymagane")),
  required: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string()
    .optional()
    .when("applies_to", {
      is: (val) => val === "Contract" || val === "Application" || val === "Installation",
      then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
    }),
  field_type: Yup.string().required(),
  field_options: Yup.array()
    .optional()
    .nullable()
    .when("field_type", {
      is: CustomFieldType.LIST.value,
      then: () => Yup.array().min(1, i18n.t("Podaj minimum jedną opcję")).required(i18n.t("Pole jest wymagane")),
    }),
});

export const CustomFieldCreateDialog = ({ appliesTo }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { typeOptions: applicationTypeOptions } = useContext(ApplicationTypeContext);
  const { typeOptions: installationTypeOptions } = useContext(InstallationTypeContext);
  const { typeOptions: contractTypeOptions } = useContext(ContractTypeContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createCustomFieldMutation.mutate({ data });
  };

  const defaultValues = {
    applies_to: appliesTo,
    required: false,
    name: undefined,
    type: undefined,
    field_type: undefined,
    field_options: [],
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const { field_type } = form.watch();

  const createCustomFieldMutation = useMutation({
    mutationFn: createCustomField,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["customFields"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pole dodatkowe zostało dodane" /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const getTypeOptions = () => {
    if (appliesTo === "Application") {
      return applicationTypeOptions;
    }
    if (appliesTo === "Contract") {
      return contractTypeOptions;
    }
    if (appliesTo === "Installation") {
      return installationTypeOptions;
    }
    return [];
  };

  const renderTypeField = () => {
    if (appliesTo === "Contract" || appliesTo === "Application" || appliesTo === "Installation") {
      return <FormSelect label="Typ" name="type" options={getTypeOptions()} />;
    }
    return null;
  };

  useEffect(() => {
    if (field_type === CustomFieldType.TEXT.value) {
      form.setValue("field_options", []);
    }
  }, [field_type]);

  if (!processPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj pole dodatkowe" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj pole dodatkowe")}</DialogTitle>
          <DialogDescription>{i18n.t("Nowe pole które będzie widoczne na rekordzie.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <ScrollArea className="flex flex-col max-h-[60vh]">
            <div className="flex flex-col gap-4 mb-5 mx-1">
              <FormField label="Nazwa" placeholder="Nazwa pola" name="name" autoComplete="off" />
              <FormCheckbox
                label="Pole jest wymagane"
                name="required"
                description="Jeśli pole będzie oznaczone jako wymagane będzie trzeba je podać podczas tworzenia rekordu"
              />
              <FormSelect label="Typ pola" options={CustomFieldType.getValues()} name="field_type" />
              {field_type === CustomFieldType.LIST.value && (
                <FormSimpleOptions name="field_options" label="Opcja do wyświetlenia" />
              )}
              {renderTypeField()}
            </div>
          </ScrollArea>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createCustomFieldMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
