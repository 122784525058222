import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { ActionSelect } from "@/components/forms/ActionSelect";
import { Layout } from "@/components/layout/Layout";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import {
  createUserWidget,
  getUserWidgets,
  getWidgets,
  updateUserWidgetLayout,
} from "@/features/dashboard/api/dashboardApi";
import { DashboardWidgetGrid } from "@/features/dashboard/components/DashboardWidgetGrid";
import { isEqual as isObjectEqual } from "@/helpers/isEqual";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, PanelsLeftBottom, Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

export const DashboardPage = () => {
  useDocumentTitle("Pulpit");
  const queryClient = useQueryClient();

  const [editMode, setEditMode] = useState(false);
  const [widgetOptions, setWidgetOptions] = useState([]);
  const [layout, setLayout] = useState([]);
  const [initialLayout, setInitialLayout] = useState([]);

  const {
    isLoading: isLoadingWidgets,
    data: widgets,
    error: errorWidgets,
  } = useQuery({
    queryKey: ["widgets"],
    queryFn: getWidgets,
    staleTime: 1000 * 60 * 30,
  });

  const {
    isLoading: isLoadingUserWidgets,
    data: userWidgets,
    error: errorUserWidgets,
  } = useQuery({
    queryKey: ["userWidgets"],
    queryFn: getUserWidgets,
    staleTime: 1000 * 60 * 30,
  });

  const createUserWidgetMutation = useMutation({
    mutationFn: createUserWidget,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["widgets"] });
        queryClient.invalidateQueries({ queryKey: ["userWidgets"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const updateUserWidgetMutation = useMutation({
    mutationFn: updateUserWidgetLayout,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["widgets"] });
        queryClient.invalidateQueries({ queryKey: ["userWidgets"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleSaveLayoutChange = () => {
    if (isObjectEqual(layout, initialLayout)) return;

    updateUserWidgetMutation.mutate({
      widgets: layout.map((item) => ({
        user_widget_id: item.userWidgetId,
        position: {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          minH: 1,
          minW: 1,
        },
      })),
    });

    setInitialLayout(layout);
  };

  const handleEditLayout = () => {
    if (editMode === true) {
      handleSaveLayoutChange();
    }
    setEditMode(!editMode);
  };

  const handleCreateUserWidget = (widgetId) => {
    const selectedWidget = widgets.data.find((w) => w.id === widgetId);

    if (!selectedWidget) return;

    const newPosition = {
      x: (layout.length % 4) * 3,
      y: Math.floor(layout.length / 4) * 2,
      w: selectedWidget.default_width ?? 2,
      h: selectedWidget.default_height ?? 1,
    };

    createUserWidgetMutation.mutate({ widget_id: widgetId, position: newPosition });
  };

  const handleLayoutChange = (newLayout) => {
    setLayout((prevLayout) =>
      newLayout?.map((item) => {
        const existingItem = prevLayout?.find((prev) => prev.i === item.i);
        return {
          ...existingItem,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        };
      }),
    );
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setLayout(initialLayout);
  };

  useEffect(() => {
    if (!isLoadingWidgets && !errorWidgets && widgets) {
      setWidgetOptions(
        widgets.data.map((widget) => ({
          value: widget.id,
          content: (
            <span className="flex flex-col gap-1 justify-center">
              <p className="font-semibold">{widget.name}</p>
              <p className="text-xs text-muted-foreground">{widget.description}</p>
            </span>
          ),
        })),
      );
    }
  }, [isLoadingWidgets, widgets, errorWidgets]);

  useEffect(() => {
    if (!isLoadingUserWidgets && !errorUserWidgets && userWidgets) {
      const updatedLayout = userWidgets.data.map((userWidget, index) => ({
        i: String(userWidget.id),
        x: userWidget.position?.x ?? (index % 4) * 3,
        y: userWidget.position?.y ?? Math.floor(index / 4) * 2,
        w: userWidget.position?.w ?? userWidget.default_width ?? 2,
        h: userWidget.position?.h ?? userWidget.default_height ?? 2,
        minH: 1,
        minW: 1,
        description: userWidget.widget.description ?? "",
        title: userWidget.widget.name,
        userWidgetId: userWidget.id,
        component: userWidget.widget.component,
        widgetKey: userWidget.widget.key,
      }));
      setLayout(updatedLayout);
      setInitialLayout(updatedLayout);
    }
  }, [isLoadingUserWidgets, userWidgets, errorUserWidgets]);

  return (
    <Layout isLoading={isLoadingUserWidgets}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {widgetOptions.length > 0 && !editMode && (
            <ActionSelect
              options={widgetOptions}
              className="w-fit whitespace-nowrap gap-2"
              placeholder="Dodaj widget"
              leftIcon={<Plus size={20} />}
              setValue={handleCreateUserWidget}
            />
          )}
          <Button
            leftIcon={editMode ? <Check size={20} /> : <PanelsLeftBottom size={20} />}
            variant="outline"
            onClick={handleEditLayout}
            title={editMode ? "Zapisz układ" : "Edytuj układ"}
          />
          {editMode && (
            <Button leftIcon={<X size={20} />} variant="outline" onClick={handleCancelEdit} title="Anuluj" />
          )}
        </div>
      </div>
      <DashboardWidgetGrid layout={layout} onLayoutChange={handleLayoutChange} editMode={editMode} />
    </Layout>
  );
};
