import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { getAutomations } from "@/features/automations/api/automationApi";
import { AutomationLogStatus } from "@/features/automations/enums/AutomationLogStatusEnum";
import { useAutomationPolicy } from "@/features/automations/policies/useAutomationPolicy";
import { useFilters } from "@/hooks/useFilters";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const AutomationLogFilters = ({ table, withColumns = true }) => {
  const { filter, setFilter } = useFilters(table);
  const automationPolicy = useAutomationPolicy();
  const [automationOptions, setAutomationOptions] = useState([]);

  const {
    isLoading: isLoadingAutomations,
    data: automations,
    error: errorAutomations,
  } = useQuery({
    queryKey: ["automations"],
    queryFn: getAutomations,
    staleTime: 1000 * 60 * 5,
    enabled: automationPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingAutomations && !errorAutomations && automations) {
      setAutomationOptions(
        automations.data.map((automation) => ({
          name: automation.name,
          value: automation.id,
        })),
      );
    }
  }, [isLoadingAutomations, errorAutomations, automations]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <ClearFiltersButton table={table} />
      <SelectFilter
        options={AutomationLogStatus.getValues()}
        setValue={(value) => setFilter("status", value)}
        title="Status"
        value={filter("status")}
      />
      {automationPolicy.viewAny() && (
        <SelectFilter
          options={automationOptions}
          isLoading={isLoadingAutomations}
          setValue={(value) => setFilter("automation", value)}
          title="Automatyzacja"
          value={filter("automation")}
        />
      )}
      {withColumns && <ColumnFilter table={table} />}
    </div>
  );
};
