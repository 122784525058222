import { useAuth } from "@/features/auth/hooks/useAuth";
import { Role } from "@/features/roles/enums/RoleEnum";

export const useUserPolicy = () => {
  const { hasPermission, hasAnyPermission, user: authUser, userHasRole } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_users", "access_own_users"]);
  };

  const view = (user) => {
    return hasAnyPermission(["show_any_user", "show_own_user"]) && user.id !== authUser.id;
  };

  const create = () => {
    return hasAnyPermission(["create_user"]);
  };

  const update = (user) => {
    if (authUser?.id === user?.id) {
      return false;
    }

    if (userHasRole(user, Role.SUPERADMIN)) {
      return false;
    }

    return hasPermission("update_user");
  };

  const destroy = (user) => {
    if (authUser?.id === user?.id) {
      return false;
    }

    if (userHasRole(user, Role.SUPERADMIN)) {
      return false;
    }

    return hasPermission("delete_user");
  };

  const impersonate = (user) => {
    if (authUser?.id === user?.id) {
      return false;
    }

    if (userHasRole(user, [Role.SUPERADMIN, Role.ADMIN])) {
      return false;
    }

    return hasPermission("impersonate_users");
  };

  const assignLeads = () => {
    return hasPermission("assign_user_leads");
  };

  const viewCalendar = () => {
    return hasPermission("access_user_calendar");
  };

  const viewTasks = () => {
    return hasPermission("access_user_tasks");
  };

  const viewStats = () => {
    return hasPermission("access_user_stats");
  };

  return {
    viewAny,
    view,
    create,
    update,
    destroy,
    impersonate,
    assignLeads,
    viewCalendar,
    viewTasks,
    viewStats,
  };
};
