import { ChevronsUpDown, CirclePercent, ListChecks, Package, Percent, TextCursorInput, UserCog } from "lucide-react";

export const CalculatorFieldType = Object.freeze({
  LIST: {
    value: "LIST",
    name: "Lista rozwijana",
    icon: () => <ChevronsUpDown className="mr-2 h-5 w-5" />,
  },
  PRODUCT: {
    value: "PRODUCT",
    name: "Produkt",
    icon: () => <Package className="mr-2 h-5 w-5" />,
  },
  NUMBER: {
    value: "NUMBER",
    name: "Pole liczbowe",
    icon: () => <TextCursorInput className="mr-2 h-5 w-5" />,
  },
  MULTIPLE: {
    value: "MULTIPLE",
    name: "Lista wielokrotnego wyboru",
    icon: () => <ListChecks className="mr-2 h-5 w-5" />,
  },
  ADDITIONAL: {
    value: "ADDITIONAL",
    name: "Ustalenia dodatkowe",
    icon: () => <UserCog className="mr-2 h-5 w-5" />,
  },
  VAT: {
    value: "VAT",
    name: "Pole VAT",
    icon: () => <Percent className="mr-2 h-5 w-5" />,
  },
  COMISSION: {
    value: "COMISSION",
    name: "Pole prowizji",
    icon: () => <CirclePercent className="mr-2 h-5 w-5" />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },
});
