export const sanitizeSchema = {
  tagNames: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "ul",
    "li",
    "ol",
    "input",
    "strong",
    "em",
    "br",
    "b",
    "i",
    "blockquote",
    "code",
    "pre",
    "a",
    "hr",
    "table",
    "tr",
    "thead",
    "tbody",
    "td",
  ],
  attributes: {
    "*": ["style", "align", "class", "id"],
    a: ["href"],
    img: ["src"],
    input: ["checked", "type"],
  },
  protocols: {
    src: ["http", "https"],
    href: ["http", "https"],
  },
};
