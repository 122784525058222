import { SelectContent, SelectItem, SelectTrigger, SelectValue, Select as ShadSelect } from "@/components/ui/select";
import i18n from "@/i18n";
import { forwardRef } from "react";

const Select = forwardRef(
  (
    {
      options = [],
      setValue,
      className,
      description,
      value,
      defaultValue,
      leftIcon,
      isLoading = false,
      placeholder = "Wybierz z listy",
      style = null,
      ...rest
    },
    ref,
  ) => {
    return (
      <ShadSelect onValueChange={setValue} defaultValue={defaultValue} value={value} {...rest}>
        <SelectTrigger
          className={className}
          style={style}
          disabled={options.length === 0 || isLoading}
          isLoading={isLoading}
          ref={ref}
        >
          {leftIcon && leftIcon}
          <SelectValue placeholder={options.length === 0 ? i18n.t("Brak opcji do wyboru.") : i18n.t(placeholder)} />
        </SelectTrigger>
        {description && (
          <span className="text-sm text-muted-foreground">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
        <SelectContent>
          {options.map((option, index) => {
            return (
              <SelectItem
                key={option.value + "_" + index}
                className="flex flex-row gap-1 items-center"
                value={option.value}
              >
                <div className="flex flex-row items-center">
                  {option.icon && <option.icon className="mr-2 h-4 w-4" />}
                  <span>{option.content ? option.content : option.name}</span>
                </div>
              </SelectItem>
            );
          })}
        </SelectContent>
      </ShadSelect>
    );
  },
);

Select.displayName = "Select";

export { Select };
