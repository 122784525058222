import { RoleDetailsPage } from "@/features/roles/pages/RoleDetailsPage";
import { RolesPage } from "@/features/roles/pages/RolesPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const RoleRoutes = () => (
  <>
    <Route exact path={routes.roles} element={<RolesPage />} />
    <Route exact path={routes.role} element={<RoleDetailsPage />} />
  </>
);
