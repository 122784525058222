import { OrderDetailsPage } from "@/features/orders/pages/OrderDetailsPage";
import { OrdersPage } from "@/features/orders/pages/OrdersPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const OrderRoutes = () => (
  <>
    <Route exact path={routes.orders} element={<OrdersPage />} />
    <Route exact path={routes.order} element={<OrderDetailsPage />} />
  </>
);
