import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { UserLevelCreateDialog } from "@/features/users/components/Levels/UserLevelCreateDialog";
import { UserLevelsTable } from "@/features/users/components/Levels/UserLevelsTable";
import { UserSettings } from "@/features/users/components/Settings/UserSettings";
import { UsersTable } from "@/features/users/components/UsersTable";
import { useSettingPolicy } from "@/features/surveys/policies/useSettingPolicy";
import { useUserLevelPolicy } from "@/features/users/policies/useUserLevelPolicy";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const UsersPage = () => {
  useDocumentTitle("Użytkownicy");
  const navigate = useNavigate();
  const userPolicy = useUserPolicy();
  const userLevelPolicy = useUserLevelPolicy();
  const settingPolicy = useSettingPolicy();
  const { activeTab, setActiveTab } = useTabs("table");

  const handleCreate = () => {
    return navigate(routes.userCreate);
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "levels" && <UserLevelCreateDialog />}
          {userPolicy.create() && activeTab === "table" && (
            <Button variant="outline" title="Dodaj użytkownika" leftIcon={<Plus size={20} />} onClick={handleCreate} />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {userLevelPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {userLevelPolicy.create() && <TabsTrigger value="levels">{i18n.t("Poziomy")}</TabsTrigger>}
            {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <UsersTable />
        </TabsContent>
        {userLevelPolicy.create() && (
          <TabsContent value="levels">
            <UserLevelsTable />
          </TabsContent>
        )}
        {settingPolicy.update() && (
          <TabsContent value="settings">
            <UserSettings />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
