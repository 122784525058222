import { useAuth } from "@/features/auth/hooks/useAuth";
import { getInstallationTypes } from "@/features/installations/api/installationApi";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const InstallationTypeContext = createContext();

export function InstallationTypeProvider({ children }) {
  const [types, setTypes] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const installationPolicy = useInstallationPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["installationTypes"],
    queryFn: getInstallationTypes,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && installationPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setTypes(response.data);
      setTypeOptions(response.data.map((type) => ({ name: type.name, value: type.id })));
    }
  }, [response, isLoading, error]);

  return (
    <InstallationTypeContext.Provider value={{ types, isLoading, typeOptions }}>
      {children}
    </InstallationTypeContext.Provider>
  );
}
