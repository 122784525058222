import { Button } from "@/components/Button";
import { AreaFileUploadInput } from "@/components/forms/AreaFileUploadInput";
import { Form } from "@/components/forms/Form";
import { FormDatePicker } from "@/components/forms/FormDatePicker";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { createContractPayment, createContractPaymentFiles } from "@/features/contracts/api/contractApi";
import { ContractPaymentSource } from "@/features/contracts/enums/ContractPaymentSourceEnum";
import { ContractPaymentType } from "@/features/contracts/enums/ContractPaymentTypeEnum";
import { getContractPaymentSums } from "@/features/contracts/helpers/getContractPaymentsSums";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const ContractPaymentCreateForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const contract = state?.contract;
  const [files, setFiles] = useState([]);
  const [paymentCreated, setPaymentCreated] = useState(false);
  const contractPaymentSourceOptions = ContractPaymentSource.getAvailableForContract(contract);

  const getMaxAmount = (paymentSource) => {
    let message;
    let max;
    const { totalClientPaid, totalDonationPaid, totalLoanPaid } = getContractPaymentSums(contract.contractPayments);
    switch (contract.payment_type) {
      case ContractPaymentType.CASH.value:
        max = parseFloat(contract.gross_price) - parseFloat(contract.payment_sum);
        message = `${i18n.t(
          "Suma wszystkich płatności nie może przekraczać całkowitej wartości brutto umowy. Pozostała kwota do wpłaty to",
        )} ${formatMoney(max)}`;
        break;
      case ContractPaymentType.LOAN.value:
      case ContractPaymentType.PREFINANCED.value:
        if (paymentSource === ContractPaymentSource.CLIENT.value) {
          max = parseFloat(contract.down_payment) - parseFloat(totalClientPaid);
          message = `${i18n.t("Wpłata od klienta dla tej umowy nie może być większa niż")} ${formatMoney(max)}`;
        } else if (paymentSource === ContractPaymentSource.DONATION.value) {
          max = parseFloat(contract.gross_price) - parseFloat(totalDonationPaid) - parseFloat(contract.down_payment);
          message = `${i18n.t(
            "Suma wszystkich płatności z dotacji nie może przekraczać całkowitej wartości dotacji. Pozostała kwota do wpłaty to",
          )} ${formatMoney(max)}`;
        } else if (paymentSource === ContractPaymentSource.LOAN.value) {
          max = parseFloat(contract.gross_price) - parseFloat(totalLoanPaid) - parseFloat(contract.down_payment);
          message = `${i18n.t(
            "Suma wszystkich płatności z kredytu nie może przekraczać całkowitej wartości kredytu. Pozostała kwota do wpłaty to",
          )} ${formatMoney(max)}`;
        }
        break;
    }
    return { max: parseFloat(max).toFixed(2), message };
  };

  const validationSchema = Yup.object({
    amount: Yup.number()
      .required(i18n.t("Pole jest wymagane"))
      .when("source", {
        is: ContractPaymentSource.CLIENT.value,
        then: () => {
          const { max, message } = getMaxAmount(ContractPaymentSource.CLIENT.value);
          return Yup.number().max(max, message).required(i18n.t("Pole jest wymagane"));
        },
      })
      .when("source", {
        is: ContractPaymentSource.LOAN.value,
        then: () => {
          const { max, message } = getMaxAmount(ContractPaymentSource.LOAN.value);
          return Yup.number().max(max, message).required(i18n.t("Pole jest wymagane"));
        },
      })
      .when("source", {
        is: ContractPaymentSource.DONATION.value,
        then: () => {
          const { max, message } = getMaxAmount(ContractPaymentSource.DONATION.value);
          return Yup.number().max(max, message).required(i18n.t("Pole jest wymagane"));
        },
      }),
    source: Yup.string().required(i18n.t("Pole jest wymagane")),
    date: Yup.date().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable().optional(),
  });

  const defaultValues = {
    amount: undefined,
    source: contractPaymentSourceOptions[0].value,
    date: undefined,
    description: undefined,
  };

  const onSubmit = (data) => {
    createContractPaymentMutation.mutate({ contractId: contract.id, contractPayment: data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createContractPaymentMutation = useMutation({
    mutationFn: createContractPayment,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contract.id, "payments"] });
        queryClient.invalidateQueries({ queryKey: ["contract", contract.id], exact: true });
        setPaymentCreated(true);
        uploadContractPaymentFilesMutation.reset();
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const uploadContractPaymentFilesMutation = useMutation({
    mutationFn: createContractPaymentFiles,
    onSuccess: (res) => {
      if (res.ok) {
        setFiles([]);
        setPaymentCreated(false);
        toast({ title: <SuccessToast title="Płatność dodana" /> });
        return navigate(-1, { state: { tab: "payments" } });
      } else {
        if (res.status === 422) {
          toast({
            title: (
              <ErrorToast title="Błąd z plikiem, upewnij się że wysyłasz plik z odpowiednim rozszerzeniem o rozmiarze maksymalnie 10MB" />
            ),
          });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  useEffect(() => {
    if (paymentCreated && createContractPaymentMutation.isSuccess) {
      if (files.length > 0) {
        const contractPaymentId = createContractPaymentMutation.data.data.id;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
          data.append(`files[${i}]`, files[i]);
        }
        uploadContractPaymentFilesMutation.mutate({ contractId: contract.id, contractPaymentId, data });
      } else {
        toast({ title: <SuccessToast title="Płatność dodana" /> });
        return navigate(-1, { state: { tab: "payments" } });
      }
    }
  }, [paymentCreated]);

  useEffect(() => {
    if (!contract) return navigate(-1);
  }, []);

  return (
    <Card className="shadow-md mt-5 w-full xl:w-1/2">
      <Form onSubmit={onSubmit} form={form}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormSelect label="Źródło płatności" options={contractPaymentSourceOptions} name="source" />
            <FormDatePicker name="date" label="Data płatności" />
            <FormNumberField name="amount" label="Kwota" />
            <FormTextarea name="description" label="Opis" />
            <div>
              <Label htmlFor="files" className="flex flex-row items-center mb-2">
                {i18n.t("Pliki do załączenia (Potwierdzenia płatności, Faktury)")}
              </Label>
              <AreaFileUploadInput onFilesSelect={setFiles} />
            </div>
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            title="Dodaj"
            leftIcon={<Plus size={20} />}
            isLoading={createContractPaymentMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(-1, { state: { tab: "payments" } })}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
