import { CreditStatusProvider } from "@/features/credits/context/CreditStatusContext";
import { CreditDetailsPage } from "@/features/credits/pages/CreditDetailsPage";
import { CreditsPage } from "@/features/credits/pages/CreditsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const CreditRoutes = () => (
  <>
    <Route
      exact
      path={routes.credits}
      element={
        <CreditStatusProvider>
          <CreditsPage />
        </CreditStatusProvider>
      }
    />
    <Route
      exact
      path={routes.credit}
      element={
        <CreditStatusProvider>
          <CreditDetailsPage />
        </CreditStatusProvider>
      }
    />
  </>
);
