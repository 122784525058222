import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { AuditStatusContext } from "@/features/audits/context/AuditStatusContext";
import { ContractStatusContext } from "@/features/contracts/context/ContractStatusContext";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { LeadStatusContext } from "@/features/leads/context/LeadStatusContext";
import { deleteProcessStep } from "@/features/processes/api/processApi";
import { ProcessStepCreateDialog } from "@/features/processes/components/ProcessStepCreateDialog";
import { ProcessStepEditDialog } from "@/features/processes/components/ProcessStepEditDialog";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useContext } from "react";

export const ProcessStep = ({ process, step, index }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { statuses: leadStatuses } = useContext(LeadStatusContext);
  const { statuses: contractStatuses } = useContext(ContractStatusContext);
  const { statuses: installationStatuses } = useContext(InstallationStatusContext);
  const { statuses: auditStatuses } = useContext(AuditStatusContext);
  const { statuses: applicationStatuses } = useContext(ApplicationStatusContext);
  const { statuses: creditStatuses } = useContext(CreditStatusContext);

  const deleteProcessStepMutation = useMutation({
    mutationFn: deleteProcessStep,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["processes"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Krok został usunięty." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const renderStatusName = (statusStep) => {
    let status;
    if (!statusStep.status_change) return null;

    switch (process.applies_to) {
      case "Audit":
        status = auditStatuses.find((status) => status.id === statusStep.status_change);
        break;
      case "Application":
        status = applicationStatuses.find((status) => status.id === statusStep.status_change);
        break;
      case "Contract":
        status = contractStatuses.find((status) => status.id === statusStep.status_change);
        break;
      case "Installation":
        status = installationStatuses.find((status) => status.id === statusStep.status_change);
        break;
      case "Lead":
        status = leadStatuses.find((status) => status.id === statusStep.status_change);
        break;
      case "Credit":
        status = creditStatuses.find((status) => status.id === statusStep.status_change);
        break;
      default:
        status = null;
        break;
    }
    if (status) {
      return <ColorBadge color={status.color}>{status.name}</ColorBadge>;
    } else return null;
  };

  const handleDeleteStep = (processStepId) => {
    deleteProcessStepMutation.mutate({ processId: process.id, processStepId });
  };

  return (
    <div key={step.id} className="flex flex-col justify-center gap-1">
      <h3 className="flex flex-col lg:flex-row gap-2 lg:items-center mt-2 justify-between border-b pb-1">
        <div className="flex flex-row gap-3 items-center">
          {index + 1}. {step.name} {renderStatusName(step)}
        </div>
        <div className="flex flex-row gap-5 items-center">
          <ProcessStepCreateDialog process={process} parent={step} variant="ghost" size="sm" className="text-primary" />
          <ProcessStepEditDialog process={process} step={step} variant="ghost" size="sm" className="text-primary" />
          {processPolicy.update() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć ten krok? Tej operacji nie można cofnąć"
              onConfirm={() => handleDeleteStep(step.id)}
              trigger={
                <Button
                  variant="ghost"
                  className="text-destructive"
                  size="sm"
                  title="Usuń"
                  leftIcon={<Trash size={16} />}
                  isLoading={deleteProcessStepMutation.isPending}
                />
              }
            />
          )}
        </div>
      </h3>
      <ul className="flex flex-col gap-2 ml-5">
        {step.children?.map((subStep, subStepIndex) => (
          <li key={subStep.id} className="flex flex-row gap-2 items-center">
            <span className="flex flex-row gap-3 items-center">
              {`${subStepIndex + 1}. ${subStep.name}`} {renderStatusName(subStep)}
            </span>
            <ProcessStepEditDialog
              process={process}
              step={subStep}
              variant="ghost"
              title=""
              size="icon"
              className="text-primary"
            />
            {processPolicy.update() && (
              <DeleteAlertDialog
                message="Czy na pewno chcesz usunąć ten krok? Tej operacji nie można cofnąć"
                onConfirm={() => handleDeleteStep(subStep.id)}
                trigger={
                  <Button
                    variant="ghost"
                    className="text-destructive"
                    size="icon"
                    leftIcon={<Trash size={16} />}
                    isLoading={deleteProcessStepMutation.isPending}
                  />
                }
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
