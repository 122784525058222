import { AutomationDetailsPage } from "@/features/automations/pages/AutomationDetailsPage";
import { AutomationsPage } from "@/features/automations/pages/AutomationsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const AutomationRoutes = () => (
  <>
    <Route exact path={routes.automations} element={<AutomationsPage />} />
    <Route exact path={routes.automation} element={<AutomationDetailsPage />} />
  </>
);
