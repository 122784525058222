import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { useCustomFields } from "@/features/customFields/hooks/useCustomFields";
import { getInstallationCrews, updateInstallationService } from "@/features/installations/api/installationApi";
import { useInstallationCrewPolicy } from "@/features/installations/policies/useInstallationCrewPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const InstallationServiceEditDialog = ({ trigger, installationService }) => {
  const queryClient = useQueryClient();
  const installationServicePolicy = useInstallationServicePolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const {
    customFields,
    renderFormCustomFields,
    customFieldValidationSchema,
    customFieldDefaultValues,
    applyCustomFieldsToForm,
  } = useCustomFields("InstallationService", installationService);

  const [isOpen, setIsOpen] = useState(false);
  const [installationCrewOptions, setInstallationCrewOptions] = useState([]);

  const validationSchema = Yup.object({
    name: Yup.string().required(i18n.t("Pole jest wymagane")),
    installation_crew_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
    ...customFieldValidationSchema,
  });

  const onSubmit = (data) => {
    updateInstallationServiceMutation.mutate({ installationServiceId: installationService.id, data });
  };

  const defaultValues = {
    name: installationService.name,
    installation_crew_id: installationService.installationCrew ? installationService.installationCrew.id : undefined,
    description: installationService.description,
    ...customFieldDefaultValues,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const {
    isLoading: isLoadingInstallationCrews,
    data: installationCrews,
    error: errorInstallationCrews,
  } = useQuery({
    queryKey: ["installationCrews"],
    queryFn: getInstallationCrews,
    staleTime: 1000 * 60 * 5,
    enabled: installationCrewPolicy.viewAny() && isOpen,
  });

  const updateInstallationServiceMutation = useMutation({
    mutationFn: updateInstallationService,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installationServices"] });
      queryClient.invalidateQueries({ queryKey: ["installationService", installationService.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingInstallationCrews && !errorInstallationCrews && installationCrews) {
      setInstallationCrewOptions(installationCrews.data.map((crew) => ({ name: crew.name, value: crew.id })));
    }
  }, [isLoadingInstallationCrews, installationCrews, errorInstallationCrews]);

  useEffect(() => {
    applyCustomFieldsToForm(form);
  }, [customFields]);

  if (!installationServicePolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja serwisu")}</DialogTitle>
          <DialogDescription>{installationService.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField name="name" label="Nazwa" />
            <FormCombobox name="installation_crew_id" label="Ekipa" options={installationCrewOptions} />
            <FormTextarea name="description" label="Opis" />
            {renderFormCustomFields()}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateInstallationServiceMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
