import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckboxGroup } from "@/components/forms/FormCheckboxGroup";
import { FormField } from "@/components/forms/FormField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { voivodeships } from "@/constants/voivodeships";
import { createInstallationCrew } from "@/features/installations/api/installationApi";
import { useInstallationCrewPolicy } from "@/features/installations/policies/useInstallationCrewPolicy";
import { getVoivodeshipOptions } from "@/helpers/getVoivodeshipOptions";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const InstallationCrewCreateDialog = () => {
  const queryClient = useQueryClient();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const { voivodeshipOptions } = getVoivodeshipOptions();

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(i18n.t("Pole jest wymagane")),
    phone_number: Yup.string().required(i18n.t("Pole jest wymagane")),
    email: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().optional().nullable(),
    voivodeships: Yup.array().of(Yup.string().oneOf(voivodeships)),
  });

  const onSubmit = (data) => {
    createInstallationCrewMutation.mutate({ data });
  };

  const defaultValues = {
    name: undefined,
    phone_number: undefined,
    email: undefined,
    voivodeships: [],
    description: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const createInstallationCrewMutation = useMutation({
    mutationFn: createInstallationCrew,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installationCrews"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Ekipa montażowa dodana." /> });
        form.reset({
          name: undefined,
          phone_number: undefined,
          email: undefined,
          voivodeships: [],
          description: "",
        });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!installationCrewPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj ekipę montażową" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="max-w-5xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj ekipę montażową")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowej ekipy montażowej.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-row gap-5 w-full">
            <div className="flex flex-col gap-4 mb-5 w-full">
              <FormField name="name" label="Nazwa ekipy" autoComplete="name" />
              <FormField name="email" type="email" autoComplete="email" label="Email" />
              <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
              <FormTextarea name="description" label="Opis" />
            </div>
            <div className="w-full">
              <FormCheckboxGroup options={voivodeshipOptions} label="Obsługiwane województwa" name="voivodeships" />
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createInstallationCrewMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
