import { getApplicationStatuses } from "@/features/applications/api/applicationApi";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ApplicationStatusContext = createContext();

export function ApplicationStatusProvider({ children }) {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const applicationPolicy = useApplicationPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["applicationStatuses"],
    queryFn: getApplicationStatuses,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && applicationPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setStatuses(response.data);
      setStatusOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <ApplicationStatusContext.Provider value={{ statuses, isLoading, statusOptions }}>
      {children}
    </ApplicationStatusContext.Provider>
  );
}
