import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CustomFieldCreateDialog } from "@/features/customFields/components/CustomFieldCreateDialog";
import { CustomFieldSettings } from "@/features/customFields/components/CustomFieldSettings";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const ClientSettings = () => {
  const { activeTab, setActiveTab } = useTabs("custom_fields");
  const customFieldPolicy = useCustomFieldPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {customFieldPolicy.create() && <TabsTrigger value="custom_fields">{i18n.t("Pola dodatkowe")}</TabsTrigger>}
      </TabsList>
      {customFieldPolicy.create() && (
        <TabsContent value="custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pola dodatkowe")}</h2>
            <CustomFieldCreateDialog appliesTo="Client" />
          </div>
          <CustomFieldSettings appliesTo="Client" />
        </TabsContent>
      )}
    </Tabs>
  );
};
