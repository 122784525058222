import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { getCalendarEvents } from "@/features/calendar/helpers/getCalendarEvents";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { addDays, endOfMonth, endOfWeek, format, isSameDay, isSameMonth, startOfMonth, startOfWeek } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export const MonthCalendarWidget = ({ data }) => {
  const currentDate = new Date();
  const daysOfWeek = ["Pn", "Wt", "Śr", "Czw", "Pt", "Sb", "Nd"];
  const events = getCalendarEvents(data);

  const days = useMemo(() => {
    const startDate = startOfWeek(startOfMonth(currentDate));
    const endDate = endOfWeek(endOfMonth(currentDate));

    const daysArray = [];
    let day = startDate;
    while (day <= endDate) {
      daysArray.push(day);
      day = addDays(day, 1);
    }

    return { startDate, endDate, daysArray };
  }, [currentDate]);

  const renderHeader = () => (
    <>
      <div className="grid grid-cols-7 gap-2 text-center mb-1 text-muted-foreground">
        {daysOfWeek.map((day) => (
          <span key={day}>{day}</span>
        ))}
      </div>
    </>
  );

  const renderDays = () => (
    <div className="grid grid-cols-7 gap-2">
      {days.daysArray.map((day) => {
        const dayEvents = events.filter((event) => isSameDay(new Date(event.date), day));
        return (
          <HoverCard key={day}>
            <HoverCardTrigger asChild>
              <div
                className={cn(
                  "relative p-2 border border-border rounded-md flex flex-col items-center aspect-square cursor-pointer",
                  isSameMonth(day, currentDate) ? "" : "bg-muted text-muted-foreground",
                  isSameDay(day, new Date()) ? "border-primary border-2" : "",
                )}
              >
                <div className={cn("text-sm font-bold", isSameDay(day, new Date()) ? "text-primary" : "")}>
                  {format(day, "d")}
                </div>
                {dayEvents.length > 0 && (
                  <div className="absolute top-0 right-0 flex">
                    <span className="w-2 h-2 bg-primary rounded-full"></span>
                  </div>
                )}
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="w-[250px]">
              <h4 className="text-sm font-semibold">{format(day, "PPPP")}</h4>
              {dayEvents.length > 0 ? (
                <ul className="mt-2 space-y-2">
                  {dayEvents.map((event, index) => (
                    <Link to={event.link} key={event.title + index} className="flex">
                      <li className="w-full text-xs border py-1 px-2 rounded-md flex flex-col gap-1 items-start">
                        <span className="flex flex-row gap-1 items-center">
                          {event.icon && <event.icon size={16} className="shrink-0" />}
                          <span className="font-semibold">{event.name}</span>
                        </span>
                        <span className="w-full truncate" title={event.title}>
                          {event.title}
                        </span>
                      </li>
                    </Link>
                  ))}
                </ul>
              ) : (
                <p className="text-xs text-muted-foreground mt-1">{i18n.t("Brak wydarzeń")}</p>
              )}
            </HoverCardContent>
          </HoverCard>
        );
      })}
    </div>
  );

  return (
    <div className="mx-auto w-full">
      {renderHeader()}
      {renderDays()}
    </div>
  );
};
