import { useAuth } from "@/features/auth/hooks/useAuth";

export const useBillingSettingPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_billing_settings");
  };

  const create = () => {
    return hasPermission("create_billing_setting");
  };

  const view = () => {
    return hasPermission("show_billing_setting");
  };

  const update = () => {
    return hasPermission("update_billing_setting");
  };

  const destroy = () => {
    return hasPermission("delete_billing_setting");
  };

  return {
    viewAny,
    create,
    view,
    update,
    destroy,
  };
};
