export const toDMS = (degree) => {
  const absolute = Math.abs(degree);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1);
  return `${degrees}°${minutes}'${seconds}"`;
};

export const generateGoogleMapsURL = (latitude, longitude) => {
  const latitudeDMS = toDMS(latitude);
  const longitudeDMS = toDMS(longitude);

  const latitudeHemisphere = latitude >= 0 ? "N" : "S";
  const longitudeHemisphere = longitude >= 0 ? "E" : "W";

  return `https://www.google.com/maps/place/${latitudeDMS}${latitudeHemisphere}+${longitudeDMS}${longitudeHemisphere}`;
};
