import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { SketchCanvas } from "@/components/forms/SketchCanvas";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Asterisk } from "lucide-react";
import { Controller, useFormContext } from "react-hook-form";

export const FormSketchCanvas = ({ name, label, description, disabled, requiredIndicator = false, ...rest }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="grid gap-2">
          <Label htmlFor={name} className={cn("flex flex-row gap-1 items-center", errors[name] && "text-destructive")}>
            {typeof label === "string" ? i18n.t(label) : label}
            {requiredIndicator ? <Asterisk size={16} className="text-destructive" /> : null}
          </Label>
          <SketchCanvas id={name} onChange={onChange} value={value} disabled={disabled} {...rest} />
          {description && (
            <span className="text-sm text-muted-foreground mt-1">
              {typeof description === "string" ? i18n.t(description) : description}
            </span>
          )}
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
