import { Button } from "@/components/Button";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { cn } from "@/lib/utils";
import { BellRing, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

export const DefaultNotification = ({ notification, icon = null }) => {
  if (!notification?.data) return;
  const { causer, message, title, link } = notification.data;

  return (
    <div className="flex flex-row gap-3">
      <div>{<NotificationIcon icon={icon} causer={causer} />}</div>
      <div className="w-full">
        <div className={cn("text-base font-semibold flex flex-col items-start justify-center gap-1")}>
          <span>{title}</span>
          <span className="font-normal">
            <div>{message}</div>
          </span>
        </div>
        {link !== null && (
          <Link to={link}>
            <Button
              variant="link"
              className="px-0 mx-0 flex flex-row items-center"
              title="Zobacz"
              rightIcon={<ChevronRight size={16} />}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export const NotificationIcon = ({ causer, icon, className }) => {
  if (icon) {
    return <div className={cn("h-10 w-10 flex items-center justify-center", className)}>{icon}</div>;
  }
  if (causer) {
    return <UserAvatar user={causer} className={className} />;
  }
  return (
    <div className={cn("h-10 w-10 flex items-center justify-center", className)}>
      <BellRing size={20} />
    </div>
  );
};
