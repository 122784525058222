import { ContractPaymentType } from "@/features/contracts/enums/ContractPaymentTypeEnum";
import { getContractPaymentSums } from "@/features/contracts/helpers/getContractPaymentsSums";
export const ContractPaymentSource = Object.freeze({
  CLIENT: { value: "CLIENT", name: "Klient" },
  LOAN: { value: "LOAN", name: "Kredyt" },
  DONATION: { value: "DONATION", name: "Dotacja" },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },

  getForContractPaymentType(paymentType) {
    if (paymentType === ContractPaymentType.LOAN.value) return [this.CLIENT, this.LOAN];
    if (paymentType === ContractPaymentType.PREFINANCED.value) return [this.CLIENT, this.DONATION];
    if (paymentType === ContractPaymentType.CASH.value) return [this.CLIENT];
    return [];
  },

  getAvailableForContract(contract) {
    let options = [];
    const { totalClientPaid, totalDonationPaid, totalLoanPaid } = getContractPaymentSums(contract.contractPayments);
    switch (contract.payment_type) {
      case ContractPaymentType.CASH.value:
        options.push(this.CLIENT);
        break;
      case ContractPaymentType.LOAN.value:
        if (totalClientPaid < contract.down_payment) options.push(this.CLIENT);
        if (contract.gross_price - contract.down_payment > totalLoanPaid) options.push(this.LOAN);
        break;
      case ContractPaymentType.PREFINANCED.value:
        if (totalClientPaid < contract.down_payment) options.push(this.CLIENT);
        if (contract.gross_price - contract.down_payment > totalDonationPaid) options.push(this.DONATION);
        break;
    }
    return options;
  },
});
