import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import i18n from "@/i18n";
import { TriangleAlert } from "lucide-react";

export function WarningAlertDialog({ message, trigger, onConfirm, title = "Wymagane potwierdzenie" }) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex flex-row gap-2 items-center">
            <TriangleAlert size={20} className="text-primary" />
            {i18n.t(title)}
          </AlertDialogTitle>
          <AlertDialogDescription>{typeof message === "string" ? i18n.t(message) : message}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{i18n.t("Anuluj")}</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm} className="bg-primary hover:bg-primary/80 gap-1">
            {i18n.t("Potwierdzam")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
