import { EmptyState } from "@/components/EmptyState";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { updateCalculatorGrantOrder, updateCalculatorSectionOrder } from "@/features/calculators/api/calculatorApi";
import { CalculatorAdditionalCost } from "@/features/calculators/components/Configuration/AdditionalCosts/CalculatorAdditionalCost";
import { CalculatorAdditionalCostCreateDialog } from "@/features/calculators/components/Configuration/AdditionalCosts/CalculatorAdditionalCostCreateDialog";
import { CalculatorDiscount } from "@/features/calculators/components/Configuration/Discounts/CalculatorDiscount";
import { CalculatorDiscountCreateDialog } from "@/features/calculators/components/Configuration/Discounts/CalculatorDiscountCreateDialog";
import { CalculatorGrant } from "@/features/calculators/components/Configuration/Grants/CalculatorGrant";
import { CalculatorGrantCreateDialog } from "@/features/calculators/components/Configuration/Grants/CalculatorGrantCreateDialog";
import { CalculatorSection } from "@/features/calculators/components/Configuration/Sections/CalculatorSection";
import { CalculatorSectionCreateDialog } from "@/features/calculators/components/Configuration/Sections/CalculatorSectionCreateDialog";
import i18n from "@/i18n";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const CalculatorConfiguration = ({ calculator }) => {
  const [sections, setSections] = useState(calculator.sections);
  const [additionalCosts, setAdditionalCosts] = useState(calculator.additionalCosts);
  const [grants, setGrants] = useState(calculator.grants);
  const [discounts, setDiscounts] = useState(calculator.discounts);

  const updateCalculatorSectionOrderMutation = useMutation({
    mutationFn: updateCalculatorSectionOrder,
    onSuccess: (res) => {
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const updateCalculatorGrantOrderMutation = useMutation({
    mutationFn: updateCalculatorGrantOrder,
    onSuccess: (res) => {
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleSectionDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sections.findIndex((section) => section.id === active.id);
      const newIndex = sections.findIndex((section) => section.id === over.id);
      const newSections = arrayMove(sections, oldIndex, newIndex);
      setSections(newSections);
      updateCalculatorSectionOrderMutation.mutate({
        calculatorId: calculator.id,
        data: {
          section_ids: newSections.map((section) => section.id),
        },
      });
    }
  };

  const handleGrantDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = grants.findIndex((grant) => grant.id === active.id);
      const newIndex = grants.findIndex((grant) => grant.id === over.id);
      const newGrants = arrayMove(grants, oldIndex, newIndex);
      setGrants(newGrants);
      updateCalculatorGrantOrderMutation.mutate({
        calculatorId: calculator.id,
        data: {
          grant_ids: newGrants.map((grant) => grant.id),
        },
      });
    }
  };

  useEffect(() => {
    if (calculator) {
      setSections(calculator.sections);
      setAdditionalCosts(calculator.additionalCosts);
      setGrants(calculator.grants);
      setDiscounts(calculator.discounts);
    }
  }, [calculator]);

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      <DndContext onDragEnd={handleSectionDragEnd}>
        <SortableContext items={sections.map((section) => section.id)} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-4 items-center justify-start flex-1">
            <div className="flex flex-row justify-between items-center w-full">
              <h2 className="font-semibold text-muted-foreground">{i18n.t("Sekcje")}</h2>
              <CalculatorSectionCreateDialog />
            </div>
            {sections.length === 0 && <EmptyState title="Brak sekcji" />}
            {sections?.map((section) => (
              <CalculatorSection section={section} key={section.id} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <div className="flex flex-col gap-16 items-center justify-start flex-1">
        <div className="flex flex-col gap-4 items-center justify-start w-full">
          <div className="flex flex-row justify-between items-center w-full">
            <h2 className="font-semibold text-muted-foreground">{i18n.t("Koszty dodatkowe")}</h2>
            <CalculatorAdditionalCostCreateDialog />
          </div>
          {additionalCosts.length === 0 && <EmptyState title="Brak kosztów dodatkowych" />}
          {additionalCosts?.map((cost) => (
            <CalculatorAdditionalCost cost={cost} key={cost.id} />
          ))}
        </div>
        <div className="flex flex-col gap-4 items-center justify-start w-full">
          <div className="flex flex-row justify-between items-center w-full">
            <h2 className="font-semibold text-muted-foreground">{i18n.t("Dotacje")}</h2>
            <CalculatorGrantCreateDialog />
          </div>
          <DndContext onDragEnd={handleGrantDragEnd}>
            <SortableContext items={grants.map((grant) => grant.id)} strategy={verticalListSortingStrategy}>
              {grants.length === 0 && <EmptyState title="Brak dotacji" />}
              {grants?.map((grant) => (
                <CalculatorGrant grant={grant} key={grant.id} />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <div className="flex flex-col gap-4 items-center justify-start w-full">
          <div className="flex flex-row justify-between items-center w-full">
            <h2 className="font-semibold text-muted-foreground">{i18n.t("Rabaty")}</h2>
            <CalculatorDiscountCreateDialog />
          </div>
          {discounts.length === 0 && <EmptyState title="Brak rabatów" />}
          {discounts?.map((discount) => (
            <CalculatorDiscount discount={discount} key={discount.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
