import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, parse } from "date-fns";
import { Check, MoreHorizontal, Pencil, Pin, PinOff, Trash, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  note: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const Note = ({ note, handleDelete, handleEdit, canDelete = false, canEdit = false }) => {
  const [editMode, setEditMode] = useState(false);

  const form = useForm({
    defaultValues: { note: note.note },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onSubmit = (data) => {
    if (note.note !== data.note) handleEdit(note.id, { note: data.note });
    setEditMode(false);
  };

  const handlePin = () => {
    handleEdit(note.id, { pinned: !note.pinned });
  };

  return (
    <Card className={cn("w-full transition-all", note.pinned && "bg-accent/50")}>
      <CardHeader className="font-semibold leading-none tracking-tight flex flex-row justify-between items-center py-2">
        <UserTooltip user={note.user} linkClassName="py-2 m-0" />
        {(canEdit || canDelete) && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="ghost" className="h-8 w-8 p-0 text-foreground">
                <span className="sr-only">{i18n.t("Otwórz menu")}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {canEdit && (
                <>
                  <DropdownMenuItem
                    className="flex flex-row gap-2 font-semibold text-sm"
                    onClick={() => setEditMode(!editMode)}
                  >
                    <Pencil size={20} className="text-primary" />
                    {i18n.t("Edytuj")}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {canDelete && (
                <>
                  <DropdownMenuItem className="flex flex-row gap-2 font-semibold text-sm" onClick={handlePin}>
                    {note.pinned ? (
                      <PinOff className="text-primary" size={20} />
                    ) : (
                      <Pin size={20} className="text-primary" />
                    )}
                    {note.pinned ? i18n.t("Odepnij notatkę") : i18n.t("Przypnij notatkę")}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {canDelete && (
                <>
                  <DropdownMenuItem
                    className="flex flex-row gap-2 font-semibold text-sm"
                    onClick={(e) => e.preventDefault()}
                  >
                    <DeleteAlertDialog
                      message="Czy na pewno chcesz usunąć tę notatkę? Tej operacji nie można cofnąć"
                      onConfirm={() => handleDelete(note.id)}
                      trigger={
                        <Button
                          variant="ghost"
                          size="xs"
                          title="Usuń"
                          className="font-semibold"
                          leftIcon={<Trash className="text-primary" size={20} />}
                        />
                      }
                    />
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </CardHeader>
      <CardContent className="text-sm text-muted-foreground" onDoubleClick={() => canEdit && setEditMode(true)}>
        {editMode ? (
          <Form form={form} onSubmit={onSubmit} className="w-full mt-3 mb-3">
            <div className="flex flex-col gap-5">
              <FormTextarea name="note" label="Treść" placeholder="Treść notatki" className="text-foreground" />
              <div className="flex flex-row gap-3 items-center justify-end">
                <Button
                  title="Anuluj"
                  type="button"
                  variant="destructive"
                  leftIcon={<X size={20} />}
                  onClick={() => setEditMode(false)}
                />
                <Button title="Zapisz" leftIcon={<Check size={20} />} />
              </div>
            </div>
          </Form>
        ) : (
          <p>{note.note}</p>
        )}
      </CardContent>
      <CardFooter>
        <p className="text-sm text-muted-foreground">
          {format(parse(note.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy HH:mm:ss")}
        </p>
      </CardFooter>
    </Card>
  );
};
