import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { getCreditProcesses, updateCreditProcessProgress } from "@/features/credits/api/creditApi";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { ProcessesCard } from "@/features/processes/components/ProcessCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const CreditProcessesCard = () => {
  const { id: creditId } = useParams();
  const creditPolicy = useCreditPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["credit", creditId, "processes"],
    queryFn: () => getCreditProcesses(creditId),
    enabled: creditPolicy.viewProcesses(),
  });

  const updateCreditProcessProgressMutation = useMutation({
    mutationFn: updateCreditProcessProgress,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["credit", creditId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["credit", creditId, "processes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleUpdateCreditProcess = (processId, step, completed) => {
    updateCreditProcessProgressMutation.mutate({
      creditId,
      processId,
      data: { step_id: step.id, completed },
    });
  };

  return (
    <ProcessesCard
      policy={creditPolicy}
      handleCheck={handleUpdateCreditProcess}
      isLoading={isLoading}
      processes={response?.data}
    />
  );
};
