import api from "@/api/api";
import {
  APPLICATIONS_ENDPOINT,
  CLIENTS_ENDPOINT,
  INSTALLATIONS_ENDPOINT,
  SURVEY_ENTRIES_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getClients = (filters = null) => api.get(CLIENTS_ENDPOINT, transformQueryFilters(filters));

export const getClient = (clientId) => api.get(CLIENTS_ENDPOINT + clientId);

export const createClient = (data) => api.post(CLIENTS_ENDPOINT, data);

export const updateClient = ({ id, data }) => api.patch(CLIENTS_ENDPOINT + id, data);

export const deleteClient = (clientId) => api.delete(CLIENTS_ENDPOINT + clientId);

export const getClientApplications = (clientId) => api.get(CLIENTS_ENDPOINT + clientId + APPLICATIONS_ENDPOINT);

export const getClientInstallations = (clientId) => api.get(CLIENTS_ENDPOINT + clientId + INSTALLATIONS_ENDPOINT);

export const getClientSurveyEntries = (clientId) => api.get(CLIENTS_ENDPOINT + clientId + SURVEY_ENTRIES_ENDPOINT);
