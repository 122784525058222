import { AccountRoutes } from "@/features/account/routes/AccountRoutes";
import { ApplicationRoutes } from "@/features/applications/routes/ApplicationRoutes";
import { AuditRoutes } from "@/features/audits/routes/AuditRoutes";
import { AuthRoutes } from "@/features/auth/routes/AuthRoutes";
import { AutomationRoutes } from "@/features/automations/routes/AutomationRoutes";
import { BillingRoutes } from "@/features/billings/routes/BillingRoutes";
import { CalculationRoutes } from "@/features/calculations/routes/CalculationRoutes";
import { CalculatorRoutes } from "@/features/calculators/routes/CalculatorRoutes";
import { CalendarRoutes } from "@/features/calendar/routes/CalendarRoutes";
import { ClientRoutes } from "@/features/clients/routes/ClientRoutes";
import { ContractRoutes } from "@/features/contracts/routes/ContractRoutes";
import { CreditRoutes } from "@/features/credits/routes/CreditRoutes";
import { DashboardRoutes } from "@/features/dashboard/routes/DashboardRoutes";
import { FileRoutes } from "@/features/files/routes/FileRoutes";
import { InstallationRoutes } from "@/features/installations/routes/InstallationRoutes";
import { LeadRoutes } from "@/features/leads/routes/LeadRoutes";
import { NotificationRoutes } from "@/features/notifications/routes/NotificationRoutes";
import { OrderRoutes } from "@/features/orders/routes/OrderRoutes";
import { ProductRoutes } from "@/features/products/routes/ProductRoutes";
import { RoleRoutes } from "@/features/roles/routes/RoleRoutes";
import { SurveyRoutes } from "@/features/surveys/routes/SurveyRoutes";
import { TaskRoutes } from "@/features/tasks/routes/TaskRoutes";
import { UserRoutes } from "@/features/users/routes/UserRoutes";
import { WarehouseRoutes } from "@/features/warehouses/routes/WarehouseRoutes";
import { NotFoundPage } from "@/pages/NotFoundPage";
import { ProtectedRoute } from "@/routes/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

export const AppRoutes = () => {
  return (
    <Routes>
      {AuthRoutes()}
      <Route exact path="/" element={<ProtectedRoute />}>
        {AccountRoutes()}
        {DashboardRoutes()}
        {UserRoutes()}
        {LeadRoutes()}
        {CalendarRoutes()}
        {TaskRoutes()}
        {FileRoutes()}
        {ContractRoutes()}
        {CalculatorRoutes()}
        {CalculationRoutes()}
        {NotificationRoutes()}
        {WarehouseRoutes()}
        {ProductRoutes()}
        {OrderRoutes()}
        {BillingRoutes()}
        {InstallationRoutes()}
        {AuditRoutes()}
        {ApplicationRoutes()}
        {ClientRoutes()}
        {SurveyRoutes()}
        {RoleRoutes()}
        {CreditRoutes()}
        {AutomationRoutes()}
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
