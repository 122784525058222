import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { Check, Eraser } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";

export const SignatureWithBackground = ({ backgroundImage, onSave }) => {
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [signature, setSignature] = useState("");
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const drawImage = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();
    image.src = backgroundImage;

    image.onload = () => {
      canvas.width = 800;
      canvas.height = 400;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      setIsImageLoaded(true);

      if (!signaturePadRef.current) {
        const signaturePad = new SignaturePad(canvas, {
          backgroundColor: "rgba(0, 0, 0, 0)",
          penColor: "black",
        });
        signaturePadRef.current = signaturePad;
      }
    };
  };

  useEffect(() => {
    drawImage();
    return () => {
      signaturePadRef.current?.off();
    };
  }, [backgroundImage]);

  const handleClear = () => {
    drawImage();
    signaturePadRef.current.clear();
    setSignature("");
  };

  const handleSave = () => {
    if (signaturePadRef.current.isEmpty()) {
      toast({ title: <ErrorToast title="Podpis jest pusty" /> });
    } else {
      const signatureData = signaturePadRef.current.toDataURL("image/png");
      setSignature(signatureData);
      onSave(signatureData);
    }
  };

  return (
    <>
      {!isImageLoaded && <Loader />}
      <div className={cn("px-5 pt-5 bg-white rounded-md", !isImageLoaded && "hidden")}>
        <canvas ref={canvasRef} />
      </div>
      <div className="flex flex-row justify-end gap-2">
        <Button title="Wyczyść" leftIcon={<Eraser size={20} />} variant="outline" type="button" onClick={handleClear} />
        <Button
          title="Zatwierdź"
          leftIcon={<Check size={20} />}
          variant={signature.length > 0 ? "success" : "outline"}
          type="button"
          onClick={handleSave}
        />
      </div>
    </>
  );
};
