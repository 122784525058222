import { isAddressNotEmpty } from "@/helpers/isAddressNotEmpty";

export const displayAddress = (model) => {
  if (!model?.address) return false;
  if (isAddressNotEmpty(model)) {
    return `${model.address.street_number ? model.address.street_number + "," : ""} ${
      model.address.postal_code ?? ""
    } ${model.address.city ?? ""} ${model.address.voivodeship ? `(${model.address.voivodeship})` : ""}`;
  } else {
    return null;
  }
};
