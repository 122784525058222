import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { InstallationServiceEditDialog } from "@/features/installations/components/InstallationServices/InstallationServiceEditDialog";
import { useInstallationCrewPolicy } from "@/features/installations/policies/useInstallationCrewPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useInstallationServicesTableColumns = (handleDelete) => {
  const installationServicePolicy = useInstallationServicePolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const contractPolicy = useContractPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installationService = row.original;
        if (installationServicePolicy.view()) {
          return (
            <Link
              to={`${routes.installationServices}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {installationService.name}
            </Link>
          );
        } else {
          return <span>{installationService.name}</span>;
        }
      },
    },
    {
      id: "contract",
      name: "Umowa",
      accessorKey: "contract",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Umowa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installationService = row.original;
        if (contractPolicy.view()) {
          return (
            <Link
              to={`${routes.contracts}/${row.original.contract.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {installationService.contract.identifier}
            </Link>
          );
        } else {
          return <span>{installationService.contract.identifier}</span>;
        }
      },
    },
    {
      id: "service_date",
      name: "Data serwisu",
      accessorKey: "service_date",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data serwisu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installationService = row.original;
        return (
          <span>
            {installationService.service_date
              ? format(parse(installationService.service_date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
              : i18n.t("Brak")}
          </span>
        );
      },
    },
    {
      id: "installationCrew",
      name: "Ekipa",
      accessorKey: "installationCrew",
      header: i18n.t("Ekipa"),
      cell: ({ row }) => {
        const installationService = row.original;
        if (installationCrewPolicy.view()) {
          return (
            <Link
              to={`${routes.installationCrews}/${row.original?.installationCrew?.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {installationService?.installationCrew?.name}
            </Link>
          );
        } else {
          return <span>{installationService?.installationCrew?.name}</span>;
        }
      },
    },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: ({ column }) => (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          {i18n.t("Data utworzenia")}
          {column.getIsSorted() === "asc" ? (
            <ArrowDown className="h-4 w-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ArrowUp className="h-4 w-4" />
          ) : (
            <ArrowUpDown className="h-4 w-4" />
          )}
        </Button>
      ),
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const installationService = row.original;
        if (!installationServicePolicy.destroy(installationService) && !installationServicePolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <InstallationServiceEditDialog
                installationService={installationService}
                trigger={
                  <DropdownMenuItem className="flex flex-row items-center" onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
              {installationServicePolicy.destroy(installationService) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(installationService.id)}
                    message="Czy na pewno chcesz usunąć ten serwis? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
