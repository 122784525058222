import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { EmptyState } from "@/components/EmptyState";
import { Layout } from "@/components/layout/Layout";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteBillingSetting, deleteBillingSettingRule, getBillingSetting } from "@/features/billings/api/billingApi";
import { BillingSettingEditDialog } from "@/features/billings/components/BillingSettings/BillingSettingEditDialog";
import { BillingSettingRuleCreateDialog } from "@/features/billings/components/BillingSettings/Rules/BillingSettingRuleCreateDialog";
import { BillingSettingRuleEditDialog } from "@/features/billings/components/BillingSettings/Rules/BillingSettingRuleEditDialog";
import { useBillingSettingPolicy } from "@/features/billings/policies/useBillingSettingPolicy";
import { formatMoney } from "@/helpers/formatMoney";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const BillingSettingDetailsPage = () => {
  const { id: billingSettingId } = useParams();
  const navigate = useNavigate();
  const billingSettingPolicy = useBillingSettingPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["billingSetting", billingSettingId],
    queryFn: () => getBillingSetting(billingSettingId),
  });
  useDocumentTitle(response?.data ? `Model rozliczeń ${response?.data?.name}` : null);

  const deleteBillingSettingMutation = useMutation({
    mutationFn: deleteBillingSetting,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billingSettings"] });
      if (res.ok) {
        return navigate(routes.billings, { state: { tab: "billing_settings" } });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const deleteBillingSettingRuleMutation = useMutation({
    mutationFn: deleteBillingSettingRule,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billingSetting", billingSettingId] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDeleteSetting = () => {
    deleteBillingSettingMutation.mutate({ billingSettingId });
  };

  const handleDeleteSettingRule = (billingSettingRuleId) => {
    deleteBillingSettingRuleMutation.mutate({ billingSettingId, billingSettingRuleId });
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <BillingSettingEditDialog
            billingSetting={response?.data}
            trigger={<Button title="Edytuj" variant="outline" leftIcon={<Pencil size={16} />} />}
          />
          {billingSettingPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć ten model? Tej operacji nie można cofnąć."
              trigger={
                <Button
                  title="Usuń model"
                  variant="outline"
                  leftIcon={<Trash size={16} />}
                  isLoading={deleteBillingSettingMutation.isPending}
                />
              }
              onConfirm={handleDeleteSetting}
            />
          )}
        </div>
      </div>
      <div className="w-1/2 flex flex-col gap-5">
        {response?.data?.rules?.length === 0 && <EmptyState title="Brak reguł" />}
        {response?.data?.rules.map((rule, index) => (
          <Card key={rule.id}>
            <CardHeader className="flex flex-row justify-between items-center">
              <div className="flex flex-col gap-1.5">
                <CardTitle>{i18n.t("Reguła") + " " + (index + 1)}</CardTitle>
              </div>
              <div className="flex flex-row items-center gap-3">
                <BillingSettingRuleEditDialog
                  trigger={<Button variant="outline" title="Edytuj" leftIcon={<Pencil size={20} />} />}
                  billingSettingRule={rule}
                />
                {billingSettingPolicy.destroy() && (
                  <DeleteAlertDialog
                    message="Czy na pewno chcesz usunąć tę regułę? Tej operacji nie można cofnąć."
                    trigger={
                      <Button
                        title="Usuń"
                        variant="destructive"
                        leftIcon={<Trash size={16} />}
                        isLoading={deleteBillingSettingRuleMutation.isPending}
                      />
                    }
                    onConfirm={() => handleDeleteSettingRule(rule.id)}
                  />
                )}
              </div>
            </CardHeader>
            <CardContent className="flex flex-col gap-3">
              <p className="flex flex-row gap-1 items-center">
                <p className="font-medium text-muted-foreground">{i18n.t("Użytkownik otrzymuje")}</p>
                {index === 0 ? (
                  <p className="font-semibold">
                    {rule.type === "percentage" && formatMoney(rule.percentage, "%") + " wartości należnej prowizji"}
                    {rule.type === "amount" && formatMoney(rule.amount) + " z prowizji na umowie"}
                  </p>
                ) : (
                  <p className="font-semibold">
                    {rule.type === "percentage" &&
                      formatMoney(rule.percentage, "%") + " pozostałej wartości należnej prowizji"}
                    {rule.type === "amount" && formatMoney(rule.amount) + " z prowizji na umowie"}
                  </p>
                )}
              </p>
              <div className="flex flex-row items-center gap-3">
                <p className="font-medium text-muted-foreground">{i18n.t("gdy umowa osiągnie status:")}</p>
                <ColorBadge color={rule.contractStatus.color}>{rule.contractStatus.name}</ColorBadge>
              </div>
            </CardContent>
          </Card>
        ))}
        <BillingSettingRuleCreateDialog billingSetting={response?.data} />
      </div>
    </Layout>
  );
};
