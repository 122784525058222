import { useAuth } from "@/features/auth/hooks/useAuth";

export const useAuditNotePolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_audit_notes");
  };

  const create = () => {
    return hasPermission("create_audit_note");
  };

  const update = () => {
    return hasPermission("update_audit_note");
  };

  const destroy = () => {
    return hasPermission("delete_audit_note");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
