import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteFileCategory, updateFileCategory } from "@/features/files/api/fileApi";
import { FileCategoryContext } from "@/features/files/context/FileCategoryContext";
import { useFileCategoriesTableColumns } from "@/features/files/hooks/useFileCategoriesTableColumns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const FileCategoriesTable = () => {
  const { categories, isLoading } = useContext(FileCategoryContext);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryClient = useQueryClient();

  const handleEdit = (fileCategoryId, data) => {
    updateFileCategoryMutation.mutate({ fileCategoryId, data });
  };

  const handleDelete = (fileCategoryId) => {
    deleteFileCategoryMutation.mutate({ fileCategoryId });
  };

  const updateFileCategoryMutation = useMutation({
    mutationFn: updateFileCategory,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["fileCategories"] });
        queryClient.invalidateQueries({ queryKey: ["files"] });
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteFileCategoryMutation = useMutation({
    mutationFn: deleteFileCategory,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["fileCategories"] });
        queryClient.invalidateQueries({ queryKey: ["files"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        if (res.status === 400) {
          toast({
            title: <ErrorToast title="Nie można usunąć tej kategorii ponieważ są pliki które ją posiadają" />,
          });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
        }
      }
    },
  });

  const { columns } = useFileCategoriesTableColumns(handleEdit, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : categories,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: categories.length,
  });

  return <Table table={table} isLoading={isLoading} />;
};
