import { useAuth } from "@/features/auth/hooks/useAuth";
import { getContractStatuses } from "@/features/contracts/api/contractApi";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ContractStatusContext = createContext();

export function ContractStatusProvider({ children }) {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const { user } = useAuth();

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["contractStatuses"],
    queryFn: getContractStatuses,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setStatuses(response.data);
      setStatusOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <ContractStatusContext.Provider value={{ statuses, isLoading, statusOptions }}>
      {children}
    </ContractStatusContext.Provider>
  );
}
