import api from "@/api/api";
import { ACTIONS_ENDPOINT, AUTOMATIONS_ENDPOINT, TRIGGERS_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getAutomations = () => api.get(AUTOMATIONS_ENDPOINT);

export const getAutomation = (automationId) => api.get(AUTOMATIONS_ENDPOINT + automationId);

export const createAutomation = ({ data }) => api.post(AUTOMATIONS_ENDPOINT, data);

export const updateAutomation = ({ automationId, data }) => api.put(AUTOMATIONS_ENDPOINT + automationId, data);

export const deleteAutomation = ({ automationId }) => api.delete(AUTOMATIONS_ENDPOINT + automationId);

export const createAutomationTrigger = ({ automationId, data }) =>
  api.post(AUTOMATIONS_ENDPOINT + automationId + TRIGGERS_ENDPOINT, data);

export const updateAutomationTrigger = ({ automationId, automationTriggerId, data }) =>
  api.put(AUTOMATIONS_ENDPOINT + automationId + TRIGGERS_ENDPOINT + automationTriggerId, data);

export const deleteAutomationTrigger = ({ automationId, automationTriggerId }) =>
  api.delete(AUTOMATIONS_ENDPOINT + automationId + TRIGGERS_ENDPOINT + automationTriggerId);

export const createAutomationAction = ({ automationId, data }) =>
  api.post(AUTOMATIONS_ENDPOINT + automationId + ACTIONS_ENDPOINT, data);

export const updateAutomationAction = ({ automationId, automationActionId, data }) =>
  api.put(AUTOMATIONS_ENDPOINT + automationId + ACTIONS_ENDPOINT + automationActionId, data);

export const updateAutomationActionOrder = ({ automationId, data }) =>
  api.put(AUTOMATIONS_ENDPOINT + automationId + ACTIONS_ENDPOINT + "order", data);

export const deleteAutomationAction = ({ automationId, automationActionId }) =>
  api.delete(AUTOMATIONS_ENDPOINT + automationId + ACTIONS_ENDPOINT + automationActionId);

export const getAutomationsLog = (filters = null) =>
  api.get(AUTOMATIONS_ENDPOINT + "logs", transformQueryFilters(filters));
