import { useAuth } from "@/features/auth/hooks/useAuth";
import { getSettings } from "@/features/settings/api/settingApi";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const SettingsContext = createContext();

export function SettingsProvider({ children }) {
  const { user } = useAuth();
  const [settings, setSettings] = useState([]);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: getSettings,
    enabled: user !== null,
    staleTime: 1000 * 60 * 30,
  });

  const getSetting = (key, defaultValue = null) => {
    const setting = settings.find((setting) => setting.key === key);
    return setting?.value ?? defaultValue;
  };

  const getSettingGroup = (group) => {
    return settings.filter((setting) => setting.group === group);
  };

  useEffect(() => {
    if (!isLoading && !error && response) {
      setSettings(response.data);
    }
  }, [response, isLoading, error]);

  return (
    <SettingsContext.Provider value={{ settings, isLoading, getSetting, getSettingGroup }}>
      {children}
    </SettingsContext.Provider>
  );
}
