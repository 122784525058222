import { Button } from "@/components/Button";
import { Text } from "@/components/Text";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { updateProfile } from "@/features/account/api/accountApi";
import { ChangeAvatar } from "@/features/account/components/ChangeAvatar";
import { useAuth } from "@/features/auth/hooks/useAuth";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { Check } from "lucide-react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
  last_name: Yup.string().required(i18n.t("Pole jest wymagane")),
  phone_number: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const ChangeAccountInformation = () => {
  const { user, updateCurrentUser } = useAuth();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: user.first_name ?? undefined,
      last_name: user.last_name ?? undefined,
      phone_number: user.phone_number ?? undefined,
    },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    const response = await updateProfile({ id: user.id, data });
    if (!response.ok) toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
    else {
      updateCurrentUser(response.data);
      toast({ title: <SuccessToast title="Informacje zostały zapisane" /> });
    }
  };

  return (
    <div className="flex flex-row gap-5 w-full">
      <Card className="flex-1">
        <CardHeader>
          <CardTitle>
            <Text translate="Twoje dane" />
          </CardTitle>
          <CardDescription>
            <Text translate="Tu możesz zmienić swoje dane" />
          </CardDescription>
        </CardHeader>
        <Form form={form} onSubmit={onSubmit}>
          <CardContent>
            <div className="flex flex-col gap-8 w-full">
              <FormField label="Imię" name="first_name" autoComplete="first_name" />
              <FormField label="Nazwisko" name="last_name" autoComplete="last_name" />
              <FormField
                label="Numer telefonu"
                name="phone_number"
                inputMode="numeric"
                autoComplete="phone_number"
                description="Numer telefonu widoczny na generowanych ofertach."
              />
            </div>
          </CardContent>
          <CardFooter className="gap-3 items-center border-t px-6 py-4">
            <Button
              type="submit"
              leftIcon={<Check size={20} />}
              title="Zapisz"
              isLoading={form.formState.isSubmitting}
            />
          </CardFooter>
        </Form>
      </Card>
      <ChangeAvatar />
    </div>
  );
};
