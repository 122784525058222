import api from "@/api/api";
import {
  APPLICATION_STATUSES_ENDPOINT,
  APPLICATION_TYPES_ENDPOINT,
  APPLICATIONS_ENDPOINT,
  FILES_ENDPOINT,
  NOTES_ENDPOINT,
  PROCESSES_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getApplicationProcesses = (applicationId) =>
  api.get(APPLICATIONS_ENDPOINT + applicationId + PROCESSES_ENDPOINT);

export const updateApplicationProcessProgress = ({ applicationId, processId, data }) =>
  api.put(APPLICATIONS_ENDPOINT + applicationId + PROCESSES_ENDPOINT + processId, data);

export const getApplicationStatuses = () => api.get(APPLICATION_STATUSES_ENDPOINT);

export const createApplicationStatus = (data) => api.post(APPLICATION_STATUSES_ENDPOINT, data);

export const updateApplicationStatus = ({ applicationStatusId, data }) =>
  api.patch(APPLICATION_STATUSES_ENDPOINT + applicationStatusId, data);

export const updateApplicationStatusOrder = (data) => api.put(APPLICATION_STATUSES_ENDPOINT + "order", data);

export const deleteApplicationStatus = ({ applicationStatusId }) =>
  api.delete(APPLICATION_STATUSES_ENDPOINT + applicationStatusId);

export const updateApplicationsStatus = ({ applicationId, data }) =>
  api.put(APPLICATIONS_ENDPOINT + applicationId + "/status", data);

export const getApplicationTypes = () => api.get(APPLICATION_TYPES_ENDPOINT);

export const createApplicationType = (data) => api.post(APPLICATION_TYPES_ENDPOINT, data);

export const updateApplicationType = ({ applicationTypeId, data }) =>
  api.patch(APPLICATION_TYPES_ENDPOINT + applicationTypeId, data);

export const deleteApplicationType = ({ applicationTypeId }) =>
  api.delete(APPLICATION_TYPES_ENDPOINT + applicationTypeId);

export const getApplications = (filters = null) => api.get(APPLICATIONS_ENDPOINT, transformQueryFilters(filters));

export const getApplication = (applicationId) => api.get(APPLICATIONS_ENDPOINT + applicationId);

export const createApplication = ({ data }) => {
  return api.post(APPLICATIONS_ENDPOINT, data);
};

export const updateApplication = ({ applicationId, data }) => {
  return api.patch(APPLICATIONS_ENDPOINT + applicationId, data);
};

export const deleteApplication = (applicationId) => api.delete(APPLICATIONS_ENDPOINT + applicationId);

export const getApplicationFiles = ({ applicationId, filters }) =>
  api.get(APPLICATIONS_ENDPOINT + applicationId + "/files", transformQueryFilters(filters));

export const createApplicationFiles = ({ applicationId, data }) =>
  api.post(APPLICATIONS_ENDPOINT + applicationId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteApplicationFile = ({ applicationId, fileId }) =>
  api.delete(APPLICATIONS_ENDPOINT + applicationId + FILES_ENDPOINT + fileId);

export const getApplicationNotes = (applicationId) => api.get(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT);

export const createApplicationNote = ({ applicationId, note }) =>
  api.post(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT, { note });

export const updateApplicationNote = ({ applicationId, noteId, note }) =>
  api.put(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT + noteId, note);

export const deleteApplicationNote = ({ applicationId, noteId }) =>
  api.delete(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT + noteId);
