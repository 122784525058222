import { Bell, CircleCheckBig, ClipboardList, FileUser, Mail, MessageSquareText, Wrench } from "lucide-react";

export const AutomationActionType = Object.freeze({
  SendSms: {
    value: "SendSms",
    name: "Wyślij sms",
    icon: (props) => <MessageSquareText {...props} />,
  },
  SendEmail: {
    value: "SendEmail",
    name: "Wyślij email",
    icon: (props) => <Mail {...props} />,
  },
  SendNotification: {
    value: "SendNotification",
    name: "Wyślij powiadomienie",
    icon: (props) => <Bell {...props} />,
  },
  CreateTask: {
    value: "CreateTask",
    name: "Stwórz zadanie",
    icon: (props) => <CircleCheckBig {...props} />,
  },
  CreateAudit: {
    value: "CreateAudit",
    name: "Stwórz audyt",
    icon: (props) => <ClipboardList {...props} />,
  },
  CreateApplication: {
    value: "CreateApplication",
    name: "Stwórz wniosek",
    icon: (props) => <FileUser {...props} />,
  },
  CreateInstallation: {
    value: "CreateInstallation",
    name: "Stwórz montaż",
    icon: (props) => <Wrench {...props} />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },
});
