import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { deleteRole, getRole } from "@/features/roles/api/roleApi";
import { RoleDetails } from "@/features/roles/components/RoleDetails";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const RoleDetailsPage = () => {
  const { id: roleId } = useParams();
  const navigate = useNavigate();
  const rolePolicy = useRolePolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["role", roleId],
    queryFn: () => getRole(roleId),
  });

  useDocumentTitle(response?.data ? `Rola ${response?.data?.name}` : null);

  const deleteRoleMutation = useMutation({
    mutationFn: deleteRole,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (res.ok) {
        return navigate(routes.users);
      } else {
        if (res.status === 400) {
          toast({ title: <ErrorToast title="Użytkownik posiada powtarzające się zadania." /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  const handleDelete = () => {
    if (rolePolicy.destroy(response.data)) {
      deleteRoleMutation.mutate(roleId);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {response && rolePolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń role"
                  isLoading={deleteRoleMutation.isPending}
                  leftIcon={<Trash size={16} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć tą rolę? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <h2 className="text-lg font-bold">{i18n.t("Uprawnienia przypisane do tej roli")}</h2>
      <RoleDetails role={response?.data} />
    </Layout>
  );
};
