import { capitalize } from "@/helpers/capitalize";
import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const Breadcrumbs = ({ idAlias, noParent = false }) => {
  const inactivePaths = ["platnosci", "erp", "kalkulatory", "modele", "ekipy", "kalkulacje"];
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  const translations = {
    uzytkownicy: "użytkownicy",
    platnosci: "Płatności",
    erp: "ERP",
    "montaze-i-serwisy": "Montaże i serwisy",
    "dodaj-produkty": "Dodaj produkty",
    "role-uzytkownikow": "Role użytkowników",
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let currentLink = "";
  const paths = location.pathname.split("/").filter((path) => path !== "");
  const crumbs = paths.map((crumb, index) => {
    let crumbText = "";
    if (crumb.length === 36) {
      crumbText = idAlias;
    } else {
      const text = translations[crumb] ? translations[crumb] : crumb;
      crumbText = capitalize(text);
    }
    currentLink += `/${crumb}`;

    const isLastCrumb = index === paths.length - 1;
    const isNonClickable = inactivePaths.includes(crumb);

    if (isLastCrumb) {
      return (
        <div key={index} className="truncate">
          <li className="flex items-center justify-center gap-3">
            {index !== 0 && !isMobile && <ChevronRight size={16} className="shrink-0" />}
            <p className={cn("font-bold truncate", crumbText === idAlias && "text-primary")} title={crumbText}>
              {crumbText}
            </p>
          </li>
        </div>
      );
    }

    if (isMobile) {
      return null;
    }

    return (
      <div key={index}>
        <li className="flex items-center justify-center gap-3">
          {index !== 0 && <ChevronRight size={16} className="shrink-0" />}
          {noParent || isNonClickable ? (
            <p className={cn(crumbText === idAlias ? "text-primary" : "")}>{crumbText}</p>
          ) : (
            <Link className={cn(crumbText === idAlias ? "text-primary" : "")} to={currentLink}>
              {crumbText}
            </Link>
          )}
        </li>
      </div>
    );
  });

  return (
    <nav aria-label="breadcrumb" className="py-2 w-2/3">
      <ol className="flex h-8 space-x-2 font-semibold text-2xl truncate">
        {isMobile ? crumbs.filter((_, index) => index === crumbs.length - 1) : crumbs}
      </ol>
    </nav>
  );
};
