import api from "@/api/api";
import { TASK_USERS_ENDPOINT, TASKS_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDate, formatDateTime } from "@/helpers/formatDate";

export const getTasks = (filters = null) => api.get(TASKS_ENDPOINT, transformQueryFilters(filters));

export const getTasksStats = (filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(TASKS_ENDPOINT + "stats", queryFilters);
};

export const getDelegatedTasks = (filters = null) =>
  api.get(TASKS_ENDPOINT + "delegated", transformQueryFilters(filters));

export const deleteTask = (taskId) => api.delete(TASKS_ENDPOINT + taskId);

export const updateTask = ({ id, data }) => api.put(TASKS_ENDPOINT + id, data);

export const createTask = ({ data: task }) => {
  const data = {
    ...task,
    due_date: task?.due_date && formatDateTime(task.due_date),
  };
  return api.post(TASKS_ENDPOINT, data);
};

export const getTaskUsers = () => api.get(TASK_USERS_ENDPOINT);
