import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { RoleEditDialog } from "@/features/roles/components/RoleEditDialog";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useRolesTableColumns = (handleDelete) => {
  const rolePolicy = useRolePolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa roli",
      size: 10,
      accessorKey: "name",
      header: i18n.t("Nazwa roli"),
      cell: ({ row }) => {
        const role = row.original;
        if (rolePolicy.view()) {
          return (
            <Link
              className="flex flex-row items-center gap-3 font-medium hover:underline"
              to={`${routes.roles}/${role.id}`}
            >
              {role.name}
            </Link>
          );
        } else return role.name;
      },
    },
    {
      id: "users_count",
      name: "Ilość użytkowników",
      accessorKey: "users_count",
      header: i18n.t("Ilość użytkowników"),
      cell: ({ row }) => row.getValue("users_count"),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const role = row.original;
        if (!rolePolicy.destroy(role) && !rolePolicy.update(role)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <RoleEditDialog
                role={role}
                trigger={
                  <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
              {rolePolicy.destroy(role) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(role.id)}
                    message="Czy na pewno chcesz usunąć tą rolę? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
