import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ApplicationTypeCreateDialog } from "@/features/applications/components/Settings/ApplicationTypes/ApplicationTypeCreateDialog";
import { ApplicationTypes } from "@/features/applications/components/Settings/ApplicationTypes/ApplicationTypes";
import { ApplicationStatusCreateDialog } from "@/features/applications/components/Settings/Statuses/ApplicationStatusCreateDialog";
import { ApplicationStatuses } from "@/features/applications/components/Settings/Statuses/ApplicationStatuses";
import { useApplicationStatusPolicy } from "@/features/applications/policies/useApplicationStatusPolicy";
import { useApplicationTypePolicy } from "@/features/applications/policies/useApplicationTypePolicy";
import { CustomFieldCreateDialog } from "@/features/customFields/components/CustomFieldCreateDialog";
import { CustomFieldSettings } from "@/features/customFields/components/CustomFieldSettings";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { ProcessCreateDialog } from "@/features/processes/components/ProcessCreateDialog";
import { ProcessSettings } from "@/features/processes/components/ProcessSettings";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { SettingsTable } from "@/features/settings/components/SettingsTable";
import { SettingGroup } from "@/features/settings/enums/SettingGroupEnum";
import { useSettings } from "@/features/settings/hooks/useSettings";
import { useSettingPolicy } from "@/features/surveys/policies/useSettingPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const ApplicationSettings = () => {
  const { activeTab, setActiveTab } = useTabs("custom_fields");
  const { getSettingGroup } = useSettings();
  const applicationStatusPolicy = useApplicationStatusPolicy();
  const applicationTypePolicy = useApplicationTypePolicy();
  const processPolicy = useProcessPolicy();
  const settingPolicy = useSettingPolicy();
  const customFieldPolicy = useCustomFieldPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {customFieldPolicy.create() && <TabsTrigger value="custom_fields">{i18n.t("Pola dodatkowe")}</TabsTrigger>}
        {applicationStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {applicationTypePolicy.create() && <TabsTrigger value="sources">{i18n.t("Typy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {customFieldPolicy.create() && (
        <TabsContent value="custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pola dodatkowe")}</h2>
            <CustomFieldCreateDialog appliesTo="Application" />
          </div>
          <CustomFieldSettings appliesTo="Application" />
        </TabsContent>
      )}
      {applicationStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ApplicationStatusCreateDialog />
          </div>
          <ApplicationStatuses />
        </TabsContent>
      )}
      {applicationTypePolicy.create() && (
        <TabsContent value="sources">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy wniosków")}</h2>
            <ApplicationTypeCreateDialog />
          </div>
          <ApplicationTypes />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Application" />
          </div>
          <ProcessSettings model="Application" />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.APPLICATION)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
