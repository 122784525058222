import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { deleteTask, getTasks, updateTask } from "@/features/tasks/api/taskApi";
import { TasksFilters } from "@/features/tasks/components/TasksFilters";
import { TaskStatus } from "@/features/tasks/enums/TaskStatusEnum";
import { useTasksTableColumns } from "@/features/tasks/hooks/useTasksTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const TasksTable = () => {
  const queryClient = useQueryClient();
  const [columnFilters, setColumnFilters] = useState([
    {
      id: "status",
      value: [TaskStatus.IN_PROGRESS.value, TaskStatus.NEW.value],
    },
  ]);
  const [sorting, setSorting] = useState([
    {
      id: "status",
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDelete = (taskId) => {
    deleteTaskMutation.mutate(taskId);
  };

  const deleteTaskMutation = useMutation({
    mutationFn: deleteTask,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const updateTaskStatusMutation = useMutation({
    mutationFn: updateTask,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleEdit = (id, data) => {
    updateTaskStatusMutation.mutate({ id, data });
  };

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["tasks", pagination, sorting, columnFilters],
    queryFn: () => getTasks({ pagination, sorting, columnFilters }),
  });

  const { columns } = useTasksTableColumns(handleDelete, handleEdit);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableColumnResizing: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return (
    <Table table={table} Filters={TasksFilters} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} />
  );
};
