import { Checkbox } from "@/components/forms/Checkbox";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormControl, FormDescription, FormItem, FormLabel } from "@/components/ui/form";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export function FormGroupedCheckboxes({
  name,
  label,
  description,
  groupedOptions,
  fallback,
  defaultValue,
  className,
  groupTranslationPrefix = "",
  ...rest
}) {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext();

  useEffect(() => {
    if (defaultValue && !getValues(name)) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <div className={cn("w-full columns-1 lg:columns-2 gap-x-5", className)}>
          {(label || description) && (
            <div className="mb-4">
              {label && <FormLabel className="text-base">{label}</FormLabel>}
              {description && <FormDescription>{description}</FormDescription>}
            </div>
          )}
          {Object.keys(groupedOptions).length === 0 && fallback ? fallback : null}
          {Object.entries(groupedOptions).map(([groupName, options]) => (
            <Card key={groupName} className="mb-4 break-inside-avoid">
              <CardHeader>
                <CardTitle>
                  {i18n.t(groupTranslationPrefix ? groupTranslationPrefix + "." + groupName : groupName)}
                </CardTitle>
              </CardHeader>
              <CardContent>
                {options.map((item) => (
                  <Controller
                    key={item.value}
                    control={control}
                    name={name}
                    render={({ field }) => {
                      return (
                        <FormItem key={item.value} className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(item.value)}
                              onCheckedChange={(checked) => {
                                const fieldValue = field.value
                                  ? Array.isArray(field.value)
                                    ? field.value
                                    : [field.value]
                                  : [];
                                return checked
                                  ? field.onChange([...fieldValue, item.value])
                                  : field.onChange(fieldValue?.filter((value) => value !== item.value));
                              }}
                              {...rest}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">{item.name}</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                ))}
              </CardContent>
            </Card>
          ))}
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
}
