import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormOptions } from "@/components/forms/FormOptions";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { updateCalculatorField } from "@/features/calculators/api/calculatorApi";
import { CalculatorFieldDependency } from "@/features/calculators/components/Configuration/FieldDependencies/CalculatorFieldDependency";
import { CalculatorFieldDependencyCreateForm } from "@/features/calculators/components/Configuration/FieldDependencies/CalculatorFieldDependencyCreateForm";
import { CalculatorFieldType } from "@/features/calculators/enums/CalculatorFieldTypeEnum";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import { ProductTypeContext } from "@/features/products/context/ProductTypeContext";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Cog, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string().required(i18n.t("Pole jest wymagane")),
  product_type: Yup.string()
    .nullable()
    .when("type", {
      is: CalculatorFieldType.PRODUCT.value,
      then: () => Yup.string().required(i18n.t("Pole jest wymagane dla typu Produkt")),
      otherwise: () => Yup.string().nullable(),
    }),
  field_options: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required(i18n.t("Każda opcja musi mieć nazwę")),
        value: Yup.string().required(i18n.t("Każda opcja musi mieć wartość")),
      }),
    )
    .nullable()
    .when("type", {
      is: (val) =>
        val === CalculatorFieldType.LIST.value ||
        val === CalculatorFieldType.MULTIPLE.value ||
        val === CalculatorFieldType.VAT.value,
      then: () => Yup.array().min(1, i18n.t("Wymagane jest dodanie co najmniej jednej opcji")),
      otherwise: () => Yup.array().nullable(),
    }),
  required: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  has_quantity: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  visible_on_offer: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  display_name: Yup.string().nullable().optional(),
  unit: Yup.string().nullable().optional(),
  price: Yup.number().min(0, i18n.t("Cena musi być większa lub równa 0")).required(i18n.t("Pole jest wymagane")),
  margin: Yup.number().min(0, i18n.t("Marża musi być większa lub równa 0")).required(i18n.t("Pole jest wymagane")),
  multiplier: Yup.number().required(i18n.t("Pole jest wymagane")),
  description: Yup.string().nullable().optional(),
  calculation_formula: Yup.string()
    .optional()
    .nullable()
});

export const CalculatorFieldEditDialog = ({ field }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();
  const calculatorPolicy = useCalculatorPolicy();
  const { typeOptions, isLoading } = useContext(ProductTypeContext);

  const [isOpen, setIsOpen] = useState(false);
  const [create, setCreate] = useState(false);

  const updateCalculatorFieldMutation = useMutation({
    mutationFn: updateCalculatorField,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      if (res.ok) {
        setIsOpen(false);
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const onSubmit = (data) => {
    updateCalculatorFieldMutation.mutate({ calculatorSectionId: field.section_id, calculatorFieldId: field.id, data });
  };

  const defaultValues = {
    name: field.name,
    display_name: field.display_name,
    type: field.type,
    product_type: field.product_type ?? "",
    field_options: field.field_options,
    required: !!field.required,
    has_quantity: !!field.has_quantity,
    visible_on_offer: !!field.visible_on_offer,
    visible: !!field.visible,
    calculable: !!field.calculable,
    unit: field.unit,
    price: field.price,
    margin: field.margin,
    multiplier: field.multiplier,
    description: field.description,
    calculation_formula: field.calculation_formula,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });

  const { type, name, field_options } = form.watch();

  useEffect(() => {
    if (
      type === CalculatorFieldType.LIST.value ||
      type === CalculatorFieldType.PRODUCT.value ||
      type === CalculatorFieldType.MULTIPLE.value
    ) {
      form.setValue("price", 0);
    }
    if (type === CalculatorFieldType.NUMBER.value) {
      form.reset({ ...defaultValues, type: CalculatorFieldType.NUMBER.value, name });
    }
    if (type === CalculatorFieldType.LIST.value) {
      form.reset({ ...defaultValues, type: CalculatorFieldType.LIST.value, name });
    }
    if (type === CalculatorFieldType.PRODUCT.value) {
      form.reset({ ...defaultValues, type: CalculatorFieldType.PRODUCT.value, name });
    }
    if (type === CalculatorFieldType.MULTIPLE.value) {
      form.reset({ ...defaultValues, type: CalculatorFieldType.MULTIPLE.value, name });
    }
    if (type === CalculatorFieldType.ADDITIONAL.value) {
      form.reset({ ...defaultValues, type: CalculatorFieldType.ADDITIONAL.value, name });
    }
    if (type === CalculatorFieldType.VAT.value) {
      form.setValue("display_name", undefined);
      form.setValue("product_type", undefined);
      form.setValue("field_options", []);
      form.setValue("required", true);
      form.setValue("has_quantity", false);
      form.setValue("visible_on_offer", true);
      form.setValue("unit", "%");
      form.setValue("price", 0);
      form.setValue("margin", 0);
      form.setValue("multiplier", 0);
      form.setValue("calculation_formula", "");
      form.setValue("field_options", [
        { name: "23%", value: "23" },
        { name: "8%", value: "8" },
      ]);
    }
    if (type === CalculatorFieldType.COMISSION.value) {
      form.reset({ ...defaultValues, type: CalculatorFieldType.COMISSION.value, name });
    }
  }, [type]);

  if (!calculatorPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button leftIcon={<Cog size={16} />} variant="outline" />
      </DialogTrigger>
      <DialogContent className="max-w-7xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja pola")}</DialogTitle>
          <DialogDescription>{field.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <ScrollArea className="h-[70vh] pr-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="flex flex-col gap-4 mb-5 border p-3 rounded-lg">
                <h2 className="font-medium text-muted-foreground">{i18n.t("Informacje podstawowe")}</h2>
                <FormField label="Nazwa" placeholder="Nazwa pola" name="name" autoComplete="off" />
                <FormSelect options={CalculatorFieldType.getValues()} label="Typ pola" name="type" />
                <FormTextarea name="description" label="Opis pola" />
                {type === CalculatorFieldType.PRODUCT.value && (
                  <FormSelect
                    options={typeOptions}
                    name="product_type"
                    label="Typ produktu do listy"
                    isLoading={isLoading}
                  />
                )}
                {(type === CalculatorFieldType.LIST.value || type === CalculatorFieldType.MULTIPLE.value) && (
                  <FormOptions
                    name="field_options"
                    label="Opcje do wyświetlenia"
                    withDefaults={type === CalculatorFieldType.LIST.value}
                  />
                )}
                {type === CalculatorFieldType.VAT.value && field_options?.length > 0 && (
                  <FormOptions name="field_options" label="Opcje do wyświetlenia" withDefaults={false} />
                )}
                {type !== CalculatorFieldType.COMISSION.value && (
                  <FormCheckbox name="visible" label="Domyślnie widoczne" />
                )}
                {type !== CalculatorFieldType.COMISSION.value && type !== CalculatorFieldType.VAT.value && (
                  <FormCheckbox
                    name="calculable"
                    label="Pole dolicza się do wartości końcowej"
                    description="Ta właściwość pozwala na ustalenie czy wartości tego pola dolicza się do ceny końcowej"
                  />
                )}
                {type !== CalculatorFieldType.VAT.value && <FormCheckbox name="required" label="Pole jest wymagane" />}
                {(type === CalculatorFieldType.LIST.value ||
                  type === CalculatorFieldType.PRODUCT.value ||
                  type === CalculatorFieldType.MULTIPLE.value) && (
                  <FormCheckbox name="has_quantity" label="Pole posiada ilość" />
                )}
              </div>
              {type !== CalculatorFieldType.VAT.value && type !== CalculatorFieldType.COMISSION.value && (
                <div className="flex flex-col gap-4 mb-5 border p-3 rounded-lg">
                  <h2 className="font-medium text-muted-foreground">{i18n.t("Oferta")}</h2>
                  <FormCheckbox name="visible_on_offer" label="Pole widoczne na ofercie" />
                  {type !== CalculatorFieldType.ADDITIONAL.value && (
                    <FormField
                      label="Jednostka na ofercie"
                      placeholder="np. szt., mb., kpl. itd."
                      name="unit"
                      autoComplete="off"
                    />
                  )}
                  <FormField label="Nazwa na ofercie" placeholder="Nazwa pola" name="display_name" autoComplete="off" />
                </div>
              )}
            </div>
            {type !== CalculatorFieldType.VAT.value && (
              <div className="flex flex-col gap-4 mb-5 border p-3 rounded-lg">
                <h2 className="font-medium text-muted-foreground">{i18n.t("Kalkulacja")}</h2>
                {type !== CalculatorFieldType.COMISSION.value && type !== CalculatorFieldType.ADDITIONAL.value && (
                  <FormNumberField
                    label="Cena jednostkowa"
                    name="price"
                    description={
                      type === CalculatorFieldType.LIST.value ||
                      type === CalculatorFieldType.PRODUCT.value ||
                      type === CalculatorFieldType.MULTIPLE.value
                        ? "Wartość pola zostanie wzięta z listy"
                        : null
                    }
                    disabled={
                      type === CalculatorFieldType.LIST.value ||
                      type === CalculatorFieldType.PRODUCT.value ||
                      type === CalculatorFieldType.MULTIPLE.value
                    }
                  />
                )}
                <FormNumberField
                  label="Mnożnik pola w %"
                  name="multiplier"
                  description="To pole pozwala na pomnożenie ceny końcowej pola, cena bazowa to 100% czyli cena większa o 20% to 120%"
                />
                <FormNumberField label="Marża pola w %" name="margin" />
                {type !== CalculatorFieldType.COMISSION.value && type !== CalculatorFieldType.ADDITIONAL.value && (
                  <FormField
                    label="Wzór na całkowitą wartość pola"
                    placeholder="np. identyfikator_pola_cena * moc_instalacji"
                    name="calculation_formula"
                    description="Domyślnie jest to cena jednostkowa pomnożona przez ilość, jeśli pole nie posiada ilości to tylko cena jednostkowa"
                    autoComplete="off"
                  />
                )}
              </div>
            )}
            <div className="flex flex-col gap-3 mb-5 border p-3 rounded-lg">
              <h2 className="font-medium text-muted-foreground">{i18n.t("Zależności względem innych pól")}</h2>
              {field.dependencies.length === 0 && !create && <EmptyState title="Brak zależności" />}
              {field.dependencies.map((dependency) => (
                <CalculatorFieldDependency key={dependency.id} dependency={dependency} field={field} />
              ))}
              <CalculatorFieldDependencyCreateForm field={field} create={create} setCreate={setCreate} />
            </div>
            <DialogFooter>
              <Button
                type="submit"
                title="Zapisz"
                leftIcon={<Check size={20} />}
                isLoading={updateCalculatorFieldMutation.isPending}
              />
              <Button
                type="button"
                title="Anuluj"
                leftIcon={<X size={20} />}
                variant="destructive"
                onClick={() => setIsOpen(false)}
              />
            </DialogFooter>
          </ScrollArea>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
