import api from "@/api/api";
import { CUSTOM_FIELDS_ENDPOINT, LEADS_ENDPOINT } from "@/api/endpoints";

export const getCustomFields = (filters = null) => api.get(CUSTOM_FIELDS_ENDPOINT, filters);

export const createCustomField = ({ data }) => api.post(CUSTOM_FIELDS_ENDPOINT, data);

export const updateCustomField = ({ customFieldId, data }) => api.put(CUSTOM_FIELDS_ENDPOINT + customFieldId, data);

export const deleteCustomField = (customFieldId) => api.delete(CUSTOM_FIELDS_ENDPOINT + customFieldId);

export const getLeadCustomFields = (leadId) => api.get(LEADS_ENDPOINT + leadId + CUSTOM_FIELDS_ENDPOINT);
