export const CustomFieldType = Object.freeze({
  LIST: {
    value: "LIST",
    name: "Lista",
  },
  TEXT: {
    value: "TEXT",
    name: "Tekstowe",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },
});
