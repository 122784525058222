import { useAuth } from "@/features/auth/hooks/useAuth";
import { getContractProducts } from "@/features/contracts/api/contractApi";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ContractProductContext = createContext();

export function ContractProductProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const { user } = useAuth();

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["contractProducts"],
    queryFn: getContractProducts,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setProducts(response.data);
      setProductOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <ContractProductContext.Provider value={{ products, isLoading, productOptions }}>
      {children}
    </ContractProductContext.Provider>
  );
}
