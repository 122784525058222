import { useAuth } from "@/features/auth/hooks/useAuth";

export const useProductTypePolicy = () => {
  const { hasPermission } = useAuth();

  const create = () => {
    return hasPermission("create_product_type");
  };

  const update = (productType) => {
    return hasPermission("update_product_type") && !productType.required;
  };

  const destroy = (productType) => {
    return hasPermission("delete_product_type") && !productType.required;
  };

  return {
    create,
    update,
    destroy,
  };
};
