import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { Bar, BarChart, CartesianGrid, LabelList, Rectangle, XAxis } from "recharts";

export const LeadConversionStat = ({ dateRange, data, isLoading }) => {
  let max = { value: 0, index: 0 };
  const chartData =
    data &&
    Object.keys(data).map((source, index) => {
      const value = data[source];
      if (data[source] > max.value) {
        max.index = index;
        max.value = value;
      }
      if (source === "") source = "Użytkownik";

      return {
        source,
        value,
        fill: `var(--color-${source})`,
      };
    });

  const chartConfig =
    data &&
    Object.keys(data).reduce(
      (config, key, index) => {
        if (key === "") key = "Użytkownik";
        config[key] = {
          label: key,
          color: `hsl(var(--chart-${index + 1}))`,
        };
        return config;
      },
      { value: { label: i18n.t("Ilość") } },
    );

  return (
    <Card className="col-span-4 lg:col-span-2">
      <CardHeader>
        <CardTitle>{i18n.t("Procent konwersji z podziałem na źródła")}</CardTitle>
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent>
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="w-full max-h-[300px]"
        >
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="source"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => chartConfig[value]?.label}
            />
            <Bar
              dataKey="value"
              strokeWidth={2}
              radius={8}
              activeIndex={max.index}
              activeBar={({ ...props }) => {
                return (
                  <Rectangle
                    {...props}
                    fillOpacity={0.8}
                    stroke={props.payload.fill}
                    strokeDasharray={4}
                    strokeDashoffset={4}
                  />
                );
              }}
            >
              <LabelList
                dataKey="value"
                offset={8}
                className="fill-foreground text-sm font-semibold"
                fontSize={12}
                formatter={(val) => val + " %"}
              />
            </Bar>
          </BarChart>
        </ChartContainerWrapper>
      </CardContent>
    </Card>
  );
};
