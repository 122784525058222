import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { renderCustomFields } from "@/features/customFields/helpers/renderCustomFields";
import { useLeadSourcePolicy } from "@/features/leads/policies/useLeadSourcePolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatMoney } from "@/helpers/formatMoney";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { format, parse } from "date-fns";

export const ClientInformationCard = ({ client }) => {
  const clientPolicy = useClientPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const contractPolicy = useContractPolicy();

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o kliencie")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko")}</p>
          <p className="text-right">{client.name}</p>
        </CardRow>
        {leadSourcePolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Źródło")}</p>
            <p className="text-right">{client?.source?.name ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Łączna wartość umów")}</p>
          <p className="flex flex-row h-8 w-32 text-sm justify-center items-center bg-primary rounded-lg font-semibold text-primary-foreground">
            {client.contracts.length
              ? formatMoney(client.contracts.reduce((total, contract) => total + contract.gross_price, 0))
              : formatMoney(0)}
          </p>
        </CardRow>
        {contractPolicy.viewMargin() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Łączna wartość marży z umów")}</p>
            <p className="flex flex-row h-8 w-32 text-sm justify-center items-center bg-green-500 rounded-lg font-semibold text-primary-foreground">
              {client.contracts.length
                ? formatMoney(client.contracts.reduce((total, contract) => total + contract?.margin, 0))
                : formatMoney(0)}
            </p>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
          <p title={client.created_at}>
            {format(parse(client.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <AddressCardRow model={client} modelName="Client" />
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {client.phone_number ? (
            <a className="font-semibold" href={`tel:${client.phone_number}`}>
              {formatPhoneNumber(client.phone_number)}
            </a>
          ) : (
            <p className="text-right">{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres e-mail")}</p>
          {client.email ? (
            <a className="font-semibold" href={`mailto:${client.email}`}>
              {client.email}
            </a>
          ) : (
            <p className="text-right">{i18n.t("Brak")}</p>
          )}
        </CardRow>
        {clientPolicy.viewUsers() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opiekun klienta")}</p>
            {client.user ? <UserTooltip user={client.user} linkClassName="break-all text-right" /> : i18n.t("Brak")}
          </CardRow>
        )}
        {client.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{client.description ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
        {renderCustomFields(client.customFields)}
      </CardContent>
    </Card>
  );
};
