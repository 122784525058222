import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { ContractTypeContext } from "@/features/contracts/context/ContractTypeContext";
import { CustomFieldEditDialog } from "@/features/customFields/components/CustomFieldEditDialog";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import i18n from "@/i18n";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { useContext } from "react";

export const useCustomFieldsTableColumns = (handleDelete, withType = false) => {
  const customFieldPolicy = useCustomFieldPolicy();
  const { types: installationTypes } = useContext(InstallationTypeContext);
  const { types: applicationTypes } = useContext(ApplicationTypeContext);
  const { types: contractTypes } = useContext(ContractTypeContext);

  const columns = [];
  if (withType) {
    columns.push({
      id: "type",
      name: "Typ",
      size: 10,
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const customField = row.original;
        const appliesTo = customField.applies_to;
        switch (appliesTo) {
          case "Installation":
            return installationTypes
              ? installationTypes.find((type) => type.id === customField.type)?.name
              : i18n.t("Typ niedostępny");
          case "Application":
            return applicationTypes
              ? applicationTypes.find((type) => type.id === customField.type)?.name
              : i18n.t("Typ niedostępny");
          case "Contract":
            return contractTypes.find((type) => type.id === customField.type)?.name;
          default:
            return null;
        }
      },
    });
  }

  columns.push(
    ...[
      {
        id: "name",
        name: "Nazwa",
        size: 10,
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {i18n.t("Nazwa")}
              {column.getIsSorted() === "asc" ? (
                <ArrowDown className="h-4 w-4" />
              ) : column.getIsSorted() === "desc" ? (
                <ArrowUp className="h-4 w-4" />
              ) : (
                <ArrowUpDown className="h-4 w-4" />
              )}
            </Button>
          );
        },
        cell: ({ row }) => <div className="flex-1 ms-3 truncate">{row.getValue("name")}</div>,
      },
      {
        id: "required",
        name: "Wymagane",
        size: 10,
        accessorKey: "required",
        header: ({ column }) => {
          return (
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              {i18n.t("Wymagane")}
              {column.getIsSorted() === "asc" ? (
                <ArrowDown className="h-4 w-4" />
              ) : column.getIsSorted() === "desc" ? (
                <ArrowUp className="h-4 w-4" />
              ) : (
                <ArrowUpDown className="h-4 w-4" />
              )}
            </Button>
          );
        },
        cell: ({ row }) => (row.getValue("required") ? i18n.t("Tak") : i18n.t("Nie")),
      },
      {
        id: "actions",
        enableHiding: false,
        cell: ({ row }) => {
          const customField = row.original;
          if (!customFieldPolicy.destroy() && !customFieldPolicy.update()) return null;

          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <CustomFieldEditDialog
                  customField={customField}
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Pencil size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Edytuj")}</span>
                    </DropdownMenuItem>
                  }
                />
                {customFieldPolicy.destroy() && (
                  <>
                    <DropdownMenuSeparator />
                    <DeleteAlertDialog
                      onConfirm={() => handleDelete(customField.id)}
                      message="Czy na pewno chcesz usunąć to pole? Tej operacji nie można cofnąć. Pole zniknie również z rekordów które to pole posiadają"
                      trigger={
                        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                          <Trash size={16} className="mr-1 text-primary" />
                          <span className="font-semibold">{i18n.t("Usuń")}</span>
                        </DropdownMenuItem>
                      }
                    />
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
  );
  return { columns };
};
