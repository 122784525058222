import { EmptyState } from "@/components/EmptyState";
import { Loader } from "@/components/Loader/Loader";
import { getProcesses } from "@/features/processes/api/processApi";
import { ProcessesList } from "@/features/processes/components/ProcessesList";
import { useQuery } from "@tanstack/react-query";

export const ProcessSettings = ({ model }) => {
  const filters = {
    applies_to: model,
  };
  const { isLoading, data: response } = useQuery({
    queryKey: ["processes", filters],
    queryFn: () => getProcesses(filters),
  });

  if (isLoading) return <Loader />;

  return response?.data?.length === 0 ? (
    <EmptyState title="Brak procesów" />
  ) : (
    <ProcessesList processes={response?.data} />
  );
};
