import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { CalculationDetails } from "@/features/calculations/components/CalculationDetails";
import { getContractCalculation } from "@/features/contracts/api/contractApi";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ContractCalculationDetailsPage = () => {
  const { id: contractId } = useParams();

  const { isLoading, data: calculation } = useQuery({
    queryKey: ["contract", contractId, "calculation"],
    queryFn: () => getContractCalculation(contractId),
  });
  useDocumentTitle(`Kalkulacja ${calculation?.data?.name}`);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-8">
        <Breadcrumbs idAlias={calculation?.data?.name} />
      </div>
      <CalculationDetails calculation={calculation?.data} />
    </Layout>
  );
};
