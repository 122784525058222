import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateCalculatorGrant } from "@/features/calculators/api/calculatorApi";
import { CalculatorGrantType } from "@/features/calculators/enums/CalculatorGrantTypeEnum";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Pencil, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string().required(i18n.t("Pole jest wymagane")),
  value: Yup.number().when("type", {
    is: CalculatorGrantType.FIXED.value,
    then: () =>
      Yup.number().min(0, i18n.t("Wartość musi być większa lub równa 0")).required(i18n.t("Pole jest wymagane")),
    otherwise: () => Yup.number().min(1).max(100).required(i18n.t("Pole jest wymagane")),
  }),
  max_amount: Yup.number()
    .nullable()
    .optional()
    .when("type", {
      is: CalculatorGrantType.PERCENT.value,
      then: () => Yup.number().min(0, i18n.t("Wartość musi być większa lub równa 0")),
    }),
});

export const CalculatorGrantEditDialog = ({ grant }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();
  const calculatorPolicy = useCalculatorPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const updateCalculatorGrantMutation = useMutation({
    mutationFn: updateCalculatorGrant,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      if (res.ok) {
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const onSubmit = (data) => {
    updateCalculatorGrantMutation.mutate({ calculatorId, calculatorGrantId: grant.id, data });
  };

  const defaultValues = {
    name: grant.name,
    type: grant.type,
    value: grant.value,
    max_amount: grant.max_amount,
    description: grant.description,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const { type } = form.watch();

  if (!calculatorPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Edytuj" leftIcon={<Pencil size={20} />} />
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja dotacji")}</DialogTitle>
          <DialogDescription>{grant.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 px-1">
            <FormField label="Nazwa" placeholder="Nazwa dotacji" name="name" autoComplete="off" />
            <FormSelect options={CalculatorGrantType.getValues()} label="Typ dotacji" name="type" />
            <FormNumberField name="value" label="Wartość dotacji" />
            {type === CalculatorGrantType.PERCENT.value && (
              <FormNumberField
                label="Maksymalna kwota dotacji"
                name="max_amount"
                description="Jeśli nie występuje, pozostaw pole puste"
              />
            )}
            <FormTextarea name="description" label="Opis" />
            <DialogFooter className="mt-5">
              <Button
                type="submit"
                title="Zapisz"
                leftIcon={<Check size={20} />}
                isLoading={updateCalculatorGrantMutation.isPending}
              />
              <Button
                type="button"
                title="Anuluj"
                leftIcon={<X size={20} />}
                variant="destructive"
                onClick={() => setIsOpen(false)}
              />
            </DialogFooter>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
