import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ThemeToggle } from "@/components/layout/ThemeToggle";
import { SiteLogo } from "@/components/SiteLogo";
import { Text } from "@/components/Text";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { resetPassword } from "@/features/auth/api/authApi";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  password: Yup.string().required(i18n.t("Pole jest wymagane")).min(8, i18n.t("Hasło musi mieć co najmniej 8 znaków")),
  password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], i18n.t("Hasła muszą się zgadzać")),
});

export const ResetPasswordPage = () => {
  useDocumentTitle("Resetowanie hasła");
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    const response = await resetPassword({ ...data, token, email });
    if (response.ok) toast({ title: <SuccessToast title="Hasło zostało zmienione, możesz się teraz zalogować." /> });
    else {
      toast({
        title: (
          <ErrorToast title="Coś poszło nie tak, spróbuj ponownie za pare minut, lub skontaktuj się z administratorem" />
        ),
      });
    }
  };

  useEffect(() => {
    if (user) return navigate(routes.dashboard);
  }, [user]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get("token");
    const emailParam = searchParams.get("email");
    if ((!tokenParam || !emailParam) && (!token || !email)) {
      return navigate(routes.login);
    } else {
      setToken(tokenParam);
      setEmail(emailParam);
      searchParams.delete("token");
      searchParams.delete("email");
      navigate(location.pathname, { replace: true });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <SiteLogo className="mx-auto w-1/2" />
      </div>
      <Card className="w-full max-w-sm mt-8">
        <CardHeader>
          <CardTitle className="text-xl">{i18n.t("Resetowanie hasła")}</CardTitle>
          <CardDescription>{i18n.t("Podaj swoje nowe hasło")}</CardDescription>
        </CardHeader>
        <CardContent>
          <Form form={form} onSubmit={onSubmit}>
            <div className="grid gap-4">
              <FormField name="password" label="Nowe hasło" type="password" autoComplete="new_password" />
              <FormField
                name="password_confirmation"
                label="Potwierdź hasło"
                type="password"
                autoComplete="password_confirmation"
              />
              <Button
                className="w-full mt-3"
                isLoading={form.formState.isSubmitting}
                title="Ustaw nowe hasło"
                type="submit"
              />
            </div>
          </Form>
        </CardContent>
        <CardFooter className="flex flex-col items-center justify-center">
          <Link
            to={routes.login}
            className="flex flex-row items-center justify-center text-sm font-semibold text-primary text-center hover:text-primary/80"
          >
            <ChevronLeft size={16} />
            <Text translate="Strona logowania" />
          </Link>
        </CardFooter>
      </Card>
      <ThemeToggle className="mt-3" />
    </div>
  );
};
