import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { ContractPaymentCreateForm } from "@/features/contracts/components/Payments/ContractPaymentCreateForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useLocation } from "react-router-dom";

export const ContractPaymentCreatePage = () => {
  useDocumentTitle("Dodawanie płatności umowy");
  const {
    state: { contract },
  } = useLocation();

  return (
    <Layout>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs idAlias={contract.identifier} />
      </div>
      <ContractPaymentCreateForm />
    </Layout>
  );
};
