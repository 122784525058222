import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateProductMetadata } from "@/features/products/api/productApi";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Pencil, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  value: Yup.string().required(i18n.t("Pole jest wymagane")),
  unit: Yup.string().nullable(),
});

export const ProductMetadataEditDialog = ({ productMetadata }) => {
  const queryClient = useQueryClient();
  const productPolicy = useProductPolicy();
  const { id: productId } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    updateProductMetadataMutation.mutate({ productId, metadataId: productMetadata.id, data });
  };

  const defaultValues = {
    value: productMetadata.value,
    unit: productMetadata.unit,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateProductMetadataMutation = useMutation({
    mutationFn: updateProductMetadata,
    onSuccess: (res) => {
      if (res.ok) {
        setIsOpen(false);
        queryClient.invalidateQueries({ queryKey: ["product", productId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["products"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!productPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button leftIcon={<Pencil size={16} />} variant="outline" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj metadane")}</DialogTitle>
          <DialogDescription>{i18n.t("Tworzenie dodatkowych atrybutów produktu.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField name="value" label="Wartość" />
            <FormField name="unit" label="Jednostka" placeholder="np. szt. lub mb" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateProductMetadataMutation.isPending}
            />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
