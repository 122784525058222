import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { routes } from "@/routes";
import { Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom";

export const ContractGroupedViewItem = ({ contract }) => {
  const contractPolicy = useContractPolicy();
  return (
    <div
      className="flex flex-col gap-3 items-start py-2 pb-5 px-3 border rounded-md"
      style={{ borderColor: contract.status.color + "80" }}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-1/2">
          {contractPolicy.view() ? (
            <Link
              to={`${routes.contracts}/${contract.id}`}
              className="flex flex-row items-center gap-3 font-semibold text-base hover:underline"
            >
              {contract.name}
            </Link>
          ) : (
            <span className="font-semibold text-base">{contract.name}</span>
          )}
        </div>
        <div className="w-1/2 flex flex-row justify-end px-1">
          {contract.email && (
            <a href={`mailto:${contract.email}`}>
              <Button size="icon" className="m-0 p-0" variant="ghost" leftIcon={<Mail size={16} />} type="button" />
            </a>
          )}
          {contract.phone_number && (
            <a href={`tel:${contract.phone_number}`}>
              <Button size="icon" className="m-0 p-0" variant="ghost" leftIcon={<Phone size={16} />} type="button" />
            </a>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2 text-xs items-center flex-wrap">
        {contract.products.map((product) => (
          <ColorBadge key={product.id} color={product.color}>
            {product.name}
          </ColorBadge>
        ))}
      </div>
      <div className="flex flex-col text-sm text-muted-foreground">
        <div>{contract?.address?.street_number}</div>
        <div>{contract?.address?.postal_code}</div>
        <div>{contract?.address?.city}</div>
      </div>
      <div className="flex flex-col text-sm text-muted-foreground">
        <UserAvatarLink user={contract.created_by} />
      </div>
    </div>
  );
};
