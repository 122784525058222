import { Button } from "@/components/Button";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { CalculatorFieldType } from "@/features/calculators/enums/CalculatorFieldTypeEnum";
import { useCalculatorCustomFields } from "@/features/calculators/hooks/useCalculatorCustomFields";
import i18n from "@/i18n";
import { Info } from "lucide-react";

export function ContractTemplateCalculationInformationDialog({ calculator }) {
  const calculatorFields = calculator.sections
    .flatMap((section) => section.fields)
    .filter(
      (field) => field.type !== CalculatorFieldType.COMISSION.value && field.type !== CalculatorFieldType.VAT.value,
    );
  const calculatorCustomFields = useCalculatorCustomFields();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast({ title: <SuccessToast title="Skopiowano do schowka" /> });
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" size="icon" leftIcon={<Info size={20} />} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{i18n.t("Dostępne pola kalkulatora")}</AlertDialogTitle>
          <AlertDialogDescription>
            {i18n.t(
              "Poniżej znajduje się lista dostępnych pól kalkulatora tego szablonu które system jest w stanie wypełnić. Umieść je w identyfikatorach pól w formularzu PDF",
            )}
            <ScrollArea className="w-full flex max-h-96 flex-col">
              <div className="flex flex-row flex-wrap gap-2 text-primary mt-5 mx-1">
                {calculatorFields.map((field) => {
                  let fieldQuantityContent = "";
                  if (field.has_quantity) {
                    const identifier = `${field.identifier}_ilosc`;
                    fieldQuantityContent = (
                      <span
                        title="Kopiuj"
                        onClick={() => handleCopy(identifier)}
                        key={identifier}
                        className="cursor-pointer border px-2 py-0.5 rounded-md hover:border-primary"
                      >
                        {identifier}
                      </span>
                    );
                  }
                  const fieldContent = (
                    <span
                      title="Kopiuj"
                      onClick={() => handleCopy(field.identifier)}
                      key={field.identifier}
                      className="cursor-pointer border px-2 py-0.5 rounded-md hover:border-primary"
                    >
                      {field.identifier}
                    </span>
                  );
                  return (
                    <>
                      {fieldContent}
                      {fieldQuantityContent}
                    </>
                  );
                })}
                {Object.keys(calculatorCustomFields).map((key) => (
                  <span
                    title="Kopiuj"
                    onClick={() => handleCopy(key)}
                    key={key}
                    className="cursor-pointer border px-2 py-0.5 rounded-md hover:border-primary"
                  >
                    {key}
                  </span>
                ))}
              </div>
            </ScrollArea>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>{i18n.t("Rozumiem")}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
