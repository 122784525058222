import { AutomationActionType } from "@/features/automations/enums/AutomationActionTypeEnum";
import { AutomationLogStatus } from "@/features/automations/enums/AutomationLogStatusEnum";
import { useAutomationResourceTableColum } from "@/features/automations/hooks/useAutomationResourceTableColum";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const useAutomationLogsTableColumns = () => {
  const columns = [
    {
      id: "automation",
      name: "Dodane przez",
      accessorKey: "automation",
      header: () => <div className="px-4">{i18n.t("Automatyzacja")}</div>,
      cell: ({ row }) => {
        const automation = row.original.automation;
        return (
          <Link
            className="flex flex-row gap-1 hover:underline whitespace-nowrap"
            to={`${routes.automations}/${automation.id}`}
          >
            <span className="font-semibold">{automation.name}</span>
          </Link>
        );
      },
    },
    {
      id: "resource",
      name: "Wywołana przez",
      accessorKey: "resource",
      header: i18n.t("Wywołana przez"),
      cell: ({ row }) => useAutomationResourceTableColum(row.original),
    },
    {
      id: "action",
      name: "Akcja",
      accessorKey: "action",
      maxSize: 20,
      header: <span>{i18n.t("Akcja")}</span>,
      cell: ({ row }) => {
        const action = row.original.action;
        return (
          <div className="flex flex-col gap-1">
            <span className="font-semibold">{AutomationActionType[action?.action_type]?.name ?? "Brak akcji"}</span>
          </div>
        );
      },
    },
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: i18n.t("Status"),
      cell: ({ row }) => {
        const automationLog = row.original;
        return (
          <span
            className={cn(
              "rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground",
              AutomationLogStatus[automationLog.status].color,
            )}
          >
            {AutomationLogStatus[automationLog.status].name}
          </span>
        );
      },
    },
    {
      id: "message",
      name: "Wiadomość",
      accessorKey: "message",
      maxSize: 20,
      header: <span>{i18n.t("Wiadomość")}</span>,
      cell: ({ row }) => {
        const message = row.original.message;
        return (
          <div className="flex flex-col gap-1">
            <span className="font-semibold">{message}</span>
          </div>
        );
      },
    },
  ];
  return { columns };
};
