import api from "@/api/api";
import {
  ACTIONS_ENDPOINT,
  AUDITS_ENDPOINT,
  AUDITS_STATUSES_ENDPOINT,
  FILES_ENDPOINT,
  NOTES_ENDPOINT,
  PROCESSES_ENDPOINT,
  USERS_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDateTime } from "@/helpers/formatDate";

export const assignAuditsToUser = ({ userId, auditIds }) =>
  api.post(USERS_ENDPOINT + userId + "/assignAudits", { audit_ids: auditIds });

export const getAuditProcesses = (auditId) => api.get(AUDITS_ENDPOINT + auditId + PROCESSES_ENDPOINT);

export const updateAuditProcessProgress = ({ auditId, processId, data }) =>
  api.put(AUDITS_ENDPOINT + auditId + PROCESSES_ENDPOINT + processId, data);

export const getAuditStatuses = () => api.get(AUDITS_STATUSES_ENDPOINT);

export const createAuditStatus = (data) => api.post(AUDITS_STATUSES_ENDPOINT, data);

export const updateAuditStatus = ({ auditStatusId, data }) => api.patch(AUDITS_STATUSES_ENDPOINT + auditStatusId, data);

export const updateAuditStatusOrder = (data) => api.put(AUDITS_STATUSES_ENDPOINT + "order", data);

export const deleteAuditStatus = ({ auditStatusId }) => api.delete(AUDITS_STATUSES_ENDPOINT + auditStatusId);

export const getAudits = (filters = null) => api.get(AUDITS_ENDPOINT, transformQueryFilters(filters));

export const getAudit = (auditId) => api.get(AUDITS_ENDPOINT + auditId);

export const createAudit = ({ data }) => api.post(AUDITS_ENDPOINT, data);

export const updateAudit = ({ auditId, data }) => api.patch(AUDITS_ENDPOINT + auditId, data);

export const updateAuditsStatus = ({ auditId, data }) => api.put(AUDITS_ENDPOINT + auditId + "/status", data);

export const deleteAudit = (auditId) => api.delete(AUDITS_ENDPOINT + auditId);

export const bulkEditStatusAudits = ({ auditIds, auditStatusId }) =>
  api.post(AUDITS_ENDPOINT + "bulkEditStatus", { audit_ids: auditIds, audit_status_id: auditStatusId });

export const getAuditFiles = ({ auditId, filters }) =>
  api.get(AUDITS_ENDPOINT + auditId + "/files", transformQueryFilters(filters));

export const createAuditFiles = ({ auditId, data }) =>
  api.post(AUDITS_ENDPOINT + auditId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteAuditFile = ({ auditId, fileId }) => api.delete(AUDITS_ENDPOINT + auditId + FILES_ENDPOINT + fileId);

export const getAuditNotes = (auditId) => api.get(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT);

export const createAuditNote = ({ auditId, note }) => api.post(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT, { note });

export const updateAuditNote = ({ auditId, noteId, note }) =>
  api.put(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT + noteId, note);

export const deleteAuditNote = ({ auditId, noteId }) => api.delete(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT + noteId);

export const getAuditActions = (auditId) => api.get(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT);

export const createAuditAction = ({ auditId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT, data);
};

export const updateAuditAction = ({ auditId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT + actionId, data);
};

export const deleteAuditAction = ({ auditId, actionId }) =>
  api.delete(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT + actionId);
