import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { InstallationCrewEditDialog } from "@/features/installations/components/Crews/InstallationCrewEditDialog";
import { useInstallationCrewPolicy } from "@/features/installations/policies/useInstallationCrewPolicy";
import { capitalize } from "@/helpers/capitalize";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useInstallationCrewsTableColumns = (handleDelete) => {
  const installationCrewPolicy = useInstallationCrewPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa ekipy",
      accessorKey: "name",
      header: <span>{i18n.t("Nazwa ekipy")}</span>,
      cell: ({ row }) => {
        const installationCrew = row.original;
        if (installationCrewPolicy.view()) {
          return (
            <Link
              className="flex flex-row items-center gap-3 font-medium hover:underline"
              to={`${routes.installationCrews}/${installationCrew.id}`}
            >
              {installationCrew.name}
            </Link>
          );
        } else {
          return installationCrew.name;
        }
      },
    },
    {
      id: "phone_number",
      name: "Numer telefonu",
      accessorKey: "phone_number",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Numer telefonu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installationCrew = row.original;
        return <a href={`tel:${installationCrew.phone_number}`}>{formatPhoneNumber(installationCrew.phone_number)}</a>;
      },
    },
    {
      id: "voivodeships",
      name: "Województwa",
      accessorKey: "voivodeships",
      header: <span className="w-[300px]">{i18n.t("Województwa")}</span>,
      cell: ({ row }) => {
        const voivodeships = row.original.voivodeships;
        return (
          <div className="w-[300px]">
            {voivodeships?.length === 0 && i18n.t("Brak")}
            {voivodeships.map((voivodeship) => capitalize(voivodeship)).join(", ")}
          </div>
        );
      },
    },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: <span className="text-right"> {i18n.t("Data utworzenia")} </span>,
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const installationCrew = row.original;
        if (!installationCrewPolicy.destroy() && !installationCrewPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <InstallationCrewEditDialog
                installationCrew={installationCrew}
                trigger={
                  <DropdownMenuItem className="flex flex-row items-center w-full" onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
              {installationCrewPolicy.destroy() && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(installationCrew.id)}
                    message="Czy na pewno chcesz usunąć tą ekipę? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
