import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { createProductBundle } from "@/features/products/api/productApi";
import { ProductsContext } from "@/features/products/context/ProductsContext";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  bundled_product_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  quantity: Yup.number().required(i18n.t("Pole jest wymagane")),
});

export const ProductBundleCreateDialog = ({ product }) => {
  const queryClient = useQueryClient();
  const productPolicy = useProductPolicy();
  const { id: productId } = useParams();
  const { products, isLoading: isLoadingProducts } = useContext(ProductsContext);
  const [productOptions, setProductOptions] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const defaultValues = {
    bundled_product_id: undefined,
    quantity: 1,
  };

  const onSubmit = (data) => {
    createProductBundleMutation.mutate({ productId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createProductBundleMutation = useMutation({
    mutationFn: createProductBundle,
    onSuccess: (res) => {
      if (res.ok) {
        setIsOpen(false);
        queryClient.invalidateQueries({ queryKey: ["products"] });
        queryClient.invalidateQueries({ queryKey: ["product", productId, "bundles"] });
        queryClient.invalidateQueries({ queryKey: ["product", productId], exact: true });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (products && !isLoadingProducts) {
      setProductOptions(
        products
          .filter((p) => p.id !== product.id)
          .map((product) => ({ name: `${product.name} (${product.sku})`, value: product.id })),
      );
    }
  }, []);

  if (!productPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj produkty w zestawie")}</DialogTitle>
          <DialogDescription>{product.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormCombobox
              name="bundled_product_id"
              isLoading={isLoadingProducts}
              label="Produkt"
              options={productOptions}
              placeholder="Wybierz produkt w zestawie"
            />
            <FormNumberField
              name="quantity"
              label="Ilość"
              placeholder="Ilość produktu w zestawie"
              positiveOnly={true}
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createProductBundleMutation.isPending}
            />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
