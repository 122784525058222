import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { createClient } from "@/features/clients/api/clientApi";
import { useCustomFields } from "@/features/customFields/hooks/useCustomFields";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { getVoivodeshipOptions } from "@/helpers/getVoivodeshipOptions";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const ClientCreateForm = () => {
  const leadPolicy = useLeadPolicy();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { voivodeshipOptions } = getVoivodeshipOptions();
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const { renderFormCustomFields, customFieldValidationSchema, customFieldDefaultValues } = useCustomFields("Client");

  const validationSchema = Yup.object({
    first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    last_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    phone_number: Yup.string()
      .required(i18n.t("Pole jest wymagane"))
      .min(9, "Numer telefonu musi mieć co najmniej 9 cyfr")
      .max(15, "Numer telefonu może mieć maksymalnie 15 cyfr"),
    email: Yup.string().email(i18n.t("To pole musi być poprawnym adresem email.")),
    street_number: Yup.string().required(i18n.t("Pole jest wymagane")),
    postal_code: Yup.string().required(i18n.t("Pole jest wymagane")),
    city: Yup.string().required(i18n.t("Pole jest wymagane")),
    voivodeship: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
    user_id: Yup.string().nullable(),
    ...customFieldValidationSchema,
  });

  const defaultValues = {
    first_name: undefined,
    last_name: undefined,
    phone_number: undefined,
    email: undefined,
    street_number: undefined,
    postal_code: undefined,
    city: undefined,
    voivodeship: undefined,
    description: undefined,
    ...customFieldDefaultValues,
  };

  const onSubmit = (data) => {
    createClientMutation.mutate(data);
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createClientMutation = useMutation({
    mutationFn: createClient,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      if (res.ok) {
        if (res?.data?.id) {
          return navigate(`${routes.clients}/${res?.data?.id}`);
        } else return navigate(routes.clients);
      } else {
        if (res.data.message.search("The phone number has already been taken.") >= 0) {
          toast({ title: <ErrorToast title="Taki numer telefonu istnieje już w systemie" /> });
        } else if (res.data.message.search("The email has already been taken.") >= 0) {
          toast({ title: <ErrorToast title="Taki adres email istnieje już w systemie" /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormField name="first_name" label="Imię" autoComplete="first_name" />
            <FormField name="last_name" label="Nazwisko" autoComplete="last_name" />
            <FormField name="email" type="email" autoComplete="email" label="Email" />
            <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
            <FormField name="street_number" label="Ulica i numer" />
            <div className="flex flex-row gap-3 items-center">
              <FormField name="postal_code" label="Kod pocztowy" />
              <FormField name="city" label="Miasto" />
            </div>
            <FormCombobox
              options={voivodeshipOptions}
              name="voivodeship"
              label="Województwo"
              required={false}
              isLoading={isLoadingUsers}
            />
            <FormTextarea name="description" label="Opis" />
            {leadPolicy.assignUserLeads() && (
              <FormCombobox
                required={false}
                name="user_id"
                label="Opiekun klienta"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
            {renderFormCustomFields()}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            title="Dodaj"
            leftIcon={<Plus size={20} />}
            isLoading={createClientMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(routes.clients)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
