import { useAuth } from "@/features/auth/hooks/useAuth";
import { getProductTypes } from "@/features/products/api/productApi";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ProductTypeContext = createContext();

export function ProductTypeProvider({ children }) {
  const [types, setTypes] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const { user } = useAuth();

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["productTypes"],
    queryFn: getProductTypes,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setTypes(response.data);
      setTypeOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <ProductTypeContext.Provider value={{ types, isLoading, typeOptions }}>{children}</ProductTypeContext.Provider>
  );
}
