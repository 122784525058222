import { useAuth } from "@/features/auth/hooks/useAuth";

export const useContractMessagePolicy = () => {
  const { hasPermission, user } = useAuth();

  const viewAny = () => {
    return hasPermission("access_contract_messages");
  };

  const create = () => {
    return hasPermission("create_contract_message");
  };

  const destroy = (message) => {
    if (hasPermission("delete_any_contract_message")) return true;
    return hasPermission("delete_contract_message") && message?.user?.id === user.id;
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
