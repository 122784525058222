import { CalculationDetailsPage } from "@/features/calculations/pages/CalculationDetailsPage";
import { CalculationSurveysPage } from "@/features/calculations/pages/CalculationSurveysPage";
import { SurveyProvider } from "@/features/surveys/context/SurveyContext";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const CalculationRoutes = () => (
  <>
    <Route exact path={routes.calculation} element={<CalculationDetailsPage />} />
    <Route
      exact
      path={routes.calculationSurveys}
      element={
        <SurveyProvider>
          <CalculationSurveysPage />
        </SurveyProvider>
      }
    />
  </>
);
