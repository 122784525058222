import { useAuth } from "@/features/auth/hooks/useAuth";

export const useCreditPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_own_credits", "access_all_credits", "access_created_credits"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_credits");
  };

  const view = () => {
    return hasAnyPermission(["show_any_credit", "show_own_credit", "show_created_credit"]);
  };

  const create = () => {
    return hasPermission("create_credit");
  };

  const update = () => {
    return hasPermission("update_credit");
  };

  const updateStatus = () => {
    return hasPermission("update_credits_status");
  };

  const destroy = () => {
    return hasPermission("delete_credit");
  };

  const viewProcesses = () => {
    return hasPermission("access_credit_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_credit_process");
  };

  const bulkEditStatus = () => {
    return hasPermission("bulk_edit_credit_status");
  };

  const assignUserCredits = () => {
    return hasPermission("assign_user_credits");
  };

  return {
    viewAny,
    viewAll,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewProcesses,
    updateProcess,
    bulkEditStatus,
    assignUserCredits,
  };
};
