import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { getUnreadNotifications } from "@/features/notifications/api/notificationApi";
import { NotificationBell } from "@/features/notifications/components/NotificationBell";
import { NotificationDropdownItem } from "@/features/notifications/components/NotificationDropdownItem";
import { isEmpty } from "@/helpers/isEmpty";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const NotificationDropdown = ({ title, className, variant = "link" }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { isLoading, data: response } = useQuery({
    queryKey: ["notifications", "unread"],
    queryFn: () => getUnreadNotifications({ limit: 5 }),
    enabled: !isEmpty(user),
    // refetchInterval: 1000 * 30,
  });

  const handleRedirect = () => {
    return navigate(routes.notifications);
  };

  return variant === "link" ? (
    <Link
      className="mt-3 w-full px-3 py-1.5 hover:bg-accent flex flex-row font-semibold items-center gap-2 rounded-md hover:cursor-pointer"
      to={routes.notifications}
    >
      <NotificationBell isActive={response?.data?.length > 0} />
      {i18n.t("Powiadomienia")}
    </Link>
  ) : variant === "hover-card" ? (
    <HoverCard openDelay={100}>
      <HoverCardTrigger asChild>
        <Link
          variant="ghost"
          to={routes.notifications}
          title={i18n.t("Powiadomienia")}
          className={cn(
            "flex w-full items-center px-4 py-2 font-semibold hover:bg-border transition-colors duration-200 rounded-md cursor-pointer",
            className,
          )}
        >
          <NotificationBell isActive={response?.data?.length > 0} />
          {title && <span className="ml-3 transition-opacity duration-200 text-sm">{i18n.t(title)}</span>}
        </Link>
      </HoverCardTrigger>
      <HoverCardContent className="w-80 p-0 mr-3 text-sm" side="right">
        {isLoading ? (
          <Loader2 className="animate-spin mx-auto my-3" />
        ) : response?.data?.length === 0 ? (
          <EmptyState title="Brak nowych powiadomień" />
        ) : (
          <>
            <div className="flex flex-col items-center justify-center divide-y divide-border">
              {response?.data?.map((notification) => (
                <NotificationDropdownItem notification={notification} key={notification.id} />
              ))}
              <div className="w-full text-center">
                <Button onClick={handleRedirect} variant="link" title="Zobacz wszystkie powiadomienia" />
              </div>
            </div>
          </>
        )}
      </HoverCardContent>
    </HoverCard>
  ) : null;
};
