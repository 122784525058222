import { FileCheck, FileCheck2, FileClock, FilePlus2 } from "lucide-react";

export const BillingStatus = Object.freeze({
  PENDING: {
    value: "PENDING",
    name: "Oczekujące",
    icon: () => <FileClock className="mr-2 h-6 w-6 text-muted-foreground" />,
  },
  PROCESSING: {
    value: "PROCESSING",
    name: "Przetwarzane",
    icon: () => <FileClock className="mr-2 h-6 w-6 text-yellow-500" />,
  },
  NEW: {
    value: "NEW",
    name: "Nowe",
    icon: () => <FilePlus2 className="mr-2 h-6 w-6 text-primary" />,
  },
  ACCEPTED: {
    value: "ACCEPTED",
    name: "Zaakceptowane",
    icon: () => <FileCheck className="mr-2 h-6 w-6 text-green-500" />,
  },
  SETTLED: {
    value: "SETTLED",
    name: "Rozliczone",
    icon: () => <FileCheck2 className="mr-2 h-6 w-6 text-muted-foreground" />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },

  getForEdit() {
    return [this.PENDING, this.NEW, this.ACCEPTED];
  },

  getValuesForEdit() {
    return this.getForEdit().map((status) => status.value);
  },
});
