import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { getUser } from "@/features/users/api/userApi";
import { UserEditForm } from "@/features/users/components/UserEditForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const UserEditPage = () => {
  const { id: userId } = useParams();
  const { isLoading, data: response } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUser(userId),
  });
  useDocumentTitle(response?.data ? `Edycja użytkownika ${response?.data?.name}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs idAlias={response?.data?.name} />
      </div>
      <UserEditForm user={response?.data} />
    </Layout>
  );
};
