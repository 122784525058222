import { useAuth } from "@/features/auth/hooks/useAuth";

export const useFilePolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_files");
  };

  const create = () => {
    return hasPermission("create_file");
  };

  const update = () => {
    return hasPermission("update_file");
  };

  const destroy = () => {
    return hasPermission("delete_file");
  };

  const download = () => {
    return hasPermission("download_file");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
    download,
  };
};
