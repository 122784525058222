import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteUserLevel, getUserLevels } from "@/features/users/api/userApi";
import { useUserLevelsTableColumns } from "@/features/users/hooks/useUserLevelsTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const UserLevelsTable = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const queryClient = useQueryClient();

  const {
    isLoading,
    isFetching,
    data: levels,
  } = useQuery({
    queryKey: ["userLevels"],
    queryFn: getUserLevels,
  });

  const handleDelete = (levelId) => {
    deleteUserLevelMutation.mutate({ levelId });
  };

  const deleteUserLevelMutation = useMutation({
    mutationFn: deleteUserLevel,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["userLevels"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const { columns } = useUserLevelsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : levels?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: levels?.data?.length,
  });

  return <Table table={table} isLoading={isLoading} isFetching={isFetching} />;
};
