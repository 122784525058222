import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CustomFieldCreateDialog } from "@/features/customFields/components/CustomFieldCreateDialog";
import { CustomFieldSettings } from "@/features/customFields/components/CustomFieldSettings";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { InstallationStatusCreateDialog } from "@/features/installations/components/Settings/Statuses/InstallationStatusCreateDialog";
import { InstallationStatuses } from "@/features/installations/components/Settings/Statuses/InstallationStatuses";
import { InstallationTypeCreateDialog } from "@/features/installations/components/Settings/InstallationTypes/InstallationTypeCreateDialog";
import { InstallationTypes } from "@/features/installations/components/Settings/InstallationTypes/InstallationTypes";
import { useInstallationStatusPolicy } from "@/features/installations/policies/useInstallationStatusPolicy";
import { useInstallationTypePolicy } from "@/features/installations/policies/useInstallationTypePolicy";
import { ProcessCreateDialog } from "@/features/processes/components/ProcessCreateDialog";
import { ProcessSettings } from "@/features/processes/components/ProcessSettings";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { SettingsTable } from "@/features/settings/components/SettingsTable";
import { SettingGroup } from "@/features/settings/enums/SettingGroupEnum";
import { useSettings } from "@/features/settings/hooks/useSettings";
import { useSettingPolicy } from "@/features/surveys/policies/useSettingPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const InstallationSettings = () => {
  const { activeTab, setActiveTab } = useTabs("installation_custom_fields");
  const { getSettingGroup } = useSettings();
  const installationStatusPolicy = useInstallationStatusPolicy();
  const installationTypePolicy = useInstallationTypePolicy();
  const processPolicy = useProcessPolicy();
  const settingPolicy = useSettingPolicy();
  const customFieldPolicy = useCustomFieldPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {customFieldPolicy.create() && (
          <TabsTrigger value="installation_custom_fields">{i18n.t("Pola dodatkowe montaży")}</TabsTrigger>
        )}
        {customFieldPolicy.create() && (
          <TabsTrigger value="installation_service_custom_fields">{i18n.t("Pola dodatkowe serwisów")}</TabsTrigger>
        )}
        {installationStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy montaży")}</TabsTrigger>}
        {installationTypePolicy.create() && <TabsTrigger value="types">{i18n.t("Typy montaży")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy montaży")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {customFieldPolicy.create() && (
        <TabsContent value="installation_service_custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pola dodatkowe serwisów")}</h2>
            <CustomFieldCreateDialog appliesTo="InstallationService" />
          </div>
          <CustomFieldSettings appliesTo="InstallationService" />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.INSTALLATION)} />
        </TabsContent>
      )}
      {installationStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <InstallationStatusCreateDialog />
          </div>
          <InstallationStatuses />
        </TabsContent>
      )}
      {installationTypePolicy.create() && (
        <TabsContent value="types">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy")}</h2>
            <InstallationTypeCreateDialog />
          </div>
          <InstallationTypes />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Installation" />
          </div>
          <ProcessSettings model="Installation" />
        </TabsContent>
      )}
      {customFieldPolicy.create() && (
        <TabsContent value="installation_custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pola dodatkowe montaży")}</h2>
            <CustomFieldCreateDialog appliesTo="Installation" />
          </div>
          <CustomFieldSettings appliesTo="Installation" />
        </TabsContent>
      )}
    </Tabs>
  );
};
