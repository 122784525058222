import { Kanban, Map, Table } from "lucide-react";

export const ViewMode = Object.freeze({
  MAP: {
    value: "MAP",
    name: "Widok mapy",
    icon: (props) => <Map {...props} />,
  },
  TABLE: {
    value: "TABLE",
    name: "Widok tabeli",
    icon: (props) => <Table {...props} />,
  },
  GROUPED: {
    value: "GROUPED",
    name: "Widok tablicy",
    icon: (props) => <Kanban {...props} />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((viewMode) => viewMode.name);
  },
});
