import { EmptyState } from "@/components/EmptyState";
import { ButtonContext } from "@/context/ButtonContext";
import { InstallationInformationCard } from "@/features/installations/components/InstallationInformationCard";
import { useContext, useEffect } from "react";

export const ContractInstallationsTab = ({ contract }) => {
  const { showButton, hideButton } = useContext(ButtonContext);

  useEffect(() => {
    showButton("addInstallation");

    return () => {
      hideButton("addInstallation");
    };
  }, []);

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
      {contract.installations?.length === 0 && <EmptyState title="Brak montaży" />}
      {contract.installations &&
        contract.installations.map((installation) => (
          <InstallationInformationCard key={installation.id} installation={installation} />
        ))}
    </div>
  );
};
