import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteClient, getClients } from "@/features/clients/api/clientApi";
import { ClientFilters } from "@/features/clients/components/ClientFilters";
import { ClientsMap } from "@/features/clients/components/ClientsMap";
import { useClientsTableColumns } from "@/features/clients/hooks/useClientsTableColumns";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const ClientsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});

  const handleDelete = (clientId) => {
    deleteClientMutation.mutate(clientId);
  };

  const deleteClientMutation = useMutation({
    mutationFn: deleteClient,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["clients", pagination, sorting, columnFilters],
    queryFn: () => getClients({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useClientsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  return (
    <div className="w-full">
      {viewMode === ViewMode.TABLE.value && (
        <Table
          table={table}
          Filters={ClientFilters}
          meta={response?.meta}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      )}
      {viewMode === ViewMode.MAP.value && <ClientsMap table={table} meta={response?.meta} clients={response?.data} />}
    </div>
  );
};
