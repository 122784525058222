import { ContractPaymentSource } from "@/features/contracts/enums/ContractPaymentSourceEnum";

export const getContractPaymentSums = (contractPayments) => {
  const totalClientPaid = contractPayments
    .filter((payment) => payment.source === ContractPaymentSource.CLIENT.value)
    .reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const totalDonationPaid = contractPayments
    .filter((payment) => payment.source === ContractPaymentSource.DONATION.value)
    .reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const totalLoanPaid = contractPayments
    .filter((payment) => payment.source === ContractPaymentSource.LOAN.value)
    .reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  return { totalClientPaid, totalDonationPaid, totalLoanPaid };
};
