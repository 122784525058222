import { Button } from "@/components/Button";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useBillingSettingPolicy } from "@/features/billings/policies/useBillingSettingPolicy";
import { Role } from "@/features/roles/enums/RoleEnum";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const UserInformationCard = ({ user }) => {
  const billingSettingPolicy = useBillingSettingPolicy();
  const userPolicy = useUserPolicy();

  if (!userPolicy.view(user)) return null;

  return (
    <Card className="w-full lg:w-1/3">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje na temat użytkownika")}</CardDescription>
      </CardHeader>
      <CardHeader className="flex justify-center items-center">
        <UserAvatar user={user} className="w-1/3 h-1/3" />
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko")}</p>
          <p className="text-right">{user.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Rola")}</p>
          <p className="text-right">{Role.displayRoles(user)}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
          <p className="text-right">{user.created_at.split(" ")[0]}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Email")}</p>
          <a className="font-semibold" href={`mailto:${user.email}`}>
            {user.email}
          </a>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {user.phone_number ? (
            <a className="font-semibold" href={`tel:${user.phone_number}`}>
              {user.phone_number}
            </a>
          ) : (
            <p className="text-xs sm:text-base font-medium">{i18n.t("Brak")}</p>
          )}
        </CardRow>
        {billingSettingPolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Model rozliczeń")}</p>
            {user.billingSetting ? (
              <Link className="font-semibold" to={routes.billingSettings + `/${user?.billingSetting?.id}`}>
                <Button variant="link" size="xs">
                  {user?.billingSetting?.name}
                </Button>
              </Link>
            ) : (
              i18n.t("Brak")
            )}
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
