import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { toast } from "@/components/ui/use-toast";
import { impersonate } from "@/features/auth/api/authApi";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { Role } from "@/features/roles/enums/RoleEnum";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, LogIn, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const useUsersTableColumns = (handleDelete) => {
  const { user: authUser, impersonateUser } = useAuth();
  const userPolicy = useUserPolicy();
  const rolePolicy = useRolePolicy();
  const navigate = useNavigate();

  const columns = [
    {
      id: "name",
      name: "Imię i nazwisko",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Imię i nazwisko")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (row.original.id !== authUser.id) {
          if (userPolicy.view(row.original)) {
            return (
              <Link
                to={`${routes.users}/${row.original.id}`}
                className="flex flex-row items-center gap-3 font-medium hover:underline"
              >
                <UserAvatar user={row.original} />
                {row.getValue("name")}
              </Link>
            );
          } else {
            return (
              <div className="flex flex-row items-center gap-3 font-medium">
                <UserAvatar user={row.original} />
                {row.getValue("name")}
              </div>
            );
          }
        } else {
          return (
            <div className="flex flex-row items-center gap-3 font-medium text-primary">
              <UserAvatar user={row.original} />
              {row.getValue("name")}
            </div>
          );
        }
      },
    },
    {
      id: "level",
      name: "Poziom",
      accessorKey: "level",
      header: i18n.t("Poziom"),
      cell: ({ row }) => {
        const userLevel = row.original.level;
        if (userLevel) {
          return <Badge className="py-1">{userLevel.name}</Badge>;
        } else {
          return <p>{i18n.t("Brak")}</p>;
        }
      },
    },
    {
      id: "email",
      name: "Email",
      accessorKey: "email",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Email")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => (
        <a className="font-semibold lowercase hover:underline" href={`mailto:${row.getValue("email")}`}>
          {row.getValue("email")}
        </a>
      ),
    },
    {
      id: "phone_number",
      name: "Numer telefonu",
      accessorKey: "phone_number",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Numer telefonu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (row.getValue("phone_number")) {
          return (
            <a className="font-semibold lowercase hover:underline" href={`tel:${row.getValue("phone_number")}`}>
              {formatPhoneNumber(row.getValue("phone_number"))}
            </a>
          );
        } else {
          return <p>{i18n.t("Brak")}</p>;
        }
      },
    },
    rolePolicy.create()
      ? {
          id: "roles",
          accessorKey: "roles",
          name: "Role",
          enableSorting: false,
          header: () => <p className="text-left px-4">{i18n.t("Role")}</p>,
          cell: ({ row }) => Role.displayRoles(row.original),
        }
      : { id: "roles" },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const user = row.original;
        if (!userPolicy.destroy(user) && !userPolicy.update(user) && !userPolicy.impersonate(user)) return null;
        const handleEdit = () => {
          return navigate(`${routes.users}/${user.id}/edytuj`);
        };

        const handleImpersonate = async () => {
          const response = await impersonate(user.id);
          if (response.ok) {
            await impersonateUser(response.data);
            navigate(routes.dashboard);
          } else {
            if (response.status === 401) {
              toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
            }
          }
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {userPolicy.impersonate(user) && (
                <>
                  <DropdownMenuItem onClick={handleImpersonate} className="flex flex-row items-center">
                    <LogIn size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Zaloguj jako")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {userPolicy.update(user) && (
                <DropdownMenuItem onClick={handleEdit} className="flex flex-row items-center">
                  <Pencil size={16} className="mr-1 text-primary" />
                  <span className="font-semibold">{i18n.t("Edytuj")}</span>
                </DropdownMenuItem>
              )}
              {userPolicy.destroy(user) && userPolicy.update(user) && <DropdownMenuSeparator />}
              {userPolicy.destroy(user) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(user.id)}
                  message="Czy na pewno chcesz usunąć tego użytkownika? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
