import { Loader } from "@/components/Loader/Loader";
import i18n from "@/i18n";

export const FullPageLoader = () => {
  return (
    <div className="fixed inset-20 bg-background flex flex-col items-center justify-center">
      <Loader />
      {i18n.t("Ładowanie...")}
    </div>
  );
};
