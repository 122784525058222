import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateUserLevel } from "@/features/users/api/userApi";
import { useUserLevelPolicy } from "@/features/users/policies/useUserLevelPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  required_points: Yup.number().min(1).nullable(),
  comission_percentage: Yup.number().min(0).max(100).required(i18n.t("Pole jest wymagane")),
});

export const UserLevelEditDialog = ({ trigger, userLevel }) => {
  const queryClient = useQueryClient();
  const userLevelPolicy = useUserLevelPolicy();
  const [isOpen, setIsOpen] = useState(false);

  const isBuiltInLevel = userLevel.required_points === null;

  const onSubmit = (data) => {
    if (isBuiltInLevel) {
      delete data.required_points;
    }
    updateUserLevelMutation.mutate({ levelId: userLevel.id, data });
  };

  const defaultValues = {
    name: userLevel.name,
    required_points: userLevel.required_points,
    comission_percentage: userLevel.comission_percentage,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateUserLevelMutation = useMutation({
    mutationFn: updateUserLevel,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["userLevels"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!userLevelPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja poziomu")}</DialogTitle>
          <DialogDescription>{userLevel.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Nazwa poziomu" placeholder="Nazwa poziomu" name="name" autoComplete="off" />
            {!isBuiltInLevel && (
              <FormNumberField
                label="Wymaga liczba punktów"
                placeholder="Wymaga liczba punktów"
                name="required_points"
              />
            )}
            <FormNumberField
              label="Procent prowizji z kalkulacji"
              placeholder="Procent prowizji z kalkulacji"
              name="comission_percentage"
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateUserLevelMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
