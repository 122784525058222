import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CreditActionsCard } from "@/features/credits/components/Actions/CreditActionsCard";
import { CreditInformationCard } from "@/features/credits/components/CreditInformationCard";
import { CreditNotesCard } from "@/features/credits/components/CreditNotesCard";
import { CreditProcessesCard } from "@/features/credits/components/CreditProcessesCard";
import { CreditFilesTable } from "@/features/credits/components/Files/CreditFilesTable";
import { useCreditFilePolicy } from "@/features/credits/policies/useCreditFilePolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const CreditDetails = ({ credit }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const creditFilePolicy = useCreditFilePolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {creditFilePolicy.viewAny() && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {creditFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <CreditInformationCard credit={credit} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <CreditNotesCard />
            <CreditActionsCard />
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <CreditProcessesCard />
          </div>
        </div>
      </TabsContent>
      {creditFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <CreditFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
