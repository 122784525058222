import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader/Loader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useDownloadCalculationOffer } from "@/features/calculations/hooks/useDownloadCalculationOffer";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, FileDown, List, MoreHorizontal, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const useCalculationsTableColumns = (handleDelete) => {
  const leadCalculationPolicy = useLeadCalculationPolicy();
  const navigate = useNavigate();

  const handleDetails = (calculation) => {
    return navigate(`${routes.calculations}/${calculation.id}`);
  };

  const columns = [
    {
      id: "calculator_name",
      name: "Kalkulator",
      accessorKey: "calculator_name",
      header: i18n.t("Kalkulator"),
      cell: ({ row }) => {
        const calculatorName = row.original.calculator.name;
        return calculatorName;
      },
    },
    {
      id: "name",
      name: "Nazwa",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (leadCalculationPolicy.view(row.original)) {
          return (
            <a className="font-semibold hover:underline cursor-pointer" onClick={() => handleDetails(row.original)}>
              {row.getValue("name")}
            </a>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "created_by",
      name: "Dodany przez",
      accessorKey: "created_by",
      header: i18n.t("Dodany przez"),
      cell: ({ row }) => {
        const creator = row.original.user;
        return <UserAvatarLink user={creator} />;
      },
    },
    {
      id: "net_price",
      name: "Wartość netto",
      accessorKey: "net_price",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Wartość netto")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => formatMoney(row.getValue("net_price")),
    },
    {
      id: "gross_price",
      name: "Wartość brutto",
      accessorKey: "gross_price",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Wartość brutto")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => formatMoney(row.getValue("gross_price")),
    },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data utworzenia")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => row.getValue("created_at"),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const calculation = row.original;
        if (!leadCalculationPolicy.view(calculation) && !leadCalculationPolicy.destroy()) {
          return null;
        }
        const { handleDownloadPdf, offerState } = useDownloadCalculationOffer();

        const handleDownload = (e) => {
          e.preventDefault();
          handleDownloadPdf(calculation);
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {leadCalculationPolicy.view(calculation) && (
                <>
                  {offerState === "loading" ? (
                    <DropdownMenuItem
                      disabled={true}
                      className="flex flex-row gap-2 font-semibold text-sm text-muted-foreground"
                    >
                      <Loader size="h-5 w-5" className="m-0 p-0" />
                      {i18n.t("Ładowanie...")}
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem onSelect={handleDownload} className="flex flex-row items-center">
                      <FileDown size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Pobierz PDF")}</span>
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuSeparator />
                </>
              )}
              {leadCalculationPolicy.view(calculation) && (
                <>
                  <DropdownMenuItem onClick={() => handleDetails(calculation)} className="flex flex-row items-center">
                    <List size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Szczegóły")}</span>
                  </DropdownMenuItem>
                </>
              )}
              {leadCalculationPolicy.destroy() && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(calculation.id)}
                    message="Czy na pewno chcesz usunąć tę kalkulacje? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
