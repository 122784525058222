import { useAuth } from "@/features/auth/hooks/useAuth";
import { getContractTypes } from "@/features/contracts/api/contractApi";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ContractTypeContext = createContext();

export function ContractTypeProvider({ children }) {
  const [types, setTypes] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const contractPolicy = useContractPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["contractTypes"],
    queryFn: getContractTypes,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && contractPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setTypes(response.data);
      setTypeOptions(response.data.map((contractType) => ({ name: contractType.name, value: contractType.id })));
    }
  }, [response, isLoading, error]);

  return (
    <ContractTypeContext.Provider value={{ types, isLoading, typeOptions }}>{children}</ContractTypeContext.Provider>
  );
}
