import { UserCreatePage } from "@/features/users/pages/UserCreatePage";
import { UserDetailsPage } from "@/features/users/pages/UserDetailsPage";
import { UserEditPage } from "@/features/users/pages/UserEditPage";
import { UsersPage } from "@/features/users/pages/UsersPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const UserRoutes = () => (
  <>
    <Route exact path={routes.users} element={<UsersPage />} />
    <Route exact path={routes.userCreate} element={<UserCreatePage />} />
    <Route exact path={routes.userEdit} element={<UserEditPage />} />
    <Route exact path={routes.user} element={<UserDetailsPage />} />
  </>
);
