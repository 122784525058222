import { Loader } from "@/components/Loader/Loader";
import { TablePagination } from "@/components/Table/TablePagination";
import { config } from "@/config";
import { LeadFilters } from "@/features/leads/components/LeadFilters";
import { LeadPopup } from "@/features/leads/components/LeadPopup";
import { LeadSelectedContext } from "@/features/leads/context/LeadSelectedContext";
import { getMapPinIconForModel, getSelectedMapPinIconForModel } from "@/helpers/getMapPinIconForModel";
import "leaflet/dist/leaflet.css";
import { useContext } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const LeadsMap = ({ leads, table, meta, isLoading, isFetching }) => {
  const { selected } = useContext(LeadSelectedContext);
  const mapStartingPoint = config("map.startingPoint");
  const mapZoom = config("map.zoom");

  return (
    <div className="w-full">
      <LeadFilters table={table} withColumns={false} />
      <MapContainer center={mapStartingPoint} zoom={mapZoom}>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup chunkedLoading>
              {leads.map((lead) => {
                if (lead.address) {
                  const isSelected = !!selected.find((v) => v === lead.id);
                  const { latitude, longitude } = lead.address;
                  if (!latitude || !longitude) return null;
                  return (
                    <Marker
                      key={lead.id}
                      position={[parseFloat(latitude), parseFloat(longitude)]}
                      icon={isSelected ? getSelectedMapPinIconForModel("Lead") : getMapPinIconForModel("Lead")}
                    >
                      <Popup maxWidth={500}>
                        <LeadPopup lead={lead} />
                      </Popup>
                    </Marker>
                  );
                }
              })}
            </MarkerClusterGroup>
          </>
        )}
      </MapContainer>
      <TablePagination meta={meta} table={table} />
    </div>
  );
};
