import { Button } from "@/components/Button";
import { Text } from "@/components/Text";
import { AvatarUploadInput } from "@/components/forms/AvatarUploadInput";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { putUserAvatar } from "@/features/account/api/accountApi";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useState } from "react";

export const ChangeAvatar = () => {
  const queryClient = useQueryClient();
  const { user, updateCurrentUser } = useAuth();

  const [file, setFile] = useState(null);

  const updateAvatarMutation = useMutation({
    mutationFn: putUserAvatar,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        updateCurrentUser(res.data);
        setFile(null);
        toast({ title: <SuccessToast title="Zdjęcie profilowe zostało zmienione" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleChangeAvatar = () => {
    if (file) {
      const data = new FormData();
      data.append(`avatar`, file);
      updateAvatarMutation.mutate({ id: user.id, data });
    }
  };

  return (
    <Card className="flex-1 h-fit">
      <CardHeader>
        <CardTitle>
          <Text translate="Zdjęcie profilowe" />
        </CardTitle>
        <CardDescription>
          <Text translate="Tu możesz wgrać swoje zdjęcie profilowe" />
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-row items-start justify-start gap-5 w-full">
        <AvatarUploadInput onFileSelect={setFile} file={file ?? null} className="w-1/3" />
      </CardContent>
      <CardFooter className="gap-3 items-center border-t px-6 py-4">
        <Button
          type="submit"
          leftIcon={<Check size={20} />}
          title="Zapisz"
          onClick={handleChangeAvatar}
          isLoading={updateAvatarMutation.isPending}
        />
      </CardFooter>
    </Card>
  );
};
