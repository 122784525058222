import api from "@/api/api";

export const login = (email, password) => api.post("/auth/login", { email, password });

export const impersonate = (userId) => api.post(`/users/${userId}/impersonate`);

export const getPermissions = () => api.get("/permissions");

export const logout = () => api.post("/auth/logout");

export const authCheck = () => api.post("/auth/check");

export const forgotPassword = ({ email }) => api.post("/forgot-password", { email });

export const resetPassword = (data) => api.post("/reset-password", data);

export const changePassword = (data) => api.put("/change-password", data);
