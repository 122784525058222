import { ActionType } from "@/features/actions/enums/ActionTypeEnum";
import { TaskStatus } from "@/features/tasks/enums/TaskStatusEnum";
import { routes } from "@/routes";
import { parse } from "date-fns";
import { CircleCheck, Wrench } from "lucide-react";

export const getCalendarEvents = (data) => {
  let events = [];
  if (data?.actions?.length) {
    events.push(
      ...data.actions.map((action) => {
        const route = action?.actionable_type.toLowerCase() + "s";
        return {
          title: action.name,
          link: `${routes[route]}/${action?.actionable?.id}`,
          icon: ActionType[action.type]?.icon,
          date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
          description: action.description,
          name: ActionType[action.type]?.name ?? "Działanie",
          className: ActionType[action.type]?.color ?? "bg-yellow-600 hover:bg-yellow-600/90",
        };
      }),
    );
  }
  if (data?.tasks?.length) {
    events.push(
      ...data.tasks
        .filter((task) => !!task.due_date && task.status !== TaskStatus.DONE.value)
        .map((task) => {
          const link =
            task.taskable_type && task.taskable_id
              ? `${routes[task.taskable_type.toLowerCase() + "s"]}/${task.taskable_id}`
              : routes.tasks;
          return {
            title: task.task,
            link,
            icon: (props) => <CircleCheck {...props} />,
            date: parse(task.due_date, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: task.description,
            name: "Zadanie",
            className: "bg-orange-500 hover:bg-orange-500/90",
          };
        }),
    );
  }
  if (data?.installations?.length) {
    events.push(
      ...data.installations
        .filter((installation) => !!installation.date)
        .map((installation) => {
          return {
            title: "Montaż",
            link: `${routes.installations}/${installation.id}`,
            icon: (props) => <Wrench {...props} />,
            date: parse(installation.date, "yyyy-MM-dd", new Date()),
            description: installation.description,
            name: "Montaż",
            className: "bg-indigo-500 hover:bg-indigo-500/90",
          };
        }),
    );
  }
  return events;
};
