import { ActionType } from "@/features/actions/enums/ActionTypeEnum";
import { CalendarView } from "@/features/calendar/components/CalendarView";
import { getLeads } from "@/features/leads/api/leadApi";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { useEffect, useState } from "react";

export const LeadsCalendar = () => {
  const [events, setEvents] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);

  const shouldFetch = dateFilters !== null;

  const filters = shouldFetch
    ? {
        columnFilters: [
          { id: "withActions", value: true },
          { id: "actions_from", value: dateFilters.startDate },
          { id: "actions_to", value: dateFilters.endDate },
        ],
      }
    : null;

  const {
    isLoading,
    data: leads,
    error,
  } = useQuery({
    queryKey: ["leads", filters],
    queryFn: () => getLeads(filters),
    enabled: shouldFetch,
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoading && leads && !error) {
      if (leads?.data?.length) {
        const actions = leads.data.flatMap((lead) => lead.actions);
        setEvents(
          actions.map((action) => ({
            title: `${action.name} - ${leads.data.find((ins) => ins.id === action.actionable.id)?.name}`,
            link: `${routes.leads}/${action?.actionable?.id}`,
            icon: ActionType[action.type]?.icon,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: ActionType[action.type]?.name ?? "Działanie",
            className: ActionType[action.type]?.color ?? "bg-yellow-600 hover:bg-yellow-600/90",
          })),
        );
      }
    }
  }, [isLoading, leads, error]);

  return <CalendarView events={events} setFilters={setDateFilters} isLoading={isLoading} />;
};
