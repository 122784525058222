import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AuditsCalendar } from "@/features/audits/components/AuditsCalendar";
import { AuditsTable } from "@/features/audits/components/AuditsTable";
import { AuditSettings } from "@/features/audits/components/Settings/AuditSettings";
import { useAuditStatusPolicy } from "@/features/audits/policies/useAuditStatusPolicy";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { useViewModes } from "@/features/viewModes/hooks/useVIewModes";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useRef } from "react";

export const AuditsPage = () => {
  useDocumentTitle("Audyty");
  const auditStatusPolicy = useAuditStatusPolicy();
  const { activeTab, setActiveTab } = useTabs();
  const { renderModeSwitcher, viewMode } = useViewModes(
    ViewMode.TABLE,
    [ViewMode.TABLE, ViewMode.MAP, ViewMode.GROUPED],
    activeTab,
  );
  const bottomRef = useRef(null);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {renderModeSwitcher()}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {auditStatusPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
            {auditStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditsTable viewMode={viewMode} />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditsCalendar />
          </div>
        </TabsContent>
        {auditStatusPolicy.create() && (
          <TabsContent value="settings">
            <AuditSettings />
          </TabsContent>
        )}
      </Tabs>
      <div ref={bottomRef}></div>
    </Layout>
  );
};
