import { EmptyState } from "@/components/EmptyState";
import { getCustomFields } from "@/features/customFields/api/customFieldApi";
import { CustomFieldsList } from "@/features/customFields/components/CustomFieldsList";
import { useQuery } from "@tanstack/react-query";

export const CustomFieldSettings = ({ appliesTo }) => {
  const { isLoading, data: customFields } = useQuery({
    queryKey: ["customFields", appliesTo],
    queryFn: () => getCustomFields({ applies_to: appliesTo }),
  });

  return customFields?.data?.length > 0 ? (
    <CustomFieldsList customFields={customFields?.data} isLoading={isLoading} />
  ) : (
    <EmptyState title="Brak pól dodatkowych" />
  );
};
