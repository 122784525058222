import { routes } from "@/routes";
import { Route } from "react-router-dom";
import { ForgotPasswordPage } from "@/features/auth/pages/ForgotPasswordPage";
import { LoginPage } from "@/features/auth/pages/LoginPage";
import { ResetPasswordPage } from "@/features/auth/pages/ResetPasswordPage";

export const AuthRoutes = () => (
  <>
    <Route exact path={routes.login} element={<LoginPage />} />
    <Route exact path={routes.forgotPassword} element={<ForgotPasswordPage />} />
    <Route exact path={routes.resetPassword} element={<ResetPasswordPage />} />
  </>
);
