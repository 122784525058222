import { ApiInterceptors } from "@/api/ApiInterceptors";
import ErrorBoundary from "@/components/ErrorBoundary";
import { ButtonProvider } from "@/context/ButtonContext";
import { LanguageProvider } from "@/context/LanguageContext";
import { ThemeProvider } from "@/context/ThemeContext";
import { ApplicationStatusProvider } from "@/features/applications/context/ApplicationStatusContext";
import { ApplicationTypeProvider } from "@/features/applications/context/ApplicationTypeContext";
import { AuditStatusProvider } from "@/features/audits/context/AuditStatusContext";
import { AuthProvider } from "@/features/auth/context/AuthContext";
import { PermissionProvider } from "@/features/auth/context/PermissionContext";
import { ContractProductProvider } from "@/features/contracts/context/ContractProductContext";
import { ContractStatusProvider } from "@/features/contracts/context/ContractStatusContext";
import { ContractTypeProvider } from "@/features/contracts/context/ContractTypeContext";
import { CreditStatusProvider } from "@/features/credits/context/CreditStatusContext";
import { FileCategoryProvider } from "@/features/files/context/FileCategoryContext";
import { InstallationStatusProvider } from "@/features/installations/context/InstallationStatusContext";
import { InstallationTypeProvider } from "@/features/installations/context/InstallationTypeContext";
import { LeadSourceProvider } from "@/features/leads/context/LeadSourceContext";
import { LeadStatusProvider } from "@/features/leads/context/LeadStatusContext";
import { ProductsProvider } from "@/features/products/context/ProductsContext";
import { ProductTypeProvider } from "@/features/products/context/ProductTypeContext";
import { SettingsProvider } from "@/features/settings/context/SettingsContext";
import { UsersProvider } from "@/features/users/context/UsersContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setDefaultOptions } from "date-fns";
import { pl } from "date-fns/locale";
import { BrowserRouter } from "react-router-dom";

export const AppProviders = ({ children }) => {
  setDefaultOptions({ locale: pl });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10_000,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <PermissionProvider>
        <ThemeProvider>
          <LanguageProvider>
            <AuthProvider>
              <ButtonProvider>
                <BrowserRouter>
                  <ErrorBoundary>
                    <UsersProvider>
                      <FileCategoryProvider>
                        <LeadStatusProvider>
                          <LeadSourceProvider>
                            <ContractProductProvider>
                              <ContractStatusProvider>
                                <ContractTypeProvider>
                                  <ProductTypeProvider>
                                    <InstallationStatusProvider>
                                      <InstallationTypeProvider>
                                        <AuditStatusProvider>
                                          <CreditStatusProvider>
                                            <ApplicationStatusProvider>
                                              <ApplicationTypeProvider>
                                                <SettingsProvider>
                                                  <ProductsProvider>
                                                    <ApiInterceptors />
                                                    {children}
                                                  </ProductsProvider>
                                                </SettingsProvider>
                                              </ApplicationTypeProvider>
                                            </ApplicationStatusProvider>
                                          </CreditStatusProvider>
                                        </AuditStatusProvider>
                                      </InstallationTypeProvider>
                                    </InstallationStatusProvider>
                                  </ProductTypeProvider>
                                </ContractTypeProvider>
                              </ContractStatusProvider>
                            </ContractProductProvider>
                          </LeadSourceProvider>
                        </LeadStatusProvider>
                      </FileCategoryProvider>
                    </UsersProvider>
                  </ErrorBoundary>
                </BrowserRouter>
              </ButtonProvider>
            </AuthProvider>
          </LanguageProvider>
        </ThemeProvider>
      </PermissionProvider>
    </QueryClientProvider>
  );
};
