import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { updateApplicationsStatus } from "@/features/applications/api/applicationApi";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { renderCustomFields } from "@/features/customFields/helpers/renderCustomFields";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const ApplicationInformationCard = ({ application }) => {
  const applicationPolicy = useApplicationPolicy();
  const leadPolicy = useLeadPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(ApplicationStatusContext);

  const { id: applicationId } = useParams();
  const queryClient = useQueryClient();

  const handleStatusClick = (value) => {
    if (value) {
      const data = { application_status_id: value };
      updateApplicationStatusMutation.mutate({ applicationId, data });
    }
  };

  const updateApplicationStatusMutation = useMutation({
    mutationFn: updateApplicationsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      queryClient.invalidateQueries({ queryKey: ["application", applicationId] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      }
    },
  });

  return (
    <div className="flex-1 flex gap-5 flex-col">
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Informacje")}</CardTitle>
          <CardDescription>{i18n.t("Informacje o wniosku")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Typ wniosku")}</p>
            <ColorBadge color={application.type.color}>{application.type.name}</ColorBadge>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia wniosku")}</p>
            <p title={application.created_at}>
              {format(parse(application.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
            </p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Status")}</p>
            {applicationPolicy.updateStatus() || application.amount_of_processes === 0 ? (
              <Select
                className="w-fit text-primary-foreground"
                style={{ backgroundColor: application.status.color }}
                options={statusOptions}
                setValue={handleStatusClick}
                value={application.status.id}
                isLoading={isLoadingStatuses || updateApplicationStatusMutation.isPending}
              />
            ) : (
              <ColorBadge color={application.status.color}>{application.status.name}</ColorBadge>
            )}
          </CardRow>
          {applicationPolicy.viewAll() && (
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
              <UserTooltip user={application.user} />
            </CardRow>
          )}
          {application.description && (
            <CardRow className="flex lg:flex-col lg:items-start gap-1">
              <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
              <p className="text-muted-foreground">{application.description}</p>
            </CardRow>
          )}
          {renderCustomFields(application.customFields)}
        </CardContent>
      </Card>
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Klient")}</CardTitle>
          <CardDescription>{i18n.t("Informacje o kliencie")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko klienta")}</p>
            {leadPolicy.view() ? (
              <Link to={`${routes.clients}/${application.lead.id}`}>
                <Button variant="link" size="xs">
                  {application.lead.name}
                </Button>
              </Link>
            ) : (
              <p className="text-right">{application.lead.name}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu klienta")}</p>
            {application.lead.phone_number ? (
              <a className="font-semibold" href={`tel:${application.lead.phone_number}`}>
                {formatPhoneNumber(application.lead.phone_number)}
              </a>
            ) : (
              <p className="text-right">{i18n.t("none")}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Email klienta")}</p>
            {application.lead.email ? (
              <a className="font-semibold" href={`mailto:${application.lead.email}`}>
                {application.lead.email}
              </a>
            ) : (
              <p className="text-right">{i18n.t("none")}</p>
            )}
          </CardRow>
          <AddressCardRow model={application?.lead} modelName="Lead" />
        </CardContent>
      </Card>
    </div>
  );
};
