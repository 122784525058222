import { useAuth } from "@/features/auth/hooks/useAuth";
import { Role } from "@/features/roles/enums/RoleEnum";
import { getUsers } from "@/features/users/api/userApi";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const UsersContext = createContext();

export function UsersProvider({ children }) {
  const { user: authUser, userHasRole } = useAuth();
  const userPolicy = useUserPolicy();
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    staleTime: 1000 * 60 * 30,
    enabled: authUser !== null && userPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setUsers(response.data);
      setUserOptions(
        response.data
          .filter((u) => !userHasRole(u, Role.SUPERADMIN))
          .map((user) => ({
            name: user.name,
            content: (
              <span className="flex flex-row gap-2 items-center">
                <UserAvatar user={user} className="w-8 h-8" />
                <span className="flex flex-col gap-1 justify-center">
                  <p className=" font-semibold">{user.name}</p>
                  <p className="text-xs">{Role.displayRoles(user)}</p>
                </span>
              </span>
            ),
            value: user.id,
          })),
      );
    }
  }, [response, isLoading, error]);

  return <UsersContext.Provider value={{ users, isLoading, userOptions }}>{children}</UsersContext.Provider>;
}
