import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { AuditStatusContext } from "@/features/audits/context/AuditStatusContext";
import { deleteAutomationTrigger } from "@/features/automations/api/automationApi";
import { AutomationTriggerCreateDialog } from "@/features/automations/components/Triggers/AutomationTriggerCreateDialog";
import { AutomationEventType } from "@/features/automations/enums/AutomationEventTypeEnum";
import { ContractStatusContext } from "@/features/contracts/context/ContractStatusContext";
import { ContractTypeContext } from "@/features/contracts/context/ContractTypeContext";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { LeadStatusContext } from "@/features/leads/context/LeadStatusContext";
import { OrderStatus } from "@/features/orders/enums/OrderStatusEnum";
import { TaskStatus } from "@/features/tasks/enums/TaskStatusEnum";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArrowRight, Trash } from "lucide-react";
import { useContext } from "react";

export const AutomationTrigger = ({ automation }) => {
  const { trigger } = automation;
  const queryClient = useQueryClient();

  const deleteAutomationTriggerMutation = useMutation({
    mutationFn: deleteAutomationTrigger,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
        queryClient.invalidateQueries({ queryKey: ["automation", automation.id] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteAutomationTriggerMutation.mutate({ automationId: automation.id, automationTriggerId: trigger.id });
  };

  if (!trigger) return <AutomationTriggerCreateDialog automation={automation} />;
  return (
    <>
      <AutomationTriggerByEventType trigger={trigger} handleDelete={handleDelete} />
      <DeleteAlertDialog
        message="Czy na pewno chcesz usunąć ten wyzwalacz? Tej operacji nie można cofnąć"
        onConfirm={handleDelete}
        trigger={
          <Button
            title="Usuń wyzwalacz"
            leftIcon={<Trash size={20} />}
            variant="destructive"
            type="button"
            className="mx-auto mt-5"
          />
        }
      />
    </>
  );
};

const AutomationTriggerByEventType = ({ trigger }) => {
  const { statuses: contractStatuses, isLoading: isLoadingContractStatuses } = useContext(ContractStatusContext);
  const { types: contractTypes, isLoading: isLoadingContractTypes } = useContext(ContractTypeContext);
  const { statuses: installationStatuses, isLoading: isLoadingInstallationStatuses } =
    useContext(InstallationStatusContext);
  const { types: installationTypes, isLoading: isLoadingInstallationTypes } = useContext(InstallationTypeContext);
  const { statuses: applicationStatuses, isLoading: isLoadingApplicationStatuses } =
    useContext(ApplicationStatusContext);
  const { types: applicationTypes, isLoading: isLoadingApplicationTypes } = useContext(ApplicationTypeContext);
  const { statuses: auditStatuses, isLoading: isLoadingAuditStatuses } = useContext(AuditStatusContext);
  const { statuses: creditStatuses, isLoading: isLoadingCreditStatuses } = useContext(CreditStatusContext);
  const { statuses: leadStatuses, isLoading: isLoadingLeadStatuses } = useContext(LeadStatusContext);

  switch (trigger.event_type) {
    case AutomationEventType.ContractStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className=" flex flex-col gap-5 items-center">
            {trigger.conditions.type && (
              <div className="flex flex-col gap-1 items-center flex-wrap">
                <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Typ")}</p>
                {renderBadge(trigger.conditions.type, contractTypes, isLoadingContractTypes)}
              </div>
            )}
            <div className="flex flex-col gap-1 items-center">
              <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
              <div className="flex flex-row items-center gap-2 flex-wrap">
                {trigger.conditions.from_status && (
                  <>
                    {renderBadge(trigger.conditions.from_status, contractStatuses, isLoadingContractStatuses)}
                    <ArrowRight size={24} className="text-muted-foreground" />
                  </>
                )}
                {trigger.conditions.to_status &&
                  renderBadge(trigger.conditions.to_status, contractStatuses, isLoadingContractStatuses)}
              </div>
            </div>
          </div>
        </div>
      );
    case AutomationEventType.ContractCreated.value:
    case AutomationEventType.ContractPaymentCreated.value:
    case AutomationEventType.ContractMessageCreated.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className=" flex flex-col gap-5 items-center">
            {trigger.conditions.type && (
              <div className="flex flex-col gap-1 items-center flex-wrap">
                <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Typ")}</p>
                {renderBadge(trigger.conditions.type, contractTypes, isLoadingContractTypes)}
              </div>
            )}
          </div>
        </div>
      );
    case AutomationEventType.InstallationCreated.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className=" flex flex-col gap-5 items-center">
            {trigger.conditions.type && (
              <div className="flex flex-col gap-1 items-center flex-wrap">
                <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Typ")}</p>
                {renderBadge(trigger.conditions.type, installationTypes, isLoadingInstallationTypes)}
              </div>
            )}
          </div>
        </div>
      );
    case AutomationEventType.InstallationStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className=" flex flex-col gap-5 items-center">
            {trigger.conditions.type && (
              <div className="flex flex-col gap-1 items-center flex-wrap">
                <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Typ")}</p>
                {renderBadge(trigger.conditions.type, installationTypes, isLoadingInstallationTypes)}
              </div>
            )}
            <div className="flex flex-col gap-1 items-center">
              <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
              <div className="flex flex-row items-center gap-2 flex-wrap">
                {trigger.conditions.from_status && (
                  <>
                    {renderBadge(trigger.conditions.from_status, installationStatuses, isLoadingInstallationStatuses)}
                    <ArrowRight size={24} className="text-muted-foreground" />
                  </>
                )}
                {trigger.conditions.to_status &&
                  renderBadge(trigger.conditions.to_status, installationStatuses, isLoadingInstallationStatuses)}
              </div>
            </div>
          </div>
        </div>
      );
    case AutomationEventType.ApplicationCreated.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className=" flex flex-col gap-5 items-center">
            {trigger.conditions.type && (
              <div className="flex flex-col gap-1 items-center flex-wrap">
                <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Typ")}</p>
                {renderBadge(trigger.conditions.type, applicationTypes, isLoadingApplicationTypes)}
              </div>
            )}
          </div>
        </div>
      );
    case AutomationEventType.ApplicationStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className=" flex flex-col gap-5 items-center">
            {trigger.conditions.type && (
              <div className="flex flex-col gap-1 items-center flex-wrap">
                <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Typ")}</p>
                {renderBadge(trigger.conditions.type, applicationTypes, isLoadingApplicationTypes)}
              </div>
            )}
            <div className="flex flex-col gap-1 items-center">
              <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
              <div className="flex flex-row items-center gap-2 flex-wrap">
                {trigger.conditions.from_status && (
                  <>
                    {renderBadge(trigger.conditions.from_status, applicationStatuses, isLoadingApplicationStatuses)}
                    <ArrowRight size={24} className="text-muted-foreground" />
                  </>
                )}
                {trigger.conditions.to_status &&
                  renderBadge(trigger.conditions.to_status, applicationStatuses, isLoadingApplicationStatuses)}
              </div>
            </div>
          </div>
        </div>
      );
    case AutomationEventType.AuditStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {trigger.conditions.from_status && (
                <>
                  {renderBadge(trigger.conditions.from_status, auditStatuses, isLoadingAuditStatuses)}
                  <ArrowRight size={24} className="text-muted-foreground" />
                </>
              )}
              {trigger.conditions.to_status &&
                renderBadge(trigger.conditions.to_status, auditStatuses, isLoadingAuditStatuses)}
            </div>
          </div>
        </div>
      );
    case AutomationEventType.CreditStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {trigger.conditions.from_status && (
                <>
                  {renderBadge(trigger.conditions.from_status, creditStatuses, isLoadingCreditStatuses)}
                  <ArrowRight size={24} className="text-muted-foreground" />
                </>
              )}
              {trigger.conditions.to_status &&
                renderBadge(trigger.conditions.to_status, creditStatuses, isLoadingCreditStatuses)}
            </div>
          </div>
        </div>
      );
    case AutomationEventType.OrderStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {trigger.conditions.from_status && (
                <>
                  <span
                    className={cn(
                      "rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground",
                      OrderStatus[trigger.conditions.from_status]?.color,
                    )}
                  >
                    {OrderStatus[trigger.conditions.from_status]?.name}
                  </span>
                  <ArrowRight size={24} className="text-muted-foreground" />
                </>
              )}
              {trigger.conditions.to_status && (
                <span
                  className={cn(
                    "rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground",
                    OrderStatus[trigger.conditions.to_status]?.color,
                  )}
                >
                  {OrderStatus[trigger.conditions.to_status]?.name}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    case AutomationEventType.LeadStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {trigger.conditions.from_status && (
                <>
                  {renderBadge(trigger.conditions.from_status, leadStatuses, isLoadingLeadStatuses)}
                  <ArrowRight size={24} className="text-muted-foreground" />
                </>
              )}
              {trigger.conditions.to_status &&
                renderBadge(trigger.conditions.to_status, leadStatuses, isLoadingLeadStatuses)}
            </div>
          </div>
        </div>
      );
    case AutomationEventType.TaskStatusChanged.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-center text-xs font-medium text-muted-foreground">{i18n.t("Status")}</p>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {trigger.conditions.from_status && (
                <>
                  <span
                    className={cn(
                      "rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground",
                      TaskStatus[trigger.conditions.from_status]?.color,
                    )}
                  >
                    {TaskStatus[trigger.conditions.from_status]?.name}
                  </span>
                  <ArrowRight size={24} className="text-muted-foreground" />
                </>
              )}
              {trigger.conditions.to_status && (
                <span
                  className={cn(
                    "rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground",
                    TaskStatus[trigger.conditions.to_status]?.color,
                  )}
                >
                  {TaskStatus[trigger.conditions.to_status]?.name}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    case AutomationEventType.InstallationServiceCreated.value:
    case AutomationEventType.AuditCreated.value:
    case AutomationEventType.CreditCreated.value:
    case AutomationEventType.OrderCreated.value:
    case AutomationEventType.TaskCreated.value:
      return (
        <div className="font-semibold flex flex-col gap-3 items-center">
          <p className="text-primary border px-2 py-1.5 rounded-md">{AutomationEventType[trigger.event_type]?.name}</p>
        </div>
      );
    default:
      return i18n.t("Niewspierany wyzwalacz, skontaktuj się z administratorem");
  }
};

const renderBadge = (entityId, entities, isLoading = false) => {
  const entity = entities.find((entity) => entity.id === entityId);
  if (isLoading) return <Loader />;
  if (!entity) return i18n.t("Brak");
  return <ColorBadge color={entity.color}>{entity.name}</ColorBadge>;
};
