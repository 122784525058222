import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteProductMetadata } from "@/features/products/api/productApi";
import { ProductMetadataCreateDialog } from "@/features/products/components/Metadata/ProductMetadataCreateDialog";
import { ProductMetadataEditDialog } from "@/features/products/components/Metadata/ProductMetadataEditDialog";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import i18n from "@/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { X } from "lucide-react";
import { useParams } from "react-router-dom";

export const ProductMetadataCard = ({ product }) => {
  const { id: productId } = useParams();

  return (
    <Card className="flex-1 shadow-md h-fit">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Metadane")}</CardTitle>
          <CardDescription>{i18n.t("Dodatkowe atrybuty produktu")}</CardDescription>
        </div>
        <ProductMetadataCreateDialog product={product} />
      </CardHeader>
      <CardContent>
        {!product.metadata.length && <EmptyState title="Brak metadanych" />}
        <div className="flex flex-col justify-center items-center divide-y">
          {product.metadata.map((productMetadata) => {
            return <ProductMetadataRow key={productMetadata.id} metadata={productMetadata} productId={productId} />;
          })}
        </div>
      </CardContent>
    </Card>
  );
};

const ProductMetadataRow = ({ metadata, productId }) => {
  const productPolicy = useProductPolicy();
  const queryClient = useQueryClient();

  const deleteProductMetadataMutation = useMutation({
    mutationFn: deleteProductMetadata,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["product", productId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["products"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDelete = (metadataId) => {
    deleteProductMetadataMutation.mutate({ productId, metadataId });
  };
  return (
    <div className="flex justify-between items-center flex-row w-full p-2">
      <div className="w-full">
        <p className="text-base font-semibold">{metadata.name}</p>
        <p className="flex flex-row gap-1 items-center">
          <p>{metadata?.value}</p>
          <p>{metadata?.unit}</p>
        </p>
      </div>
      <span className="flex flex-row gap-3 items-center w-fit">
        <ProductMetadataEditDialog productMetadata={metadata} />
        {productPolicy.update() && (
          <DeleteAlertDialog
            message="Czy na pewno chcesz usunąć ten atrybut? Tej operacji nie można cofnąć"
            onConfirm={() => handleDelete(metadata.id)}
            trigger={
              <Button
                leftIcon={<X size={16} className="shrink-0" />}
                variant="destructive"
                isLoading={deleteProductMetadataMutation.isPending}
              />
            }
          />
        )}
      </span>
    </div>
  );
};
