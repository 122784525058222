import { useAuth } from "@/features/auth/hooks/useAuth";

export const useAuditFilePolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_audit_files");
  };

  const create = () => {
    return hasPermission("create_audit_file");
  };

  const destroy = () => {
    return hasPermission("delete_audit_file");
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
