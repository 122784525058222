import { useAuth } from "@/features/auth/hooks/useAuth";

export const useContractFileCategoryPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_contract_file_categories");
  };

  const create = () => {
    return hasPermission("create_contract_file_category");
  };

  const destroy = () => {
    return hasPermission("delete_contract_file_category");
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
