import { Button } from "@/components/Button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { LeadStatus } from "@/features/leads/enums/LeadStatusEnum";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, Info } from "lucide-react";
import { Link } from "react-router-dom";

export const useProductPotentialDemandTableColumns = () => {
  const productPolicy = useProductPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa produktu",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa produktu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (productPolicy.view(row.original)) {
          return (
            <Link
              to={`${routes.products}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "type",
      name: "Typ",
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const product = row.original;
        return <span>{product.type.name}</span>;
      },
    },
    {
      id: "price",
      name: "Cena",
      accessorKey: "price",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Cena")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => formatMoney(row.getValue("price")),
    },
    {
      id: "potential_demand",
      name: "Potencjalna ilość produktu do zamówienia",
      accessorKey: "potential_demand",
      header: () => {
        return (
          <div className="flex flex-row gap-2 items-center">
            {i18n.t("Potencjalna ilość produktu do zamówienia")}
            <HoverCard>
              <HoverCardTrigger asChild>
                <Button variant="ghost" size="icon" leftIcon={<Info size={20} />} />
              </HoverCardTrigger>
              <HoverCardContent className="max-w-3xl z-500">
                <p className="text-sm text-muted-foreground flex flex-row flex-wrap">
                  {i18n.t(
                    `Potencjalna ilość produktu do zamówienia liczona jest na podstawie ostatnich kalkulacji z każdego rodzaju kalkulatora, wszystkich kontaktów które mają status inny niż`,
                  )}
                  <span className="flex flex-row flex-wrap">
                    <p className="font-semibold text-primary mr-1">{LeadStatus.RESIGNATION}</p>
                    <p className="font-semibold mr-1">{i18n.t(`oraz`)}</p>
                    <p className="font-semibold text-primary">{LeadStatus.CONTRACT_SIGNED}</p>
                  </span>
                </p>
              </HoverCardContent>
            </HoverCard>
          </div>
        );
      },
      cell: ({ row }) => {
        return parseInt(row.getValue("potential_demand"));
      },
    },
    {
      id: "total_quantity",
      name: "Ilość produktu w magazynach",
      accessorKey: "total_quantity",
      header: i18n.t("Ilość produktu w magazynach"),
      cell: ({ row }) => {
        const product = row.original;
        if (product.warehouses_count) {
          if (product.total_quantity !== null) {
            return parseInt(row.getValue("total_quantity"));
          } else {
            return i18n.t("W magazynie zewnętrznym");
          }
        } else {
          return i18n.t("Nie dodany");
        }
      },
    },
  ];
  return { columns };
};
