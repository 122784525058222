import { getApplicationTypes } from "@/features/applications/api/applicationApi";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ApplicationTypeContext = createContext();

export function ApplicationTypeProvider({ children }) {
  const [types, setTypes] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const applicationPolicy = useApplicationPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["applicationTypes"],
    queryFn: getApplicationTypes,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && applicationPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setTypes(response.data);
      setTypeOptions(
        response.data.map((applicationType) => ({ name: applicationType.name, value: applicationType.id })),
      );
    }
  }, [response, isLoading, error]);

  return (
    <ApplicationTypeContext.Provider value={{ types, isLoading, typeOptions }}>
      {children}
    </ApplicationTypeContext.Provider>
  );
}
