import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SettingsTable } from "@/features/settings/components/SettingsTable";
import { SettingGroup } from "@/features/settings/enums/SettingGroupEnum";
import { useSettings } from "@/features/settings/hooks/useSettings";
import { useSettingPolicy } from "@/features/surveys/policies/useSettingPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const UserSettings = () => {
  const { activeTab, setActiveTab } = useTabs("settings");
  const { getSettingGroup } = useSettings();
  const settingPolicy = useSettingPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.USER)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
