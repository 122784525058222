import { DesktopMenu } from "@/components/layout/Navbar/DesktopMenu";
import { MobileMenu } from "@/components/layout/Navbar/MobileMenu";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useBillingPolicy } from "@/features/billings/policies/useBillingPolicy";
import { getCalculators } from "@/features/calculators/api/calculatorApi";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useFilePolicy } from "@/features/files/policies/useFilePolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import { useSurveyPolicy } from "@/features/surveys/policies/useSurveyPolicy";
import { useTaskPolicy } from "@/features/tasks/policies/useTaskPolicy";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { useWarehousePolicy } from "@/features/warehouses/policies/useWarehousePolicy";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import {
  Boxes,
  Calculator,
  Calendar,
  CircleCheck,
  CircleDollarSign,
  ClipboardList,
  ClipboardPen,
  Download,
  FileSignature,
  FileUser,
  HandCoins,
  Handshake,
  IdCard,
  LayoutDashboard,
  Package,
  PackageSearch,
  Settings,
  User,
  Workflow,
  Wrench,
} from "lucide-react";
import { useEffect, useState } from "react";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { LuWarehouse } from "react-icons/lu";
import { useLocation } from "react-router-dom";

export const Sidebar = () => {
  const { hasPermission } = useAuth();
  const userPolicy = useUserPolicy();
  const leadPolicy = useLeadPolicy();
  const contractPolicy = useContractPolicy();
  const taskPolicy = useTaskPolicy();
  const filePolicy = useFilePolicy();
  const warehousePolicy = useWarehousePolicy();
  const productPolicy = useProductPolicy();
  const billingPolicy = useBillingPolicy();
  const calculatorPolicy = useCalculatorPolicy();
  const installationPolicy = useInstallationPolicy();
  const auditPolicy = useAuditPolicy();
  const applicationPolicy = useApplicationPolicy();
  const clientPolicy = useClientPolicy();
  const surveyPolicy = useSurveyPolicy();
  const rolePolicy = useRolePolicy();
  const creditPolicy = useCreditPolicy();
  const location = useLocation();

  const defaultCalculatorSubmenu = {
    title: "Ustawienia",
    visible: calculatorPolicy.create(),
    to: routes.calculatorsSettings,
    icon: (props) => <Settings {...props} />,
  };

  const [calculatorSubmenu, setCalculatorSubmenu] = useState([defaultCalculatorSubmenu]);

  const {
    data: calculators,
    isLoading: isLoadingCalculators,
    error: errorCalculators,
  } = useQuery({
    queryKey: ["calculators"],
    queryFn: getCalculators,
    staleTime: 1000 * 60 * 5,
    enabled: calculatorPolicy.viewAny(),
  });

  const groupedMenuItems = {
    Główne: [
      {
        title: "Pulpit",
        groupName: "Główne",
        visible: true,
        to: routes.dashboard,
        icon: (props) => <LayoutDashboard {...props} />,
      },
      {
        title: "Klienci",
        groupName: "Główne",
        visible: clientPolicy.viewAny(),
        to: routes.clients,
        icon: (props) => <Handshake {...props} />,
      },
      {
        title: "Zadania",
        groupName: "Główne",
        visible: taskPolicy.viewAny(),
        to: routes.tasks,
        icon: (props) => <CircleCheck {...props} />,
      },
      {
        title: "Pliki do pobrania",
        groupName: "Główne",
        visible: filePolicy.viewAny(),
        to: routes.files,
        icon: (props) => <Download {...props} />,
      },
      {
        title: "Kalendarz",
        groupName: "Główne",
        visible: hasPermission("access_calendar"),
        to: routes.calendar,
        icon: (props) => <Calendar {...props} />,
      },
    ],
    Sprzedaż: [
      {
        title: "Audyty",
        groupName: "Sprzedaż",
        visible: auditPolicy.viewAny(),
        to: routes.audits,
        icon: (props) => <ClipboardList {...props} />,
      },
      {
        title: "Kredyty",
        groupName: "Sprzedaż",
        visible: creditPolicy.viewAny(),
        to: routes.credits,
        icon: (props) => <HandCoins {...props} />,
      },
      {
        title: "Kontakty",
        groupName: "Sprzedaż",
        visible: leadPolicy.viewAny(),
        to: routes.leads,
        icon: FaFilterCircleDollar,
      },
      {
        title: "Rozliczenia",
        groupName: "Sprzedaż",
        visible: billingPolicy.viewAny(),
        to: routes.billings,
        icon: (props) => <CircleDollarSign {...props} />,
      },
      {
        title: "Kalkulatory",
        groupName: "Sprzedaż",
        visible: (calculatorPolicy.viewAny() && calculators?.data?.length > 0) || calculatorPolicy.viewAll(),
        icon: (props) => <Calculator {...props} />,
        submenu: calculatorSubmenu,
        to: routes.calculators,
      },
    ],
    Realizacja: [
      {
        title: "ERP",
        groupName: "Realizacja",
        visible: warehousePolicy.viewAny() || productPolicy.viewAny(),
        to: routes.erp,
        icon: (props) => <Boxes {...props} />,
        submenu: [
          {
            title: "Magazyny",
            visible: warehousePolicy.viewAny(),
            to: routes.warehouses,
            icon: LuWarehouse,
          },
          {
            title: "Produkty",
            visible: productPolicy.viewAny(),
            to: routes.products,
            icon: (props) => <Package {...props} />,
          },
          {
            title: "Zamówienia",
            visible: warehousePolicy.viewAny(),
            to: routes.orders,
            icon: (props) => <PackageSearch {...props} />,
          },
        ],
      },
      {
        title: "Umowy",
        groupName: "Realizacja",
        visible: contractPolicy.viewAny(),
        to: routes.contracts,
        icon: (props) => <FileSignature {...props} />,
      },
      {
        title: "Wnioski",
        groupName: "Realizacja",
        visible: applicationPolicy.viewAny(),
        to: routes.applications,
        icon: (props) => <FileUser {...props} />,
      },
      {
        title: "Montaże i serwisy",
        groupName: "Realizacja",
        visible: installationPolicy.viewAny(),
        to: routes.installationsAndServices,
        icon: (props) => <Wrench {...props} />,
      },
    ],
    Ustawienia: [
      {
        title: "Użytkownicy",
        groupName: "Ustawienia",
        visible: userPolicy.viewAny(),
        to: routes.users,
        icon: (props) => <User {...props} />,
      },
      {
        title: "Role",
        groupName: "Ustawienia",
        visible: rolePolicy.create(),
        to: routes.roles,
        icon: (props) => <IdCard {...props} />,
      },
      {
        title: "Ankiety",
        groupName: "Ustawienia",
        visible: surveyPolicy.viewAny(),
        to: routes.surveys,
        icon: (props) => <ClipboardPen {...props} />,
      },
      {
        title: "Automatyzacje",
        groupName: "Ustawienia",
        visible: hasPermission("access_automations"),
        to: routes.automations,
        icon: (props) => <Workflow {...props} />,
        // icon: (props) => <Bot {...props} />,
      },
    ],
  };

  const getActive = (item) => {
    let isActive = `/${location.pathname.split("/")[1]}` === item.to;
    if (item.submenu) {
      const pathname = location.pathname.split("/")[1];
      const index = `/${pathname}`.search(item.to ?? null);
      isActive = index >= 0;
    }
    if (item.parent) {
      const uuid = `${location.pathname.split("/")[2]}`;
      if (uuid.length === 36) {
        isActive = `${location.pathname}` === item.to;
      }
    }
    return isActive;
  };

  useEffect(() => {
    if (calculators && !isLoadingCalculators && !errorCalculators) {
      setCalculatorSubmenu([
        defaultCalculatorSubmenu,
        ...calculators.data.map((calculator) => ({
          title: calculator.name,
          visible: calculatorPolicy.viewAny(),
          to: `${routes.calculators}/${calculator.id}`,
          icon: (props) => <Calculator {...props} />,
          parent: routes.calculators,
        })),
      ]);
    }
  }, [calculators, isLoadingCalculators, errorCalculators]);

  return (
    <>
      <DesktopMenu groupedMenuItems={groupedMenuItems} getActive={getActive} />
      <MobileMenu groupedMenuItems={groupedMenuItems} getActive={getActive} />
    </>
  );
};
