import { InstallationCrewInformationCard } from "@/features/installations/components/Crews/InstallationCrewInformationCard";
import { InstallationCrewInstallationsCard } from "@/features/installations/components/Crews/InstallationCrewInstallationsCard";
import { InstallationCrewInstallationServicesCard } from "@/features/installations/components/Crews/InstallationCrewInstallationServicesCard";

export const InstallationCrewDetails = ({ installationCrew }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full gap-5">
      <InstallationCrewInformationCard installationCrew={installationCrew} />
      <div className="flex flex-1 flex-col gap-5 h-fit">
        <InstallationCrewInstallationsCard installations={installationCrew.installations} />
        <InstallationCrewInstallationServicesCard installationServices={installationCrew.installationServices} />
      </div>
    </div>
  );
};
