import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ApplicationsTable } from "@/features/applications/components/ApplicationsTable";
import { ApplicationSettings } from "@/features/applications/components/Settings/ApplicationSettings";
import { useApplicationStatusPolicy } from "@/features/applications/policies/useApplicationStatusPolicy";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { useViewModes } from "@/features/viewModes/hooks/useVIewModes";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const ApplicationsPage = () => {
  useDocumentTitle("Wnioski");
  const { activeTab, setActiveTab } = useTabs();
  const { renderModeSwitcher, viewMode } = useViewModes(ViewMode.TABLE, [ViewMode.TABLE, ViewMode.GROUPED], activeTab);
  const applicationStatusPolicy = useApplicationStatusPolicy();

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {renderModeSwitcher()}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {applicationStatusPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {applicationStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ApplicationsTable viewMode={viewMode} />
          </div>
        </TabsContent>
        {applicationStatusPolicy.create() && (
          <TabsContent value="settings">
            <ApplicationSettings />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
