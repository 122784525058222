import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { ClientCreateForm } from "@/features/clients/components/ClientCreateForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const ClientCreatePage = () => {
  useDocumentTitle("Dodawanie klienta");
  return (
    <Layout>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      <ClientCreateForm />
    </Layout>
  );
};
