import { getAuditStatuses } from "@/features/audits/api/auditApi";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const AuditStatusContext = createContext();

export function AuditStatusProvider({ children }) {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const auditPolicy = useAuditPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["auditStatuses"],
    queryFn: getAuditStatuses,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && auditPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setStatuses(response.data);
      setStatusOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <AuditStatusContext.Provider value={{ statuses, isLoading, statusOptions }}>{children}</AuditStatusContext.Provider>
  );
}
