import api from "@/api/api";
import { FILES_CATEGORIES_ENDPOINT, FILES_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const downloadFile = (fileId) => api.get(FILES_ENDPOINT + fileId + "/download", {}, { responseType: "blob" });

export const deleteFile = (fileId) => api.delete(FILES_ENDPOINT + fileId);

export const getFiles = (filters = null) => api.get(FILES_ENDPOINT, transformQueryFilters(filters));

export const updateFile = ({ fileId, data }) => api.patch(FILES_ENDPOINT + fileId, data);

export const getFileCategories = () => api.get(FILES_CATEGORIES_ENDPOINT);

export const createFileCategory = (data) => api.post(FILES_CATEGORIES_ENDPOINT, data);

export const updateFileCategory = ({ fileCategoryId, data }) =>
  api.put(FILES_CATEGORIES_ENDPOINT + fileCategoryId, data);

export const deleteFileCategory = ({ fileCategoryId, data }) =>
  api.delete(FILES_CATEGORIES_ENDPOINT + fileCategoryId, data);

export const createFile = ({ data }) =>
  api.post(FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editFileType = ({ type, fileId }) => api.put(FILES_ENDPOINT + fileId, { type });
