import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { EditableCell } from "@/components/Table/EditableCell";
import { EditableColorCell } from "@/components/Table/EditableColorCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useApplicationTypePolicy } from "@/features/applications/policies/useApplicationTypePolicy";
import i18n from "@/i18n";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useApplicationTypesTableColumns = (handleEdit, handleDelete) => {
  const applicationTypePolicy = useApplicationTypePolicy();
  const columns = [
    {
      id: "name",
      name: "Typ wniosku",
      size: 10,
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ wniosku")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const applicationType = row.original;
        const onSave = (name) => {
          handleEdit(applicationType.id, { name });
        };

        if (applicationTypePolicy.update()) {
          return <EditableCell value={applicationType.name} onSave={onSave} />;
        } else {
          return (
            <div className="flex-1 ms-3 truncate" title={row.original.name}>
              {row.getValue("name")}
            </div>
          );
        }
      },
    },
    {
      id: "color",
      name: "Kolor",
      accessorKey: "color",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kolor")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const applicationType = row.original;
        const onSave = (color) => {
          handleEdit(applicationType.id, { color });
        };

        const formatter = (color) => {
          return <span className="h-5 w-5" style={{ backgroundColor: color }}></span>;
        };

        if (applicationTypePolicy.update()) {
          return (
            <EditableColorCell type="color" value={applicationType.color} onSave={onSave} id={applicationType.id} />
          );
        } else return applicationType.color ? formatter(applicationType.color) : i18n.t("Brak");
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const applicationType = row.original;
        if (!applicationTypePolicy.destroy(applicationType)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {applicationTypePolicy.destroy(applicationType) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(applicationType.id)}
                  message="Czy na pewno chcesz usunąć ten typ? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
