import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteCustomField } from "@/features/customFields/api/customFieldApi";
import { useCustomFieldsTableColumns } from "@/features/customFields/hooks/useCustomFieldsTableColumns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const CustomFieldsList = ({ isLoading, customFields }) => {
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const deleteCustomFieldMutation = useMutation({
    mutationFn: deleteCustomField,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["customFields"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pole dodatkowe zostało usunięte." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDelete = (customFieldId) => {
    deleteCustomFieldMutation.mutate(customFieldId);
  };

  const { columns } = useCustomFieldsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : customFields,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: customFields?.length,
  });

  return <Table table={table} isLoading={isLoading} />;
};
