import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CreditsCalendar } from "@/features/credits/components/CreditsCalendar";
import { CreditsTable } from "@/features/credits/components/CreditsTable";
import { CreditSettings } from "@/features/credits/components/Settings/CreditSettings";
import { useCreditStatusPolicy } from "@/features/credits/policies/useCreditStatusPolicy";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { useViewModes } from "@/features/viewModes/hooks/useVIewModes";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useRef } from "react";

export const CreditsPage = () => {
  useDocumentTitle("Kredyty");
  const creditStatusPolicy = useCreditStatusPolicy();
  const { activeTab, setActiveTab } = useTabs();
  const { renderModeSwitcher, viewMode } = useViewModes(
    ViewMode.TABLE,
    [ViewMode.TABLE, ViewMode.MAP, ViewMode.GROUPED],
    activeTab,
  );
  const bottomRef = useRef(null);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {renderModeSwitcher()}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {creditStatusPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
            {creditStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <CreditsTable viewMode={viewMode} />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <CreditsCalendar />
          </div>
        </TabsContent>
        {creditStatusPolicy.create() && (
          <TabsContent value="settings">
            <CreditSettings />
          </TabsContent>
        )}
      </Tabs>
      <div ref={bottomRef}></div>
    </Layout>
  );
};
