import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React from "react";

import { cn } from "@/lib/utils";

const Progress = React.forwardRef(({ className, value, overflowWarning = false, max = 100, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    max={max}
    value={value}
    className={cn("relative h-4 w-full overflow-hidden rounded-full bg-secondary", className)}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={cn(
        "h-full w-full flex-1 transition-all duration-1000",
        value >= 100 ? "bg-green-500" : "bg-primary",
        overflowWarning && value > 100 && "bg-yellow-500",
      )}
      style={{
        transform: `translateX(-${100 - Math.min(100, ((value || 0) / (max || 100)) * 100)}%)`,
      }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
