import { Table } from "@/components/Table/Table";
import { ButtonContext } from "@/context/ButtonContext";
import { ContractFilters } from "@/features/contracts/components/ContractFilters";
import { useContractsTableColumns } from "@/features/contracts/hooks/useContractsTableColumns";
import { getLeadContracts } from "@/features/leads/api/leadApi";
import { useQuery } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const LeadContractsTable = () => {
  const { id: leadId } = useParams();
  const { showButton, hideButton } = useContext(ButtonContext);

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "created_at",
      desc: true,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["lead", leadId, "contracts", pagination, sorting, columnFilters],
    queryFn: () => getLeadContracts({ leadId, filters: { pagination, sorting, columnFilters } }),
  });

  const { columns } = useContractsTableColumns();
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  useEffect(() => {
    showButton("addContract");

    return () => {
      hideButton("addContract");
    };
  }, []);

  return (
    <Table
      table={table}
      Filters={ContractFilters}
      meta={response?.meta}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};
