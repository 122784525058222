import { Progress } from "@/components/Progress";
import i18n from "@/i18n";

export const UserLevelProgressWidget = ({ data }) => {
  const { current_level, next_level, total_points } = data;
  if (!current_level)
    return <p className="text-muted-foreground text-sm">{i18n.t("Nie posiadasz przydzielonego poziomu")}</p>;
  return (
    <div className="flex flex-col gap-1 w-full text-sm">
      <div className="flex flex-row gap-3 items-center mb-1">
        <p>{i18n.t("Liczba punktów")}</p>
        <p className="text-xs text-muted-foreground">
          {total_points}
          {" / "}
          {next_level?.required_points}
        </p>
      </div>
      <Progress current={total_points} total={next_level?.required_points} formatter={null} />
      <div className="flex flex-row gap-3 items-center justify-between">
        <p className="w-fit text-muted-foreground text-xs">{current_level?.name}</p>
        {current_level?.name !== next_level?.name && (
          <p className="w-fit text-muted-foreground text-xs">{next_level?.name}</p>
        )}
      </div>
    </div>
  );
};
