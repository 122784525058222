import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { yesNoOptions } from "@/constants/yesNoOptions";
import { ProductTypeContext } from "@/features/products/context/ProductTypeContext";
import { getWarehouses } from "@/features/warehouses/api/warehouseApi";
import { useWarehousePolicy } from "@/features/warehouses/policies/useWarehousePolicy";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/useFilters";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";

export const ProductFilters = ({ table, withWarehouse = true }) => {
  const warehousePolicy = useWarehousePolicy();
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const { filter, setFilter } = useFilters(table);
  const { typeOptions, isLoading } = useContext(ProductTypeContext);

  const {
    isLoading: isLoadingWarehouses,
    data: warehouses,
    error: errorWarehouses,
  } = useQuery({
    queryKey: ["warehouses"],
    queryFn: getWarehouses,
    staleTime: 1000 * 60 * 5,
    enabled: warehousePolicy.viewAny() && withWarehouse,
  });

  useEffect(() => {
    if (!isLoadingWarehouses && !errorWarehouses && warehouses) {
      setWarehouseOptions(warehouses.data.map((warehouse) => ({ name: warehouse.name, value: warehouse.id })));
    }
  }, [isLoadingWarehouses, warehouses, errorWarehouses]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={typeOptions}
        isLoading={isLoading}
        setValue={(value) => setFilter("type", value)}
        title="Typ"
        value={filter("type")}
      />
      <SelectFilter
        title="Widoczny"
        value={filter("available")}
        setValue={(value) => setFilter("available", value)}
        options={yesNoOptions}
      />
      {warehousePolicy.viewAny() && withWarehouse && warehouses?.data?.length > 0 && (
        <MultipleSelectFilter
          isLoading={isLoadingWarehouses}
          options={appendEmptyOption(warehouseOptions, "Nie dodane")}
          setValue={(value) => setFilter("warehouse", value)}
          title="Magazyn"
          value={filter("warehouse")}
        />
      )}
      <ColumnFilter table={table} />
    </div>
  );
};
