import { authCheck } from "@/features/auth/api/authApi";
import authStorage from "@/features/auth/helpers/authStorage";
import { usePermissions } from "@/features/auth/hooks/usePermissions";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useQueryClient } from "@tanstack/react-query";
import { createContext, useEffect } from "react";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useLocalStorage("user", null);
  const [impersonatingUser, setImpersonatingUser] = useLocalStorage("impersonatingUser", null);

  const queryClient = useQueryClient();

  const { fetchPermissions } = usePermissions();

  const fetchCurrentUser = async () => {
    if (authStorage.getToken()) {
      const response = await authCheck();
      if (response.ok) {
        setUser(response.data.user);
        await fetchPermissions();
      } else {
        setUser(null);
        queryClient.clear();
        authStorage.removeToken();
      }
    } else {
      setUser(null);
      authStorage.removeToken();
      queryClient.clear();
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, impersonatingUser, setImpersonatingUser }}>
      {children}
    </AuthContext.Provider>
  );
}
