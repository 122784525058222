import { FileUploadInput } from "@/components/forms/FileUploadInput";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { ContractFileCategoryContext } from "@/features/contracts/context/ContractFileCategoryContext";
import i18n from "@/i18n";
import { Asterisk } from "lucide-react";
import { useContext } from "react";

export const ContractFileCategoriesCreateForm = ({ productIds, files, setFiles }) => {
  const { getFileCategoriesForProducts } = useContext(ContractFileCategoryContext);

  const contractFileCategories = getFileCategoriesForProducts(productIds);

  const uniqueFileCategories = contractFileCategories.reduce((acc, { fileCategory, required }) => {
    const existing = acc.find((c) => c.id === fileCategory.id);
    if (existing) {
      existing.required = existing.required || required;
    } else {
      acc.push({ ...fileCategory, required });
    }
    return acc;
  }, []);

  const requiredContractFiles = uniqueFileCategories.filter(({ required }) => required);
  const optionalContractFiles = uniqueFileCategories.filter(({ required }) => !required);

  return (
    <Card className="h-fit">
      <CardHeader>
        <CardTitle className="text-base text-primary">{i18n.t("Pliki")}</CardTitle>
        <CardDescription>{i18n.t("Pliki z audytu zostaną dołączone automatycznie.")}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-5">
        {requiredContractFiles.map((category, index) => (
          <div className="space-y-2" key={`contract_required_file_${index}`}>
            <Label htmlFor={category.id} className="flex flex-row items-center mb-1">
              {category.name}
              <Asterisk size={20} className="text-destructive" />
            </Label>
            <FileUploadInput id={category.id} onFilesSelect={(f) => setFiles({ ...files, [category.id]: f })} />
          </div>
        ))}
        {optionalContractFiles.map((category, index) => (
          <div className="space-y-2" key={`contract_optional_file_${index}`}>
            <Label htmlFor={category.id} className="flex flex-row items-center mb-1">
              {category.name}
            </Label>
            <FileUploadInput id={category.id} onFilesSelect={(f) => setFiles({ ...files, [category.id]: f })} />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
