import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { getCalculation } from "@/features/calculations/api/calculationApi";
import { CalculationDetails } from "@/features/calculations/components/CalculationDetails";
import { useDownloadCalculationOffer } from "@/features/calculations/hooks/useDownloadCalculationOffer";
import { CalculationEditDialog } from "@/features/calculations/pages/CalculationEditDialog";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { ClipboardPen, FileDown, Pencil } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const CalculationDetailsPage = () => {
  const { id: calculationId } = useParams();
  const leadCalculationPolicy = useLeadCalculationPolicy();
  const navigate = useNavigate();

  const { isLoading: isLoadingCalculation, data: calculation } = useQuery({
    queryKey: ["calculation", calculationId],
    queryFn: () => getCalculation(calculationId),
  });
  useDocumentTitle(calculation?.data ? `Kalkulacja ${calculation?.data?.name}` : null);

  const { handleDownloadPdf, offerState } = useDownloadCalculationOffer();

  const handleRedirectToSurveys = () => {
    return navigate(`${routes.calculations}/${calculationId}/ankiety`);
  };

  return (
    <Layout isLoading={isLoadingCalculation}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-8">
        <Breadcrumbs idAlias={calculation?.data?.name} noParent={true} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {leadCalculationPolicy.update() && (
            <CalculationEditDialog
              trigger={<Button title="Edytuj" variant="outline" leftIcon={<Pencil size={20} />} />}
              calculation={calculation?.data}
            />
          )}
          {leadCalculationPolicy.view(calculation?.data) && (
            <Button
              leftIcon={<FileDown size={20} />}
              title="Pobierz PDF"
              variant="outline"
              onClick={() => handleDownloadPdf(calculation?.data)}
              isLoading={offerState === "loading"}
            />
          )}
          {leadCalculationPolicy.view(calculation?.data) && calculation?.data?.surveys_count > 0 && (
            <Button
              title="Ankiety"
              variant="outline"
              leftIcon={<ClipboardPen size={20} />}
              onClick={handleRedirectToSurveys}
            />
          )}
        </div>
      </div>
      {leadCalculationPolicy.viewAny() && <CalculationDetails calculation={calculation?.data} />}
    </Layout>
  );
};
