import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { createWarehouse } from "@/features/warehouses/api/warehouseApi";
import { useWarehousePolicy } from "@/features/warehouses/policies/useWarehousePolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  external: Yup.boolean().nullable().optional(),
});

export const WarehouseCreateDialog = () => {
  const queryClient = useQueryClient();
  const warehousePolicy = useWarehousePolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createWarehouseMutation.mutate({ data });
  };

  const defaultValues = {
    external: false,
    name: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const createWarehouseMutation = useMutation({
    mutationFn: createWarehouse,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["warehouses"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Magazyn dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!warehousePolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj magazyn" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj magazyn")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego magazynu.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Nazwa" placeholder="Nazwa magazynu" name="name" autoComplete="off" />
            <FormCheckbox name="external" label="Magazyn zewnętrzny" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createWarehouseMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
