import { Table } from "@/components/Table/Table";
import { getWarehouseOperations } from "@/features/warehouses/api/warehouseApi";
import { useWarehouseOperationsTableColumns } from "@/features/warehouses/hooks/useWarehouseOperationsTableColumns";
import { useQuery } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const WarehouseOperationsTable = () => {
  const { id: warehouseId } = useParams();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["warehouse", warehouseId, "operations", pagination, sorting, columnFilters],
    queryFn: () => getWarehouseOperations({ warehouseId, filters: { pagination, sorting, columnFilters } }),
  });

  const { columns } = useWarehouseOperationsTableColumns();
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return <Table table={table} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} />;
};
