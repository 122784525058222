import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { EditableCell } from "@/components/Table/EditableCell";
import { EditableColorCell } from "@/components/Table/EditableColorCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuditStatusPolicy } from "@/features/audits/policies/useAuditStatusPolicy";
import i18n from "@/i18n";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useAuditStatusesTableColumns = (handleEdit, handleDelete) => {
  const auditStatusPolicy = useAuditStatusPolicy();
  const columns = [
    {
      id: "name",
      name: "Nazwa statusu",
      size: 10,
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa statusu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const auditStatus = row.original;
        const onSave = (name) => {
          handleEdit(auditStatus.id, { name });
        };

        if (auditStatusPolicy.updateName(auditStatus)) {
          return <EditableCell value={auditStatus.name} onSave={onSave} />;
        } else {
          return (
            <div className="flex-1 ms-3 truncate" title={row.original.name}>
              {row.getValue("name")}
            </div>
          );
        }
      },
    },
    {
      id: "color",
      name: "Kolor",
      accessorKey: "color",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kolor")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const auditStatus = row.original;
        const onSave = (color) => {
          handleEdit(auditStatus.id, { color });
        };

        const formatter = (color) => {
          return <span className="h-5 w-5" style={{ backgroundColor: color }}></span>;
        };

        if (auditStatusPolicy.update()) {
          return <EditableColorCell type="color" value={auditStatus.color} onSave={onSave} id={auditStatus.id} />;
        } else return auditStatus.color ? formatter(auditStatus.color) : i18n.t("Brak");
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const auditStatus = row.original;
        if (!auditStatusPolicy.destroy(auditStatus)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {auditStatusPolicy.destroy(auditStatus) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(auditStatus.id)}
                  message="Czy na pewno chcesz usunąć ten status? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
