import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { getContracts } from "@/features/contracts/api/contractApi";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { useFilters } from "@/hooks/useFilters";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";

export const ApplicationFilters = ({ table, withColumns = true }) => {
  const applicationPolicy = useApplicationPolicy();
  const contractPolicy = useContractPolicy();
  const { statusOptions } = useContext(ApplicationStatusContext);
  const { typeOptions } = useContext(ApplicationTypeContext);
  const { filter, setFilter } = useFilters(table);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const [contractOptions, setOptions] = useState([]);

  const {
    isLoading: isLoadingContracts,
    data: contracts,
    error: errorContracts,
  } = useQuery({
    queryKey: ["contracts", { hasApplications: true }],
    queryFn: () => getContracts({ hasApplications: true }),
    staleTime: 1000 * 60 * 5,
    enabled: applicationPolicy.viewAll() && contractPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingContracts && !errorContracts && contracts) {
      setOptions(
        contracts.data.map((contract) => ({
          name: `${contract.name} (${contract.identifier})`,
          value: contract.id,
        })),
      );
    }
  }, [isLoadingContracts, errorContracts, contracts]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={statusOptions}
        setValue={(value) => setFilter("status", value)}
        title="Status"
        value={filter("status")}
      />
      <MultipleSelectFilter
        options={typeOptions}
        setValue={(value) => setFilter("type", value)}
        title="Typ wniosku"
        value={filter("type")}
      />
      {applicationPolicy.viewAll() && (
        <MultipleSelectFilter
          options={userOptions}
          setValue={(value) => setFilter("user", value)}
          title="Użytkownik"
          isLoading={isLoadingUsers}
          value={filter("user")}
        />
      )}
      {applicationPolicy.viewAll() && contractPolicy.viewAny() && (
        <MultipleSelectFilter
          options={contractOptions}
          setValue={(value) => setFilter("contract", value)}
          title="Umowa"
          isLoading={isLoadingUsers}
          value={filter("contract")}
        />
      )}
      {withColumns && <ColumnFilter table={table} />}
    </div>
  );
};
