import { CardRow } from "@/components/ui/CardRow";

export const renderCustomFields = (customFields) => {
  if (!customFields) return null;
  return customFields?.map((customField) => (
    <CardRow key={customField.key}>
      <p className="text-xs sm:text-base font-medium">{customField.name}</p>
      <p>{customField.value}</p>
    </CardRow>
  ));
};
