import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { createCalculator } from "@/features/calculators/api/calculatorApi";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const CalculatorCreateDialog = () => {
  const queryClient = useQueryClient();
  const calculatorPolicy = useCalculatorPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createCalculatorMutation.mutate({ data });
  };

  const defaultValues = {
    name: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const createCalculatorMutation = useMutation({
    mutationFn: createCalculator,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculators"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Kalkulator dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!calculatorPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj nowy kalkulator" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj kalkulator")}</DialogTitle>
          <DialogDescription>
            {i18n.t(
              "Dodawanie nowego kalkulatora. Będzie widoczny dla wszystkich użytkowników dopiero po opublikowaniu.",
            )}
          </DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Nazwa" placeholder="Nazwa kalkulatora" name="name" autoComplete="off" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createCalculatorMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
