import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { updateAuditsStatus } from "@/features/audits/api/auditApi";
import { AuditStatusContext } from "@/features/audits/context/AuditStatusContext";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { renderCustomFields } from "@/features/customFields/helpers/renderCustomFields";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const AuditInformationCard = ({ audit, client = null }) => {
  const auditPolicy = useAuditPolicy();
  const leadPolicy = useLeadPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(AuditStatusContext);
  const auditLead = client ? client : audit.lead;

  const { id: auditId } = useParams();
  const queryClient = useQueryClient();

  const handleStatusClick = (value) => {
    if (value) {
      const data = { audit_status_id: value };
      updateAuditStatusMutation.mutate({ auditId, data });
    }
  };

  const updateAuditStatusMutation = useMutation({
    mutationFn: updateAuditsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      queryClient.invalidateQueries({ queryKey: ["audit", auditId] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Cos poszło nie tak." /> });
      }
    },
  });

  return (
    <Card className="shadow-md flex-1">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o audycie")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        {leadPolicy.view() && !client && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Kontakt")}</p>
            <Link to={`${routes.leads}/${auditLead.id}`}>
              <Button variant="link" size="xs">
                {auditLead.name}
              </Button>
            </Link>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko kontaktu")}</p>
          <p className="text-right">{auditLead.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu kontaktu")}</p>
          {auditLead.phone_number ? (
            <a className="font-semibold" href={`tel:${auditLead.phone_number}`}>
              {formatPhoneNumber(auditLead.phone_number)}
            </a>
          ) : (
            <p className="text-right">{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Email kontaktu")}</p>
          {auditLead.email ? (
            <a className="font-semibold" href={`mailto:${auditLead.email}`}>
              {auditLead.email}
            </a>
          ) : (
            <p className="text-right">{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Status audytu")}</p>
          {auditPolicy.updateStatus() || audit.amount_of_processes === 0 ? (
            <Select
              className="w-fit text-primary-foreground"
              style={{ backgroundColor: audit.status.color }}
              options={statusOptions}
              setValue={handleStatusClick}
              value={audit.status.id}
              isLoading={isLoadingStatuses || updateAuditStatusMutation.isPending}
            />
          ) : (
            <ColorBadge color={audit.status.color}>{audit.status.name}</ColorBadge>
          )}
        </CardRow>
        <AddressCardRow model={auditLead} modelName="Lead" />
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia audytu")}</p>
          <p title={audit.created_at}>
            {format(parse(audit.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        {auditPolicy.viewAll() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
            <UserTooltip user={audit.user} />
          </CardRow>
        )}
        {audit.creator && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Stworzony przez")}</p>
            <UserTooltip user={audit.creator} />
          </CardRow>
        )}
        {audit.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{audit.description}</p>
          </CardRow>
        )}
        {renderCustomFields(audit.customFields)}
      </CardContent>
    </Card>
  );
};
