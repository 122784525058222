import api from "@/api/api";
import { USERS_ENDPOINT } from "@/api/endpoints";

export const putUserAvatar = ({ id, data }) =>
  api.post(USERS_ENDPOINT + id + "/avatar", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProfile = ({ id, data }) => api.put(USERS_ENDPOINT + id + "/updateProfile", data);
