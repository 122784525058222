import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import {
  getInstallationProcesses,
  updateInstallationProcessProgress,
} from "@/features/installations/api/installationApi";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { ProcessesCard } from "@/features/processes/components/ProcessCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const InstallationProcessesCard = () => {
  const { id: installationId } = useParams();
  const installationPolicy = useInstallationPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["installation", installationId, "processes"],
    queryFn: () => getInstallationProcesses(installationId),
    enabled: installationPolicy.viewProcesses(),
  });

  const updateInstallationProcessProgressMutation = useMutation({
    mutationFn: updateInstallationProcessProgress,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["installation", installationId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["installation", installationId, "processes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleUpdateInstallationProcess = (processId, step, completed) => {
    updateInstallationProcessProgressMutation.mutate({
      installationId,
      processId,
      data: { step_id: step.id, completed },
    });
  };

  return (
    <ProcessesCard
      policy={installationPolicy}
      handleCheck={handleUpdateInstallationProcess}
      isLoading={isLoading}
      processes={response?.data}
    />
  );
};
