import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { ProductsContext } from "@/features/products/context/ProductsContext";
import { createManyWarehouseProducts } from "@/features/warehouses/api/warehouseApi";
import { useWarehouseProductPolicy } from "@/features/warehouses/policies/useWarehouseProductPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useEffect } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const WarehouseProductCreateForm = ({ warehouse }) => {
  const warehouseProductPolicy = useWarehouseProductPolicy();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { productOptions, isLoading } = useContext(ProductsContext);

  const createManyWarehouseProductsMutation = useMutation({
    mutationFn: createManyWarehouseProducts,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["warehouses"] });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id], exact: true });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id, "products"] });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id, "operations"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Produkty zostały dodane do magazynu." /> });
        return navigate(`${routes.warehouses}/${warehouse.id}`, { state: { tab: "products" } });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const validationSchema = Yup.object({
    products: Yup.array().of(
      Yup.object({
        product_id: Yup.string().required(i18n.t("Pole jest wymagane")),
        quantity: Yup.number(i18n.t("Pole musi być liczbą"))
          .required(i18n.t("Pole jest wymagane"))
          .when([], {
            is: !!warehouse.external,
            then: () => Yup.string().optional().nullable(),
          }),
      }).required(i18n.t("Pole jest wymagane")),
    ),
  });

  const defaultValues = {
    products: null,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    createManyWarehouseProductsMutation.mutate({ warehouseId: warehouse.id, data });
  };

  useEffect(() => {
    if (!warehouseProductPolicy.create()) return navigate(-1);
  }, []);

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Card className="w-1/2">
        <CardHeader>
          <CardTitle>{i18n.t("Dodawanie wielu produktów")}</CardTitle>
          <CardDescription>{warehouse.name}</CardDescription>
        </CardHeader>
        <CardContent className="pt-3">
          <FormProductOptions
            placeholder="Nazwa produktu"
            name="products"
            autoComplete="off"
            productOptions={productOptions}
            warehouse={warehouse}
            isLoading={isLoading}
          />
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            title="Dodaj produkty"
            leftIcon={<Plus size={20} />}
            type="submit"
            isLoading={createManyWarehouseProductsMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            onClick={() => navigate(routes.products)}
            variant="destructive"
          />
        </CardFooter>
      </Card>
    </Form>
  );
};

const FormProductOptions = ({ name, label, productOptions, warehouse, isLoading, className }) => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const emptyOption = { product_id: "", quantity: "" };

  const options = watch(name) || [emptyOption];

  const addOption = () => {
    const currentOptions = getValues(name) || [emptyOption];
    setValue(name, [...currentOptions, emptyOption]);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setValue(name, updatedOptions.length > 0 ? updatedOptions : [emptyOption]);
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setValue(name, updatedOptions);
  };

  useEffect(() => {
    if (!options || options.length === 0) {
      setValue(name, [emptyOption]);
    }
  }, []);

  const getFilteredProductOptions = (currentIndex) => {
    const selectedProductIds = options.map((option, index) => (index !== currentIndex ? option.product_id : null));
    return productOptions.filter((option) => !selectedProductIds.includes(option.value));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div className={className}>
          <Label htmlFor={name} className={errors[name] && "text-destructive"}>
            {i18n.t(label)}
          </Label>
          <div className="space-y-5 mt-1">
            {options.map((option, index) => (
              <div key={index} className="flex gap-3 items-end">
                <div className="flex-1">
                  <FormCombobox
                    name={`${name}[${index}].product_id`}
                    options={getFilteredProductOptions(index)}
                    isLoading={isLoading}
                    className=""
                    label={i18n.t("Produkt")}
                    setCurrentValue={(value) => handleOptionChange(index, "product_id", value)}
                  />
                </div>
                {!warehouse.external && (
                  <div className="flex-1">
                    <FormNumberField
                      label="Ilość"
                      name={`${name}[${index}].quantity`}
                      placeholder={i18n.t("Ilość")}
                      value={option.quantity}
                      onChange={(e) => handleOptionChange(index, "quantity", e.target.value)}
                    />
                  </div>
                )}
                {options.length > 1 && (
                  <Button
                    type="button"
                    variant="ghost"
                    className="text-destructive px-3 shrink-0"
                    leftIcon={<X size={16} className="shrink-0" />}
                    onClick={() => removeOption(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <Button
            type="button"
            variant="link"
            onClick={addOption}
            className="m-0 pl-2 gap-1"
            leftIcon={<Plus size={16} />}
            title="Dodaj opcję"
          />
        </div>
      )}
    />
  );
};
