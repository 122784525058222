import { config } from "@/config";
import authStorage from "@/features/auth/helpers/authStorage";
import { create } from "apisauce";

const api = create({
  baseURL: config("api.url"),
  timeout: config("api.timeout"),
  headers: {
    Accept: "application/json",
    ContentType: "application/json",
  },
});

api.addRequestTransform((request) => {
  const token = authStorage.getToken();
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
});

api.addResponseTransform((response) => {
  if (!response?.config?.responseType || response?.config?.responseType !== "blob") {
    if (response.ok && response.data) {
      const { meta, data } = response.data;
      response.data = data;
      response.meta = meta;
    }
  }
});

export default api;
