import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { TaskStatus } from "@/features/tasks/enums/TaskStatusEnum";
import { useTaskPolicy } from "@/features/tasks/policies/useTaskPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { useFilters } from "@/hooks/useFilters";
import { useContext } from "react";

export const DelegatedTasksFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);
  const taskPolicy = useTaskPolicy();
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={TaskStatus.getValues()}
        setValue={(value) => setFilter("status", value)}
        title="Status"
        value={filter("status")}
      />
      {taskPolicy.assignTask() && (
        <MultipleSelectFilter
          options={userOptions}
          isLoading={isLoadingUsers}
          setValue={(value) => setFilter("user", value)}
          title="Użytkownik"
          value={filter("user")}
        />
      )}
      <SelectFilter
        options={[
          { name: "Tak", value: "true" },
          { name: "Nie", value: "false" },
        ]}
        setValue={(value) => setFilter("recurring", value)}
        title="Powtarzające"
        value={filter("recurring")}
      />
      <ColumnFilter table={table} />
    </div>
  );
};
