import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { WarehouseCreateDialog } from "@/features/warehouses/components/WarehouseCreateDialog";
import { WarehousesTable } from "@/features/warehouses/components/WarehousesTable";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const WarehousesPage = () => {
  useDocumentTitle("Magazyny");
  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <WarehouseCreateDialog />
        </div>
      </div>
      <WarehousesTable />
    </Layout>
  );
};
