import api from "@/api/api";
import { FILES_ENDPOINT, ITEMS_ENDPOINT, NOTES_ENDPOINT, ORDERS_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getOrders = (filters) => api.get(ORDERS_ENDPOINT, transformQueryFilters(filters));

export const getOrder = (orderId) => api.get(ORDERS_ENDPOINT + orderId);

export const createOrder = ({ data }) => api.post(ORDERS_ENDPOINT, data);

export const deleteOrder = (orderId) => api.delete(ORDERS_ENDPOINT + orderId);

export const updateOrder = ({ orderId, data }) => api.patch(ORDERS_ENDPOINT + orderId, data);

export const getOrderFiles = ({ orderId, filters }) => api.get(ORDERS_ENDPOINT + orderId + "/files", filters);

export const createOrderFiles = ({ orderId, data }) =>
  api.post(ORDERS_ENDPOINT + orderId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteOrderFile = ({ orderId, fileId }) => api.delete(ORDERS_ENDPOINT + orderId + FILES_ENDPOINT + fileId);

export const getOrderNotes = (orderId) => api.get(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT);

export const createOrderNote = ({ orderId, note }) => api.post(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT, { note });

export const updateOrderNote = ({ orderId, noteId, note }) =>
  api.put(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT + noteId, note);

export const deleteOrderNote = ({ orderId, noteId }) => api.delete(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT + noteId);

export const getOrderItems = (orderId) => api.get(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT);

export const createOrderItem = ({ orderId, data }) => api.post(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT, data);

export const deleteOrderItem = ({ orderId, itemId }) => api.delete(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT + itemId);

export const updateOrderItem = ({ orderId, itemId, data }) =>
  api.patch(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT + itemId, data);
