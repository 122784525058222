import { useAuth } from "@/features/auth/hooks/useAuth";

export const useLeadCalculationPolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_lead_calculations");
  };

  const view = (calculation) => {
    // return hasAnyPermission(["access_all_clients", "access_own_clients"]);
    if (hasPermission("show_any_lead_calculation")) {
      return true;
    } else if (hasPermission("show_own_lead_calculation")) {
      return user.id === calculation.user.id;
    } else return false;
  };

  const update = () => {
    return hasPermission("update_lead_calculation");
  };

  const create = () => {
    return hasPermission("create_lead_calculation");
  };

  const destroy = () => {
    return hasPermission("delete_lead_calculation");
  };

  return {
    viewAny,
    view,
    update,
    create,
    destroy,
  };
};
