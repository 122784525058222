import api from "@/api/api";
import { USER_WIDGETS_ENDPOINT, WIDGETS_ENDPOINT } from "@/api/endpoints";

export const getWidgets = () => api.get(WIDGETS_ENDPOINT);

export const getUserWidgets = () => api.get(USER_WIDGETS_ENDPOINT);

export const createUserWidget = (data) => api.post(USER_WIDGETS_ENDPOINT, data);

export const updateUserWidgetLayout = ({ widgets }) => api.patch(USER_WIDGETS_ENDPOINT + "editLayout", { widgets });

export const deleteUserWidget = (userWidgetId) => api.delete(USER_WIDGETS_ENDPOINT + userWidgetId);

export const getWidgetData = (widgetKey) => api.get(USER_WIDGETS_ENDPOINT + "data/" + widgetKey);
