import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { updateCreditsStatus } from "@/features/credits/api/creditApi";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { renderCustomFields } from "@/features/customFields/helpers/renderCustomFields";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const CreditInformationCard = ({ credit, client = null }) => {
  const creditPolicy = useCreditPolicy();
  const leadPolicy = useLeadPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(CreditStatusContext);
  const creditLead = client ? client : credit.lead;

  const { id: creditId } = useParams();
  const queryClient = useQueryClient();

  const handleStatusClick = (value) => {
    if (value) {
      const data = { credit_status_id: value };
      updateCreditStatusMutation.mutate({ creditId, data });
    }
  };

  const updateCreditStatusMutation = useMutation({
    mutationFn: updateCreditsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["credits"] });
      queryClient.invalidateQueries({ queryKey: ["credit", creditId] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Cos poszło nie tak." /> });
      }
    },
  });

  return (
    <Card className="shadow-md flex-1">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o audycie")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        {leadPolicy.view() && !client && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Kontakt")}</p>
            <Link to={`${routes.leads}/${creditLead.id}`}>
              <Button variant="link" size="xs">
                {creditLead.name}
              </Button>
            </Link>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko kontaktu")}</p>
          <p className="text-right">{creditLead.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu kontaktu")}</p>
          {creditLead.phone_number ? (
            <a className="font-semibold" href={`tel:${creditLead.phone_number}`}>
              {formatPhoneNumber(creditLead.phone_number)}
            </a>
          ) : (
            <p className="text-right">{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Email kontaktu")}</p>
          {creditLead.email ? (
            <a className="font-semibold" href={`mailto:${creditLead.email}`}>
              {creditLead.email}
            </a>
          ) : (
            <p className="text-right">{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Status kredytu")}</p>
          {creditPolicy.updateStatus() || credit?.amount_of_processes === 0 ? (
            <Select
              className="w-fit"
              style={{ backgroundColor: credit.status.color }}
              options={statusOptions}
              setValue={handleStatusClick}
              value={credit.status.id}
              isLoading={isLoadingStatuses || updateCreditStatusMutation.isPending}
            />
          ) : (
            <ColorBadge color={credit.status.color}>{credit.status.name}</ColorBadge>
          )}
        </CardRow>
        <AddressCardRow model={credit?.lead} modelName="Lead" />
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia kredytu")}</p>
          <p title={credit.created_at}>
            {format(parse(credit.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        {creditPolicy.viewAll() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
            <UserTooltip user={credit.user} />
          </CardRow>
        )}
        {credit.creator && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Stworzony przez")}</p>
            <UserTooltip user={credit.creator} />
          </CardRow>
        )}
        {credit.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{credit.description}</p>
          </CardRow>
        )}
        {renderCustomFields(credit.customFields)}
      </CardContent>
    </Card>
  );
};
