import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteSurveyQuestion } from "@/features/surveys/api/surveyApi";
import { SurveyQuestionEditDialog } from "@/features/surveys/components/Configuration/Questions/SurveyQuestionEditDialog";
import { SurveyQuestionType } from "@/features/surveys/enums/SurveyQuestionTypeEnum";
import { capitalize } from "@/helpers/capitalize";
import { useSortable } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Asterisk, GripVertical, Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const SurveyQuestion = ({ question }) => {
  const type = SurveyQuestionType[question.type];
  const { id: surveyId } = useParams();
  const queryClient = useQueryClient();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.id });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
  };

  const deleteSurveyQuestionMutation = useMutation({
    mutationFn: deleteSurveyQuestion,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
        queryClient.invalidateQueries({ queryKey: ["survey", surveyId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteSurveyQuestionMutation.mutate({ surveySectionId: question.section_id, surveyQuestionId: question.id });
  };

  return (
    <div ref={setNodeRef} style={style} className="flex flex-row items-start justify-between first:pt-0 py-4">
      <div className="flex flex-row gap-1 items-center cursor-move font-medium w-fit" {...attributes} {...listeners}>
        <GripVertical size={16} className="shrink-0" />
        {question.order}.
        <span className="flex flex-row items-center border rounded-md px-2 py-1 whitespace-nowrap">
          {<type.icon />}
          {type.name}
        </span>
        {capitalize(question.name)}
        {question.required ? <Asterisk size={16} className="text-destructive" /> : null}
      </div>
      <div className="flex flex-row gap-2 items-center">
        <SurveyQuestionEditDialog question={question} />
        <DeleteAlertDialog
          message="Czy na pewno chcesz usunąć to pytanie? Tej operacji nie można cofnąć"
          onConfirm={handleDelete}
          trigger={
            <Button
              variant="outline"
              leftIcon={<Trash size={16} />}
              isLoading={deleteSurveyQuestionMutation.isPending}
            />
          }
        />
      </div>
    </div>
  );
};
