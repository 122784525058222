import { Progress } from "@/components/Progress";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { ContractPaymentType } from "@/features/contracts/enums/ContractPaymentTypeEnum";
import { getContractPaymentSums } from "@/features/contracts/helpers/getContractPaymentsSums";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";

export const ContractPaymentInformationCard = ({ contract }) => {
  const { user } = useAuth();
  const contractPolicy = useContractPolicy();
  const { totalClientPaid, totalDonationPaid, totalLoanPaid } = getContractPaymentSums(contract.contractPayments);

  let remainingTranchePaid = totalClientPaid;
  let creditAmount = contract.gross_price - contract.down_payment;

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle className="flex flex-row gap-2 items-center">
            <p>{i18n.t("Płatność")}</p>
            {contract.payment_type && <Badge>{ContractPaymentType[contract.payment_type].name}</Badge>}
          </CardTitle>
          <CardDescription>{i18n.t("Informacje o płatnościach")}</CardDescription>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-5">
        <div className="grid grid-cols-2 gap-3 justify-between">
          <Card className="flex flex-col gap-3 items-center justify-center py-5 px-2">
            <p className="font-medium break-all text-center">{i18n.t("contract.net_price")}</p>
            <p className="font-bold">{formatMoney(contract.net_price) ?? i18n.t("none")}</p>
          </Card>
          <Card className="flex flex-col gap-3 items-center justify-center py-5 px-2">
            <p className="font-medium break-all text-center">{i18n.t("contract.gross_price")}</p>
            <p className="font-bold">{formatMoney(contract.gross_price) ?? i18n.t("none")}</p>
          </Card>
          <Card className="flex flex-col gap-3 items-center justify-center py-5 px-2">
            <p className="font-medium break-all text-center">{i18n.t("contract.vat")}</p>
            <p className="font-bold">{contract.vat ? contract.vat + "%" : i18n.t("none")}</p>
          </Card>
          <Card className="flex flex-col gap-3 items-center justify-center py-5 px-2">
            <span className="flex flex-col justify-center items-center">
              <p className="font-medium break-all text-center">{i18n.t("comission")}</p>
              <UserTooltip user={contract.created_by} />
            </span>
            {(contractPolicy.updateComission() || contract.created_by?.id === user.id) && (
              <p className="font-bold">{formatMoney(contract.comission) ?? i18n.t("none")}</p>
            )}
          </Card>
          {contractPolicy.viewMargin() && (
            <Card className="col-span-2 flex flex-col gap-3 items-center justify-center py-5 px-2">
              <span className="flex flex-col justify-center items-center">
                <p className="font-medium break-all text-center">{i18n.t("margin")}</p>
              </span>
              <p className="font-bold">{formatMoney(contract.margin) ?? i18n.t("none")}</p>
            </Card>
          )}
        </div>
        {contract.tranches.length > 0 && (
          <div className="flex flex-col gap-5 justify-center items-center">
            {contract.tranches.map((tranche, i) => {
              const tranchePaid = Math.min(tranche, remainingTranchePaid);
              remainingTranchePaid = Math.max(remainingTranchePaid - tranche, 0);

              return (
                <div key={i} className="flex flex-col gap-1 w-full">
                  <div className="flex flex-row gap-3 items-center">
                    <p>
                      {i18n.t("Transza ")} {i + 1}
                    </p>
                    <p className="text-xs text-muted-foreground">
                      {formatMoney(tranchePaid)}
                      {" / "}
                      {formatMoney(tranche)}
                    </p>
                  </div>
                  <Progress
                    overflowWarning={true}
                    total={tranche}
                    current={tranchePaid}
                    delayAnimation={(i + 1) * 500}
                  />
                </div>
              );
            })}
          </div>
        )}
        {!!contract.down_payment && (
          <div className="flex flex-col gap-5 justify-center items-center">
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-row gap-3 items-center">
                <p>{i18n.t("Wpłata własna klienta")}</p>
                <p className="text-xs text-muted-foreground">
                  {formatMoney(totalClientPaid)}
                  {" / "}
                  {formatMoney(contract.down_payment)}
                </p>
              </div>
              <Progress overflowWarning={true} total={contract.down_payment} current={totalClientPaid} />
            </div>
            {contract.payment_type === ContractPaymentType.LOAN.value && (
              <div className="flex flex-col gap-1 w-full">
                <div className="flex flex-row gap-3 items-center">
                  <p>{i18n.t("Kredyt")}</p>
                  <p className="text-xs text-muted-foreground">
                    {formatMoney(totalLoanPaid)}
                    {" / "}
                    {formatMoney(creditAmount)}
                  </p>
                </div>
                <Progress overflowWarning={true} total={creditAmount} current={totalLoanPaid} />
              </div>
            )}
            {contract.payment_type === ContractPaymentType.PREFINANCED.value && (
              <div className="flex flex-col gap-1 w-full">
                <div className="flex flex-row gap-3 items-center">
                  <p>{i18n.t("Dotacja")}</p>
                  <p className="text-xs text-muted-foreground">
                    {formatMoney(totalDonationPaid)}
                    {" / "}
                    {formatMoney(creditAmount)}
                  </p>
                </div>
                <Progress overflowWarning={true} total={creditAmount} current={totalDonationPaid} />
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
