import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AuditStatusCreateDialog } from "@/features/audits/components/Settings/Statuses/AuditStatusCreateDialog";
import { AuditStatuses } from "@/features/audits/components/Settings/Statuses/AuditStatuses";
import { useAuditStatusPolicy } from "@/features/audits/policies/useAuditStatusPolicy";
import { CustomFieldCreateDialog } from "@/features/customFields/components/CustomFieldCreateDialog";
import { CustomFieldSettings } from "@/features/customFields/components/CustomFieldSettings";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { ProcessCreateDialog } from "@/features/processes/components/ProcessCreateDialog";
import { ProcessSettings } from "@/features/processes/components/ProcessSettings";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { SettingsTable } from "@/features/settings/components/SettingsTable";
import { SettingGroup } from "@/features/settings/enums/SettingGroupEnum";
import { useSettings } from "@/features/settings/hooks/useSettings";
import { useSettingPolicy } from "@/features/surveys/policies/useSettingPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const AuditSettings = () => {
  const { activeTab, setActiveTab } = useTabs("custom_fields");
  const { getSettingGroup } = useSettings();
  const auditStatusPolicy = useAuditStatusPolicy();
  const processPolicy = useProcessPolicy();
  const customFieldPolicy = useCustomFieldPolicy();
  const settingPolicy = useSettingPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {customFieldPolicy.create() && <TabsTrigger value="custom_fields">{i18n.t("Pola dodatkowe")}</TabsTrigger>}
        {auditStatusPolicy.update() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {customFieldPolicy.create() && (
        <TabsContent value="custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pola dodatkowe")}</h2>
            <CustomFieldCreateDialog appliesTo="Audit" />
          </div>
          <CustomFieldSettings appliesTo="Audit" />
        </TabsContent>
      )}
      {auditStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <AuditStatusCreateDialog />
          </div>
          <AuditStatuses />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Audit" />
          </div>
          <ProcessSettings model="Audit" />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.AUDIT)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
