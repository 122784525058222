import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const ClientPopup = ({ client }) => {
  const clientPolicy = useClientPolicy();
  if (!client) return null;
  const { address } = client;

  return (
    <p className="flex flex-col items-start justify-center text-xs gap-2">
      <span className="font-semibold text-sm text-muted-foreground flex items-center">
        {clientPolicy.view() ? <Link to={`${routes.clients}/${client.id}`}>{client.name}</Link> : client.name}
      </span>
      <span className="flex flex-col">
        <span>{`${address.street_number}, ${address.postal_code} ${address.city}`}</span>
        <span className="capitalize">{address.voivodeship}</span>
      </span>
    </p>
  );
};
