import { WarningAlertDialog } from "@/components/Alert/WarningAlertDialog";
import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteUserWidget, getWidgetData } from "@/features/dashboard/api/dashboardApi";
import { WidgetComponentWrapper } from "@/features/dashboard/components/WidgetComponentWrapper";
import { ActiveContractsWidget } from "@/features/dashboard/components/Widgets/ActiveContractsWidget";
import { ActiveLeadsWidget } from "@/features/dashboard/components/Widgets/ActiveLeadsWidget";
import { FinishedContractsWidget } from "@/features/dashboard/components/Widgets/FinishedContractsWidget";
import { IncomeChartWidget } from "@/features/dashboard/components/Widgets/IncomeChartWidget";
import { IncomeSummaryWidget } from "@/features/dashboard/components/Widgets/IncomeSummaryWidget";
import { InstallationsCountWidget } from "@/features/dashboard/components/Widgets/InstallationsCountWidget";
import { LastContractsWidget } from "@/features/dashboard/components/Widgets/LastContractsWidget";
import { MonthCalendarWidget } from "@/features/dashboard/components/Widgets/MonthCalendarWidget";
import { NewContractsWidget } from "@/features/dashboard/components/Widgets/NewContractsWidget";
import { NewLeadsWidget } from "@/features/dashboard/components/Widgets/NewLeadsWidget";
import { PaidComissionsWidget } from "@/features/dashboard/components/Widgets/PaidComissionsWidget";
import { PotentialComissionsWidget } from "@/features/dashboard/components/Widgets/PotentialComissionsWidget";
import { TasksCountWidget } from "@/features/dashboard/components/Widgets/TasksCountWidget";
import { UnpaidComissionsWidget } from "@/features/dashboard/components/Widgets/UnpaidComissionsWidget";
import { UserLevelProgressWidget } from "@/features/dashboard/components/Widgets/UserLevelProgressWidget";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";

const widgetFallback = <p>{i18n.t("Błąd podczas ładowania widget'u, skontaktuj się z administratorem")}</p>;

const widgetComponents = {
  NewLeadsWidget: (props) => <NewLeadsWidget {...props} />,
  IncomeSummaryWidget: (props) => <IncomeSummaryWidget {...props} />,
  NewContractsWidget: (props) => <NewContractsWidget {...props} />,
  LastContractsWidget: (props) => <LastContractsWidget {...props} />,
  IncomeChartWidget: (props) => <IncomeChartWidget {...props} />,
  ActiveLeadsWidget: (props) => <ActiveLeadsWidget {...props} />,
  TasksCountWidget: (props) => <TasksCountWidget {...props} />,
  ActiveContractsWidget: (props) => <ActiveContractsWidget {...props} />,
  InstallationsCountWidget: (props) => <InstallationsCountWidget {...props} />,
  FinishedContractsWidget: (props) => <FinishedContractsWidget {...props} />,
  UnpaidComissionsWidget: (props) => <UnpaidComissionsWidget {...props} />,
  PaidComissionsWidget: (props) => <PaidComissionsWidget {...props} />,
  PotentialComissionsWidget: (props) => <PotentialComissionsWidget {...props} />,
  MonthCalendarWidget: (props) => <MonthCalendarWidget {...props} />,
  UserLevelProgressWidget: (props) => <UserLevelProgressWidget {...props} />,
};

export const DashboardWidgetGridItem = ({ editMode, item }) => {
  const queryClient = useQueryClient();

  const WidgetComponent = widgetComponents[item.component] || (() => widgetFallback);

  const { data: response, isLoading } = useQuery({
    queryKey: ["widgetData", item.component],
    queryFn: () => getWidgetData(item.widgetKey),
    staleTime: 1000 * 60 * 5,
    enabled: !!item.widgetKey,
  });

  const deleteUserWidgetMutation = useMutation({
    mutationFn: deleteUserWidget,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["userWidgets"] });
      queryClient.invalidateQueries({ queryKey: ["widgets"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = (userWidgetId) => {
    deleteUserWidgetMutation.mutate(userWidgetId);
  };

  return (
    <Card
      className={cn(
        "flex flex-1 flex-col max-h-full h-full overflow-y-auto overflow-x-auto",
        editMode && "drag-handle cursor-move border-dashed border-2",
      )}
    >
      <CardHeader className="flex flex-row justify-between items-start pb-3">
        <div className="flex flex-col gap-0.5">
          <CardTitle className="flex flex-row items-center gap-2 text-base">{item.title}</CardTitle>
          <CardDescription className="text-sm">{item.description}</CardDescription>
        </div>
        {editMode && (
          <WarningAlertDialog
            onConfirm={() => handleDelete(item.userWidgetId)}
            title="Potwierdź akcję"
            message="Na pewno chcesz usunąć ten widget? Możesz go potem przywrócić."
            trigger={<Button leftIcon={<Trash size={16} />} size="icon" variant="ghost" />}
          />
        )}
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Loader />
        ) : (
          <WidgetComponentWrapper data={response?.data}>
            <WidgetComponent data={response?.data} />
          </WidgetComponentWrapper>
        )}
      </CardContent>
    </Card>
  );
};
