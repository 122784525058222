import { SurveyDetailsPage } from "@/features/surveys/pages/SurveyDetailsPage";
import { SurveysPage } from "@/features/surveys/pages/SurveysPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const SurveyRoutes = () => (
  <>
    <Route exact path={routes.surveys} element={<SurveysPage />} />
    <Route exact path={routes.survey} element={<SurveyDetailsPage />} />
  </>
);
