import { WarningAlertDialog } from "@/components/Alert/WarningAlertDialog";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { sanitizeSchema } from "@/constants/markdownSanitizeSchema";
import { SurveySignatureDialog } from "@/features/calculations/components/Surveys/SurveySignatureDialog";
import { createSurveyEntry, downloadSurveyEntryPdf } from "@/features/surveys/api/surveyApi";
import { SurveySection } from "@/features/surveys/components/Layout/SurveySection";
import { SurveyContext } from "@/features/surveys/context/SurveyContext";
import { SurveyQuestionType } from "@/features/surveys/enums/SurveyQuestionTypeEnum";
import SurveyEntryBuilder from "@/features/surveys/helpers/SurveyEntryBuilder";
import { useSurveyEntryPolicy } from "@/features/surveys/policies/useSurveyEntryPolicy";
import { sanitizeMarkdown } from "@/helpers/sanitizeMarkdown";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { saveAs } from "file-saver";
import { Check, ClipboardCheck, FileDown } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const createValidationSchema = (questions) => {
  const schemaShape = {};

  questions.forEach((question) => {
    let questionSchema = Yup.string();

    if (question.required) {
      questionSchema = questionSchema.required(i18n.t("Pole jest wymagane"));
    }

    if (question.type === SurveyQuestionType.LIST.value) {
      if (question.required) {
        questionSchema = Yup.string()
          .oneOf(
            question.question_options.map((option) => option.name),
            i18n.t("Wybierz poprawną wartość"),
          )
          .required(i18n.t("Pole jest wymagane"));
      } else {
        const oneOf = question.required
          ? question.question_options.map((option) => option.name)
          : [...question.question_options.map((option) => option.name), "-"];
        questionSchema = Yup.string().oneOf(oneOf, i18n.t("Wybierz poprawną wartość"));
      }
    } else if (question.type === SurveyQuestionType.CHECKBOX.value) {
      if (question.required) {
        questionSchema = Yup.array()
          .of(
            Yup.string().oneOf(
              question.question_options.map((option) => option.name),
              i18n.t("Wybierz poprawną wartość"),
            ),
          )
          .min(1, i18n.t("Zaznacz co najmniej jedną opcję"))
          .required(i18n.t("Pole jest wymagane"));
      } else {
        const oneOf = question.required
          ? question.question_options.map((option) => option.name)
          : [...question.question_options.map((option) => option.name), "-"];
        questionSchema = Yup.array()
          .of(Yup.string().oneOf(oneOf, i18n.t("Wybierz poprawną wartość")).optional())
          .nullable()
          .optional();
      }
    }

    schemaShape[question.identifier] = questionSchema;
  });

  return Yup.object().shape(schemaShape);
};

const buildDefaultValues = (survey) => {
  const questions = survey.sections.flatMap((section) => section.questions);
  let defaultValues = questions.reduce((acc, current) => {
    acc[current.identifier] = undefined;
    return acc;
  }, {});

  if (survey.entries?.length > 0) {
    const entryAnswers = survey.entries[0].answers;
    const entryQuestions = entryAnswers.sections?.flatMap((section) => section.questions);
    defaultValues = questions.reduce((acc, current) => {
      const entryQuestion = entryQuestions.find((question) => question.identifier === current.identifier);
      acc[current.identifier] = entryQuestion?.value ? entryQuestion.value : undefined;
      return acc;
    }, {});
  }

  return { defaultValues };
};

export const CalculationSurvey = ({ survey, calculation }) => {
  const surveyEntryPolicy = useSurveyEntryPolicy();
  const questions = survey.sections.flatMap((section) => section.questions);
  const validationSchema = createValidationSchema(questions);
  const queryClient = useQueryClient();
  const { setSurvey } = useContext(SurveyContext);
  const [surveyEntryPdfState, setSurveyEntryPdfState] = useState("idle");

  const createSurveyEntryMutation = useMutation({
    mutationFn: createSurveyEntry,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
      queryClient.invalidateQueries({ queryKey: ["calculation", calculation.id, "surveys"] });
      queryClient.invalidateQueries({ queryKey: ["lead", calculation?.lead?.id, "calculations"] });
    },
  });
  const { defaultValues } = buildDefaultValues(survey);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues,
  });

  const onSubmit = async () => {
    const values = form.getValues();
    const surveyEntryBuilder = new SurveyEntryBuilder(survey);
    const answers = surveyEntryBuilder.setValues(values).build();

    if (answers.sections) {
      for (const section of answers.sections) {
        section.questions = await Promise.all(
          section.questions.map(async (question) => {
            if (question.type === "PLAIN_TEXT" && typeof question.value === "string") {
              return {
                ...question,
                value: await sanitizeMarkdown(question.value, sanitizeSchema),
              };
            }
            return question;
          }),
        );
      }
    }

    delete answers.entries;
    const data = { answers, calculation_id: calculation.id };
    createSurveyEntryMutation.mutate({ surveyId: survey.id, data });
  };

  const handleDownloadSurvey = async () => {
    setSurveyEntryPdfState(() => "loading");
    const response = await downloadSurveyEntryPdf(survey.entries[0]?.id);
    if (response.ok) {
      saveAs(response?.data, survey.name + ".pdf");
      toast({ title: <SuccessToast title="Oferta została pobrana" /> });
    } else {
      toast({ title: <ErrorToast title="Błąd podczas pobierania ankiety." /> });
    }
    setSurveyEntryPdfState(() => "idle");
  };

  useEffect(() => {
    setSurvey(survey);
  }, [survey]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className="columns-1 lg:columns-2 gap-x-5">
        {survey.sections.map((section) => (
          <SurveySection section={section} key={section.id} />
        ))}
        {surveyEntryPolicy.create() && questions.length > 0 && survey.entries?.length === 0 && (
          <div className="flex flex-row justify-center">
            <WarningAlertDialog
              trigger={
                <Button
                  title="Zapisz ankietę"
                  leftIcon={<ClipboardCheck />}
                  type="button"
                  disabled={!form.formState.isValid}
                />
              }
              onConfirm={onSubmit}
              message="Czy na pewno chcesz zapisać tą ankietę? Nie będzie jej już można edytować"
            />
          </div>
        )}
        {survey.entries?.length > 0 && survey.entries[0].signature && (
          <div className="flex flex-row items-center gap-1 justify-center text-green-500 mb-3">
            <Check />
            <p>{i18n.t("Ankieta podpisana")}</p>
            <p>
              {`(${format(
                parse(survey.entries[0].signature.created_at, "yyyy-MM-dd HH:mm:ss", new Date()),
                "dd MMM yyyy HH:mm",
              )})`}
            </p>
          </div>
        )}
        {survey.entries?.length > 0 && (
          <div className="flex flex-row justify-center gap-3">
            {!survey.entries[0].signature && <SurveySignatureDialog surveyEntryId={survey.entries[0].id} />}
            <Button
              variant="outline"
              title="Pobierz ankietę w PDF"
              leftIcon={<FileDown />}
              type="button"
              isLoading={surveyEntryPdfState === "loading"}
              onClick={handleDownloadSurvey}
            />
          </div>
        )}
      </div>
    </Form>
  );
};
