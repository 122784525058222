import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ContractFileCategories } from "@/features/contracts/components/Settings/FileCategories/ContractFileCategories";
import { ContractFileCategoryCreateDialog } from "@/features/contracts/components/Settings/FileCategories/ContractFileCategoryCreateDialog";
import { ContractProductCreateDialog } from "@/features/contracts/components/Settings/Products/ContractProductCreateDialog";
import { ContractProducts } from "@/features/contracts/components/Settings/Products/ContractProducts";
import { ContractStatusCreateDialog } from "@/features/contracts/components/Settings/Statuses/ContractStatusCreateDialog";
import { ContractStatuses } from "@/features/contracts/components/Settings/Statuses/ContractStatuses";
import { ContractTemplateCreateDialog } from "@/features/contracts/components/Settings/Templates/ContractTemplateCreateDialog";
import { ContractTemplateInformationDialog } from "@/features/contracts/components/Settings/Templates/ContractTemplateInformationDialog";
import { ContractTemplates } from "@/features/contracts/components/Settings/Templates/ContractTemplates";
import { ContractTypeCreateDialog } from "@/features/contracts/components/Settings/ContractTypes/ContractTypeCreateDialog";
import { ContractTypes } from "@/features/contracts/components/Settings/ContractTypes/ContractTypes";
import { useContractFileCategoryPolicy } from "@/features/contracts/policies/useContractFileCategoryPolicy";
import { useContractProductPolicy } from "@/features/contracts/policies/useContractProductPolicy";
import { useContractStatusPolicy } from "@/features/contracts/policies/useContractStatusPolicy";
import { useContractTemplatePolicy } from "@/features/contracts/policies/useContractTemplatePolicy";
import { useContractTypePolicy } from "@/features/contracts/policies/useContractTypePolicy";
import { CustomFieldCreateDialog } from "@/features/customFields/components/CustomFieldCreateDialog";
import { CustomFieldSettings } from "@/features/customFields/components/CustomFieldSettings";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { ProcessCreateDialog } from "@/features/processes/components/ProcessCreateDialog";
import { ProcessSettings } from "@/features/processes/components/ProcessSettings";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { SettingsTable } from "@/features/settings/components/SettingsTable";
import { SettingGroup } from "@/features/settings/enums/SettingGroupEnum";
import { useSettings } from "@/features/settings/hooks/useSettings";
import { useSettingPolicy } from "@/features/surveys/policies/useSettingPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const ContractSettings = () => {
  const { activeTab, setActiveTab } = useTabs("custom_fields");
  const { getSettingGroup } = useSettings();
  const contractStatusPolicy = useContractStatusPolicy();
  const contractTypePolicy = useContractTypePolicy();
  const contractProductPolicy = useContractProductPolicy();
  const contractFileCategoryPolicy = useContractFileCategoryPolicy();
  const settingPolicy = useSettingPolicy();
  const processPolicy = useProcessPolicy();
  const contractTemplatePolicy = useContractTemplatePolicy();
  const customFieldPolicy = useCustomFieldPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {customFieldPolicy.create() && <TabsTrigger value="custom_fields">{i18n.t("Pola dodatkowe")}</TabsTrigger>}
        {contractStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {contractTypePolicy.create() && <TabsTrigger value="types">{i18n.t("Typy")}</TabsTrigger>}
        {contractProductPolicy.create() && <TabsTrigger value="products">{i18n.t("Produkty")}</TabsTrigger>}
        {contractFileCategoryPolicy.create() && (
          <TabsTrigger value="file_categories">{i18n.t("Kategorie plików")}</TabsTrigger>
        )}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
        {contractTemplatePolicy.viewAny() && <TabsTrigger value="templates">{i18n.t("Szablony")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {customFieldPolicy.create() && (
        <TabsContent value="custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
            <CustomFieldCreateDialog appliesTo="Contract" />
          </div>
          <CustomFieldSettings appliesTo="Contract" />
        </TabsContent>
      )}
      {contractStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ContractStatusCreateDialog />
          </div>
          <ContractStatuses />
        </TabsContent>
      )}
      {contractTypePolicy.create() && (
        <TabsContent value="types">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy")}</h2>
            <ContractTypeCreateDialog />
          </div>
          <ContractTypes />
        </TabsContent>
      )}
      {contractProductPolicy.create() && (
        <TabsContent value="products">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Produkty")}</h2>
            <ContractProductCreateDialog />
          </div>
          <ContractProducts />
        </TabsContent>
      )}
      {contractFileCategoryPolicy.create() && (
        <TabsContent value="file_categories">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Kategorie plików")}</h2>
            <ContractFileCategoryCreateDialog />
          </div>
          <ContractFileCategories />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Contract" />
          </div>
          <ProcessSettings model="Contract" />
        </TabsContent>
      )}
      {contractTemplatePolicy.viewAny() && (
        <TabsContent value="templates">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Szablony")}</h2>
            <div className="flex flex-row items-center gap-3">
              <ContractTemplateInformationDialog />
              <ContractTemplateCreateDialog />
            </div>
          </div>
          <ContractTemplates />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.CONTRACT)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
