import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Form } from "@/components/forms/Form";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { Loader } from "@/components/Loader/Loader";
import { Note } from "@/components/Notes/Note";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { createAuditNote, deleteAuditNote, getAuditNotes, updateAuditNote } from "@/features/audits/api/auditApi";
import { useAuditNotePolicy } from "@/features/audits/policies/useAuditNotePolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, StickyNote, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  note: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const AuditNotesCard = () => {
  const { id: auditId } = useParams();
  const auditNotePolicy = useAuditNotePolicy();
  const [create, setCreate] = useState(false);

  const queryClient = useQueryClient();
  const { isLoading, data: response } = useQuery({
    queryKey: ["audit", auditId, "notes"],
    queryFn: () => getAuditNotes(auditId),
  });

  const form = useForm({
    defaultValues: { note: undefined },
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const deleteAuditNoteMutation = useMutation({
    mutationFn: deleteAuditNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["audit", auditId, "notes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const updateAuditNoteMutation = useMutation({
    mutationFn: updateAuditNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["audit", auditId, "notes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const createAuditNoteMutation = useMutation({
    mutationFn: createAuditNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["audit", auditId, "notes"] });
        setCreate(false);
        form.reset();
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleEdit = (noteId, note) => {
    updateAuditNoteMutation.mutate({ auditId, noteId, note });
  };

  const handleDelete = (noteId) => {
    deleteAuditNoteMutation.mutate({ auditId, noteId });
  };

  const onSubmit = (data) => {
    createAuditNoteMutation.mutate({ auditId, note: data.note });
  };

  if (!auditNotePolicy.viewAny()) return null;

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Notatki")}</CardTitle>
          <CardDescription>{i18n.t("Notatki napisane do tego audytu")}</CardDescription>
        </div>
        {auditNotePolicy.create() && (
          <Button
            variant="outline"
            title="Dodaj notatkę"
            leftIcon={<StickyNote size={20} />}
            onClick={() => setCreate(!create)}
          />
        )}
      </CardHeader>
      <ScrollArea className="w-full flex max-h-96 flex-col">
        <CardContent>
          <div className="flex flex-col gap-3 justify-center items-center">
            {create && (
              <Form form={form} onSubmit={onSubmit} className="w-full mt-3 mb-3">
                <div className="flex flex-col gap-5">
                  <FormTextarea name="note" label="Treść" placeholder="Treść notatki" />
                  <div className="flex flex-row gap-3 items-center justify-end">
                    <Button
                      title="Anuluj"
                      type="button"
                      variant="destructive"
                      leftIcon={<X size={20} />}
                      onClick={() => setCreate(false)}
                    />
                    <Button title="Dodaj" leftIcon={<Plus size={20} />} isLoading={createAuditNoteMutation.isPending} />
                  </div>
                </div>
              </Form>
            )}
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {!create && !response?.data?.length && <EmptyState title="Brak notatek" />}
                {response?.data?.map((note) => {
                  return (
                    <Note
                      key={note.id}
                      note={note}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      canDelete={auditNotePolicy.destroy()}
                      canEdit={auditNotePolicy.update()}
                    />
                  );
                })}
              </>
            )}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
