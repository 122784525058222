import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

export const Loader = ({ className, size = "h-10 w-10" }) => {
  return (
    <div className={cn("flex w-full justify-center items-center p-3", className)}>
      <Loader2 className={cn("animate-spin", size)} />
    </div>
  );
};
