import { useAuth } from "@/features/auth/hooks/useAuth";

export const useUserLevelPolicy = () => {
  const { hasPermission } = useAuth();

  const create = () => {
    return hasPermission("create_user_level");
  };

  const update = () => {
    return hasPermission("update_user_level");
  };

  const destroy = (level) => {
    return hasPermission("delete_user_level") && level.required_points !== null;
  };

  return {
    create,
    update,
    destroy,
  };
};
