import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Form } from "@/components/forms/Form";
import { FormDateTimePicker } from "@/components/forms/FormDateTimePicker";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { ActionType } from "@/features/actions/enums/ActionTypeEnum";
import { groupActionsArchivedUpcoming } from "@/features/actions/helpers/groupActions";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { ClientAction } from "@/features/clients/components/Actions/ClientAction";
import { createLeadAction, getLeadActions } from "@/features/leads/api/leadApi";
import { useLeadActionPolicy } from "@/features/leads/policies/useLeadActionPolicy";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { roundToNearestMinutes } from "date-fns";
import { CalendarPlus, Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string().required(i18n.t("Pole jest wymagane")),
  action_at: Yup.date(i18n.t("Pole musi być poprawną datą.")).required(i18n.t("Pole jest wymagane")),
  description: Yup.string().nullable(),
});

export const ClientActionsCard = ({ client }) => {
  const { id: clientId } = useParams();
  const { user } = useAuth();
  const clientActionPolicy = useLeadActionPolicy();
  const [create, setCreate] = useState(false);
  const queryClient = useQueryClient();

  const defaultValues = {
    action_at: roundToNearestMinutes(new Date(), { nearestTo: 15 }),
    description: "",
    name: "",
    type: undefined,
  };

  const onSubmit = (data) => {
    createClientActionMutation.mutate({ leadId: clientId, action: data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["client", clientId, "actions"],
    queryFn: () => getLeadActions(clientId),
  });

  const createClientActionMutation = useMutation({
    mutationFn: createLeadAction,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["client", clientId, "actions"] });
        queryClient.invalidateQueries({ queryKey: ["user", user.id, "calendar"] });
        setCreate(false);
        form.reset();
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const { upcomingActions, archiveActions } = groupActionsArchivedUpcoming(response ? response?.data : []);

  if (!clientActionPolicy.viewAny()) return null;

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Działania")}</CardTitle>
          <CardDescription>{i18n.t("Działania związane z tym klientem")}</CardDescription>
        </div>
        {clientActionPolicy.create() && (
          <Button
            variant="outline"
            title="Dodaj działanie"
            leftIcon={<CalendarPlus size={20} />}
            onClick={() => setCreate(!create)}
          />
        )}
      </CardHeader>
      <ScrollArea className={cn("w-full flex flex-col", create ? "max-h-[500px]" : "max-h-96")}>
        <CardContent>
          <div className="flex flex-col gap-5">
            {create && (
              <Card className="shadow-md border-l-4 border-l-primary">
                <CardHeader>
                  <CardTitle>
                    <span className="text-primary font-bold text-lg flex flex-row gap-1 flex-wrap">
                      <p className="text-foreground">{i18n.t("Działanie na")}</p>
                      {client.name}
                    </span>
                  </CardTitle>
                </CardHeader>
                <Form onSubmit={onSubmit} form={form}>
                  <CardContent>
                    <div className="flex flex-col gap-4">
                      <FormField name="name" label="Nazwa działania" />
                      <FormDateTimePicker name="action_at" placeholder="Data działania" label="Data działania" />
                      <FormSelect name="type" options={ActionType.getForClient()} label="Typ działania" />
                      <FormTextarea
                        name="description"
                        label="Opis działania"
                        placeholder="Opis działania (opcjonalne)"
                      />
                    </div>
                  </CardContent>
                  <CardFooter className="gap-3 items-center border-t px-6 py-4">
                    <Button
                      type="submit"
                      title="Zapisz"
                      leftIcon={<Check size={20} />}
                      isLoading={createClientActionMutation.isPending}
                    />
                    <Button
                      title="Anuluj"
                      type="button"
                      leftIcon={<X size={20} />}
                      variant="destructive"
                      onClick={() => setCreate(false)}
                    />
                  </CardFooter>
                </Form>
              </Card>
            )}
            {isLoading && !response ? (
              <Loader />
            ) : (
              <>
                {!create && !response?.data?.length && <EmptyState title="Brak działań" />}
                {upcomingActions.map((action) => {
                  return <ClientAction key={action.id} action={action} />;
                })}
                {archiveActions.map((action) => {
                  return <ClientAction key={action.id} action={action} />;
                })}
              </>
            )}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
