import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormFileUpload } from "@/components/forms/FormFileUpload";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { getCalculators } from "@/features/calculators/api/calculatorApi";
import { createContractTemplate, getContractTemplates } from "@/features/contracts/api/contractApi";
import { useContractTemplatePolicy } from "@/features/contracts/policies/useContractTemplatePolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  calculator_id: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const ContractTemplateCreateDialog = () => {
  const queryClient = useQueryClient();
  const contractTemplatePolicy = useContractTemplatePolicy();

  const [isOpen, setIsOpen] = useState(false);
  const [calculatorOptions, setCalculatorOptions] = useState([]);

  const {
    isLoading,
    data: calculators,
    error,
  } = useQuery({
    queryKey: ["calculators"],
    queryFn: getCalculators,
    enabled: isOpen,
  });

  const {
    isLoading: isLoadingContractTemplates,
    data: contractTemplates,
    error: errorContractTemplates,
  } = useQuery({
    queryKey: ["contractTemplates"],
    queryFn: getContractTemplates,
    enabled: contractTemplatePolicy.viewAny(),
  });

  const onSubmit = (values) => {
    const data = new FormData();
    if (values.template?.length === 0) {
      toast({ title: <ErrorToast title="Szablon umowy jest wymagany" /> });
    } else {
      data.append(`template`, values.template[0]);
      data.append("calculator_id", values.calculator_id);
      createContractTemplateMutation.mutate(data);
    }
  };

  const defaultValues = {
    calculator_id: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });

  const createContractTemplateMutation = useMutation({
    mutationFn: createContractTemplate,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contractTemplates"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Szablon dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (
      !isLoading &&
      !error &&
      calculators &&
      !isLoadingContractTemplates &&
      !errorContractTemplates &&
      contractTemplates
    ) {
      const calculatorsIds = contractTemplates.data.map((template) => template.calculator.id);
      setCalculatorOptions(
        calculators.data
          .filter((calculator) => !calculatorsIds.includes(calculator.id))
          .map((calculator) => ({ name: calculator.name, value: calculator.id })),
      );
    }
  }, [isLoading, calculators, error, isLoadingContractTemplates, errorContractTemplates, contractTemplates]);

  if (!contractTemplatePolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj szablon" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj szablon")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego szablonu umowy.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormCombobox
              name="calculator_id"
              label="Kalkulator"
              description="Kalkulator którego dotyczy dany szablon"
              options={calculatorOptions}
            />
            <FormFileUpload name="template" label="Szablon umowy" multiple={false} />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createContractTemplateMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
