import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CalculatorField } from "@/features/calculators/components/Layout/CalculatorField";
import { isEmpty } from "@/helpers/isEmpty";
import { cn } from "@/lib/utils";
import { useFormContext } from "react-hook-form";

export const CalculatorSection = ({ section }) => {
  const fields = section.fields;

  const { watch } = useFormContext();
  const watchFields = watch();

  const isSectionVisible = isEmpty(watchFields)
    ? true
    : fields.some((field) => watchFields.visibility && watchFields.visibility[field.identifier]);

  return (
    <Card className={cn("w-full h-fit break-inside-avoid mb-5", !isSectionVisible && "hidden")}>
      <CardHeader>
        <CardTitle>{section.name}</CardTitle>
        <CardDescription>{section.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-6">
          {fields.map((field) => (
            <CalculatorField field={field} sectionFields={fields} key={field.id} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
