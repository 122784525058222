import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { getContractProcesses, updateContractProcessProgress } from "@/features/contracts/api/contractApi";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { ProcessesCard } from "@/features/processes/components/ProcessCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ContractProcessesCard = () => {
  const { id: contractId } = useParams();
  const contractPolicy = useContractPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["contract", contractId, "processes"],
    queryFn: () => getContractProcesses(contractId),
    enabled: contractPolicy.viewProcesses(),
  });

  const updateContractProcessProgressMutation = useMutation({
    mutationFn: updateContractProcessProgress,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "processes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleUpdateContractProcess = (processId, step, completed) => {
    updateContractProcessProgressMutation.mutate({ contractId, processId, data: { step_id: step.id, completed } });
  };

  return (
    <ProcessesCard
      policy={contractPolicy}
      handleCheck={handleUpdateContractProcess}
      isLoading={isLoading}
      processes={response?.data}
    />
  );
};
