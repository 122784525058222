import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { getApplicationProcesses, updateApplicationProcessProgress } from "@/features/applications/api/applicationApi";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { ProcessesCard } from "@/features/processes/components/ProcessCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ApplicationProcessesCard = () => {
  const { id: applicationId } = useParams();
  const applicationPolicy = useApplicationPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["application", applicationId, "processes"],
    queryFn: () => getApplicationProcesses(applicationId),
    enabled: applicationPolicy.viewProcesses(),
  });

  const updateApplicationProcessProgressMutation = useMutation({
    mutationFn: updateApplicationProcessProgress,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["application", applicationId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["application", applicationId, "processes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleUpdateApplicationProcess = (processId, step, completed) => {
    updateApplicationProcessProgressMutation.mutate({
      applicationId,
      processId,
      data: { step_id: step.id, completed },
    });
  };

  return (
    <ProcessesCard
      policy={applicationPolicy}
      handleCheck={handleUpdateApplicationProcess}
      isLoading={isLoading}
      processes={response?.data}
    />
  );
};
