import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { createAutomationAction } from "@/features/automations/api/automationApi";
import { AutomationActionType } from "@/features/automations/enums/AutomationActionTypeEnum";
import { getMailRecipientOptions } from "@/features/automations/helpers/getMailRecipientOptions";
import { getNotificationRecipientOptions } from "@/features/automations/helpers/getNotificationRecipientOptions";
import { getSmsRecipientOptions } from "@/features/automations/helpers/getSmsRecipientOptions";
import { getTaskOwnerOptions } from "@/features/automations/helpers/getTaskOwnerOptions";
import { useAutomationPolicy } from "@/features/automations/policies/useAutomationPolicy";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { TaskDueDateUnit } from "@/features/tasks/enums/TaskDueDateUnitEnum";
import { UsersContext } from "@/features/users/context/UsersContext";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  action_type: Yup.string().required(i18n.t("Pole jest wymagane")),
  parameters: Yup.object(),
});

export const AutomationActionCreateDialog = ({ automation }) => {
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const { typeOptions: applicationTypeOptions, isLoading: isLoadingApplicationTypes } =
    useContext(ApplicationTypeContext);
  const { typeOptions: installationTypeOptions, isLoading: isLoadingInstallationTypes } =
    useContext(InstallationTypeContext);
  const queryClient = useQueryClient();
  const automationPolicy = useAutomationPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createAutomationActionMutation.mutate({ automationId: automation.id, data });
  };

  const defaultValues = {
    action_type: undefined,
    parameters: {},
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });
  const watch = form.watch();

  const createAutomationActionMutation = useMutation({
    mutationFn: createAutomationAction,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["automation", automation.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Akcja dodana." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const renderActionParametersFields = () => {
    switch (watch.action_type) {
      case AutomationActionType.SendSms.value:
        return (
          <>
            <FormCombobox
              className="text-left"
              options={getSmsRecipientOptions()}
              label="Odbiorca"
              name="parameters.sms_recipient"
            />
            {watch.parameters.sms_recipient === "user_id" && (
              <FormCombobox
                label="Wybierz użytkownika"
                options={userOptions}
                isLoading={isLoadingUsers}
                name="parameters.user_id"
              />
            )}
            <FormTextarea label="Treść" name="parameters.sms_content" />
          </>
        );
      case AutomationActionType.CreateTask.value:
        return (
          <>
            <FormCombobox
              className="text-left"
              options={getTaskOwnerOptions()}
              label="Zadanie dla"
              name="parameters.task_owner"
            />
            {watch.parameters.task_owner === "user_id" && (
              <FormCombobox
                label="Wybierz użytkownika"
                options={userOptions}
                isLoading={isLoadingUsers}
                name="parameters.user_id"
              />
            )}
            <FormField label="Zadanie" placeholder="Treść zadania" name="parameters.task.task" />
            <div className="flex flex-row gap-3 items-center">
              <div className="w-full">
                <FormNumberField
                  integerOnly={true}
                  label="Termin wykonania zadania"
                  name="parameters.task.due_date_amount"
                />
              </div>
              <div className="w-full">
                <FormSelect
                  options={TaskDueDateUnit.getValues()}
                  label="Jednostka"
                  name="parameters.task.due_date_unit"
                  className="flex-1"
                />
              </div>
            </div>
            <FormTextarea
              name="parameters.task.description"
              label="Opis zadania"
              placeholder="Opis zadania (opcjonalne)"
            />
          </>
        );
      case AutomationActionType.SendEmail.value:
        return (
          <>
            <FormCombobox
              className="text-left"
              options={getMailRecipientOptions()}
              label="Odbiorca"
              name="parameters.mail_recipient"
            />
            {watch.parameters.mail_recipient === "user_id" && (
              <FormCombobox
                label="Wybierz użytkownika"
                options={userOptions}
                isLoading={isLoadingUsers}
                name="parameters.user_id"
              />
            )}
            <FormField name="parameters.mail_subject" label="Tytuł" />
            <FormTextarea label="Treść" name="parameters.mail_content" />
          </>
        );
      case AutomationActionType.CreateAudit.value:
        return (
          <>
            <FormCombobox
              label="Użytkownik odpowiedzialny"
              options={userOptions}
              isLoading={isLoadingUsers}
              name="parameters.user_id"
              required={false}
              placeholder="Nieprzypisany audyt"
            />
            <FormTextarea name="parameters.audit.description" label="Opis" placeholder="Opis audytu (opcjonalne)" />
          </>
        );
      case AutomationActionType.CreateApplication.value:
        return (
          <>
            <FormCombobox
              label="Typ wniosku"
              isLoading={isLoadingApplicationTypes}
              options={applicationTypeOptions}
              name="parameters.application.type"
            />
            <FormCombobox
              label="Użytkownik odpowiedzialny"
              options={userOptions}
              isLoading={isLoadingUsers}
              name="parameters.user_id"
            />
            <FormTextarea
              name="parameters.application.description"
              label="Opis"
              placeholder="Opis wniosku (opcjonalne)"
            />
          </>
        );
      case AutomationActionType.CreateInstallation.value:
        return (
          <>
            <FormCombobox
              label="Typ montażu"
              isLoading={isLoadingInstallationTypes}
              options={installationTypeOptions}
              name="parameters.installation.type"
            />
            <FormCombobox
              label="Użytkownik odpowiedzialny"
              options={userOptions}
              isLoading={isLoadingUsers}
              name="parameters.user_id"
            />
            <FormField
              name="parameters.installation.name"
              label="Nazwa"
              description={`Opcjonalnie możesz podać nazwę nowo stworzonego montażu, jeśli tego nie zrobisz zostanie stworzony z nazwą według wzoru "Nazwa klienta - Typ montażu"`}
            />
            <FormTextarea
              name="parameters.installation.description"
              label="Opis"
              placeholder="Opis montażu (opcjonalne)"
            />
          </>
        );
      case AutomationActionType.SendNotification.value:
        return (
          <>
            <FormCombobox
              className="text-left"
              options={getNotificationRecipientOptions()}
              label="Odbiorca"
              name="parameters.notification_recipient"
            />
            {watch.parameters.notification_recipient === "user_id" && (
              <FormCombobox
                label="Wybierz użytkownika"
                options={userOptions}
                isLoading={isLoadingUsers}
                name="parameters.user_id"
              />
            )}
            <FormField name="parameters.notification_title" label="Tytuł" />
            <FormTextarea label="Treść" name="parameters.notification_message" />
          </>
        );
      default:
        return i18n.t("Niewspierana akcja, skontaktuj się z administratorem");
    }
  };

  if (!automationPolicy.update()) return i18n.t("Brak wyzwalacza");

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj akcję" leftIcon={<Plus size={20} />} className="w-full" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj akcję")}</DialogTitle>
          <DialogDescription>{automation.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect
              label="Typ akcji"
              name="action_type"
              autoComplete="off"
              options={AutomationActionType.getValues()}
            />
            {watch.action_type && renderActionParametersFields()}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createAutomationActionMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
