import { LeadSelectedContext } from "@/features/leads/context/LeadSelectedContext";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { routes } from "@/routes";
import { useContext } from "react";
import { Link } from "react-router-dom";

export const LeadPopup = ({ lead }) => {
  const { selected, setSelected } = useContext(LeadSelectedContext);
  const leadPolicy = useLeadPolicy();
  if (!lead) return null;

  const { address } = lead;

  const handleSelect = (checked) => {
    if (checked) {
      setSelected([...selected, lead.id]);
    } else {
      setSelected(selected.filter((item) => item !== lead.id));
    }
  };

  return (
    <p className="flex flex-col items-start justify-center text-xs gap-2">
      <span className="font-semibold text-sm text-muted-foreground flex items-center">
        <input
          type="checkbox"
          className="mr-1"
          value={selected.includes(lead.id)}
          onChange={(e) => handleSelect(e.target.checked)}
        />
        {leadPolicy.view() ? <Link to={`${routes.leads}/${lead.id}`}>{lead.name}</Link> : lead.name}
      </span>
      <span className="flex flex-col">
        <span>{`${address.street_number}, ${address.postal_code} ${address.city}`}</span>
        <span className="capitalize">{address.voivodeship}</span>
      </span>
    </p>
  );
};
