import { useAuth } from "@/features/auth/hooks/useAuth";

export const useClientPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_clients", "access_own_clients"]);
  };

  const viewUsers = () => {
    return hasAnyPermission(["access_all_clients"]);
  };

  const create = () => {
    return hasPermission("create_client");
  };

  const view = () => {
    return hasAnyPermission(["show_any_client", "show_own_client"]);
  };

  const update = () => {
    return hasPermission("update_client");
  };

  const destroy = () => {
    return hasPermission("delete_client");
  };

  return {
    viewAny,
    viewUsers,
    view,
    create,
    update,
    destroy,
  };
};
