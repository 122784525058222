import { Button } from "@/components/Button";
import { Input } from "@/components/forms/Input";
import { DesktopMenuLink } from "@/components/layout/Navbar/DesktopMenuLink";
import { SquareSiteLogo } from "@/components/SquareSiteLogo";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { NotificationDropdown } from "@/features/notifications/components/NotificationDropdown";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { removePolishCharacters } from "@/helpers/stringHelpers";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { LogOut } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const DesktopMenu = ({ groupedMenuItems, getActive }) => {
  const { logOut, user } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [search, setSearch] = useState("");

  const [filteredMenuItems, setFilteredMenuItems] = useState(groupedMenuItems);

  useEffect(() => {
    if (search.length > 0) {
      const normalizedSearch = removePolishCharacters(search.toLowerCase());

      const filtered = Object.entries(groupedMenuItems).reduce((acc, [groupName, items]) => {
        const filteredItems = items.filter((item) => {
          const normalizedTitle = removePolishCharacters(item.title.toLowerCase());
          return normalizedTitle.includes(normalizedSearch) && item.visible;
        });

        if (filteredItems.length > 0) {
          acc[groupName] = filteredItems;
        }
        return acc;
      }, {});

      setFilteredMenuItems(filtered);
    } else {
      setFilteredMenuItems(groupedMenuItems);
    }
  }, [search, groupedMenuItems]);

  return (
    <nav
      className={cn(
        "fixed left-0 top-0 z-40 h-screen bg-background border-r border-border text-foreground transition-all duration-300 ease-in-out flex-col items-center justify-between pt-5 hidden lg:flex",
        isExpanded ? "w-64" : "w-[75px]",
      )}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="flex flex-col w-full h-[80vh] transition-all px-3">
        <div className="h-[5vh] flex gap-3 items-center justify-center z-10 w-full">
          <SquareSiteLogo className={cn("h-10")} linkTo={routes.dashboard} />
        </div>
        <Input
          className={cn(
            "transition-all duration-300 opacity-0 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 h-0 py-0 px-3",
            isExpanded && "opacity-100 h-10 mt-3 mb-3",
          )}
          placeholder="Szukaj..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <ScrollArea className="h-[70vh]">
          <nav className={cn("w-full flex flex-col gap-3 mt-1")}>
            {Object.entries(filteredMenuItems).map(([groupName, items]) => {
              const visibleItems = items.filter((item) => item.visible);
              return visibleItems.length > 0 ? (
                <div key={groupName} className="flex flex-col gap-2 w-full">
                  <p
                    className={cn(
                      "text-xs font-semibold text-muted-foreground w-full",
                      isExpanded ? "opacity-100 h-fit" : "opacity-0 h-0",
                    )}
                  >
                    {groupName}
                  </p>
                  {visibleItems.map((item, index) => (
                    <DesktopMenuLink key={index} menuItem={item} isMenuExpanded={isExpanded} getActive={getActive} />
                  ))}
                </div>
              ) : null;
            })}
          </nav>
        </ScrollArea>
      </div>
      {/* bottom menu */}
      <div className={cn("w-full h-[18vh] flex flex-col gap-2 mt-3 justify-center items-center px-3")}>
        <NotificationDropdown title={isExpanded ? "Powiadomienia" : ""} variant="hover-card" />
        <Separator className={cn(isExpanded ? "w-full" : "w-3/4")} />
        <div className={cn("flex justify-start items-center gap-1 w-full px-1", isExpanded ? "rounded-md" : "")}>
          <Link
            to={routes.account}
            className={cn("flex justify-start gap-2 items-center rounded-md w-full hover:bg-border box-content py-1")}
          >
            <UserAvatar user={user} />
            <div className={cn("flex flex-col justify-center items-start", isExpanded ? "block" : "hidden")}>
              <span className="flex flex-col justify-center">
                <p className="font-semibold text-sm">{user.name}</p>
              </span>
            </div>
          </Link>
          <Button
            leftIcon={<LogOut className="h-5 w-5 flex-shrink-0" />}
            variant="ghost"
            className={cn("px-4 h-12", isExpanded ? "block" : "hidden")}
            onClick={logOut}
          />
        </div>
        <span className="text-xs flex flex-row items-center h-[2vh] transition-all text-muted-foreground font-semibold">
          <p className={cn("whitespace-nowrap mr-1", isExpanded ? "block" : "hidden")}>{i18n.t("Powered by")}</p>
          <a href="https://easecrm.pl">easecrm.pl</a>
        </span>
      </div>
    </nav>
  );
};
