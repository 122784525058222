import api from "@/api/api";
import { COSTS_ENDPOINT, LEVELS_ENDPOINT, STATS_ENDPOINT, USERS_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDate } from "@/helpers/formatDate";

export const getUsers = (filters) => api.get(USERS_ENDPOINT, transformQueryFilters(filters));

export const getUser = (id) => api.get(USERS_ENDPOINT + id);

export const getUserStats = (userId, filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(`${USERS_ENDPOINT}${userId}${STATS_ENDPOINT}`, queryFilters);
};

export const createUser = ({ data }) => api.post(USERS_ENDPOINT, data);

export const deleteUser = (id) => api.delete(USERS_ENDPOINT + id);

export const updateUser = ({ id, data }) => api.put(USERS_ENDPOINT + id, data);

export const getUserCosts = (userId) => api.get(USERS_ENDPOINT + userId + COSTS_ENDPOINT);

export const createUserCost = ({ userId, data }) => api.post(USERS_ENDPOINT + userId + COSTS_ENDPOINT, data);

export const deleteUserCost = ({ userId, costId }) => api.delete(USERS_ENDPOINT + userId + COSTS_ENDPOINT + costId);

export const getUserLevels = () => api.get(LEVELS_ENDPOINT);

export const createUserLevel = ({ data }) => api.post(LEVELS_ENDPOINT, data);

export const updateUserLevel = ({ levelId, data }) => api.patch(LEVELS_ENDPOINT + levelId, data);

export const deleteUserLevel = ({ levelId }) => api.delete(LEVELS_ENDPOINT + levelId);
