import { ClientCreatePage } from "@/features/clients/pages/ClientCreatePage";
import { ClientDetailsPage } from "@/features/clients/pages/ClientDetailsPage";
import { ClientEditPage } from "@/features/clients/pages/ClientEditPage";
import { ClientsPage } from "@/features/clients/pages/ClientsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const ClientRoutes = () => (
  <>
    <Route exact path={routes.clients} element={<ClientsPage />} />
    <Route exact path={routes.clientCreate} element={<ClientCreatePage />} />
    <Route exact path={routes.client} element={<ClientDetailsPage />} />
    <Route exact path={routes.clientEdit} element={<ClientEditPage />} />
  </>
);
