import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AuditActionsCard } from "@/features/audits/components/Actions/AuditActionsCard";
import { AuditInformationCard } from "@/features/audits/components/AuditInformationCard";
import { AuditNotesCard } from "@/features/audits/components/AuditNotesCard";
import { AuditProcessesCard } from "@/features/audits/components/AuditProcessesCard";
import { AuditFilesTable } from "@/features/audits/components/Files/AuditFilesTable";
import { useAuditFilePolicy } from "@/features/audits/policies/useAuditFilePolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const AuditDetails = ({ audit }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const auditFilePolicy = useAuditFilePolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {auditFilePolicy.viewAny() && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {auditFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <AuditInformationCard audit={audit} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <AuditNotesCard />
            <AuditActionsCard />
          </div>
          <AuditProcessesCard />
        </div>
      </TabsContent>
      {auditFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
