import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DateRangePicker } from "@/components/forms/DateRangePicker";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ContractsTable } from "@/features/contracts/components/ContractsTable";
import { ContractSettings } from "@/features/contracts/components/Settings/ContractSettings";
import { ContractsStats } from "@/features/contracts/components/Stats/ContractsStats";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { useContractStatusPolicy } from "@/features/contracts/policies/useContractStatusPolicy";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { useViewModes } from "@/features/viewModes/hooks/useVIewModes";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useState } from "react";

export const ContractsPage = () => {
  useDocumentTitle("Umowy");
  const { activeTab, setActiveTab } = useTabs();
  const contractPolicy = useContractPolicy();
  const contractStatusPolicy = useContractStatusPolicy();
  const { renderModeSwitcher, viewMode } = useViewModes(ViewMode.TABLE, [ViewMode.TABLE, ViewMode.GROUPED], activeTab);

  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "stats" && (
            <DateRangePicker onSelect={setDateRange} selected={dateRange} placeholder="Wybierz zakres" />
          )}
          {renderModeSwitcher()}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {(contractPolicy.viewStats() || contractStatusPolicy.create()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {contractPolicy.viewStats() && <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>}
            {contractStatusPolicy.update() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractsTable viewMode={viewMode} />
          </div>
        </TabsContent>
        {contractPolicy.viewStats() && (
          <TabsContent value="stats">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <ContractsStats dateRange={dateRange} />
            </div>
          </TabsContent>
        )}
        {contractStatusPolicy.update() && (
          <TabsContent value="settings">
            <ContractSettings />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
