import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { FormList } from "@/components/forms/FormList";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { createSurveyQuestion } from "@/features/surveys/api/surveyApi";
import { SurveyQuestionType } from "@/features/surveys/enums/SurveyQuestionTypeEnum";
import { useSurveyPolicy } from "@/features/surveys/policies/useSurveyPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string().required(i18n.t("Pole jest wymagane")),
  display_name: Yup.string().optional().nullable(),
  question_options: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required(i18n.t("Każda opcja musi mieć nazwę")),
      }),
    )
    .nullable()
    .when("type", {
      is: SurveyQuestionType.LIST.value,
      then: () => Yup.array().min(1, i18n.t("Wymagane jest dodanie co najmniej jednej opcji")),
      otherwise: () => Yup.array().nullable(),
    }),
  required: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  description: Yup.string().when(["type"], {
    is: SurveyQuestionType.PLAIN_TEXT.value,
    then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
    otherwise: () => Yup.string().optional().nullable(),
  }),
});

export const SurveyQuestionCreateDialog = ({ surveySectionId }) => {
  const queryClient = useQueryClient();
  const { id: surveyId } = useParams();
  const surveyPolicy = useSurveyPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const createSurveyQuestionMutation = useMutation({
    mutationFn: createSurveyQuestion,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["survey", surveyId] });
      if (res.ok) {
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const onSubmit = (data) => {
    createSurveyQuestionMutation.mutate({ surveySectionId, data });
  };

  const defaultValues = {
    name: "",
    display_name: undefined,
    type: "",
    question_options: [],
    required: false,
    description: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });

  const { type, name, display_name } = form.watch();

  useEffect(() => {
    if (type !== SurveyQuestionType.LIST.value || type !== SurveyQuestionType.CHECKBOX.value) {
      form.setValue("question_options", []);
    }
  }, [type]);

  useEffect(() => {
    if (display_name === undefined) {
      form.setValue("display_name", name);
    }
  }, [name]);

  if (!surveyPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj nowe pytanie" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="max-w-6xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj pytanie")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego pytania do ankiety.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect options={SurveyQuestionType.getValues()} label="Typ pytania" name="type" />
            {type && (
              <>
                <FormField label="Pytanie" placeholder="Treść pytania" name="name" autoComplete="off" />
                {type !== SurveyQuestionType.PLAIN_TEXT.value && (
                  <FormField
                    label="Nazwa wyświetlana"
                    placeholder={name ? name : "Nazwa pola"}
                    name="display_name"
                    autoComplete="off"
                  />
                )}
                {(type === SurveyQuestionType.LIST.value || type === SurveyQuestionType.CHECKBOX.value) && (
                  <FormList
                    name="question_options"
                    label="Opcje do wyświetlenia"
                    withDefaults={type === SurveyQuestionType.LIST.value}
                  />
                )}
                {type === SurveyQuestionType.PLAIN_TEXT.value && (
                  <MarkdownEditor
                    height="500px"
                    width="68rem"
                    value={form.watch("description")}
                    onChange={(value) => {
                      form.setValue("description", value);
                    }}
                  />
                )}
                {type !== SurveyQuestionType.PLAIN_TEXT.value && (
                  <>
                    <FormCheckbox name="required" label="Pole jest wymagane" />
                    <FormTextarea name="description" label="Opis" placeholder="Opis pola" />
                  </>
                )}
              </>
            )}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createSurveyQuestionMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
