import { EmptyState } from "@/components/EmptyState";
import { Loader } from "@/components/Loader/Loader";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { getContractChanges } from "@/features/contracts/api/contractApi";
import { useContractChangePolicy } from "@/features/contracts/policies/useContractChangePolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import i18n from "@/i18n";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { ArrowRight } from "lucide-react";
import { useParams } from "react-router-dom";

export const ContractChanges = () => {
  const { id: contractId } = useParams();
  const contractChangesPolicy = useContractChangePolicy();

  const { isLoading, data: changes } = useQuery({
    queryKey: ["contract", contractId, "changes"],
    queryFn: () => getContractChanges(contractId),
    staleTime: 60 * 60 * 5,
    enabled: contractChangesPolicy.viewAny(),
  });

  const renderChanges = (changeItem) => {
    const { changes } = changeItem;

    return (
      <div className="mt-3 flex flex-col divide-y divide-accent">
        {changes.map((change) => {
          const { new: newValue, old: oldValue, field } = change;
          return (
            <div className="flex flex-row gap-3 items-center py-3 hover:bg-accent" key={changeItem.id + "_changes"}>
              <Badge className="w-fit flex flex-row gap-3 items-center hover:bg-foreground bg-foreground dark:text-background">
                {i18n.t("contract." + field)}
              </Badge>
              <p className="text-red-500 font-medium">
                {!oldValue ? (
                  <span>{i18n.t("Brak")}</span>
                ) : (
                  <span>{Array.isArray(oldValue) ? oldValue.join(", ") : oldValue}</span>
                )}
              </p>
              <ArrowRight size={14} />
              <p className="text-green-500 font-bold">{Array.isArray(newValue) ? newValue.join(", ") : newValue}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Accordion type="multiple" collapsible className="w-1/2" defaultValue={["item-0"]}>
      {changes?.data?.length === 0 && <EmptyState title="Brak zmian." />}
      {changes?.data?.map((change, index) => {
        if (change.changes.length === 0) return null;

        return (
          <AccordionItem key={change.id} value={`item-${index}`}>
            <AccordionTrigger className="hover:no-underline">
              <span className="flex flex-row gap-5 items-center">
                <p className="text-base font-bold">
                  {format(parse(change.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMMM yyyy HH:mm")}
                </p>
              </span>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col gap-2">
              <div className="flex flex-row gap-1 items-center">
                <span className="font-semibold">{i18n.t("Zmiana przez: ")}</span>
                <UserTooltip user={change.user} />
              </div>
              {change.reason && (
                <p className="flex flex-row gap-1 items-center">
                  <span className="font-semibold">{i18n.t("Powód zmiany: ")}</span>
                  {change.reason}
                </p>
              )}
              {renderChanges(change)}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
