import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteCredit, getCredits } from "@/features/credits/api/creditApi";
import { CreditFilters } from "@/features/credits/components/CreditFilters";
import { CreditGroupedViewItem } from "@/features/credits/components/CreditGroupedViewItem";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { useCreditsTableColumns } from "@/features/credits/hooks/useCreditsTableColumns";
import { GroupedView } from "@/features/viewModes/components/GroupedView";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const CreditsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const { statuses } = useContext(CreditStatusContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const handleDelete = (creditId) => {
    deleteCreditMutation.mutate(creditId);
  };

  const deleteCreditMutation = useMutation({
    mutationFn: deleteCredit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["credits"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["credits", pagination, sorting, columnFilters],
    queryFn: () => getCredits({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useCreditsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full flex-col flex">
      <div className="flex flex-col gap-5 w-full">
        {viewMode === ViewMode.TABLE.value && (
          <Table
            table={table}
            Filters={CreditFilters}
            meta={response?.meta}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        )}
        {viewMode === ViewMode.GROUPED.value && (
          <GroupedView
            isFetching={isFetching}
            isLoading={isLoading}
            table={table}
            Filters={CreditFilters}
            meta={response?.meta}
            groupedData={response?.data?.reduce((acc, item) => {
              const key = item.status.name;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(item);
              return acc;
            }, {})}
            groupHeaders={statuses.map((status) => ({ name: status.name, color: status.color }))}
            renderItem={(credit) => <CreditGroupedViewItem credit={credit} />}
          />
        )}
      </div>
    </div>
  );
};
