import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CalculationFieldsSummary } from "@/features/calculations/components/CalculationFieldsSummary";
import { CalculationPriceSummaryCardContent } from "@/features/calculators/components/CalculationPriceSummaryCardContent";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import i18n from "@/i18n";

export const CalculationDetails = ({ calculation }) => {
  const leadCalculationPolicy = useLeadCalculationPolicy();

  return (
    <div className="columns-1 lg:columns-2 gap-x-5">
      <CalculationFieldsSummary calculation={calculation} />
      {leadCalculationPolicy.view(calculation) ? (
        <Card className="w-full h-fit break-inside-avoid mb-5">
          <CardHeader>
            <CardTitle>{i18n.t("Podsumowanie")}</CardTitle>
            <CardDescription>{i18n.t("Kwoty oraz pola dodatkowe wyliczone podczas kalkulacji")}</CardDescription>
          </CardHeader>
          <CardContent>
            <CalculationPriceSummaryCardContent calculator={calculation.calculator} />
          </CardContent>
        </Card>
      ) : (
        <div className="flex-1"></div>
      )}
    </div>
  );
};
