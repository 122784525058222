import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormGroupedCheckboxes } from "@/components/forms/FormGroupedCheckboxes";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { PermissionEnum } from "@/features/auth/enums/PermissionEnum";
import { updateRole } from "@/features/roles/api/roleApi";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  permissions: Yup.array()
    .min(1)
    .of(Yup.string().oneOf(PermissionEnum.getValues()))
    .required(i18n.t("Pole jest wymagane")),
});

export const RoleDetails = ({ role }) => {
  const groupedPermissions = PermissionEnum.getGroupedValues();
  const navigate = useNavigate();
  const rolePolicy = useRolePolicy();

  const updateRoleMutation = useMutation({
    mutationFn: updateRole,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const defaultValues = {
    permissions:
      role.permissions
        ?.filter((permission) => PermissionEnum.getValues().includes(permission.name))
        .map((permission) => permission.name) ?? [],
  };

  const onSubmit = (data) => {
    updateRoleMutation.mutate({ roleId: role.id, data });
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className="mt-5">
      <Form onSubmit={onSubmit} form={form}>
        <FormGroupedCheckboxes
          name="permissions"
          disabled={!rolePolicy.updatePermissions()}
          groupedOptions={groupedPermissions}
          groupTranslationPrefix="permissions"
        />
        <div className="flex flex-row gap-3 items-center mt-5">
          <Button
            type="submit"
            title="Zapisz"
            leftIcon={<Check size={20} />}
            isLoading={updateRoleMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(routes.roles)}
          />
        </div>
      </Form>
    </div>
  );
};
