import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteInstallation, getInstallations } from "@/features/installations/api/installationApi";
import { InstallationFilters } from "@/features/installations/components/InstallationFilters";
import { InstallationGroupedViewItem } from "@/features/installations/components/InstallationGroupedViewItem";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { useInstallationsTableColumns } from "@/features/installations/hooks/useInstallationsTableColumns";
import { GroupedView } from "@/features/viewModes/components/GroupedView";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const InstallationsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const { statuses } = useContext(InstallationStatusContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});

  const handleDelete = (installationId) => {
    deleteInstallationMutation.mutate(installationId);
  };

  const deleteInstallationMutation = useMutation({
    mutationFn: deleteInstallation,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installations"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["installations", pagination, sorting, columnFilters],
    queryFn: () => getInstallations({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useInstallationsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  return (
    <div className="w-full">
      {viewMode === ViewMode.TABLE.value && (
        <Table
          table={table}
          Filters={InstallationFilters}
          meta={response?.meta}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      )}
      {viewMode === ViewMode.GROUPED.value && (
        <GroupedView
          table={table}
          meta={response?.meta}
          Filters={InstallationFilters}
          isLoading={isLoading}
          isFetching={isFetching}
          groupedData={response?.data?.reduce((acc, item) => {
            const key = item.status.name;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {})}
          groupHeaders={statuses.map((status) => ({ name: status.name, color: status.color }))}
          renderItem={(installation) => <InstallationGroupedViewItem installation={installation} />}
        />
      )}
    </div>
  );
};
