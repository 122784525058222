import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormMultipleSelect } from "@/components/forms/FormMultipleSelect";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { PermissionEnum } from "@/features/auth/enums/PermissionEnum";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { getBillingSettings } from "@/features/billings/api/billingApi";
import { useBillingSettingPolicy } from "@/features/billings/policies/useBillingSettingPolicy";
import { getRoles } from "@/features/roles/api/roleApi";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import { createUser, getUserLevels } from "@/features/users/api/userApi";
import { useUserLevelPolicy } from "@/features/users/policies/useUserLevelPolicy";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const UserCreateForm = () => {
  const billingSettingPolicy = useBillingSettingPolicy();
  const userLevelPolicy = useUserLevelPolicy();
  const rolePolicy = useRolePolicy();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasPermission } = useAuth();

  const [billingSettingOptions, setBillingSettingOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);

  const validationSchema = Yup.object({
    first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    last_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    email: Yup.string().required(i18n.t("Pole jest wymagane")),
    phone_number: Yup.string().required(i18n.t("Pole jest wymagane")),
    roles: Yup.array().required(i18n.t("Pole jest wymagane")),
    billing_setting_id: Yup.string().optional().nullable(),
  });

  const defaultValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    roles: [],
    billing_setting_id: undefined,
  };

  const onSubmit = (data) => {
    createUserMutation.mutate({ data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createUserMutation = useMutation({
    mutationFn: createUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (res.ok) {
        return navigate(routes.users);
      } else {
        if (
          res.status === 422 &&
          res.data.errors?.email &&
          res.data.errors?.email[0] === "The email has already been taken."
        ) {
          toast({ title: <ErrorToast title="Użytkownik o takim adresie email już istnieje" /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  const {
    isLoading: isLoadingBillingSettings,
    data: billingSettings,
    error: errorBillingSettings,
  } = useQuery({
    queryKey: ["billingSettings"],
    queryFn: getBillingSettings,
    staleTime: 1000 * 60 * 5,
    enabled: billingSettingPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingRoles,
    data: roles,
    error: errorRoles,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
    staleTime: 1000 * 60 * 5,
    enabled: rolePolicy.viewAny(),
  });

  const {
    isLoading: isLoadingLevels,
    data: levels,
    error: errorLevels,
  } = useQuery({
    queryKey: ["userLevels"],
    queryFn: getUserLevels,
    staleTime: 1000 * 60 * 5,
    enabled: userLevelPolicy.create(),
  });

  useEffect(() => {
    if (!isLoadingBillingSettings && !errorBillingSettings && billingSettings) {
      setBillingSettingOptions(billingSettings.data.map((setting) => ({ name: setting.name, value: setting.id })));
    }
  }, [isLoadingBillingSettings, billingSettings, errorBillingSettings]);

  useEffect(() => {
    if (!isLoadingLevels && !errorLevels && levels) {
      setLevelOptions(levels.data.map((level) => ({ name: level.name, value: level.id })));
    }
  }, [isLoadingLevels, levels, errorLevels]);

  useEffect(() => {
    if (!isLoadingRoles && !errorRoles && roles) {
      if (hasPermission(PermissionEnum.ACCESS_ALL_USERS.value)) {
        setRoleOptions(roles.data.map((role) => ({ name: role.name, value: role.name })));
      } else {
        setRoleOptions([]);
      }
    }
  }, [isLoadingRoles, roles, errorRoles]);

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormField name="first_name" label="Imię" autoComplete="first_name" />
            <FormField name="last_name" label="Nazwisko" autoComplete="last_name" />
            <FormField name="email" type="email" autoComplete="email" label="Email" />
            <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
            <FormField
              name="password_hidden"
              label="Hasło"
              disabled={true}
              description="Hasło zostanie wysłane na email podany powyżej"
            />
            {hasPermission(PermissionEnum.ACCESS_ALL_USERS) && (
              <FormMultipleSelect isLoading={isLoadingRoles} name="roles" label="Rola" options={roleOptions} />
            )}
            {userLevelPolicy.create() && (
              <FormSelect
                name="level_id"
                label="Poziom"
                options={appendEmptyOption(levelOptions)}
                isLoading={isLoadingLevels}
              />
            )}
            {billingSettingPolicy.viewAny() && (
              <FormCombobox
                name="billing_setting_id"
                label="Model rozliczeń"
                required={false}
                options={billingSettingOptions}
                isLoading={isLoadingBillingSettings}
              />
            )}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button type="submit" title="Dodaj" leftIcon={<Plus size={20} />} isLoading={createUserMutation.isPending} />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(-1)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
