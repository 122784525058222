import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { useOrderPolicy } from "@/features/orders/policies/useOrderPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const useAutomationResourceTableColum = (automationLog) => {
  const contractPolicy = useContractPolicy();
  const leadPolicy = useLeadPolicy();
  const installationPolicy = useInstallationPolicy();
  const installationServicePolicy = useInstallationServicePolicy();
  const applicationPolicy = useApplicationPolicy();
  const auditPolicy = useAuditPolicy();
  const creditPolicy = useCreditPolicy();
  const orderPolicy = useOrderPolicy();

  const resource = automationLog.resource;
  const resourceType = automationLog.resource_type;
  if (resourceType === "Lead")
    if (leadPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.leads}/${resource.id}`}>
          <span>{i18n.t("Kontakt")}</span>
          <span className="font-semibold">{resource.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Kontakt")}</span>
          <span className="font-semibold">{resource.name}</span>
        </>
      );
    }
  else if (resourceType === "Contract")
    if (contractPolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.contracts}/${resource.id}`}
        >
          <span>{i18n.t("Umowa")}</span>
          <span className="font-semibold">{resource.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Umowa")}</span>
          <span className="font-semibold">{resource.name}</span>
        </>
      );
    }
  else if (resourceType === "Audit")
    if (auditPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.audits}/${resource.id}`}>
          <span className="font-semibold">{i18n.t("Audyt")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Audyt")}</span>;
    }
  else if (resourceType === "Credit")
    if (creditPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.credits}/${resource.id}`}>
          <span className="font-semibold">{i18n.t("Kredyt")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Kredyt")}</span>;
    }
  else if (resourceType === "Installation")
    if (installationPolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.installations}/${resource.id}`}
        >
          <span>{i18n.t("Montaż")}</span>
          <span className="font-semibold">{resource.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Montaż")}</span>
          <span className="font-semibold">{resource.name}</span>
        </>
      );
    }
  else if (resourceType === "Application")
    if (applicationPolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.applications}/${resource.id}`}
        >
          <span className="font-semibold">{i18n.t("Wniosek")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Wniosek")}</span>;
    }
  else if (resourceType === "InstallationService")
    if (installationServicePolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.installationServices}/${resource.id}`}
        >
          <span className="font-semibold">{i18n.t("Serwis")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Serwis")}</span>;
    }
  else if (resourceType === "Order")
    if (orderPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.orders}/${resource.id}`}>
          <span className="font-semibold">{i18n.t("Zamówienie")}</span>;
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Zamówienie")}</span>;
    }
  else if (resourceType === "Task") return <span>{i18n.t("Zadanie")}</span>;
  else {
    return i18n.t("-");
  }
};
