import api from "@/api/api";
import { OPERATIONS_ENDPOINT, PRODUCTS_ENDPOINT, WAREHOUSES_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getWarehouses = (filters) => api.get(WAREHOUSES_ENDPOINT, transformQueryFilters(filters));

export const getWarehouse = (warehouseId) => api.get(WAREHOUSES_ENDPOINT + warehouseId);

export const createWarehouse = ({ data }) => api.post(WAREHOUSES_ENDPOINT, data);

export const deleteWarehouse = (warehouseId) => api.delete(WAREHOUSES_ENDPOINT + warehouseId);

export const updateWarehouse = ({ warehouseId, data }) => api.put(WAREHOUSES_ENDPOINT + warehouseId, data);

export const getWarehouseProducts = ({ filters, warehouseId }) =>
  api.get(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT, transformQueryFilters(filters));

export const createWarehouseProduct = ({ warehouseId, data }) =>
  api.post(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT, data);

export const createManyWarehouseProducts = ({ warehouseId, data }) =>
  api.post(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT + "createMany", data);

export const deleteWarehouseProduct = ({ warehouseId, productId }) =>
  api.delete(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT + productId);

export const getWarehouseOperations = ({ warehouseId, filters }) =>
  api.get(WAREHOUSES_ENDPOINT + warehouseId + OPERATIONS_ENDPOINT, transformQueryFilters(filters));
