import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Select } from "@/components/forms/Select";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Asterisk } from "lucide-react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormSelect = ({
  name,
  options,
  parent,
  label,
  className,
  setCurrentValue = () => {},
  requiredIndicator = false,
  defaultValue,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getFieldState,
  } = useFormContext();

  const parentValue = watch(parent);
  let disabled = parent && !parentValue;

  const handleChange = (value, onChange) => {
    onChange(value);
    setCurrentValue(value);
  };

  useEffect(() => {
    if (parent && getFieldState(parent)?.isDirty) {
      setValue(name, null);
    }
  }, [parentValue]);

  useEffect(() => {
    const value = watch(name);
    if (!value && defaultValue !== undefined) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="grid gap-2">
          <Label htmlFor={name} className={cn("flex flex-row gap-1 items-center", errors[name] && "text-destructive")}>
            {typeof label === "string" ? i18n.t(label) : label}
            {requiredIndicator ? <Asterisk size={16} className="text-destructive" /> : null}
          </Label>
          <Select
            id={name}
            name={name}
            setValue={(value) => handleChange(value, field.onChange)}
            options={options}
            disabled={disabled}
            className={cn("truncate", className)}
            {...field}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
