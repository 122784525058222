import { cn } from "@/lib/utils";

export const ColorBadge = ({ color, className, children }) => {
  return (
    <span
      style={{ backgroundColor: color ?? "hsl(var(--primary))" }}
      className={cn(
        "px-2 py-1 w-fit rounded-md font-medium whitespace-nowrap text-sm",
        !color && "text-primary-foreground",
        className,
      )}
    >
      {children}
    </span>
  );
};
