export const PermissionEnum = Object.freeze({
  // Calendar
  ACCESS_CALENDAR: { value: "access_calendar", name: "Dostęp do kalendarza", group: "Calendar" },

  // Users
  IMPERSONATE_USERS: { value: "impersonate_users", name: "Logowanie się na konta użytkowników", group: "Users" },
  ACCESS_ALL_USERS: { value: "access_all_users", name: "Dostęp do wszystkich użytkowników", group: "Users" },
  ACCESS_OWN_USERS: { value: "access_own_users", name: "Dostęp do użytkowników ze swojej grupy", group: "Users" },
  SHOW_ANY_USER: { value: "show_any_user", name: "Podgląd dowolnego użytkownika", group: "Users" },
  SHOW_OWN_USER: { value: "show_own_user", name: "Podgląd użytkownika ze swojej grupy", group: "Users" },
  CREATE_USER: { value: "create_user", name: "Tworzenie użytkownika", group: "Users" },
  UPDATE_USER: { value: "update_user", name: "Edycja użytkownika", group: "Users" },
  DELETE_USER: { value: "delete_user", name: "Usuwanie użytkownika", group: "Users" },
  ACCESS_USER_STATS: { value: "access_user_stats", name: "Dostęp do statystyk użytkownika", group: "Users" },
  ACCESS_USER_CALENDAR: { value: "access_user_calendar", name: "Dostęp do kalendarza użytkownika", group: "Users" },
  ACCESS_USER_COSTS: { value: "access_user_costs", name: "Dostęp do kosztów stałych użytkownika", group: "Users" },
  CREATE_USER_COST: { value: "create_user_cost", name: "Tworzenie kosztów stałych użytkownika", group: "Users" },
  DELETE_USER_COST: { value: "delete_user_cost", name: "Usuwanie kosztów stałych użytkownika", group: "Users" },

  // Contacts
  IMPORT_LEADS: { value: "import_leads", name: "Importowanie kontaktów", group: "Contacts" },
  ACCESS_LEAD_STATS: { value: "access_lead_stats", name: "Dostęp do statystyk kontaktów", group: "Contacts" },
  ASSIGN_USER_LEADS: { value: "assign_user_leads", name: "Przypisywanie kontaktów do użytkowników", group: "Contacts" },
  ACCESS_ALL_LEADS: { value: "access_all_leads", name: "Dostęp do wszystkich kontaktów", group: "Contacts" },
  ACCESS_OWN_LEADS: {
    value: "access_own_leads",
    name: "Dostęp do kontaktów własnych i ze swojej grupy",
    group: "Contacts",
  },
  SHOW_ANY_LEAD: { value: "show_any_lead", name: "Podgląd dowolnego kontaktu", group: "Contacts" },
  SHOW_OWN_LEAD: { value: "show_own_lead", name: "Podgląd kontaktów własnych i ze swojej grupy", group: "Contacts" },
  CREATE_LEAD: { value: "create_lead", name: "Tworzenie kontaktu", group: "Contacts" },
  UPDATE_LEAD: { value: "update_lead", name: "Edycja kontaktu", group: "Contacts" },
  UPDATE_LEADS_STATUS: { value: "update_leads_status", name: "Edycja statusu kontaktu", group: "Contacts" },
  DELETE_LEAD: { value: "delete_lead", name: "Usuwanie kontaktu", group: "Contacts" },
  BULK_DELETE_LEADS: { value: "bulk_delete_leads", name: "Masowe usuwanie kontaktów", group: "Contacts" },
  BULK_UNASSIGN_LEAD_USER: {
    value: "bulk_unassign_lead_user",
    name: "Masowe odpinanie kontaktów od użytkownika",
    group: "Contacts",
  },
  BULK_EDIT_LEAD_STATUS: { value: "bulk_edit_lead_status", name: "Masowa zmiana statusu kontaktów", group: "Contacts" },

  // Lead Sources
  ACCESS_LEAD_SOURCES: { value: "access_lead_sources", name: "Dostęp do źródeł kontaktów", group: "Lead Sources" },
  CREATE_LEAD_SOURCE: { value: "create_lead_source", name: "Tworzenie źródeł kontaktów", group: "Lead Sources" },
  UPDATE_LEAD_SOURCE: { value: "update_lead_source", name: "Edycja źródeł kontaktów", group: "Lead Sources" },
  DELETE_LEAD_SOURCE: { value: "delete_lead_source", name: "Usuwanie źródeł kontaktów", group: "Lead Sources" },

  // Lead Statuses
  CREATE_LEAD_STATUS: { value: "create_lead_status", name: "Tworzenie statusów kontaktów", group: "Lead Statuses" },
  UPDATE_LEAD_STATUS: { value: "update_lead_status", name: "Edycja statusów kontaktów", group: "Lead Statuses" },
  DELETE_LEAD_STATUS: { value: "delete_lead_status", name: "Usuwanie statusów kontaktów", group: "Lead Statuses" },

  // Lead Files
  ACCESS_LEAD_FILES: { value: "access_lead_files", name: "Dostęp do plików kontaktów", group: "Lead Files" },
  CREATE_LEAD_FILE: { value: "create_lead_file", name: "Tworzenie plików kontaktów", group: "Lead Files" },
  DELETE_LEAD_FILE: { value: "delete_lead_file", name: "Usuwanie plików kontaktów", group: "Lead Files" },

  // Lead Notes
  ACCESS_LEAD_NOTES: { value: "access_lead_notes", name: "Dostęp do notatek kontaktów", group: "Lead Notes" },
  CREATE_LEAD_NOTE: { value: "create_lead_note", name: "Tworzenie notatek kontaktów", group: "Lead Notes" },
  UPDATE_LEAD_NOTE: { value: "update_lead_note", name: "Edycja notatek kontaktów", group: "Lead Notes" },
  DELETE_LEAD_NOTE: { value: "delete_lead_note", name: "Usuwanie notatek kontaktów", group: "Lead Notes" },

  // Lead Actions
  ACCESS_LEAD_ACTIONS: { value: "access_lead_actions", name: "Dostęp do działań kontaktów", group: "Lead Actions" },
  CREATE_LEAD_ACTION: { value: "create_lead_action", name: "Tworzenie działań kontaktów", group: "Lead Actions" },
  UPDATE_LEAD_ACTION: { value: "update_lead_action", name: "Edycja działań kontaktów", group: "Lead Actions" },
  DELETE_LEAD_ACTION: { value: "delete_lead_action", name: "Usuwanie działań kontaktów", group: "Lead Actions" },

  // Lead Calculations
  ACCESS_LEAD_CALCULATIONS: {
    value: "access_lead_calculations",
    name: "Dostęp do kalkulacji kontaktów",
    group: "Lead Calculations",
  },
  SHOW_ANY_LEAD_CALCULATION: {
    value: "show_any_lead_calculation",
    name: "Podgląd dowolnej kalkulacji kontaktów",
    group: "Lead Calculations",
  },
  SHOW_OWN_LEAD_CALCULATION: {
    value: "show_own_lead_calculation",
    name: "Podgląd kalkulacji kontaktów własnych i ze swojej grupy",
    group: "Lead Calculations",
  },
  CREATE_LEAD_CALCULATION: {
    value: "create_lead_calculation",
    name: "Tworzenie kalkulacji kontaktów",
    group: "Lead Calculations",
  },
  UPDATE_LEAD_CALCULATION: {
    value: "update_lead_calculation",
    name: "Edycja kalkulacji kontaktów",
    group: "Lead Calculations",
  },
  DELETE_LEAD_CALCULATION: {
    value: "delete_lead_calculation",
    name: "Usuwanie kalkulacji kontaktów",
    group: "Lead Calculations",
  },

  // Lead Processes
  ACCESS_LEAD_PROCESSES: {
    value: "access_lead_processes",
    name: "Dostęp do procesów na kontakcie",
    group: "Lead Processes",
  },
  UPDATE_LEAD_PROCESS: {
    value: "update_lead_process",
    name: "Edycja procesu na kontakcie",
    group: "Lead Processes",
  },

  // Tasks
  ACCESS_TASKS: { value: "access_tasks", name: "Dostęp do zadań", group: "Tasks" },
  ACCESS_TASK_STATS: { value: "access_task_stats", name: "Dostęp do statystyk zadań", group: "Tasks" },
  CREATE_TASK: { value: "create_task", name: "Tworzenie zadań", group: "Tasks" },
  UPDATE_TASK: { value: "update_task", name: "Edycja zadań", group: "Tasks" },
  ASSIGN_TASK: { value: "assign_task", name: "Przypisywanie zadań użytkownikom", group: "Tasks" },
  DELETE_TASK: { value: "delete_task", name: "Usuwanie własnych zadań", group: "Tasks" },
  DELETE_ANY_TASK: { value: "delete_any_task", name: "Usuwanie dowolnego zadania", group: "Tasks" },

  // Contracts
  ACCESS_CONTRACT_MARGIN: { value: "access_contract_margin", name: "Dostęp do marży na umowie", group: "Contracts" },
  ACCESS_CONTRACT_STATS: { value: "access_contract_stats", name: "Dostęp do statystyk umów", group: "Contracts" },
  ACCESS_ALL_CONTRACTS: { value: "access_all_contracts", name: "Dostęp do wszystkich umów", group: "Contracts" },
  ACCESS_OWN_CONTRACTS: {
    value: "access_own_contracts",
    name: "Dostęp do umów własnych i ze swojej grupy",
    group: "Contracts",
  },
  SHOW_ANY_CONTRACT: { value: "show_any_contract", name: "Podgląd dowolnej umowy", group: "Contracts" },
  SHOW_OWN_CONTRACT: {
    value: "show_own_contract",
    name: "Podgląd umów własnych i ze swojej grupy",
    group: "Contracts",
  },
  CREATE_CONTRACT: { value: "create_contract", name: "Tworzenie umów", group: "Contracts" },
  UPDATE_CONTRACT: { value: "update_contract", name: "Edycja umów", group: "Contracts" },
  UPDATE_CONTRACTS_STATUS: { value: "update_contracts_status", name: "Zmiana statusu umowy", group: "Contracts" },
  UPDATE_CONTRACT_COMISSION: {
    value: "update_contract_comission",
    name: "Zmiana prowizji umów",
    group: "Contracts",
  },
  DELETE_CONTRACT: { value: "delete_contract", name: "Usuwanie umów", group: "Contracts" },

  // Contract Status
  CREATE_CONTRACT_STATUS: {
    value: "create_contract_status",
    name: "Tworzenie statusów umów",
    group: "Contract Status",
  },
  UPDATE_CONTRACT_STATUS: {
    value: "update_contract_status",
    name: "Edycja statusów umów",
    group: "Contract Status",
  },
  DELETE_CONTRACT_STATUS: {
    value: "delete_contract_status",
    name: "Usuwanie statusów umów",
    group: "Contract Status",
  },

  // Contract Types
  CREATE_CONTRACT_TYPE: {
    value: "create_contract_type",
    name: "Tworzenie typów umów",
    group: "Contract Status",
  },
  UPDATE_CONTRACT_TYPE: {
    value: "update_contract_type",
    name: "Edycja typów umów",
    group: "Contract Status",
  },
  DELETE_CONTRACT_TYPE: {
    value: "delete_contract_type",
    name: "Usuwanie typów umów",
    group: "Contract Status",
  },

  // Contract Product
  CREATE_CONTRACT_PRODUCT: {
    value: "create_contract_product",
    name: "Tworzenie produktów umów",
    group: "Contract Product",
  },
  UPDATE_CONTRACT_PRODUCT: {
    value: "update_contract_product",
    name: "Edycja produktów umów",
    group: "Contract Product",
  },
  DELETE_CONTRACT_PRODUCT: {
    value: "delete_contract_product",
    name: "Usuwanie produktów umów",
    group: "Contract Product",
  },

  // Contract Notes
  ACCESS_CONTRACT_NOTES: {
    value: "access_contract_notes",
    name: "Dostęp do notatek umów",
    group: "Contract Notes",
  },
  CREATE_CONTRACT_NOTE: {
    value: "create_contract_note",
    name: "Tworzenie notatek umów",
    group: "Contract Notes",
  },
  UPDATE_CONTRACT_NOTE: { value: "update_contract_note", name: "Edycja notatek umów", group: "Contract Notes" },
  DELETE_CONTRACT_NOTE: { value: "delete_contract_note", name: "Usuwanie notatek umów", group: "Contract Notes" },

  // Contract Files
  ACCESS_CONTRACT_FILES: {
    value: "access_contract_files",
    name: "Dostęp do plików umów",
    group: "Contract Files",
  },
  CREATE_CONTRACT_FILE: { value: "create_contract_file", name: "Tworzenie plików umów", group: "Contract Files" },
  DELETE_CONTRACT_FILE: { value: "delete_contract_file", name: "Usuwanie plików umów", group: "Contract Files" },

  // Contract Payments
  ACCESS_CONTRACT_PAYMENTS: {
    value: "access_contract_payments",
    name: "Dostęp do płatności umów",
    group: "Contract Payments",
  },
  CREATE_CONTRACT_PAYMENT: {
    value: "create_contract_payment",
    name: "Tworzenie płatności umów",
    group: "Contract Payments",
  },
  DELETE_CONTRACT_PAYMENT: {
    value: "delete_contract_payment",
    name: "Usuwanie płatności umów",
    group: "Contract Payments",
  },

  // Contract Messages
  ACCESS_CONTRACT_MESSAGES: {
    value: "access_contract_messages",
    name: "Dostęp do konwersacji umów",
    group: "Contract Messages",
  },
  CREATE_CONTRACT_MESSAGE: {
    value: "create_contract_message",
    name: "Tworzenie wiadomości umów",
    group: "Contract Messages",
  },
  DELETE_CONTRACT_MESSAGE: {
    value: "delete_contract_message",
    name: "Usuwanie własnych wiadomości umów",
    group: "Contract Messages",
  },
  DELETE_ANY_CONTRACT_MESSAGE: {
    value: "delete_any_contract_message",
    name: "Usuwanie dowolnej wiadomości umów",
    group: "Contract Messages",
  },

  // Contract Users
  ACCESS_CONTRACT_USERS: {
    value: "access_contract_users",
    name: "Dostęp do użytkowników umów",
    group: "Contract Users",
  },
  CREATE_CONTRACT_USER: {
    value: "create_contract_user",
    name: "Tworzenie użytkowników umów",
    group: "Contract Users",
  },
  DELETE_CONTRACT_USER: {
    value: "delete_contract_user",
    name: "Usuwanie użytkowników umów",
    group: "Contract Users",
  },

  // Contract Processes
  ACCESS_CONTRACT_PROCESSES: {
    value: "access_contract_processes",
    name: "Dostęp do procesów umów",
    group: "Contract Processes",
  },
  UPDATE_CONTRACT_PROCESS: {
    value: "update_contract_process",
    name: "Edycja procesów umów",
    group: "Contract Processes",
  },

  // Contract Changes
  ACCESS_CONTRACT_CHANGES: {
    value: "access_contract_changes",
    name: "Dostęp do historii zmian umów",
    group: "Contract Changes",
  },

  // Files
  ACCESS_FILES: { value: "access_files", name: "Dostęp do plików", group: "Files" },
  CREATE_FILE: { value: "create_file", name: "Tworzenie plików", group: "Files" },
  UPDATE_FILE: { value: "update_file", name: "Edycja plików", group: "Files" },
  DOWNLOAD_FILE: { value: "download_file", name: "Pobieranie plików", group: "Files" },
  DELETE_FILE: { value: "delete_file", name: "Usuwanie plików", group: "Files" },

  // File Categories
  CREATE_FILE_CATEGORY: { value: "create_file_category", name: "Tworzenie kategorii plików", group: "File Categories" },
  UPDATE_FILE_CATEGORY: { value: "update_file_category", name: "Edycja kategorii plików", group: "File Categories" },
  DELETE_FILE_CATEGORY: { value: "delete_file_category", name: "Usuwanie kategorii plików", group: "File Categories" },

  // Processes
  ACCESS_PROCESSES: { value: "access_processes", name: "Dostęp do procesów", group: "Processes" },
  CREATE_PROCESS: { value: "create_process", name: "Tworzenie procesów", group: "Processes" },
  UPDATE_PROCESS: { value: "update_process", name: "Edycja procesów", group: "Processes" },
  DELETE_PROCESS: { value: "delete_process", name: "Usuwanie procesów", group: "Processes" },

  // Warehouse
  ACCESS_WAREHOUSES: { value: "access_warehouses", name: "Dostęp do magazynów", group: "Warehouse" },
  SHOW_WAREHOUSE: { value: "show_warehouse", name: "Podgląd magazynów", group: "Warehouse" },
  CREATE_WAREHOUSE: { value: "create_warehouse", name: "Tworzenie magazynów", group: "Warehouse" },
  UPDATE_WAREHOUSE: { value: "update_warehouse", name: "Edycja magazynów", group: "Warehouse" },
  DELETE_WAREHOUSE: { value: "delete_warehouse", name: "Usuwanie magazynów", group: "Warehouse" },

  // Products
  ACCESS_PRODUCTS: { value: "access_products", name: "Dostęp do produktów", group: "Products" },
  SHOW_PRODUCT: { value: "show_product", name: "Podgląd produktów", group: "Products" },
  CREATE_PRODUCT: { value: "create_product", name: "Tworzenie produktów", group: "Products" },
  UPDATE_PRODUCT: { value: "update_product", name: "Edycja produktów", group: "Products" },
  DELETE_PRODUCT: { value: "delete_product", name: "Usuwanie produktów", group: "Products" },
  IMPORT_PRODUCTS: { value: "import_products", name: "Importowanie produktów", group: "Products" },
  EXPORT_PRODUCTS: { value: "export_products", name: "Eksportowanie produktów", group: "Products" },

  // Product Types
  CREATE_PRODUCT_TYPE: { value: "create_product_type", name: "Tworzenie typów produktów", group: "Product Types" },
  UPDATE_PRODUCT_TYPE: { value: "update_product_type", name: "Edycja typów produktów", group: "Product Types" },
  DELETE_PRODUCT_TYPE: { value: "delete_product_type", name: "Usuwanie typów produktów", group: "Product Types" },

  // Metadata
  CREATE_METADATA: { value: "create_metadata", name: "Tworzenie metadanych", group: "Metadata" },

  // Warehouse Product
  ACCESS_WAREHOUSE_PRODUCTS: {
    value: "access_warehouse_products",
    name: "Dostęp do produktów magazynu",
    group: "Warehouse Product",
  },
  CREATE_WAREHOUSE_PRODUCT: {
    value: "create_warehouse_product",
    name: "Dodawanie produktów do magazynu",
    group: "Warehouse Product",
  },
  DELETE_WAREHOUSE_PRODUCT: {
    value: "delete_warehouse_product",
    name: "Usuwanie produktów z magazynu",
    group: "Warehouse Product",
  },

  // Warehouse Operations
  ACCESS_WAREHOUSE_OPERATIONS: {
    value: "access_warehouse_operations",
    name: "Dostęp do operacji magazynowych",
    group: "Warehouse Operations",
  },

  // Billings
  ACCESS_ALL_BILLINGS: { value: "access_all_billings", name: "Dostęp do wszystkich rozliczeń", group: "Billings" },
  ACCESS_OWN_BILLINGS: { value: "access_own_billings", name: "Dostęp do własnych rozliczeń", group: "Billings" },
  CREATE_BILLING: { value: "create_billing", name: "Tworzenie rozliczeń", group: "Billings" },
  UPDATE_BILLING: { value: "update_billing", name: "Edycja rozliczeń", group: "Billings" },
  DELETE_BILLING: { value: "delete_billing", name: "Usuwanie rozliczeń", group: "Billings" },

  // Billing Groups
  ACCESS_ALL_BILLING_GROUPS: {
    value: "access_all_billing_groups",
    name: "Dostęp do wszystkich faktur",
    group: "Billing Groups",
  },
  ACCESS_OWN_BILLING_GROUPS: {
    value: "access_own_billing_groups",
    name: "Dostęp do własnych faktur",
    group: "Billing Groups",
  },
  CREATE_BILLING_GROUP: { value: "create_billing_group", name: "Tworzenie faktur", group: "Billing Groups" },
  UPDATE_BILLING_GROUP: { value: "update_billing_group", name: "Edycja statusu faktur", group: "Billing Groups" },
  DELETE_BILLING_GROUP: { value: "delete_billing_group", name: "Usuwanie faktur", group: "Billing Groups" },

  // Calculators
  ACCESS_ALL_CALCULATORS: {
    value: "access_all_calculators",
    name: "Dostęp do wszystkich kalkulatorów",
    group: "Calculators",
  },
  ACCESS_CALCULATORS: { value: "access_calculators", name: "Dostęp do kalkulatorów", group: "Calculators" },
  CREATE_CALCULATOR: { value: "create_calculator", name: "Tworzenie kalkulatorów", group: "Calculators" },
  UPDATE_CALCULATOR: { value: "update_calculator", name: "Edycja kalkulatorów", group: "Calculators" },
  DELETE_CALCULATOR: { value: "delete_calculator", name: "Usuwanie kalkulatorów", group: "Calculators" },

  // Billing Settings
  ACCESS_BILLING_SETTINGS: {
    value: "access_billing_settings",
    name: "Dostęp do modeli rozliczeń",
    group: "Billing Settings",
  },
  CREATE_BILLING_SETTING: {
    value: "create_billing_setting",
    name: "Tworzenie modeli rozliczeń",
    group: "Billing Settings",
  },
  SHOW_BILLING_SETTING: {
    value: "show_billing_setting",
    name: "Podgląd modeli rozliczeń",
    group: "Billing Settings",
  },
  UPDATE_BILLING_SETTING: {
    value: "update_billing_setting",
    name: "Edycja modeli rozliczeń",
    group: "Billing Settings",
  },
  DELETE_BILLING_SETTING: {
    value: "delete_billing_setting",
    name: "Usuwanie modeli rozliczeń",
    group: "Billing Settings",
  },

  // Installations
  ACCESS_ALL_INSTALLATIONS: {
    value: "access_all_installations",
    name: "Dostęp do wszystkich montaży",
    group: "Installations",
  },
  ACCESS_OWN_INSTALLATIONS: {
    value: "access_own_installations",
    name: "Dostęp do własnych montaży",
    group: "Installations",
  },
  CREATE_INSTALLATION: { value: "create_installation", name: "Tworzenie montaży", group: "Installations" },
  SHOW_OWN_INSTALLATION: {
    value: "show_own_installation",
    name: "Podgląd własnych montaży",
    group: "Installations",
  },
  SHOW_ANY_INSTALLATION: {
    value: "show_any_installation",
    name: "Podgląd dowolnych montaży",
    group: "Installations",
  },
  UPDATE_INSTALLATION: { value: "update_installation", name: "Edycja montaży", group: "Installations" },
  UPDATE_INSTALLATIONS_STATUS: {
    value: "update_installations_status",
    name: "Zmiana statusu montaży",
    group: "Installations",
  },
  DELETE_INSTALLATION: { value: "delete_installation", name: "Usuwanie montaży", group: "Installations" },

  // Installation Status
  CREATE_INSTALLATION_STATUS: {
    value: "create_installation_status",
    name: "Tworzenie statusów montaży",
    group: "Installation Status",
  },
  UPDATE_INSTALLATION_STATUS: {
    value: "update_installation_status",
    name: "Edycja statusów montaży",
    group: "Installation Status",
  },
  DELETE_INSTALLATION_STATUS: {
    value: "delete_installation_status",
    name: "Usuwanie statusów montaży",
    group: "Installation Status",
  },

  // Installation Type
  CREATE_INSTALLATION_TYPE: {
    value: "create_installation_type",
    name: "Tworzenie typów montaży",
    group: "Installation Type",
  },
  UPDATE_INSTALLATION_TYPE: {
    value: "update_installation_type",
    name: "Edycja typów montaży",
    group: "Installation Type",
  },
  DELETE_INSTALLATION_TYPE: {
    value: "delete_installation_type",
    name: "Usuwanie typów montaży",
    group: "Installation Type",
  },

  // Installation Files
  ACCESS_INSTALLATION_FILES: {
    value: "access_installation_files",
    name: "Dostęp do plików montaży",
    group: "Installation Files",
  },
  CREATE_INSTALLATION_FILE: {
    value: "create_installation_file",
    name: "Tworzenie plików montaży",
    group: "Installation Files",
  },
  DELETE_INSTALLATION_FILE: {
    value: "delete_installation_file",
    name: "Usuwanie plików montaży",
    group: "Installation Files",
  },

  // Installation Notes
  ACCESS_INSTALLATION_NOTES: {
    value: "access_installation_notes",
    name: "Dostęp do notatek montaży",
    group: "Installation Notes",
  },
  CREATE_INSTALLATION_NOTE: {
    value: "create_installation_note",
    name: "Tworzenie notatek montaży",
    group: "Installation Notes",
  },
  UPDATE_INSTALLATION_NOTE: {
    value: "update_installation_note",
    name: "Edycja notatek montaży",
    group: "Installation Notes",
  },
  DELETE_INSTALLATION_NOTE: {
    value: "delete_installation_note",
    name: "Usuwanie notatek montaży",
    group: "Installation Notes",
  },

  // Installation Actions
  ACCESS_INSTALLATION_ACTIONS: {
    value: "access_installation_actions",
    name: "Dostęp do działań montaży",
    group: "Installation Actions",
  },
  CREATE_INSTALLATION_ACTION: {
    value: "create_installation_action",
    name: "Tworzenie działań montaży",
    group: "Installation Actions",
  },
  UPDATE_INSTALLATION_ACTION: {
    value: "update_installation_action",
    name: "Edycja działań montaży",
    group: "Installation Actions",
  },
  DELETE_INSTALLATION_ACTION: {
    value: "delete_installation_action",
    name: "Usuwanie działań montaży",
    group: "Installation Actions",
  },

  // Installation Process
  ACCESS_INSTALLATION_PROCESSES: {
    value: "access_installation_processes",
    name: "Dostęp do procesów montaży",
    group: "Installation Process",
  },
  UPDATE_INSTALLATION_PROCESS: {
    value: "update_installation_process",
    name: "Edycja procesów montaży",
    group: "Installation Process",
  },

  // Installation Crew
  ACCESS_INSTALLATION_CREWS: {
    value: "access_installation_crews",
    name: "Dostęp do ekip montażowych",
    group: "Installation Crew",
  },
  CREATE_INSTALLATION_CREW: {
    value: "create_installation_crew",
    name: "Tworzenie ekip montażowych",
    group: "Installation Crew",
  },
  SHOW_INSTALLATION_CREW: {
    value: "show_installation_crew",
    name: "Podgląd ekip montażowych",
    group: "Installation Crew",
  },
  UPDATE_INSTALLATION_CREW: {
    value: "update_installation_crew",
    name: "Edycja ekip montażowych",
    group: "Installation Crew",
  },
  DELETE_INSTALLATION_CREW: {
    value: "delete_installation_crew",
    name: "Usuwanie ekip montażowych",
    group: "Installation Crew",
  },

  // Installation Services
  ACCESS_INSTALLATION_SERVICES: {
    value: "access_installation_services",
    name: "Dostęp do serwisów",
    group: "Installation Services",
  },
  CREATE_INSTALLATION_SERVICE: {
    value: "create_installation_service",
    name: "Tworzenie serwisów",
    group: "Installation Services",
  },
  SHOW_INSTALLATION_SERVICE: {
    value: "show_installation_service",
    name: "Podgląd serwisów",
    group: "Installation Services",
  },
  UPDATE_INSTALLATION_SERVICE: {
    value: "update_installation_service",
    name: "Edycja serwisów",
    group: "Installation Services",
  },
  DELETE_INSTALLATION_SERVICE: {
    value: "delete_installation_service",
    name: "Usuwanie serwisów",
    group: "Installation Services",
  },

  // Installation Service Notes
  ACCESS_INSTALLATION_SERVICE_NOTES: {
    value: "access_installation_service_notes",
    name: "Dostęp do notatek serwisów",
    group: "Installation Service Notes",
  },
  CREATE_INSTALLATION_SERVICE_NOTE: {
    value: "create_installation_service_note",
    name: "Tworzenie notatek serwisów",
    group: "Installation Service Notes",
  },
  UPDATE_INSTALLATION_SERVICE_NOTE: {
    value: "update_installation_service_note",
    name: "Edycja notatek serwisów",
    group: "Installation Service Notes",
  },
  DELETE_INSTALLATION_SERVICE_NOTE: {
    value: "delete_installation_service_note",
    name: "Usuwanie notatek serwisów",
    group: "Installation Service Notes",
  },

  // Audits
  ASSIGN_USER_AUDITS: { value: "assign_user_audits", name: "Przypisywanie audytów do użytkownika", group: "Audits" },
  BULK_EDIT_AUDIT_STATUS: { value: "bulk_edit_audit_status", name: "Masowa zmiana statusu audytów", group: "Audits" },
  ACCESS_ALL_AUDITS: { value: "access_all_audits", name: "Dostęp do wszystkich audytów", group: "Audits" },
  ACCESS_OWN_AUDITS: { value: "access_own_audits", name: "Dostęp do własnych audytów", group: "Audits" },
  ACCESS_CREATED_AUDITS: { value: "access_created_audits", name: "Dostęp do stworzonych audytów", group: "Audits" },
  CREATE_AUDIT: { value: "create_audit", name: "Tworzenie audytów", group: "Audits" },
  SHOW_OWN_AUDIT: { value: "show_own_audit", name: "Podgląd własnych audytów", group: "Audits" },
  SHOW_CREATED_AUDIT: { value: "show_created_audit", name: "Podgląd stworzonych audytów", group: "Audits" },
  SHOW_ANY_AUDIT: { value: "show_any_audit", name: "Podgląd dowolnych audytów", group: "Audits" },
  UPDATE_AUDIT: { value: "update_audit", name: "Edycja audytów", group: "Audits" },
  UPDATE_AUDITS_STATUS: { value: "update_audits_status", name: "Zmiana statusu audytu", group: "Audits" },
  DELETE_AUDIT: { value: "delete_audit", name: "Usuwanie audytów", group: "Audits" },

  // Audit Status
  CREATE_AUDIT_STATUS: { value: "create_audit_status", name: "Tworzenie statusów audytów", group: "Audit Status" },
  UPDATE_AUDIT_STATUS: { value: "update_audit_status", name: "Edycja statusów audytów", group: "Audit Status" },
  DELETE_AUDIT_STATUS: { value: "delete_audit_status", name: "Usuwanie statusów audytów", group: "Audit Status" },

  // Audit Files
  ACCESS_AUDIT_FILES: { value: "access_audit_files", name: "Dostęp do plików audytów", group: "Audit Files" },
  CREATE_AUDIT_FILE: { value: "create_audit_file", name: "Tworzenie plików audytów", group: "Audit Files" },
  DELETE_AUDIT_FILE: { value: "delete_audit_file", name: "Usuwanie plików audytów", group: "Audit Files" },

  // Audit Notes
  ACCESS_AUDIT_NOTES: { value: "access_audit_notes", name: "Dostęp do notatek audytów", group: "Audit Notes" },
  CREATE_AUDIT_NOTE: { value: "create_audit_note", name: "Tworzenie notatek audytów", group: "Audit Notes" },
  UPDATE_AUDIT_NOTE: { value: "update_audit_note", name: "Edycja notatek audytów", group: "Audit Notes" },
  DELETE_AUDIT_NOTE: { value: "delete_audit_note", name: "Usuwanie notatek audytów", group: "Audit Notes" },

  // Audit Actions
  ACCESS_AUDIT_ACTIONS: { value: "access_audit_actions", name: "Dostęp do działań audytów", group: "Audit Actions" },
  CREATE_AUDIT_ACTION: { value: "create_audit_action", name: "Tworzenie działań audytów", group: "Audit Actions" },
  UPDATE_AUDIT_ACTION: { value: "update_audit_action", name: "Edycja działań audytów", group: "Audit Actions" },
  DELETE_AUDIT_ACTION: { value: "delete_audit_action", name: "Usuwanie działań audytów", group: "Audit Actions" },

  // Audit Processes
  ACCESS_AUDIT_PROCESSES: {
    value: "access_audit_processes",
    name: "Dostęp do procesów audytów",
    group: "Audit Processes",
  },
  UPDATE_AUDIT_PROCESS: { value: "update_audit_process", name: "Edycja procesów audytów", group: "Audit Processes" },

  // Applications
  UPDATE_APPLICATIONS_STATUS: {
    value: "update_applications_status",
    name: "Zmiana statusu wniosków",
    group: "Applications",
  },
  ACCESS_ALL_APPLICATIONS: {
    value: "access_all_applications",
    name: "Dostęp do wszystkich wniosków",
    group: "Applications",
  },
  ACCESS_OWN_APPLICATIONS: {
    value: "access_own_applications",
    name: "Dostęp do własnych wniosków",
    group: "Applications",
  },
  CREATE_APPLICATION: { value: "create_application", name: "Tworzenie wniosków", group: "Applications" },
  SHOW_OWN_APPLICATION: { value: "show_own_application", name: "Podgląd własnych wniosków", group: "Applications" },
  SHOW_ANY_APPLICATION: { value: "show_any_application", name: "Podgląd dowolnych wniosków", group: "Applications" },
  UPDATE_APPLICATION: { value: "update_application", name: "Edycja wniosków", group: "Applications" },
  DELETE_APPLICATION: { value: "delete_application", name: "Usuwanie wniosków", group: "Applications" },

  // Application Status
  CREATE_APPLICATION_STATUS: {
    value: "create_application_status",
    name: "Tworzenie statusów wniosków",
    group: "Application Status",
  },
  UPDATE_APPLICATION_STATUS: {
    value: "update_application_status",
    name: "Edycja statusów wniosków",
    group: "Application Status",
  },
  DELETE_APPLICATION_STATUS: {
    value: "delete_application_status",
    name: "Usuwanie statusów wniosków",
    group: "Application Status",
  },

  // Application Type
  CREATE_APPLICATION_TYPE: {
    value: "create_application_type",
    name: "Tworzenie typów wniosków",
    group: "Application Type",
  },
  UPDATE_APPLICATION_TYPE: {
    value: "update_application_type",
    name: "Edycja typów wniosków",
    group: "Application Type",
  },
  DELETE_APPLICATION_TYPE: {
    value: "delete_application_type",
    name: "Usuwanie typów wniosków",
    group: "Application Type",
  },

  // Application Files
  ACCESS_APPLICATION_FILES: {
    value: "access_application_files",
    name: "Dostęp do plików wniosków",
    group: "Application Files",
  },
  CREATE_APPLICATION_FILE: {
    value: "create_application_file",
    name: "Tworzenie plików wniosków",
    group: "Application Files",
  },
  DELETE_APPLICATION_FILE: {
    value: "delete_application_file",
    name: "Usuwanie plików wniosków",
    group: "Application Files",
  },

  // Application Notes
  ACCESS_APPLICATION_NOTES: {
    value: "access_application_notes",
    name: "Dostęp do notatek wniosków",
    group: "Application Notes",
  },
  CREATE_APPLICATION_NOTE: {
    value: "create_application_note",
    name: "Tworzenie notatek wniosków",
    group: "Application Notes",
  },
  UPDATE_APPLICATION_NOTE: {
    value: "update_application_note",
    name: "Edycja notatek wniosków",
    group: "Application Notes",
  },
  DELETE_APPLICATION_NOTE: {
    value: "delete_application_note",
    name: "Usuwanie notatek wniosków",
    group: "Application Notes",
  },

  // Application Processes
  ACCESS_APPLICATION_PROCESSES: {
    value: "access_application_processes",
    name: "Dostęp do procesów wniosków",
    group: "Application Processes",
  },
  UPDATE_APPLICATION_PROCESS: {
    value: "update_application_process",
    name: "Edycja procesów wniosków",
    group: "Application Processes",
  },

  // Clients
  ACCESS_ALL_CLIENTS: { value: "access_all_clients", name: "Dostęp do wszystkich klientów", group: "Clients" },
  ACCESS_OWN_CLIENTS: {
    value: "access_own_clients",
    name: "Dostęp do klientów własnych i ze swojej grupy",
    group: "Clients",
  },
  SHOW_ANY_CLIENT: { value: "show_any_client", name: "Podgląd dowolnego klienta", group: "Clients" },
  SHOW_OWN_CLIENT: { value: "show_own_client", name: "Podgląd klientów własnych i ze swojej grupy", group: "Clients" },
  CREATE_CLIENT: { value: "create_client", name: "Tworzenie klienta", group: "Clients" },
  UPDATE_CLIENT: { value: "update_client", name: "Edycja klienta", group: "Clients" },
  DELETE_CLIENT: { value: "delete_client", name: "Usuwanie klienta", group: "Clients" },

  // Orders
  ACCESS_ALL_ORDERS: { value: "access_all_orders", name: "Dostęp do wszystkich zamówień", group: "Orders" },
  ACCESS_OWN_ORDERS: { value: "access_own_orders", name: "Dostęp do przypisanych zamówień", group: "Orders" },
  SHOW_OWN_ORDER: { value: "show_own_order", name: "Podgląd przypisanych zamówień", group: "Orders" },
  SHOW_ANY_ORDER: { value: "show_any_order", name: "Podgląd dowolnych zamówień", group: "Orders" },
  UPDATE_ORDER: { value: "update_order", name: "Edycja zamówień", group: "Orders" },
  DELETE_ORDER: { value: "delete_order", name: "Usuwanie zamówień", group: "Orders" },

  // Order Files
  ACCESS_ORDER_FILES: { value: "access_order_files", name: "Dostęp do plików zamówień", group: "Order Files" },
  CREATE_ORDER_FILE: { value: "create_order_file", name: "Tworzenie plików zamówień", group: "Order Files" },
  DELETE_ORDER_FILE: { value: "delete_order_file", name: "Usuwanie plików zamówień", group: "Order Files" },

  // Order Notes
  ACCESS_ORDER_NOTES: { value: "access_order_notes", name: "Dostęp do notatek zamówień", group: "Order Notes" },
  CREATE_ORDER_NOTE: { value: "create_order_note", name: "Tworzenie notatek zamówień", group: "Order Notes" },
  UPDATE_ORDER_NOTE: { value: "update_order_note", name: "Edycja notatek zamówień", group: "Order Notes" },
  DELETE_ORDER_NOTE: { value: "delete_order_note", name: "Usuwanie notatek zamówień", group: "Order Notes" },

  // User Levels
  CREATE_USER_LEVEL: { value: "create_user_level", name: "Tworzenie poziomów użytkowników", group: "User Levels" },
  UPDATE_USER_LEVEL: { value: "update_user_level", name: "Edycja poziomów użytkowników", group: "User Levels" },
  DELETE_USER_LEVEL: { value: "delete_user_level", name: "Usuwanie poziomów użytkowników", group: "User Levels" },

  // Settings
  ACCESS_SETTINGS: { value: "access_settings", name: "Dostęp do pozostałych ustawień", group: "Settings" },
  UPDATE_SETTING: { value: "update_setting", name: "Edycja pozostałych ustawień", group: "Settings" },

  // Contract File Categories
  CREATE_CONTRACT_FILE_CATEGORY: {
    value: "create_contract_file_category",
    name: "Tworzenie kategorii plików umów",
    group: "Contract File Categories",
  },
  DELETE_CONTRACT_FILE_CATEGORY: {
    value: "delete_contract_file_category",
    name: "Usuwanie kategorii plików umów",
    group: "Contract File Categories",
  },

  // Surveys
  ACCESS_SURVEYS: { value: "access_surveys", name: "Dostęp do ankiet", group: "Surveys" },
  SHOW_SURVEY: { value: "show_survey", name: "Podgląd ankiet", group: "Surveys" },
  CREATE_SURVEY: { value: "create_survey", name: "Tworzenie ankiet", group: "Surveys" },
  UPDATE_SURVEY: { value: "update_survey", name: "Edycja ankiet", group: "Surveys" },
  DELETE_SURVEY: { value: "delete_survey", name: "Usuwanie ankiet", group: "Surveys" },

  // Survey Entries
  ACCESS_SURVEY_ENTRIES: {
    value: "access_survey_entries",
    name: "Dostęp do wypełnień ankiet",
    group: "Survey Entries",
  },
  SHOW_SURVEY_ENTRY: { value: "show_survey_entry", name: "Podgląd wypełnień ankiet", group: "Survey Entries" },
  CREATE_SURVEY_ENTRY: { value: "create_survey_entry", name: "Tworzenie wypełnień ankiet", group: "Survey Entries" },
  UPDATE_SURVEY_ENTRY: { value: "update_survey_entry", name: "Edycja wypełnień ankiet", group: "Survey Entries" },
  DELETE_SURVEY_ENTRY: { value: "delete_survey_entry", name: "Usuwanie wypełnień ankiet", group: "Survey Entries" },

  // Contract Templates
  ACCESS_CONTRACT_TEMPLATES: {
    value: "access_contract_templates",
    name: "Dostęp do szablonów umów",
    group: "Contract Templates",
  },
  CREATE_CONTRACT_TEMPLATE: {
    value: "create_contract_template",
    name: "Tworzenie szablonów umów",
    group: "Contract Templates",
  },
  DELETE_CONTRACT_TEMPLATE: {
    value: "delete_contract_template",
    name: "Usuwanie szablonów umów",
    group: "Contract Templates",
  },

  // Roles
  ACCESS_ROLES: { value: "access_roles", name: "Dostęp do ról", group: "Roles" },
  SHOW_ROLE: { value: "show_role", name: "Podgląd ról", group: "Roles" },
  CREATE_ROLE: { value: "create_role", name: "Tworzenie ról", group: "Roles" },
  UPDATE_ROLE: { value: "update_role", name: "Edycja ról", group: "Roles" },
  DELETE_ROLE: { value: "delete_role", name: "Usuwanie ról", group: "Roles" },

  // Custom Fields
  CREATE_CUSTOM_FIELD: { value: "create_custom_field", name: "Tworzenie pól dodatkowych", group: "Custom Fields" },
  UPDATE_CUSTOM_FIELD: { value: "update_custom_field", name: "Edycja pól dodatkowych", group: "Custom Fields" },
  DELETE_CUSTOM_FIELD: { value: "delete_custom_field", name: "Usuwanie pól dodatkowych", group: "Custom Fields" },

  // Credits
  ASSIGN_USER_CREDITS: {
    value: "assign_user_credits",
    name: "Przypisywanie kredytów do użytkownika",
    group: "Credits",
  },
  BULK_EDIT_CREDIT_STATUS: {
    value: "bulk_edit_credit_status",
    name: "Masowa zmiana statusu kredytów",
    group: "Credits",
  },
  ACCESS_ALL_CREDITS: { value: "access_all_credits", name: "Dostęp do wszystkich kredytów", group: "Credits" },
  ACCESS_OWN_CREDITS: { value: "access_own_credits", name: "Dostęp do własnych kredytów", group: "Credits" },
  ACCESS_CREATED_CREDITS: { value: "access_created_credits", name: "Dostęp do stworzonych kredytów", group: "Credits" },
  CREATE_CREDIT: { value: "create_credit", name: "Tworzenie kredytów", group: "Credits" },
  SHOW_CREATED_CREDIT: { value: "show_created_credit", name: "Podgląd stworzonych kredytów", group: "Credits" },
  SHOW_OWN_CREDIT: { value: "show_own_credit", name: "Podgląd własnych kredytów", group: "Credits" },
  SHOW_ANY_CREDIT: { value: "show_any_credit", name: "Podgląd dowolnych kredytów", group: "Credits" },
  UPDATE_CREDIT: { value: "update_credit", name: "Edycja kredytów", group: "Credits" },
  UPDATE_CREDITS_STATUS: { value: "update_credits_status", name: "Zmiana statusu kredytu", group: "Credits" },
  DELETE_CREDIT: { value: "delete_credit", name: "Usuwanie kredytów", group: "Credits" },

  // Credit Status
  CREATE_CREDIT_STATUS: { value: "create_credit_status", name: "Tworzenie statusów kredytów", group: "Credit Status" },
  UPDATE_CREDIT_STATUS: { value: "update_credit_status", name: "Edycja statusów kredytów", group: "Credit Status" },
  DELETE_CREDIT_STATUS: { value: "delete_credit_status", name: "Usuwanie statusów kredytów", group: "Credit Status" },

  // Credit Files
  ACCESS_CREDIT_FILES: { value: "access_credit_files", name: "Dostęp do plików kredytów", group: "Credit Files" },
  CREATE_CREDIT_FILE: { value: "create_credit_file", name: "Tworzenie plików kredytów", group: "Credit Files" },
  DELETE_CREDIT_FILE: { value: "delete_credit_file", name: "Usuwanie plików kredytów", group: "Credit Files" },

  // Credit Notes
  ACCESS_CREDIT_NOTES: { value: "access_credit_notes", name: "Dostęp do notatek kredytów", group: "Credit Notes" },
  CREATE_CREDIT_NOTE: { value: "create_credit_note", name: "Tworzenie notatek kredytów", group: "Credit Notes" },
  UPDATE_CREDIT_NOTE: { value: "update_credit_note", name: "Edycja notatek kredytów", group: "Credit Notes" },
  DELETE_CREDIT_NOTE: { value: "delete_credit_note", name: "Usuwanie notatek kredytów", group: "Credit Notes" },

  // Credit Actions
  ACCESS_CREDIT_ACTIONS: {
    value: "access_credit_actions",
    name: "Dostęp do działań kredytów",
    group: "Credit Actions",
  },
  CREATE_CREDIT_ACTION: { value: "create_credit_action", name: "Tworzenie działań kredytów", group: "Credit Actions" },
  UPDATE_CREDIT_ACTION: { value: "update_credit_action", name: "Edycja działań kredytów", group: "Credit Actions" },
  DELETE_CREDIT_ACTION: { value: "delete_credit_action", name: "Usuwanie działań kredytów", group: "Credit Actions" },

  // Credit Processes
  ACCESS_CREDIT_PROCESSES: {
    value: "access_credit_processes",
    name: "Dostęp do procesów kredytów",
    group: "Credit Processes",
  },
  UPDATE_CREDIT_PROCESS: {
    value: "update_credit_process",
    name: "Edycja procesów kredytów",
    group: "Credit Processes",
  },

  // Automations
  ACCESS_AUTOMATIONS: { value: "access_automations", name: "Dostęp do automatyzacji", group: "Automations" },
  SHOW_AUTOMATION: { value: "show_automation", name: "Podgląd automatyzacji", group: "Automations" },
  CREATE_AUTOMATION: { value: "create_automation", name: "Tworzenie automatyzacji", group: "Automations" },
  UPDATE_AUTOMATION: { value: "update_automation", name: "Edycja automatyzacji", group: "Automations" },
  DELETE_AUTOMATION: { value: "delete_automation", name: "Usuwanie automatyzacji", group: "Automations" },

  cases() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getValues() {
    return this.cases().map((permission) => permission.value);
  },

  getGroupedValues() {
    return this.cases().reduce((acc, permission) => {
      const { group } = permission;
      if (!acc[group]) acc[group] = [];
      acc[group].push(permission);
      return acc;
    }, {});
  },

  getValuesForGroup(group) {
    return this.getGroupedValues()[group] || [];
  },
});
