import { Button } from "@/components/Button";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FormField } from "@/components/forms/FormField";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Plus, X } from "lucide-react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormSimpleOptions = ({ name, label, className }) => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const options = watch(name) || [];

  const addOption = () => {
    const currentOptions = getValues(name) || [];
    setValue(name, [...currentOptions, ""]);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setValue(name, updatedOptions.length > 0 ? updatedOptions : []);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;

    setValue(name, updatedOptions);
  };

  useEffect(() => {
    if (!options || options.length === 0) {
      setValue(name, []);
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div className={className}>
          <Label htmlFor={name} className={errors[name] && "text-destructive"}>
            {i18n.t(label)}
          </Label>
          <div className="space-y-2 mt-1">
            {options.map((option, index) => (
              <div key={index} className="flex gap-2 items-center">
                <FormField
                  name={`${name}[${index}]`}
                  placeholder={i18n.t("Opcja")}
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                />
                {options.length > 1 && (
                  <Button
                    type="button"
                    variant="ghost"
                    className="text-destructive px-2"
                    leftIcon={<X size={16} className="shrink-0" />}
                    onClick={() => removeOption(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <Button
            type="button"
            variant="link"
            onClick={addOption}
            className="m-0 pl-2 gap-1"
            leftIcon={<Plus size={16} />}
            title="Dodaj opcję"
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
