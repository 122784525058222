import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { useLeadSourcePolicy } from "@/features/leads/policies/useLeadSourcePolicy";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const useClientsTableColumns = (handleDelete) => {
  const clientPolicy = useClientPolicy();
  const clientSourcePolicy = useLeadSourcePolicy();
  const navigate = useNavigate();

  const columns = [
    {
      id: "name",
      name: "Imię i nazwisko",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Imię i nazwisko")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (clientPolicy.view()) {
          return (
            <Link
              to={`${routes.clients}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "phone_number",
      name: "Numer telefonu",
      accessorKey: "phone_number",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Numer telefonu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const client = row.original;
        return <a href={`tel:${client.phone_number}`}>{formatPhoneNumber(client.phone_number)}</a>;
      },
    },
    {
      id: "postal_code",
      name: "Kod pocztowy",
      accessorKey: "postal_code",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kod pocztowy")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const client = row.original;
        return (
          <span>
            {client.address?.postal_code ? client.address.postal_code : "-"}
            <br />
            {client.address?.city}
          </span>
        );
      },
    },
    clientSourcePolicy.viewAny()
      ? {
          id: "source",
          name: "Źródło",
          accessorKey: "source",
          header: ({ column }) => {
            return (
              <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                {i18n.t("Źródło")}
                {column.getIsSorted() === "asc" ? (
                  <ArrowDown className="h-4 w-4" />
                ) : column.getIsSorted() === "desc" ? (
                  <ArrowUp className="h-4 w-4" />
                ) : (
                  <ArrowUpDown className="h-4 w-4" />
                )}
              </Button>
            );
          },
          cell: ({ row }) => {
            const source = row.original?.source;
            return <span>{source?.name ?? i18n.t("Brak")}</span>;
          },
        }
      : { id: "source" },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const client = row.original;
        if (!clientPolicy.destroy() && !clientPolicy.update()) return null;

        const handleEdit = () => {
          return navigate(`${routes.clients}/${client.id}/edytuj`);
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {clientPolicy.update() && (
                <>
                  <DropdownMenuItem onClick={handleEdit} className="flex flex-row items-center">
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {clientPolicy.destroy() && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(client.id)}
                  message="Czy na pewno chcesz usunąć tego klienta? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
