import i18n from "@/i18n";

export const getTaskOwnerOptions = () => {
  return [
    {
      name: "Użytkownik odpowiedzialny",
      value: "user",
      content: (
        <span className="flex flex-col gap-1 justify-center">
          <p className="font-medium">{i18n.t("Użytkownik odpowiedzialny")}</p>
          <p className="text-xs text-muted-foreground">
            {i18n.t("Użytkownik odpowiedzialny lub przypisany danego zasobu określonego w wyzwalaczu (jeśli dostępny)")}
          </p>
        </span>
      ),
    },
    {
      name: "Użytkownik nadrzędny",
      value: "parent_user",
      content: (
        <span className="flex flex-col gap-1 justify-center">
          <p className="font-medium">{i18n.t("Użytkownik nadrzędny")}</p>
          <p className="text-xs text-muted-foreground">
            {i18n.t(
              "Użytkownik nadrzędny użytkownika odpowiedzialnego lub przypisanego danego zasobu określonego w wyzwalaczu (jeśli dostępny)",
            )}
          </p>
        </span>
      ),
    },
    {
      name: "Wybrany użytkownik",
      value: "user_id",
      content: (
        <span className="flex flex-col gap-1 justify-center">
          <p className="font-medium">{i18n.t("Wybrany użytkownik")}</p>
          <p className="text-xs text-muted-foreground">{i18n.t("Wybrany użytkownik w systemie")}</p>
        </span>
      ),
    },
  ];
};
