import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteLead, getLeads } from "@/features/leads/api/leadApi";
import { LeadFilters } from "@/features/leads/components/LeadFilters";
import { LeadGroupedViewItem } from "@/features/leads/components/LeadGroupedViewItem";
import { LeadsMap } from "@/features/leads/components/LeadsMap";
import { LeadStatusContext } from "@/features/leads/context/LeadStatusContext";
import { useLeadsTableColumns } from "@/features/leads/hooks/useLeadsTableColumns";
import { GroupedView } from "@/features/viewModes/components/GroupedView";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const LeadsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const { statuses } = useContext(LeadStatusContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const handleDelete = (leadId) => {
    deleteLeadMutation.mutate(leadId);
  };

  const deleteLeadMutation = useMutation({
    mutationFn: deleteLead,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["leads", pagination, sorting, columnFilters],
    queryFn: () => getLeads({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useLeadsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full">
      {viewMode === ViewMode.TABLE.value && (
        <Table
          table={table}
          Filters={LeadFilters}
          meta={response?.meta}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      )}
      {viewMode === ViewMode.MAP.value && (
        <LeadsMap
          isLoading={isLoading}
          isFetching={isFetching}
          table={table}
          leads={response?.data}
          meta={response?.meta}
        />
      )}
      {viewMode === ViewMode.GROUPED.value && (
        <GroupedView
          isFetching={isFetching}
          isLoading={isLoading}
          table={table}
          Filters={LeadFilters}
          meta={response?.meta}
          groupedData={response?.data?.reduce((acc, item) => {
            const key = item.status.name;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {})}
          groupHeaders={statuses.map((status) => ({ name: status.name, color: status.color }))}
          renderItem={(lead) => <LeadGroupedViewItem lead={lead} />}
        />
      )}
    </div>
  );
};
