import { useAuth } from "@/features/auth/hooks/useAuth";

export const useMetadataPolicy = () => {
  const { hasPermission } = useAuth();

  const create = () => {
    return hasPermission("create_metadata");
  };

  return {
    create,
  };
};
