import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormCreateSelect } from "@/components/forms/FormCreateSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { createFileCategory } from "@/features/files/api/fileApi";
import { FileCategoryContext } from "@/features/files/context/FileCategoryContext";
import { useFileCategoryPolicy } from "@/features/files/policies/useFileCategoryPolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

export const FileCategoryInput = () => {
  const fileCategoryPolicy = useFileCategoryPolicy();
  const queryClient = useQueryClient();
  const { categoryOptions, isLoading } = useContext(FileCategoryContext);

  const { setValue } = useFormContext();

  const createFileCategoryMutation = useMutation({
    mutationFn: createFileCategory,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["fileCategories"] });
      if (res.ok) {
        setValue("file_category_id", res.data.id);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleCreateFileCategory = (category) => {
    createFileCategoryMutation.mutate({ name: category });
  };

  return fileCategoryPolicy.create() ? (
    <FormCreateSelect
      isLoading={isLoading}
      options={categoryOptions}
      name="file_category_id"
      label="Kategoria"
      required={false}
      onCreate={handleCreateFileCategory}
    />
  ) : (
    <FormCombobox options={categoryOptions} name="file_category_id" label="Kategoria" isLoading={isLoading} />
  );
};
