import api from "@/api/api";
import { PROCESS_STEPS_ENDPOINT, PROCESSES_ENDPOINT } from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getProcesses = (filters = null) => api.get(PROCESSES_ENDPOINT, transformQueryFilters(filters));

export const createProcess = ({ data }) => api.post(PROCESSES_ENDPOINT, data);

export const updateProcess = ({ processId, data }) => api.put(PROCESSES_ENDPOINT + processId, data);

export const deleteProcess = (processId) => api.delete(PROCESSES_ENDPOINT + processId);

export const createProcessStep = ({ processId, data }) =>
  api.post(PROCESSES_ENDPOINT + processId + PROCESS_STEPS_ENDPOINT, data);

export const deleteProcessStep = ({ processId, processStepId }) =>
  api.delete(PROCESSES_ENDPOINT + processId + PROCESS_STEPS_ENDPOINT + processStepId);

export const updateProcessStep = ({ processId, processStepId, data }) =>
  api.put(PROCESSES_ENDPOINT + processId + PROCESS_STEPS_ENDPOINT + processStepId, data);
