import api from "@/api/api";
import {
  ADDITIONAL_COSTS_ENDPOINT,
  CALCULATOR_SECTIONS_ENDPOINT,
  CALCULATORS_ENDPOINT,
  DEPENDENCIES_ENDPOINT,
  DISCOUNTS_ENDPOINT,
  FIELDS_ENDPOINT,
  GRANTS_ENDPOINT,
  SECTIONS_ENDPOINT,
} from "@/api/endpoints";

export const getCalculators = () => api.get(CALCULATORS_ENDPOINT);

export const createCalculator = ({ data }) => api.post(CALCULATORS_ENDPOINT, data);

export const getCalculator = (calculatorId) => api.get(CALCULATORS_ENDPOINT + calculatorId);

export const updateCalculator = ({ calculatorId, data }) => api.put(CALCULATORS_ENDPOINT + calculatorId, data);

export const updateCalculatorVisibility = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + "/updateVisibility", data);

export const updateCalculatorSurvey = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + "/surveys", data);

export const deleteCalculator = ({ calculatorId }) => api.delete(CALCULATORS_ENDPOINT + calculatorId);

export const duplicateCalculator = ({ calculatorId }) => api.post(CALCULATORS_ENDPOINT + calculatorId + "/duplicate");

export const createCalculatorAdditionalCost = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + ADDITIONAL_COSTS_ENDPOINT, data);

export const updateCalculatorAdditionalCost = ({ calculatorId, calculatorAdditionalCostId, data }) =>
  api.patch(CALCULATORS_ENDPOINT + calculatorId + ADDITIONAL_COSTS_ENDPOINT + calculatorAdditionalCostId, data);

export const deleteCalculatorAdditionalCost = ({ calculatorId, calculatorAdditionalCostId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + ADDITIONAL_COSTS_ENDPOINT + calculatorAdditionalCostId);

export const createCalculatorSection = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT, data);

export const updateCalculatorSection = ({ calculatorId, calculatorSectionId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT + calculatorSectionId, data);

export const updateCalculatorSectionOrder = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT + "order", data);

export const deleteCalculatorSection = ({ calculatorId, calculatorSectionId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT + calculatorSectionId);

export const createCalculatorField = ({ calculatorSectionId, data }) =>
  api.post(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT, data);

export const updateCalculatorField = ({ calculatorSectionId, calculatorFieldId, data }) =>
  api.put(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + calculatorFieldId, data);

export const updateCalculatorFieldOrder = ({ calculatorSectionId, data }) =>
  api.put(SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + "order", data);

export const deleteCalculatorField = ({ calculatorSectionId, calculatorFieldId }) =>
  api.delete(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + calculatorFieldId);

export const duplicateCalculatorField = ({ calculatorSectionId, calculatorFieldId }) =>
  api.post(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + calculatorFieldId + "/duplicate");

export const createCalculatorFieldDependency = ({ calculatorFieldId, data }) =>
  api.post(FIELDS_ENDPOINT + calculatorFieldId + DEPENDENCIES_ENDPOINT, data);

export const deleteCalculatorFieldDependency = ({ calculatorFieldId, calculatorFieldDependencyId }) =>
  api.delete(FIELDS_ENDPOINT + calculatorFieldId + DEPENDENCIES_ENDPOINT + calculatorFieldDependencyId);

export const createCalculatorGrant = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT, data);

export const updateCalculatorGrant = ({ calculatorId, calculatorGrantId, data }) =>
  api.patch(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT + calculatorGrantId, data);

export const deleteCalculatorGrant = ({ calculatorId, calculatorGrantId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT + calculatorGrantId);

export const updateCalculatorGrantOrder = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT + "order", data);

export const createCalculatorDiscount = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + DISCOUNTS_ENDPOINT, data);

export const updateCalculatorDiscount = ({ calculatorId, calculatorDiscountId, data }) =>
  api.patch(CALCULATORS_ENDPOINT + calculatorId + DISCOUNTS_ENDPOINT + calculatorDiscountId, data);

export const deleteCalculatorDiscount = ({ calculatorId, calculatorDiscountId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + DISCOUNTS_ENDPOINT + calculatorDiscountId);

export const getCalculatorContractTemplate = (calculatorId) =>
  api.get(CALCULATORS_ENDPOINT + calculatorId + "/contractTemplate", {}, { responseType: "blob" });
