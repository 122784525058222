import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { InstallationServiceCreateDialog } from "@/features/installations/components/InstallationServices/InstallationServiceCreateDialog";
import { useInstallationCrewPolicy } from "@/features/installations/policies/useInstallationCrewPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { Link } from "react-router-dom";

export const ClientInstallationServicesCard = ({ installationServices }) => {
  const installationServicePolicy = useInstallationServicePolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();

  if (!installationServicePolicy.viewAny()) return null;

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Serwisy klienta")}</CardTitle>
          <CardDescription>{i18n.t("Lista wszystkich serwisów tego klienta")}</CardDescription>
        </div>
        <InstallationServiceCreateDialog />
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-3 justify-center items-center">
          {installationServices?.length === 0 && <EmptyState title="Brak serwisów" />}
          {installationServices?.map((installationService) => {
            return (
              <Card
                key={installationService.id}
                className="p-4 shadow-md border-l-4 w-full flex flex-row justify-between items-start gap-3 border-l-primary"
              >
                <div className="flex flex-col justify-between items-start w-full gap-3">
                  <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                    <div>
                      {installationServicePolicy.view() ? (
                        <Link to={`${routes.installationServices}/${installationService.id}`}>
                          <p className="m-0 p-0 h-fit text-lg font-semibold">{installationService.name}</p>
                        </Link>
                      ) : (
                        <span className="font-semibold text-lg">{installationService.name}</span>
                      )}
                      <div className="flex flex-wrap flex-row items-center text-muted-foreground font-medium text-sm">
                        {format(parse(installationService.service_date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}
                      </div>
                    </div>
                    <div className="flex flex-wrap flex-row items-center font-medium text-sm">
                      {installationCrewPolicy.view() ? (
                        <Link to={`${routes.installationCrews}/${installationService.installationCrew?.id}`}>
                          <Button variant="link" className="m-0 p-0 h-fit text-sm font-semibold">
                            {installationService.installationCrew?.name}
                          </Button>
                        </Link>
                      ) : (
                        <span className="font-semibold text-lg">{installationService.installationCrew?.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};
