import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { Link } from "react-router-dom";

export const InstallationServicePopup = ({ installationService }) => {
  const installationPolicy = useInstallationPolicy();

  if (!installationService) return null;

  const { address } = installationService.contract;

  return (
    <p className="flex flex-col items-start justify-center text-xs gap-2">
      <span className="font-semibold text-sm text-muted-foreground flex items-center">
        {installationPolicy.view() ? (
          <Link to={`${routes.installationServices}/${installationService.id}`}>{installationService.name}</Link>
        ) : (
          installationService.name
        )}
      </span>
      <span className="flex flex-col gap-2">
        <span className="font-bold">
          {installationService.service_date
            ? format(parse(installationService.service_date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
            : i18n.t("Brak")}
        </span>
        <span className="flex flex-col">
          <span>{`${address.street_number}`}</span>
          <span>{`${address.postal_code} ${address.city}}`}</span>
          <span className="capitalize font-semibold">{address.voivodeship}</span>
        </span>
        <a className="font-bold" href={`tel:${installationService.contract.phone_number}`}>
          {formatPhoneNumber(installationService.contract.phone_number)}
        </a>
      </span>
    </p>
  );
};
