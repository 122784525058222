import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateOrder } from "@/features/orders/api/orderApi";
import { useOrderPolicy } from "@/features/orders/policies/useOrderPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const OrderEditDialog = ({ trigger, order }) => {
  const queryClient = useQueryClient();
  const orderPolicy = useOrderPolicy();
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    user_id: Yup.string().optional().nullable(),
    description: Yup.string().nullable(),
  });

  const onSubmit = (data) => {
    updateOrderMutation.mutate({ orderId: order.id, data });
  };

  const defaultValues = {
    user_id: order?.user?.id ?? undefined,
    description: order.description,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateOrderMutation = useMutation({
    mutationFn: updateOrder,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      queryClient.invalidateQueries({ queryKey: ["order", order.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!orderPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja montażu")}</DialogTitle>
          <DialogDescription>{order.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            {orderPolicy.viewAll() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateOrderMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
