import api from "@/api/api";
import { CALCULATIONS_ENDPOINT, SURVEYS_ENDPOINT } from "@/api/endpoints";

export const getCalculation = (calculationId) => api.get(CALCULATIONS_ENDPOINT + calculationId);

export const updateCalculation = ({ calculationId, data }) => api.patch(CALCULATIONS_ENDPOINT + calculationId, data);

export const deleteCalculation = (calculationId) => api.delete(CALCULATIONS_ENDPOINT + calculationId);

export const downloadCalculationOffer = (calculationId) =>
  api.get(CALCULATIONS_ENDPOINT + calculationId + "/offer", {}, { responseType: "blob" });

export const getCalculationSurveys = (calculationId) =>
  api.get(CALCULATIONS_ENDPOINT + calculationId + SURVEYS_ENDPOINT);
