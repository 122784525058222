import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { updateAutomationActionOrder } from "@/features/automations/api/automationApi";
import { AutomationAction } from "@/features/automations/components/Actions/AutomationAction";
import { AutomationActionCreateDialog } from "@/features/automations/components/Actions/AutomationActionCreateDialog";
import { AutomationTrigger } from "@/features/automations/components/Triggers/AutomationTrigger";
import i18n from "@/i18n";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArrowDown, ChevronsRight, Workflow } from "lucide-react";
import { useEffect, useState } from "react";

export const AutomationDetails = ({ automation }) => {
  const queryClient = useQueryClient();
  const [actions, setActions] = useState(automation.actions);

  const updateAutomationActionOrderMutation = useMutation({
    mutationFn: updateAutomationActionOrder,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["automation", automation.id] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleActionDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = actions.findIndex((action) => action.id === active.id);
      const newIndex = actions.findIndex((action) => action.id === over.id);
      const newActions = arrayMove(actions, oldIndex, newIndex);
      setActions(newActions);
      updateAutomationActionOrderMutation.mutate({
        automationId: automation.id,
        data: {
          action_ids: newActions.map((action) => action.id),
        },
      });
    }
  };

  useEffect(() => {
    if (automation) {
      setActions(automation.actions);
    }
  }, [automation]);

  return (
    <div className="flex flex-col lg:flex-row items-center gap-5 justify-center lg:h-[80vh]">
      <div className="flex flex-col gap-1 items-center text-muted-foreground py-5">
        <p className="text-sm font-medium">{i18n.t("Jeśli")}</p>
        <Workflow size={24} className="text-muted-foreground" />
      </div>
      <Card className="text-center w-full lg:w-1/3">
        <CardHeader>
          <CardTitle>{i18n.t("Wyzwalacz")}</CardTitle>
        </CardHeader>
        <CardContent>
          <AutomationTrigger automation={automation} />
        </CardContent>
      </Card>
      <div className="flex flex-col gap-1 items-center text-muted-foreground py-5">
        <p className="font-medium text-sm">{i18n.t("To wtedy")}</p>
        <ChevronsRight size={30} className="text-muted-foreground hidden lg:block" />
        <ArrowDown size={30} className="text-muted-foreground lg:hidden" />
      </div>
      <Card className="text-center w-full lg:w-1/3">
        <CardHeader>
          <CardTitle>{i18n.t("Akcje")}</CardTitle>
        </CardHeader>
        <ScrollArea className="w-full flex max-h-[80vh] flex-col">
          <CardContent>
            <DndContext onDragEnd={handleActionDragEnd}>
              <SortableContext items={actions.map((action) => action.id)} strategy={verticalListSortingStrategy}>
                <div className="flex flex-col gap-4 items-center justify-start flex-1">
                  {actions?.map((action) => (
                    <AutomationAction automation={automation} action={action} key={action.id} />
                  ))}
                  <AutomationActionCreateDialog automation={automation} />
                </div>
              </SortableContext>
            </DndContext>
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
};
