import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { getVoivodeshipOptions } from "@/helpers/getVoivodeshipOptions";

export const VoivodeshipFilter = ({ setFilter, filter }) => {
  const { voivodeshipOptions } = getVoivodeshipOptions();
  return (
    <MultipleSelectFilter
      options={voivodeshipOptions}
      setValue={(value) => setFilter("voivodeship", value)}
      title="Województwo"
      value={filter("voivodeship")}
    />
  );
};
