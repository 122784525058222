import { getCalendar } from "@/features/calendar/api/calendarApi";
import { CalendarView } from "@/features/calendar/components/CalendarView";
import { getCalendarEvents } from "@/features/calendar/helpers/getCalendarEvents";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const UserCalendar = () => {
  const userPolicy = useUserPolicy();
  const { id: userId } = useParams();
  const [events, setEvents] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);
  const shouldFetch = dateFilters !== null;

  const filters = shouldFetch
    ? {
        columnFilters: [
          { id: "from", value: dateFilters.startDate },
          { id: "to", value: dateFilters.endDate },
          { id: "user", value: userId },
        ],
      }
    : null;

  const {
    isLoading,
    data: calendar,
    error,
  } = useQuery({
    queryKey: ["user", userId, "calendar", filters],
    queryFn: () => getCalendar(filters),
    staleTime: 1000 * 60 * 30,
    enabled: userPolicy.viewCalendar() && shouldFetch,
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoading && calendar && !error) {
      setEvents(getCalendarEvents(calendar.data));
    }
  }, [isLoading, calendar, error]);

  return <CalendarView events={events} isLoading={isLoading} setFilters={setDateFilters} />;
};
