import { useAuth } from "@/features/auth/hooks/useAuth";
import { Role } from "@/features/roles/enums/RoleEnum";

export const useRolePolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_roles");
  };

  const view = () => {
    return hasPermission("show_role");
  };

  const create = () => {
    return hasPermission("create_role");
  };

  const update = (role) => {
    if (Role.cases().includes(role.name)) return false;
    return hasPermission("update_role");
  };

  const updatePermissions = () => {
    return hasPermission("update_role");
  };

  const destroy = (role) => {
    if (Role.cases().includes(role.name)) return false;
    return hasPermission("delete_role");
  };

  return {
    viewAny,
    view,
    create,
    update,
    updatePermissions,
    destroy,
  };
};
