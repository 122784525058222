import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DateRangePicker } from "@/components/forms/DateRangePicker";
import { Layout } from "@/components/layout/Layout";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import { deleteUser, getUser } from "@/features/users/api/userApi";
import { UserCostsCard } from "@/features/users/components/Costs/UserCostsCard";
import { UserLevelCard } from "@/features/users/components/Levels/UserLevelCard";
import { UserStats } from "@/features/users/components/Stats/UserStats";
import { UserCalendar } from "@/features/users/components/UserCalendar";
import { UserChildrenCard } from "@/features/users/components/UserChildrenCard";
import { UserInformationCard } from "@/features/users/components/UserInformationCard";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const UserDetailsPage = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const userPolicy = useUserPolicy();
  const queryClient = useQueryClient();
  const { activeTab, setActiveTab } = useTabs("information");

  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUser(userId),
  });

  useDocumentTitle(response?.data ? `Użytkownik ${response?.data?.name}` : null);

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (res.ok) {
        return navigate(routes.users);
      } else {
        if (res.status === 400) {
          toast({
            title: (
              <ErrorToast title="Nie można usunąć tego użytkownika ponieważ posiada stworzonych przez siebie użytkowników." />
            ),
          });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  const handleDelete = () => {
    if (userPolicy.destroy(response.data)) {
      deleteUserMutation.mutate(userId);
    }
  };

  const handleEdit = () => {
    if (userPolicy.update(response.data)) {
      return navigate(`${routes.users}/${userId}/edytuj`);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "stats" && (
            <DateRangePicker onSelect={setDateRange} selected={dateRange} placeholder="Wybierz zakres" />
          )}
          {activeTab === "information" && response && userPolicy.update(response?.data) && (
            <Button variant="outline" title="Edytuj" leftIcon={<Pencil size={16} />} onClick={handleEdit} />
          )}
          {activeTab === "information" && response && userPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń użytkownika"
                  isLoading={deleteUserMutation.isPending}
                  leftIcon={<Trash size={16} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć tego użytkownika? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {(userPolicy.viewStats() || userPolicy.viewCalendar()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
            {userPolicy.viewCalendar() && <TabsTrigger value="actions">{i18n.t("Kalendarz")}</TabsTrigger>}
            {userPolicy.viewStats() && <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="information">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <UserInformationCard user={response?.data} />
            <UserCostsCard user={response?.data} />
            <div className="flex flex-col flex-1 gap-5 h-fit">
              <UserLevelCard user={response?.data} />
              <UserChildrenCard user={response?.data} />
            </div>
          </div>
        </TabsContent>
        {userPolicy.viewCalendar() && (
          <TabsContent value="actions">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <UserCalendar />
            </div>
          </TabsContent>
        )}
        {userPolicy.viewStats() && (
          <TabsContent value="stats">
            <UserStats id={userId} dateRange={dateRange} />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
