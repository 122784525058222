import { Loader } from "@/components/Loader/Loader";
import { TablePagination } from "@/components/Table/TablePagination";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";

export const GroupedView = ({
  table,
  meta,
  Filters = null,
  isLoading = false,
  isFetching = false,
  groupedData = {},
  groupHeaders = [],
  renderItem = () => {},
}) => {
  return (
    <div className="w-full flex flex-col">
      {Filters && Filters({ table, withColumns: false, withActions: false })}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-row w-full gap-5 items-start overflow-x-scroll pb-5">
          {groupHeaders.map((header, index) => (
            <div className="flex flex-col gap-3 items-center justify-start" key={`group_${index}`}>
              <div
                className={cn(
                  "min-w-[350px] rounded-lg py-2 flex flex-row items-center justify-center border relative",
                  !header.color && "border-primary",
                )}
                style={{ backgroundColor: header.color, borderColor: header.color }}
              >
                <div className="font-semibold">{header.name}</div>
                <div
                  className={cn(
                    "absolute right-2 rounded-md px-3 py-1 text-foreground bg-background flex flex-row justify-center items-center",
                  )}
                >
                  {groupedData[header.name]?.length ?? 0}
                </div>
              </div>
              <ScrollArea className="w-full flex flex-col max-h-[90vh]">
                <div className="w-full flex flex-col gap-3">
                  {isFetching ? (
                    <Loader />
                  ) : (
                    groupedData[header.name]?.map((headerData, index) => (
                      <div key={headerData?.id || index} className="bg-card">
                        {renderItem(headerData)}
                      </div>
                    ))
                  )}
                </div>
              </ScrollArea>
            </div>
          ))}
        </div>
      )}
      <TablePagination meta={meta} table={table} />
    </div>
  );
};
