import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { ContractTypeContext } from "@/features/contracts/context/ContractTypeContext";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { deleteProcess } from "@/features/processes/api/processApi";
import { ProcessEditDialog } from "@/features/processes/components/ProcessEditDialog";
import { ProcessStep } from "@/features/processes/components/ProcessStep";
import { ProcessStepCreateDialog } from "@/features/processes/components/ProcessStepCreateDialog";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useContext } from "react";

export const ProcessListItem = ({ process }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { types: installationTypes } = useContext(InstallationTypeContext);
  const { types: applicationTypes } = useContext(ApplicationTypeContext);
  const { types: contractTypes } = useContext(ContractTypeContext);

  const deleteProcessMutation = useMutation({
    mutationFn: deleteProcess,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["processes"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Proces został usunięty." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDeleteProcess = () => {
    deleteProcessMutation.mutate(process.id);
  };

  return (
    <Card className="w-full h-fit shadow-md" key={process.id}>
      <CardHeader className="flex flex-col lg:flex-row justify-between lg:items-center gap-3">
        <div className="flex flex-col gap-1.5">
          {process.applies_to === "Contract" && (
            <CardDescription>
              <Badge>{contractTypes.find((type) => type.id === process.type)?.name}</Badge>
            </CardDescription>
          )}
          {process.applies_to === "Application" && (
            <CardDescription>
              <Badge>{applicationTypes.find((type) => type.id === process.type)?.name}</Badge>
            </CardDescription>
          )}
          {process.applies_to === "Installation" && (
            <CardDescription>
              <Badge>{installationTypes.find((type) => type.id === process.type)?.name}</Badge>
            </CardDescription>
          )}
          <div className="flex flex-row gap-1 items-center">
            <CardTitle>
              {process.name}
              {process.roles.length > 0 && (
                <p className="text-muted-foreground text-sm mt-1">
                  {process.roles.map((role) => role.name).join(", ")}
                </p>
              )}
            </CardTitle>
          </div>
        </div>
        <div className="flex flex-row gap-3 items-center">
          <ProcessStepCreateDialog process={process} />
          <ProcessEditDialog process={process} />
          {processPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć ten proces? Tej operacji nie można cofnąć"
              onConfirm={handleDeleteProcess}
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń"
                  leftIcon={<Trash size={16} />}
                  isLoading={deleteProcessMutation.isPending}
                />
              }
            />
          )}
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {process?.steps.map((step, stepIndex) => (
          <ProcessStep key={step.id} process={process} step={step} index={stepIndex} />
        ))}
      </CardContent>
    </Card>
  );
};
