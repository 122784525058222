import { SortableTable } from "@/components/Table/SortableTable";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import {
  deleteApplicationStatus,
  updateApplicationStatus,
  updateApplicationStatusOrder,
} from "@/features/applications/api/applicationApi";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { useApplicationStatusesTableColumns } from "@/features/applications/hooks/useApplicationStatusesTableColumns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const ApplicationStatuses = () => {
  const { statuses, isLoading } = useContext(ApplicationStatusContext);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryClient = useQueryClient();

  const handleEdit = (applicationStatusId, data) => {
    updateApplicationStatusMutation.mutate({ applicationStatusId, data });
  };

  const handleChangeOrder = (rows) => {
    updateApplicationStatusOrderMutation.mutate({
      application_status_id: rows.map((row) => row.original.id),
    });
  };

  const handleDelete = (applicationStatusId) => {
    deleteApplicationStatusMutation.mutate({ applicationStatusId });
  };

  const updateApplicationStatusOrderMutation = useMutation({
    mutationFn: updateApplicationStatusOrder,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["applicationStatuses"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const updateApplicationStatusMutation = useMutation({
    mutationFn: updateApplicationStatus,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["applicationStatuses"] });
        queryClient.invalidateQueries({ queryKey: ["applications"] });
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteApplicationStatusMutation = useMutation({
    mutationFn: deleteApplicationStatus,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["applicationStatuses"] });
        queryClient.invalidateQueries({ queryKey: ["applications"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        if (res.status === 400) {
          toast({
            title: <ErrorToast title="Nie można usunąć tego statusu ponieważ są wnioski które go posiadają" />,
          });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
        }
      }
    },
  });

  const { columns } = useApplicationStatusesTableColumns(handleEdit, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : statuses,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: statuses.length,
  });

  return <SortableTable table={table} isLoading={isLoading} onOrderChange={handleChangeOrder} />;
};
