import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { WarehouseProductCreateForm } from "@/features/warehouses/components/WarehouseProduct/WarehouseProductCreateForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const WarehouseProductCreatePage = () => {
  useDocumentTitle("Dodawanie produktu do magazynu");
  const { state } = useLocation();
  const navigate = useNavigate();
  const warehouse = state?.warehouse;

  useEffect(() => {
    if (!warehouse) return navigate(-1);
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={warehouse?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"></div>
      </div>
      <WarehouseProductCreateForm warehouse={warehouse} />
    </Layout>
  );
};
