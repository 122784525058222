import { EmptyState } from "@/components/EmptyState";
import { Checkbox } from "@/components/forms/Checkbox";
import { Loader } from "@/components/Loader/Loader";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useEffect, useState } from "react";

export const ProcessesCard = ({ processes, isLoading, handleCheck, policy }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [processesData, setProcessesData] = useState([]);

  useEffect(() => {
    if (processes && !isLoading) {
      setProcessesData(
        processes.map((process) => {
          const progress = process.progress ? JSON.parse(process.progress) : [];
          return process.steps.map((step) => ({
            ...step,
            completed: progress[step.id] || false,
            children: step.children.map((child) => ({
              ...child,
              completed: progress[child.id] || false,
            })),
          }));
        }),
      );
    }
  }, [processes, isLoading]);

  if (!policy.viewProcesses() || processes?.length === 0) return null;

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader className="flex flex-row justify-between items-center pb-3">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Procesy")}</CardTitle>
          <CardDescription>{i18n.t("Procesy do obsługi tego zasobu")}</CardDescription>
        </div>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Loader />
        ) : (
          <Tabs value={selectedTab} onValueChange={(value) => setSelectedTab(value)}>
            {processes.length > 1 && (
              <TabsList className="mb-3">
                {processes.map((process, index) => (
                  <TabsTrigger key={process.id} value={index}>
                    {process.name}
                  </TabsTrigger>
                ))}
              </TabsList>
            )}
            {processes.map((process, index) => (
              <TabsContent key={process.id} value={index} className="flex flex-col gap-2">
                {processesData[index]?.length === 0 && <EmptyState title="Brak kroków w procesie" />}
                {processesData[index]?.map((step, stepIndex) => (
                  <div key={step.id} className="flex flex-col justify-center gap-1">
                    <h3 className="flex flex-row gap-2 items-center">
                      <Checkbox
                        disabled={!policy.updateProcess()}
                        checked={step.completed}
                        onCheckedChange={(value) => handleCheck(process.id, step, value)}
                      />
                      {stepIndex + 1}. {step.name}
                    </h3>
                    <ul className="flex flex-col gap-2 ml-5">
                      {step?.children?.map((subStep, subStepIndex) => (
                        <li key={subStep.id} className="flex flex-row gap-2 items-center">
                          <Checkbox
                            disabled={!policy.updateProcess()}
                            checked={subStep.completed}
                            onCheckedChange={(value) => handleCheck(process.id, subStep, value)}
                          />
                          {subStepIndex + 1}. {subStep.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </TabsContent>
            ))}
          </Tabs>
        )}
      </CardContent>
    </Card>
  );
};
