import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormCreateSelect } from "@/components/forms/FormCreateSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { createProductType } from "@/features/products/api/productApi";
import { ProductTypeContext } from "@/features/products/context/ProductTypeContext";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

export const ProductTypeInput = () => {
  const productPolicy = useProductPolicy();
  const queryClient = useQueryClient();

  const { typeOptions, isLoading } = useContext(ProductTypeContext);

  const { setValue } = useFormContext();

  const createProductTypeMutation = useMutation({
    mutationFn: createProductType,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["productTypes"] });
      if (res.ok) {
        setValue("product_type_id", res.data.id);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleCreateType = (source) => {
    createProductTypeMutation.mutate({ name: source });
  };

  return productPolicy.update() ? (
    <FormCreateSelect
      options={typeOptions}
      name="product_type_id"
      label="Typ"
      required={false}
      onCreate={handleCreateType}
      isLoading={isLoading}
    />
  ) : (
    <FormCombobox options={typeOptions} name="product_type_id" label="Typ" isLoading={isLoading} />
  );
};
