import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { createProduct } from "@/features/products/api/productApi";
import { ProductTypeInput } from "@/features/products/components/ProductTypes/ProductTypeInput";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  price: Yup.number().required(i18n.t("Pole jest wymagane")),
  product_type_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  available: Yup.boolean().required(i18n.t("Pole jest wymagane")),
  description: Yup.string().optional().nullable(),
});

export const ProductCreateForm = () => {
  const queryClient = useQueryClient();
  const productPolicy = useProductPolicy();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    createProductMutation.mutate({ data });
  };

  const defaultValues = {
    name: undefined,
    display_name: undefined,
    price: undefined,
    product_type_id: undefined,
    available: true,
    description: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createProductMutation = useMutation({
    mutationFn: createProduct,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Produkt został dodany." /> });
        return navigate(routes.products);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!productPolicy.update()) return null;

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Card className="w-1/2 mt-5">
        <CardHeader>
          <CardTitle>{i18n.t("Dodawanie produktu")}</CardTitle>
        </CardHeader>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-5">
            <FormField label="Nazwa" placeholder="Nazwa produktu" name="name" autoComplete="off" />
            <FormField
              label="Nazwa na ofercie"
              placeholder="Nazwa produktu na ofercie"
              name="display_name"
              autoComplete="off"
            />
            <FormNumberField label="Cena" name="price" />
            <ProductTypeInput />
            <FormCheckbox label="Widoczny" name="available" />
            <FormTextarea name="description" label="Opis" />
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            title="Zapisz"
            leftIcon={<Check size={20} />}
            isLoading={createProductMutation.isPending}
          />
          <Button
            type="button"
            title="Anuluj"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(-1)}
          />
        </CardFooter>
      </Card>
    </Form>
  );
};
