import { Breadcrumbs } from "@/components/Breadcrumbs";
import { EmptyState } from "@/components/EmptyState";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { getAutomations } from "@/features/automations/api/automationApi";
import { Automation } from "@/features/automations/components/Automation";
import { AutomationCreateDialog } from "@/features/automations/components/AutomationCreateDialog";
import { AutomationLogsTable } from "@/features/automations/components/Logs/AutomationLogsTable";
import { useAutomationPolicy } from "@/features/automations/policies/useAutomationPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useQuery } from "@tanstack/react-query";

export const AutomationsPage = () => {
  useDocumentTitle("Automatyzacje");
  const automationPolicy = useAutomationPolicy();
  const { activeTab, setActiveTab } = useTabs("list");

  const { data: automations, isLoading } = useQuery({
    queryKey: ["automations"],
    queryFn: getAutomations,
    staleTime: 1000 * 60 * 5,
    enabled: automationPolicy.viewAny(),
  });

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <AutomationCreateDialog />
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="mb-6">
          <TabsTrigger value="list">{i18n.t("Lista")}</TabsTrigger>
          <TabsTrigger value="log">{i18n.t("Historia")}</TabsTrigger>
        </TabsList>
        <TabsContent value="list">
          {automations?.data?.length === 0 && <EmptyState title="Brak automatyzacji" />}
          {automations?.data?.map((automation) => {
            return <Automation key={automation.id} automation={automation} />;
          })}
        </TabsContent>
        <TabsContent value="log">
          <AutomationLogsTable />
        </TabsContent>
      </Tabs>
    </Layout>
  );
};
