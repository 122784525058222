import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { createCalculatorFieldDependency } from "@/features/calculators/api/calculatorApi";
import { attributeOptions } from "@/features/calculators/constants/attributeOptions";
import { operatorOptions } from "@/features/calculators/constants/operatorOptions";
import { CalculatorContext } from "@/features/calculators/context/CalculatorContext";
import { isEmpty } from "@/helpers/isEmpty";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  depends_on_field_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  attribute: Yup.string().required(i18n.t("Pole jest wymagane")),
  operator: Yup.string().required(i18n.t("Pole jest wymagane")),
  value: Yup.string().required(i18n.t("Pole jest wymagane")),
  action: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const CalculatorFieldDependencyCreateForm = ({ field, create, setCreate }) => {
  const { id: calculatorId } = useParams();
  const queryClient = useQueryClient();
  const { calculator } = useContext(CalculatorContext);
  const [fieldOptions, setFieldOptions] = useState([]);

  const createCalculatorFieldDependencyMutation = useMutation({
    mutationFn: createCalculatorFieldDependency,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      if (res.ok) {
        form.reset();
        setCreate(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const defaultValues = {
    depends_on_field_id: undefined,
    attribute: undefined,
    operator: undefined,
    value: undefined,
    action: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { attribute } = form.watch();

  const onSubmit = () => {
    const values = form.getValues();
    if (isEmpty(form.formState.errors)) {
      const data = {
        depends_on_field_id: values.depends_on_field_id,
        condition: {
          attribute: values.attribute,
          operator: values.operator,
          value: values.value,
        },
        action: values.action,
      };
      createCalculatorFieldDependencyMutation.mutate({ calculatorFieldId: field.id, data });
    }
  };

  useEffect(() => {
    if (!isEmpty(calculator)) {
      const fields = calculator.sections.flatMap((section) => section.fields);
      setFieldOptions(fields.filter((f) => f.id !== field.id).map((f) => ({ name: f.identifier, value: f.id })));
    }
  }, []);

  return !create ? (
    <div>
      <Button
        variant="outline"
        type="button"
        title="Dodaj zależność"
        leftIcon={<Plus size={20} />}
        onClick={() => setCreate(true)}
      />
    </div>
  ) : (
    <Form onSubmit={onSubmit} form={form}>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-3 items-center">
        <FormSelect options={fieldOptions} label="Pole zależne" name="depends_on_field_id" />
        <FormSelect name="attribute" label="Atrybut" options={attributeOptions} />
        {attribute !== "has_value" && attribute !== "doesnt_have_value" && (
          <>
            <FormSelect name="operator" label="Operator" options={operatorOptions} />
            <FormField name="value" label="Wartość" placeholder="np. 10" />
          </>
        )}
        <FormSelect
          name="action"
          label="Akcja"
          options={[
            { name: "ukryj to pole", value: "HIDE" },
            { name: "pokaż to pole", value: "SHOW" },
          ]}
        />
      </div>
      <div className="flex justify-start mt-5 gap-2">
        <Button
          type="button"
          title="Dodaj"
          leftIcon={<Plus size={16} />}
          onClick={onSubmit}
          isLoading={createCalculatorFieldDependencyMutation.isPending}
        />
        <Button
          type="button"
          variant="destructive"
          title="Anuluj"
          leftIcon={<X size={16} />}
          onClick={() => setCreate(false)}
        />
      </div>
    </Form>
  );
};
