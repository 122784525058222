import { Input as ShadInput } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Asterisk } from "lucide-react";
import { forwardRef } from "react";

const Input = forwardRef(
  ({ name, description, isError, placeholder, label, className, requiredIndicator = false, ...rest }, ref) => {
    return (
      <div className="grid gap-2">
        {label && (
          <Label htmlFor={name} className={cn("flex flex-row gap-1 items-center", isError && "text-destructive")}>
            {typeof label === "string" ? i18n.t(label) : label}
            {requiredIndicator ? <Asterisk size={16} className="text-destructive" /> : null}
          </Label>
        )}
        <ShadInput
          ref={ref}
          name={name}
          id={name}
          placeholder={placeholder ? i18n.t(placeholder) : i18n.t(label)}
          className={className}
          {...rest}
        />
        {description && (
          <span className="text-sm text-muted-foreground">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
