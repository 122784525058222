import { Table } from "@/components/Table/Table";
import { getAutomationsLog } from "@/features/automations/api/automationApi";
import { AutomationLogFilters } from "@/features/automations/components/Logs/AutomationLogFilters";
import { useAutomationLogsTableColumns } from "@/features/automations/hooks/useAutomationLogsTableColumns";
import { useAutomationPolicy } from "@/features/automations/policies/useAutomationPolicy";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const AutomationLogsTable = () => {
  const automationPolicy = useAutomationPolicy();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    data: response,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["automations", "log", pagination, sorting, columnFilters],
    queryFn: () => getAutomationsLog({ pagination, sorting, columnFilters }),
    staleTime: 1000 * 60 * 5,
    enabled: automationPolicy.create(),
    placeholderData: keepPreviousData,
  });

  const { columns } = useAutomationLogsTableColumns();
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  return (
    <Table
      table={table}
      Filters={AutomationLogFilters}
      meta={response?.meta}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
};
