import { Button } from "@/components/Button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { Role } from "@/features/roles/enums/RoleEnum";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const UserTooltip = ({ user, linkClassName, fallback = "Brak użytkownika" }) => {
  const { user: loggedInUser } = useAuth();
  const userPolicy = useUserPolicy();
  if (!user) return <span className="w-fit text-sm">{i18n.t(fallback)}</span>;
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild className="text-right">
          {loggedInUser.id !== user.id && userPolicy.view(user) ? (
            <Link className="w-fit" to={`${routes.users}/${user.id}`}>
              <Button variant="link" size="xs" className={cn(linkClassName, "break-all")}>
                {user.name}
              </Button>
            </Link>
          ) : loggedInUser.id !== user.id ? (
            <p className="w-fit text-sm text-primary cursor-pointer underline-offset-4 hover:underline">{user.name}</p>
          ) : (
            <p className="w-fit text-sm">{user.name}</p>
          )}
        </TooltipTrigger>
        {loggedInUser.id !== user.id && (
          <TooltipContent>
            <div className="flex flex-row gap-2 items-start justify-center p-2">
              <UserAvatar user={user} />
              <div className="flex flex-col gap-2">
                <span className="flex flex-col gap-1">
                  <p className="text-xs leading-none text-muted-foreground">{Role.displayRoles(user)}</p>
                  <p className="text-base leading-none">{user.name}</p>
                </span>
                <a href={`mailto:${user.email}`}>
                  <p className="text-xs leading-none text-muted-foreground">{user.email}</p>
                </a>
                {user.phone_number ? (
                  <a href={`tel:${user.phone_number}`}>
                    <p className="text-xs leading-none text-muted-foreground">{formatPhoneNumber(user.phone_number)}</p>
                  </a>
                ) : null}
              </div>
            </div>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
