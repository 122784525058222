import { EmptyState } from "@/components/EmptyState";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { CornerDownRight } from "lucide-react";
import { Link } from "react-router-dom";

export const UserChildrenCard = ({ user }) => {
  const sortChildren = (children) => {
    return [...children].sort((a, b) => a.children.length - b.children.length);
  };

  const renderUserTree = (user, depth = 0) => {
    return (
      <li key={user.id} className="flex flex-col gap-2">
        <div className="flex items-center gap-2" style={{ paddingLeft: `${depth * 20}px` }}>
          <CornerDownRight className="text-muted-foreground" size={18} />
          <Link className="hover:underline" to={`${routes.users}/${user.id}`}>
            {user.name}
          </Link>
        </div>
        {user.children.length > 0 && (
          <ul className="mt-2">{sortChildren(user.children).map((child) => renderUserTree(child, depth + 1))}</ul>
        )}
      </li>
    );
  };

  return (
    <Card className="flex-1 h-fit">
      <CardHeader>
        <CardTitle>{i18n.t("Użytkownicy")}</CardTitle>
        <CardDescription>{i18n.t("Lista użytkowników dodanych przez tego użytkownika")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-border overflow-x-auto">
        {user.children.length > 0 ? (
          <ul className="grid gap-4">
            <li className="flex items-center gap-2 font-bold text-primary">
              <span>{user.name}</span>
            </li>
            {sortChildren(user.children).map((child) => renderUserTree(child))}
          </ul>
        ) : (
          <EmptyState title="Brak użytkowników" />
        )}
      </CardContent>
    </Card>
  );
};
