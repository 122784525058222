import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { updateNotification } from "@/features/notifications/api/notificationApi";
import { NotificationContent } from "@/features/notifications/components/NotificationContent/NotificationContent";
import { cn } from "@/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check } from "lucide-react";

export const Notification = ({ notification }) => {
  const queryClient = useQueryClient();
  const updateNotificationMutation = useMutation({
    mutationFn: updateNotification,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["notifications"], exact: true });
        queryClient.invalidateQueries({ queryKey: ["notifications", "unread"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleMarkAsRead = () => {
    updateNotificationMutation.mutate({ notificationId: notification.id });
  };

  return (
    <Card
      className={cn(
        "w-full flex flex-row items-start justify-between gap-3 py-3 px-3",
        notification.read_at && "opacity-30",
      )}
    >
      <NotificationContent notification={notification} />
      {!notification.read_at && (
        <Button
          variant="outline"
          onClick={handleMarkAsRead}
          className="flex flex-row gap-1"
          title="Oznacz jako przeczytane"
          rightIcon={<Check size={24} />}
        />
      )}
    </Card>
  );
};
