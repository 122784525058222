import { ContractFileCategoryProvider } from "@/features/contracts/context/ContractFileCategoryContext";
import { ContractCalculationDetailsPage } from "@/features/contracts/pages/ContractCalculationDetailsPage";
import { ContractCreatePage } from "@/features/contracts/pages/ContractCreatePage";
import { ContractDetailsPage } from "@/features/contracts/pages/ContractDetailsPage";
import { ContractEditPage } from "@/features/contracts/pages/ContractEditPage";
import { ContractPaymentCreatePage } from "@/features/contracts/pages/ContractPaymentCreatePage";
import { ContractsPage } from "@/features/contracts/pages/ContractsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const ContractRoutes = () => (
  <>
    <Route
      exact
      path={routes.contracts}
      element={
        <ContractFileCategoryProvider>
          <ContractsPage />
        </ContractFileCategoryProvider>
      }
    />
    <Route
      exact
      path={routes.contractCreate}
      element={
        <ContractFileCategoryProvider>
          <ContractCreatePage />
        </ContractFileCategoryProvider>
      }
    />
    <Route exact path={routes.contract} element={<ContractDetailsPage />} />
    <Route exact path={routes.contractEdit} element={<ContractEditPage />} />
    <Route exact path={routes.contractPaymentCreate} element={<ContractPaymentCreatePage />} />
    <Route exact path={routes.contractCalculation} element={<ContractCalculationDetailsPage />} />
  </>
);
