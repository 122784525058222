import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateBilling } from "@/features/billings/api/billingApi";
import { BillingStatus } from "@/features/billings/enums/BillingStatusEnum";
import { useBillingPolicy } from "@/features/billings/policies/useBillingPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const BillingEditDialog = ({ trigger, billing }) => {
  const queryClient = useQueryClient();
  const billingPolicy = useBillingPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    status: Yup.string().oneOf(BillingStatus.getValuesForEdit()).required(i18n.t("Pole jest wymagane")),
    amount: Yup.number().required(i18n.t("Pole jest wymagane")),
    correction_reason: Yup.string()
      .nullable()
      .when(["amount"], {
        is: (amount) => amount !== billing.amount,
        then: () => Yup.string().required(i18n.t("Pole jest wymagane jeśli kwota uległa zmianie")),
      }),
    description: Yup.string().nullable(),
  });

  const onSubmit = (data) => {
    updateBillingMutation.mutate({ billingId: billing.id, data });
  };

  const defaultValues = {
    status: billing.status,
    amount: billing.amount,
    description: billing.description,
    correction_reason: billing.correction_reason,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const { amount } = form.watch();

  const updateBillingMutation = useMutation({
    mutationFn: updateBilling,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billings"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!billingPolicy.update() || billing.billing_group) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja rozliczenia")}</DialogTitle>
          <DialogDescription>{`${billing.contract ? billing.contract.identifier : billing.description} - ${
            billing.amount
          }`}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect label="Status" name="status" options={BillingStatus.getForEdit()} />
            <FormField label="Kwota" name="amount" type="number" />
            {(amount !== billing.amount || billing.corrected_amount) && (
              <FormField label="Powód zmiany" name="correction_reason" />
            )}
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateBillingMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
