import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteAudit, getAudits } from "@/features/audits/api/auditApi";
import { AuditFilters } from "@/features/audits/components/AuditFilters";
import { AuditGroupedViewItem } from "@/features/audits/components/AuditGroupedViewItem";
import { AuditsMap } from "@/features/audits/components/Map/AuditsMap";
import { AuditStatusContext } from "@/features/audits/context/AuditStatusContext";
import { useAuditsTableColumns } from "@/features/audits/hooks/useAuditsTableColumns";
import { GroupedView } from "@/features/viewModes/components/GroupedView";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const AuditsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const { statuses } = useContext(AuditStatusContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const handleDelete = (auditId) => {
    deleteAuditMutation.mutate(auditId);
  };

  const deleteAuditMutation = useMutation({
    mutationFn: deleteAudit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["audits", pagination, sorting, columnFilters],
    queryFn: () => getAudits({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useAuditsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full flex-col flex">
      <div className="flex flex-col gap-5 w-full">
        {viewMode === ViewMode.TABLE.value && (
          <Table
            table={table}
            Filters={AuditFilters}
            meta={response?.meta}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        )}
        {viewMode === ViewMode.MAP.value && (
          <AuditsMap
            isLoading={isLoading}
            isFetching={isFetching}
            table={table}
            audits={response?.data}
            meta={response?.meta}
          />
        )}
        {viewMode === ViewMode.GROUPED.value && (
          <GroupedView
            isFetching={isFetching}
            isLoading={isLoading}
            table={table}
            Filters={AuditFilters}
            meta={response?.meta}
            groupedData={response?.data?.reduce((acc, item) => {
              const key = item.status.name;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(item);
              return acc;
            }, {})}
            groupHeaders={statuses.map((status) => ({ name: status.name, color: status.color }))}
            renderItem={(audit) => <AuditGroupedViewItem audit={audit} />}
          />
        )}
      </div>
    </div>
  );
};
