import { useAuth } from "@/features/auth/hooks/useAuth";
import { getInstallationStatuses } from "@/features/installations/api/installationApi";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const InstallationStatusContext = createContext();

export function InstallationStatusProvider({ children }) {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const installationPolicy = useInstallationPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["installationStatuses"],
    queryFn: getInstallationStatuses,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && installationPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setStatuses(response.data);
      setStatusOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <InstallationStatusContext.Provider value={{ statuses, isLoading, statusOptions }}>
      {children}
    </InstallationStatusContext.Provider>
  );
}
