import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { deleteProductType, updateProductType } from "@/features/products/api/productApi";
import { ProductTypeContext } from "@/features/products/context/ProductTypeContext";
import { useProductTypesTableColumns } from "@/features/products/hooks/useProductTypesTableColumns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const ProductTypesTable = () => {
  const { types, isLoading } = useContext(ProductTypeContext);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryClient = useQueryClient();

  const handleEdit = (productTypeId, data) => {
    updateContractProductMutation.mutate({ productTypeId, data });
  };

  const updateContractProductMutation = useMutation({
    mutationFn: updateProductType,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["productTypes"] });
        queryClient.invalidateQueries({ queryKey: ["products"] });
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteContractProductMutation = useMutation({
    mutationFn: deleteProductType,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["productTypes"] });
        queryClient.invalidateQueries({ queryKey: ["products"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        if (res.status === 400) {
          toast({ title: <ErrorToast title="Nie można usunąć tego typu ponieważ są produkty które go posiadają" /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
        }
      }
    },
  });

  const handleDelete = (productTypeId) => {
    deleteContractProductMutation.mutate({ productTypeId });
  };

  const { columns } = useProductTypesTableColumns(handleEdit, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : types,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: types.length,
  });

  return <Table table={table} isLoading={isLoading} />;
};
