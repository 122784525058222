import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { AuditStatusContext } from "@/features/audits/context/AuditStatusContext";
import { ContractStatusContext } from "@/features/contracts/context/ContractStatusContext";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { LeadStatusContext } from "@/features/leads/context/LeadStatusContext";
import { updateProcessStep } from "@/features/processes/api/processApi";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Pencil, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  status_change: Yup.string().optional().nullable(),
});

export const ProcessStepEditDialog = ({ process, step, variant = "outline", ...rest }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { statusOptions: leadStatusOptions, isLoading: isLoadingLeadStatuses } = useContext(LeadStatusContext);
  const { statusOptions: contractStatusOptions, isLoading: isLoadingContractStatuses } =
    useContext(ContractStatusContext);
  const { statusOptions: installationStatusOptions, isLoading: isLoadingInstallationStatuses } =
    useContext(InstallationStatusContext);
  const { statusOptions: auditStatusOptions, isLoading: isLoadingAuditStatuses } = useContext(AuditStatusContext);
  const { statusOptions: applicationStatusOptions, isLoading: isLoadingApplicationStatuses } =
    useContext(ApplicationStatusContext);
  const { statusOptions: creditStatusOptions, isLoading: isLoadingCreditStatuses } = useContext(CreditStatusContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    updateProcessStepMutation.mutate({ processId: process.id, processStepId: step.id, data });
  };

  const defaultValues = {
    name: step.name,
    status_change: step.status_change ?? undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateProcessStepMutation = useMutation({
    mutationFn: updateProcessStep,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        queryClient.invalidateQueries({ queryKey: ["processes"] });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const renderStatusChangeField = () => {
    switch (process.applies_to) {
      case "Application":
        return (
          <FormSelect
            options={appendEmptyOption(applicationStatusOptions)}
            label="Zmiana statusu"
            name="status_change"
            isLoading={isLoadingApplicationStatuses}
          />
        );
      case "Lead":
        return (
          <FormSelect
            options={appendEmptyOption(leadStatusOptions)}
            label="Zmiana statusu"
            name="status_change"
            isLoading={isLoadingLeadStatuses}
          />
        );
      case "Contract":
        return (
          <FormSelect
            options={appendEmptyOption(contractStatusOptions)}
            label="Zmiana statusu"
            name="status_change"
            isLoading={isLoadingContractStatuses}
          />
        );
      case "Audit":
        return (
          <FormSelect
            options={appendEmptyOption(auditStatusOptions)}
            label="Zmiana statusu"
            name="status_change"
            isLoading={isLoadingAuditStatuses}
          />
        );
      case "Installation":
        return (
          <FormSelect
            options={appendEmptyOption(installationStatusOptions)}
            label="Zmiana statusu"
            name="status_change"
            isLoading={isLoadingInstallationStatuses}
          />
        );
      case "Credit":
        return (
          <FormSelect
            options={appendEmptyOption(creditStatusOptions)}
            label="Zmiana statusu"
            name="status_change"
            isLoading={isLoadingCreditStatuses}
          />
        );
      default:
        return null;
    }
  };

  if (!processPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button title="Edytuj" variant={variant} leftIcon={<Pencil size={16} />} {...rest} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja kroku")}</DialogTitle>
          <DialogDescription>{step.name}</DialogDescription>
        </DialogHeader>
        <Form form={form} onSubmit={onSubmit} className="flex flex-col gap-3">
          <div className="flex flex-col gap-4 mb-5">
            <FormField name="name" autoComplete="off" label="Nazwa" placeholder="Nazwa kroku" />
            {renderStatusChangeField()}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateProcessStepMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
