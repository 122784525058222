import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { BillingCreateForm } from "@/features/billings/components/BillingCreateForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const BillingCreatePage = () => {
  useDocumentTitle("Dodawanie rozliczenia");
  return (
    <Layout>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      <BillingCreateForm />
    </Layout>
  );
};
