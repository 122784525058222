import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export const ContractApplicationInformationCard = ({ application }) => {
  const userPolicy = useUserPolicy();
  const applicationPolicy = useApplicationPolicy();

  return (
    <Card className="shadow-md">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{application.type.name}</CardTitle>
          <CardDescription>{i18n.t("Informacje o wniosku")}</CardDescription>
        </div>
        {applicationPolicy.view() && (
          <Link to={`${routes.applications}/${application.id}`}>
            <Button variant="outline" title="Przejdź do wniosku" rightIcon={<ArrowRight size={16} />} />
          </Link>
        )}
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia wniosku")}</p>
          <p title={application.created_at}>
            {format(parse(application.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Status")}</p>
          <ColorBadge color={application.status.color}>{application.status.name}</ColorBadge>
        </CardRow>
        {userPolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
            <UserTooltip user={application.user} />
          </CardRow>
        )}
        {application.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{application.description}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
