import { Breadcrumbs } from "@/components/Breadcrumbs";
import { EmptyState } from "@/components/EmptyState";
import { Layout } from "@/components/layout/Layout";
import { getCalculators } from "@/features/calculators/api/calculatorApi";
import { CalculatorCreateDialog } from "@/features/calculators/components/CalculatorCreateDialog";
import { CalculatorSetting } from "@/features/calculators/components/CalculatorSetting";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import { getUsers } from "@/features/users/api/userApi";
import { useUserPolicy } from "@/features/users/policies/useUserPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const CalculatorsSettingsPage = () => {
  useDocumentTitle("Kalkulatory");
  const calculatorPolicy = useCalculatorPolicy();
  const userPolicy = useUserPolicy();

  const [userOptions, setUserOptions] = useState([]);

  const { data: calculators, isLoading: isLoadingCalculators } = useQuery({
    queryKey: ["calculators"],
    queryFn: getCalculators,
    staleTime: 1000 * 60 * 5,
    enabled: calculatorPolicy.viewAll(),
  });

  const {
    isLoading,
    data: users,
    error,
  } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    staleTime: 1000 * 60 * 5,
    enabled: userPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && users) {
      setUserOptions(users.data.map((user) => ({ name: user.name, value: user.id })));
    }
  }, [isLoading, error, users]);

  return (
    <Layout isLoading={isLoadingCalculators || isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <CalculatorCreateDialog />
        </div>
      </div>
      {calculators?.data?.length === 0 && <EmptyState title="Brak kalkulatorów" />}
      {calculators?.data?.map((calculator) => {
        return <CalculatorSetting key={calculator.id} calculator={calculator} userOptions={userOptions} />;
      })}
    </Layout>
  );
};
