import { useAuth } from "@/features/auth/hooks/useAuth";

export const useContractPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_contracts", "access_own_contracts"]);
  };

  const view = () => {
    return hasAnyPermission(["show_any_contract", "show_own_contract"]);
  };

  const create = () => {
    return hasPermission("create_contract");
  };

  const update = () => {
    return hasPermission("update_contract");
  };

  const updateStatus = () => {
    return hasPermission("update_contracts_status");
  };

  const destroy = () => {
    return hasPermission("delete_contract");
  };

  const viewStats = () => {
    return hasPermission("access_contract_stats");
  };

  const viewProcesses = () => {
    return hasPermission("access_contract_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_contract_process");
  };

  const updateComission = () => {
    return hasPermission("update_contract_comission");
  };

  const viewMargin = () => {
    return hasPermission("access_contract_margin");
  };

  return {
    viewAny,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewStats,
    viewProcesses,
    updateProcess,
    updateComission,
    viewMargin,
  };
};
