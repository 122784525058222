import { Checkbox } from "@/components/forms/Checkbox";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FormControl, FormDescription, FormItem, FormLabel } from "@/components/ui/form";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Asterisk } from "lucide-react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export function FormCheckboxGroup({
  name,
  label,
  description,
  options,
  fallback,
  defaultValue,
  className,
  requiredIndicator = false,
  ...rest
}) {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext();

  useEffect(() => {
    if (defaultValue && !getValues(name)) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <div className={cn("w-full", className)}>
          {(label || description) && (
            <div className="mb-4">
              {label && (
                <FormLabel className="text-base flex flex-row gap-1 items-center">
                  {typeof label === "string" ? i18n.t(label) : label}
                  {requiredIndicator ? <Asterisk size={16} className="text-destructive" /> : null}
                </FormLabel>
              )}
              {description && <FormDescription>{description}</FormDescription>}
            </div>
          )}
          {options.length === 0 && fallback ? fallback : null}
          {options.map((item) => (
            <Controller
              key={item.value}
              control={control}
              name={name}
              render={({ field, index }) => {
                return (
                  <FormItem
                    key={item.value + "_checkbox_" + index}
                    className="flex flex-row items-start space-x-3 space-y-0"
                  >
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(item.value)}
                        onCheckedChange={(checked) => {
                          const fieldValue = field.value
                            ? Array.isArray(field.value)
                              ? field.value
                              : [field.value]
                            : [];
                          return checked
                            ? field.onChange([...fieldValue, item.value])
                            : field.onChange(field.value?.filter((value) => value !== item.value));
                        }}
                        {...rest}
                      />
                    </FormControl>
                    <FormLabel className="font-normal">{item.name}</FormLabel>
                  </FormItem>
                );
              }}
            />
          ))}
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
}
