import { Button } from "@/components/Button";
import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { Combobox } from "@/components/forms/Combobox";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { assignCreditsToUser, bulkEditStatusCredits } from "@/features/credits/api/creditApi";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { getLeads } from "@/features/leads/api/leadApi";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/useFilters";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, User } from "lucide-react";
import { useContext, useEffect, useState } from "react";

export const CreditFilters = ({ table, withActions = true, withColumns = true }) => {
  const { hasAnyPermission } = useAuth();
  const creditPolicy = useCreditPolicy();
  const leadPolicy = useLeadPolicy();
  const tableSelected = table.getSelectedRowModel()?.flatRows;
  const queryClient = useQueryClient();
  const { statusOptions } = useContext(CreditStatusContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const { filter, setFilter } = useFilters(table);

  const [showMassActions, setShowMassActions] = useState(false);
  const [leadOptions, setLeadOptions] = useState([]);
  const [assignedUserId, setAssignedUserId] = useState(undefined);

  const {
    isLoading: isLoadingLeads,
    data: leads,
    error: errorLeads,
  } = useQuery({
    queryKey: ["leads"],
    queryFn: () => getLeads({ hasCredit: true }),
    staleTime: 1000 * 60 * 5,
    enabled: leadPolicy.viewAny() && creditPolicy.viewAll(),
  });

  const assignCreditsToUserMutation = useMutation({
    mutationFn: assignCreditsToUser,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie przypisano kredyty do użytkownika" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["credits"] });
    },
  });

  const bulkEditStatusCreditsMutation = useMutation({
    mutationFn: bulkEditStatusCredits,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zmieniono status" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["credits"] });
    },
  });

  const handleAssignCreditsToUser = (userId) => {
    if (tableSelected?.length > 0) {
      assignCreditsToUserMutation.mutate({ userId, creditIds: tableSelected.map((s) => s.id) });
    }
  };

  const handleBulkEditStatus = (creditStatusId) => {
    if (tableSelected?.length > 0) {
      bulkEditStatusCreditsMutation.mutate({
        creditIds: tableSelected.map((s) => s.id),
        creditStatusId,
      });
    }
  };

  useEffect(() => {
    if (!isLoadingLeads && !errorLeads && leads) {
      setLeadOptions(leads.data.map((user) => ({ name: user.name, value: user.id })));
    }
  }, [isLoadingLeads, leads, errorLeads]);

  useEffect(() => {
    if (assignCreditsToUserMutation.status === "success" || assignCreditsToUserMutation.status === "error") {
      const timeout = setTimeout(() => {
        setAssignedUserId(undefined);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [assignCreditsToUserMutation.status]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-3 mb-3">
        <div className="flex flex-row gap-3 w-full lg:max-w-sm">
          <ClearFiltersButton table={table} />
          <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
        </div>
        <MultipleSelectFilter
          options={statusOptions}
          setValue={(value) => setFilter("status", value)}
          title="Status"
          value={filter("status")}
        />
        <VoivodeshipFilter filter={filter} setFilter={setFilter} />
        <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
        {creditPolicy.viewAll() && (
          <MultipleSelectFilter
            options={appendEmptyOption(userOptions, "Nieprzypisane")}
            setValue={(value) => setFilter("user", value)}
            title="Użytkownik"
            isLoading={isLoadingUsers}
            value={filter("user")}
          />
        )}
        {leadPolicy.viewAny() && creditPolicy.viewAll() && (
          <MultipleSelectFilter
            options={leadOptions}
            setValue={(value) => setFilter("lead", value)}
            title="Kontakt"
            isLoading={isLoadingLeads}
            value={filter("lead")}
          />
        )}
        {withColumns && <ColumnFilter table={table} />}
        {withActions &&
          hasAnyPermission([
            "bulk_delete_credits",
            "bulk_unassign_credit_user",
            "bulk_edit_credit_status",
            "assign_user_credits",
          ]) && (
            <Button
              variant="outline"
              title="Akcje masowe"
              className="w-full lg:w-fit"
              onClick={() => setShowMassActions(!showMassActions)}
            />
          )}
      </div>
      {showMassActions && (
        <div className="flex flex-row flex-wrap gap-3 mb-3">
          {creditPolicy.bulkEditStatus() && (
            <Select
              className="flex-1 lg:flex-initial w-fit font-medium"
              placeholder="Zmien status zaznaczonym"
              leftIcon={<Pencil size={16} className="mr-1" />}
              disabled={tableSelected.length === 0}
              options={statusOptions}
              setValue={handleBulkEditStatus}
              isLoading={bulkEditStatusCreditsMutation.isPending}
            />
          )}
          {creditPolicy.assignUserCredits() && (
            <Combobox
              placeholder="Przypisz do użytkownika"
              className="flex-1 lg:flex-initial lg:w-80 justify-center"
              variant="outline"
              leftIcon={<User size={16} />}
              options={userOptions}
              value={assignedUserId}
              disabled={tableSelected.length === 0}
              isLoading={isLoadingUsers || assignCreditsToUserMutation.isPending}
              setValue={handleAssignCreditsToUser}
            />
          )}
        </div>
      )}
    </div>
  );
};
