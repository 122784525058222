import { Button } from "@/components/Button";
import { Combobox } from "@/components/forms/Combobox";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useDownloadCalculationOffer } from "@/features/calculations/hooks/useDownloadCalculationOffer";
import { CalculationPriceSummaryCardContent } from "@/features/calculators/components/CalculationPriceSummaryCardContent";
import { CalculatorFieldType } from "@/features/calculators/enums/CalculatorFieldTypeEnum";
import { getClients } from "@/features/clients/api/clientApi";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { createLeadCalculation, getLeads } from "@/features/leads/api/leadApi";
import { useLeadCalculationPolicy } from "@/features/leads/policies/useLeadCalculationPolicy";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { ProductsContext } from "@/features/products/context/ProductsContext";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { ChevronLeft, ClipboardPen, CornerLeftUp, FileDown, UserPlus } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CalculationSummaryDialog = ({ showPrice, setShowPrice, calculation }) => {
  const { hasPermission } = useAuth();
  const leadCalculationPolicy = useLeadCalculationPolicy();
  const leadPolicy = useLeadPolicy();
  const productPolicy = useProductPolicy();
  const clientPolicy = useClientPolicy();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { products } = useContext(ProductsContext);
  const { handleDownloadPdf, offerState } = useDownloadCalculationOffer();

  const [leadClientOptions, setLeadClientOptions] = useState([]);
  const [createdCalculation, setCreatedCalculation] = useState(null);

  let productsWithEmptyQuantity =
    calculation &&
    productPolicy.create() &&
    calculation.sections
      .flatMap((section) => section.fields)
      .filter((field) => field.type === CalculatorFieldType.PRODUCT.value)
      .map((field) => {
        return products.find((product) => product.id === field.value);
      })
      .filter((field) => field?.total_quantity === 0);

  const filters = {
    active: true,
  };

  const {
    isLoading: isLoadingLeads,
    data: leads,
    error: errorLeads,
  } = useQuery({
    queryKey: ["leads", filters],
    queryFn: () => getLeads(filters),
    staleTime: 1000 * 60 * 5,
    enabled: showPrice && leadPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingClients,
    data: clients,
    error: errorClients,
  } = useQuery({
    queryKey: ["clients", filters],
    queryFn: () => getClients(filters),
    staleTime: 1000 * 60 * 5,
    enabled: showPrice && clientPolicy.viewAny(),
  });

  const createLeadCalculationMutation = useMutation({
    mutationFn: createLeadCalculation,
  });

  const createCalculation = (leadId) => {
    const lead = leadClientOptions.find((lead) => lead.value === leadId);
    const data = {
      calculator: calculation,
      net_price: calculation.netPrice,
      gross_price: calculation.grossPrice,
      vat: calculation.vat,
      name: `Kalkulacja - ${lead.name} - ${format(new Date(), "dd.MM.yyyy")}`,
      comission: calculation.comission,
      margin: calculation.marginValue,
    };
    createLeadCalculationMutation.mutate(
      { leadId, data },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ["lead", leadId, "calculations"] });
          queryClient.invalidateQueries({ queryKey: ["lead", leadId], exact: true });
          if (res.ok) {
            toast({ title: <SuccessToast title="Zapisano pomyślnie." /> });
            setCreatedCalculation(res.data);
          } else {
            toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
          }
        },
      },
    );
  };

  const handleOpenChange = () => {
    createLeadCalculationMutation.reset();
    setShowPrice(!showPrice);
  };

  const getFieldValue = (field) => {
    let value = null;
    let quantity = null;
    if (!field.value || field.value === "null" || field.value?.length === 0) return { value, quantity };

    switch (field.type) {
      case CalculatorFieldType.LIST.value: {
        value = field.value;
        break;
      }
      case CalculatorFieldType.PRODUCT.value: {
        const product = products.find((product) => product.id === field.value);
        value = product?.name;
        break;
      }
      case CalculatorFieldType.MULTIPLE.value: {
        value = field.value.join(", ");
        break;
      }
      case CalculatorFieldType.ADDITIONAL.value: {
        value = field.value.map((val) => val.name).join(", ");
        break;
      }
      case CalculatorFieldType.NUMBER.value:
        value = field.value;
        break;
      default:
        break;
    }

    if (field.has_quantity && field.value && field.value !== "null") {
      quantity = field.quantity;
    }

    return { value, quantity };
  };

  const handleRedirectToSurveys = () => {
    navigate(`${routes.calculations}/${createdCalculation.id}/ankiety`);
  };

  useEffect(() => {
    setLeadClientOptions(() => []);
    if (!isLoadingLeads && !errorLeads && leads) {
      setLeadClientOptions((prev) => [...prev, ...leads.data.map((lead) => ({ name: lead.name, value: lead.id }))]);
    }
    if (!isLoadingClients && !errorClients && clients) {
      setLeadClientOptions((prev) => [
        ...prev,
        ...clients.data.map((client) => ({ name: client.name, value: client.id })),
      ]);
    }
  }, [isLoadingLeads, leads, errorLeads, errorClients, clients, isLoadingClients]);

  if (!calculation) return null;

  return (
    <Dialog open={showPrice} onOpenChange={handleOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Podsumowanie")}</DialogTitle>
          <DialogDescription>
            {i18n.t("Podsumowanie kalkulacji, możesz ją zapisać lub wrócić do kalkulatora.")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="flex max-h-[60vh] flex-col">
          <div className="w-full border-none mt-5 px-5">
            <CalculationPriceSummaryCardContent calculator={calculation} />
            {hasPermission("access_contract_margin") && (
              <div className="flex flex-col px-3">
                <Accordion collapsible defaultValue={["summary"]}>
                  <AccordionItem value="summary">
                    <AccordionTrigger className="hover:no-underline">
                      <span className="flex flex-row gap-5 items-center">
                        <p className="text-base font-bold">{i18n.t("Zestawienie kosztów")}</p>
                      </span>
                    </AccordionTrigger>
                    <AccordionContent className="flex flex-col gap-2">
                      {calculation?.sections?.flatMap((section) => section.fields).length > 0 && (
                        <h2 className="text-muted-foreground font-semibold">{i18n.t("Pola")}</h2>
                      )}
                      {calculation?.sections?.flatMap((section) =>
                        section.fields
                          .filter(
                            (field) =>
                              !!field.calculable &&
                              field.type !== CalculatorFieldType.VAT.value &&
                              field.type !== CalculatorFieldType.COMISSION.value,
                          )
                          .map((field) => {
                            if (field.calculatedPrice === 0) return null;
                            const { value, quantity } = getFieldValue(field);
                            if (field.bundledProducts && field.bundledProducts.length > 0) {
                              return (
                                <div key={field.name}>
                                  <div className="flex flex-row justify-between items-center">
                                    {quantity > 1 ? (
                                      <p className="text-base flex flex-row items-center gap-1">
                                        {`${field.name} (${quantity} x ${value}) (${i18n.t("zestaw")})`}
                                      </p>
                                    ) : (
                                      <p className="text-base flex flex-row items-center gap-1">
                                        {`${field.name} (${value}) (${i18n.t("zestaw")})`}
                                      </p>
                                    )}
                                    <p className="text-base text-right">{`${formatMoney(
                                      field.calculatedPrice,
                                    )} (+${formatMoney(field.marginValue)} marży)`}</p>
                                  </div>
                                  {field.bundledProducts.map((bundledProduct) => (
                                    <div
                                      key={bundledProduct.name}
                                      className="ml-5 flex flex-row justify-between items-center"
                                    >
                                      <p className="text-base flex flex-row items-center gap-1">
                                        <CornerLeftUp size={16} />
                                        {`${bundledProduct.quantity} x ${bundledProduct.name}`}
                                      </p>
                                      <p className="text-base text-right">{`${formatMoney(
                                        bundledProduct.calculatedPrice,
                                      )} (+${formatMoney(bundledProduct.marginValue)} marży)`}</p>
                                    </div>
                                  ))}
                                </div>
                              );
                            } else
                              return (
                                <div key={field.name} className="flex flex-row justify-between items-center">
                                  {quantity > 1 ? (
                                    <p className="text-base">{`${field.name} (${quantity} x ${value})`}</p>
                                  ) : (
                                    <p className="text-base">{`${field.name} (${value})`}</p>
                                  )}
                                  <p className="text-base text-right">{`${formatMoney(
                                    field.calculatedPrice,
                                  )} (+${formatMoney(field.marginValue)} marży)`}</p>
                                </div>
                              );
                          }),
                      )}
                      {calculation?.additionalCosts?.filter(({ calculatedPrice }) => calculatedPrice !== 0)?.length >
                        0 && <h2 className="text-muted-foreground font-semibold">{i18n.t("Koszty dodatkowe")}</h2>}
                      {calculation?.additionalCosts
                        ?.filter(({ calculatedPrice }) => calculatedPrice !== 0)
                        .map(({ name, calculatedPrice, marginValue }) => (
                          <div key={name} className="flex flex-row justify-between items-center">
                            <p className="text-base">{name}</p>
                            <p className="text-base text-right">{`${formatMoney(calculatedPrice)} (${formatMoney(
                              marginValue,
                            )} marży)`}</p>
                          </div>
                        ))}
                      {calculation?.discounts?.filter(({ calculatedPrice }) => calculatedPrice !== 0)?.length > 0 && (
                        <h2 className="text-muted-foreground font-semibold">{i18n.t("Rabaty")}</h2>
                      )}
                      {calculation?.discounts
                        ?.filter(({ calculatedPrice }) => calculatedPrice !== 0)
                        ?.map(({ name, calculatedPrice }) => (
                          <div key={name} className="flex flex-row justify-between items-center">
                            <p className="text-base">{name}</p>
                            <p className="text-base text-right">{`- ${formatMoney(calculatedPrice)}`}</p>
                          </div>
                        ))}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
            )}
            <div className="flex flex-row justify-center items-center w-full gap-3 px-8 mt-5">
              {leadCalculationPolicy.create() && (
                <div className="flex flex-col gap-2 items-center flex-1">
                  <Combobox
                    placeholder="Przypisz do kontaktu"
                    className="w-full gap-2 justify-center"
                    variant={createLeadCalculationMutation.isSuccess ? "success" : "default"}
                    leftIcon={<UserPlus size={20} />}
                    options={leadClientOptions}
                    isLoading={isLoadingLeads || createLeadCalculationMutation.isPending}
                    setValue={(value) => {
                      createCalculation(value);
                    }}
                  />
                  {createLeadCalculationMutation.isSuccess && createdCalculation?.surveys_count > 0 && (
                    <Button
                      type="button"
                      className="w-full my-0 h-full"
                      variant="outline"
                      title="Przejdź do ankiety"
                      leftIcon={<ClipboardPen size={20} />}
                      onClick={handleRedirectToSurveys}
                    />
                  )}
                  {createLeadCalculationMutation.isSuccess && (
                    <Button
                      type="button"
                      className="w-full my-0 h-full"
                      variant="outline"
                      title="Pobierz PDF"
                      leftIcon={<FileDown size={20} />}
                      isLoading={offerState === "loading"}
                      onClick={() => handleDownloadPdf(createdCalculation)}
                    />
                  )}
                  {productsWithEmptyQuantity.length > 0 && (
                    <p className="text-destructive text-sm font-semibold text-center">
                      {i18n.t(
                        "Uwaga! Kalkulacja posiada produkty których stan magazynowy wynosi 0 lub są nie dodane do magazynów!",
                      )}
                    </p>
                  )}
                </div>
              )}
              <Button
                type="button"
                className="my-0 h-full flex-1"
                variant="outline"
                title="Powrót do kalkulatora"
                leftIcon={<ChevronLeft size={20} />}
                isLoading={false}
                onClick={handleOpenChange}
              />
            </div>
          </div>
        </ScrollArea>
        <DialogFooter className="mt-5"></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
