import api from "@/api/api";
import {
  BILLING_GROUPS_ENDPOINT,
  BILLING_SETTING_RULES_ENDPOINT,
  BILLING_SETTINGS_ENDPOINT,
  BILLINGS_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";

export const getBillings = (filters) => api.get(BILLINGS_ENDPOINT, transformQueryFilters(filters));

export const createBilling = ({ data }) => api.post(BILLINGS_ENDPOINT, data);

export const updateBilling = ({ billingId, data }) => api.patch(BILLINGS_ENDPOINT + billingId, data);

export const deleteBilling = ({ billingId }) => api.delete(BILLINGS_ENDPOINT + billingId);

export const getBillingGroups = (filters) => api.get(BILLING_GROUPS_ENDPOINT, transformQueryFilters(filters));

export const createBillingGroup = ({ data }) =>
  api.post(BILLING_GROUPS_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateBillingGroup = ({ billingGroupId, data }) => api.put(BILLING_GROUPS_ENDPOINT + billingGroupId, data);

export const deleteBillingGroup = ({ billingGroupId }) => api.delete(BILLING_GROUPS_ENDPOINT + billingGroupId);

export const getBillingSettings = (filters) => api.get(BILLING_SETTINGS_ENDPOINT, transformQueryFilters(filters));

export const getBillingSetting = (billingSettingId) => api.get(BILLING_SETTINGS_ENDPOINT + billingSettingId);

export const createBillingSetting = ({ data }) => api.post(BILLING_SETTINGS_ENDPOINT, data);

export const updateBillingSetting = ({ billingSettingId, data }) =>
  api.patch(BILLING_SETTINGS_ENDPOINT + billingSettingId, data);

export const deleteBillingSetting = ({ billingSettingId }) => api.delete(BILLING_SETTINGS_ENDPOINT + billingSettingId);

export const createBillingSettingRule = ({ billingSettingId, data }) =>
  api.post(BILLING_SETTINGS_ENDPOINT + billingSettingId + BILLING_SETTING_RULES_ENDPOINT, data);

export const updateBillingSettingRule = ({ billingSettingId, billingSettingRuleId, data }) =>
  api.patch(BILLING_SETTINGS_ENDPOINT + billingSettingId + BILLING_SETTING_RULES_ENDPOINT + billingSettingRuleId, data);

export const deleteBillingSettingRule = ({ billingSettingId, billingSettingRuleId }) =>
  api.delete(BILLING_SETTINGS_ENDPOINT + billingSettingId + BILLING_SETTING_RULES_ENDPOINT + billingSettingRuleId);
