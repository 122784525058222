import { useAuth } from "@/features/auth/hooks/useAuth";

export const useUserCostPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_user_costs");
  };

  const create = () => {
    return hasPermission("create_user_cost");
  };

  const destroy = () => {
    return hasPermission("delete_user_cost");
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
