import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { BillingGroupStatus } from "@/features/billings/enums/BillingGroupStatusEnum";
import { useBillingGroupPolicy } from "@/features/billings/policies/useBillingGroupPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { useFilters } from "@/hooks/useFilters";
import { useContext } from "react";

export const BillingGroupFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);
  const billingGroupPolicy = useBillingGroupPolicy();
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-3 mb-3">
        <div className="flex flex-row gap-3 w-full lg:max-w-sm">
          <ClearFiltersButton table={table} />
          <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
        </div>
        <MultipleSelectFilter
          options={BillingGroupStatus.getValues()}
          setValue={(value) => setFilter("status", value)}
          title="Status"
          value={filter("status")}
        />
        {billingGroupPolicy.viewAll() && (
          <MultipleSelectFilter
            options={userOptions}
            setValue={(value) => setFilter("user", value)}
            title="Użytkownik"
            isLoading={isLoadingUsers}
            value={filter("user")}
          />
        )}
        <ColumnFilter table={table} />
      </div>
    </div>
  );
};
