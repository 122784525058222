import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useAuditPolicy } from "@/features/audits/policies/useAuditPolicy";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { useOrderPolicy } from "@/features/orders/policies/useOrderPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const useTaskableTableColumn = (task) => {
  const contractPolicy = useContractPolicy();
  const leadPolicy = useLeadPolicy();
  const installationPolicy = useInstallationPolicy();
  const applicationPolicy = useApplicationPolicy();
  const auditPolicy = useAuditPolicy();
  const clientPolicy = useClientPolicy();
  const creditPolicy = useCreditPolicy();
  const installationServicePolicy = useInstallationServicePolicy();
  const orderPolicy = useOrderPolicy();

  const taskable = task.taskable;
  const taskableType = task.taskable_type;
  if (taskableType === "Lead")
    if (leadPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.leads}/${taskable.id}`}>
          {i18n.t("Kontakt")} <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          {i18n.t("Kontakt")} <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Contract")
    if (contractPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.contracts}/${taskable.id}`}>
          {i18n.t("Umowa")} <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          {i18n.t("Umowa")} <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Audit")
    if (auditPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.audits}/${taskable.id}`}>
          <span className="font-semibold">{i18n.t("Audyt")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Audyt")}</span>;
    }
  else if (taskableType === "Credit")
    if (creditPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.credits}/${taskable.id}`}>
          <span className="font-semibold">{i18n.t("Kredyt")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Kredyt")}</span>;
    }
  else if (taskableType === "Installation")
    if (installationPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.installations}/${taskable.id}`}>
          {i18n.t("Montaż")} <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          {i18n.t("Montaż")} <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "InstallationService")
    if (installationServicePolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.installationServices}/${taskable.id}`}>
          {i18n.t("Serwis")} <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          {i18n.t("Serwis")} <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Order")
    if (orderPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.orders}/${taskable.id}`}>
          <span className="font-semibold">{i18n.t("Zamówienie")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Zamówienie")}</span>;
    }
  else if (taskableType === "Application")
    if (applicationPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.applications}/${taskable.id}`}>
          <span className="font-semibold">{i18n.t("Wniosek")}</span>
        </Link>
      );
    } else {
      return <span className="font-semibold">{i18n.t("Wniosek")}</span>;
    }
  else if (taskableType === "Client")
    if (clientPolicy.view()) {
      return (
        <Link className="hover:underline whitespace-nowrap" to={`${routes.clients}/${taskable.id}`}>
          {i18n.t("Klient")} <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          {i18n.t("Klient")} <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else {
    return i18n.t("-");
  }
};
