import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CustomFieldCreateDialog } from "@/features/customFields/components/CustomFieldCreateDialog";
import { CustomFieldSettings } from "@/features/customFields/components/CustomFieldSettings";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { LeadStatusCreateDialog } from "@/features/leads/components/Settings/Statuses/LeadStatusCreateDialog";
import { LeadStatuses } from "@/features/leads/components/Settings/Statuses/LeadStatuses";
import { LeadSourceCreateDialog } from "@/features/leads/components/Sources/LeadSourceCreateDialog";
import { LeadSources } from "@/features/leads/components/Sources/LeadSources";
import { useLeadSourcePolicy } from "@/features/leads/policies/useLeadSourcePolicy";
import { useLeadStatusPolicy } from "@/features/leads/policies/useLeadStatusPolicy";
import { ProcessCreateDialog } from "@/features/processes/components/ProcessCreateDialog";
import { ProcessSettings } from "@/features/processes/components/ProcessSettings";
import { useProcessPolicy } from "@/features/processes/policies/useProcessPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const LeadSettings = () => {
  const { activeTab, setActiveTab } = useTabs("custom_fields");
  const leadSourcePolicy = useLeadSourcePolicy();
  const leadStatusPolicy = useLeadStatusPolicy();
  const processPolicy = useProcessPolicy();
  const customFieldPolicy = useCustomFieldPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {customFieldPolicy.create() && <TabsTrigger value="custom_fields">{i18n.t("Pola dodatkowe")}</TabsTrigger>}
        {leadStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {leadSourcePolicy.create() && <TabsTrigger value="sources">{i18n.t("Źródła")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
      </TabsList>
      {customFieldPolicy.create() && (
        <TabsContent value="custom_fields">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pola dodatkowe")}</h2>
            <CustomFieldCreateDialog appliesTo="Lead" />
          </div>
          <CustomFieldSettings appliesTo="Lead" />
        </TabsContent>
      )}
      {leadStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <LeadStatusCreateDialog />
          </div>
          <LeadStatuses />
        </TabsContent>
      )}
      {leadSourcePolicy.create() && (
        <TabsContent value="sources">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Źródła")}</h2>
            <LeadSourceCreateDialog />
          </div>
          <LeadSources />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Lead" />
          </div>
          <ProcessSettings model="Lead" />
        </TabsContent>
      )}
    </Tabs>
  );
};
