import { Button } from "@/components/Button";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { useContractFillSchema } from "@/features/contracts/hooks/useContractFillSchema";
import i18n from "@/i18n";
import { Info } from "lucide-react";

export function ContractTemplateInformationDialog() {
  const { groupedSchema } = useContractFillSchema();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast({ title: <SuccessToast title="Skopiowano do schowka" /> });
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="outline" title="Dostępne pola" leftIcon={<Info size={20} />} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{i18n.t("Dostępne pola")}</AlertDialogTitle>
          <AlertDialogDescription>
            {i18n.t(
              "Poniżej znajduje się lista dostępnych pól które system jest w stanie wypełnić. Umieść je w identyfikatorach pól w formularzu PDF",
            )}
            <ScrollArea className="w-full flex max-h-96 flex-col">
              <div className="flex flex-col gap-4 text-primary mt-5 mx-1">
                {Object.keys(groupedSchema).map((groupKey, index) => (
                  <div className="flex flex-col gap-1 flex-wrap" key={"group" + String(index)}>
                    <p className="font-semibold text-muted-foreground">{groupKey}</p>
                    <div className="flex flex-row flex-wrap gap-2 text-primary">
                      {groupKey === "Kalkulacja" ? (
                        <span className="text-foreground">
                          {i18n.t(
                            "Pola kalkulacji są zmienne i zależą od kalkulatora z którego pochodzi kalkulacja, pełna lista znajduje się przy nazwie kalkulatora w tabeli poniżej.",
                          )}
                        </span>
                      ) : (
                        Object.keys(groupedSchema[groupKey]).map((key, index) => (
                          <span
                            title="Kopiuj"
                            onClick={() => handleCopy(key)}
                            key={index}
                            className="cursor-pointer border px-2 py-0.5 rounded-md hover:border-primary"
                          >
                            {key}
                          </span>
                        ))
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>{i18n.t("Rozumiem")}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
