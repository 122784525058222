import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ProductsContext } from "@/features/products/context/ProductsContext";
import { createWarehouseProduct } from "@/features/warehouses/api/warehouseApi";
import { useWarehouseProductPolicy } from "@/features/warehouses/policies/useWarehouseProductPolicy";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PackagePlus, Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

export const WarehouseProductCreateDialog = ({ warehouse }) => {
  const queryClient = useQueryClient();
  const { id: warehouseId } = useParams();
  const warehouseProductPolicy = useWarehouseProductPolicy();

  const [isOpen, setIsOpen] = useState(false);
  const { productOptions } = useContext(ProductsContext);

  const validationSchema = Yup.object({
    product_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    quantity: Yup.number(i18n.t("Pole musi być liczbą"))
      .required(i18n.t("Pole jest wymagane"))
      .when([], {
        is: !!warehouse.external,
        then: () => Yup.number().nullable().optional(),
      }),
  });

  const defaultValues = {
    product_id: undefined,
    quantity: undefined,
  };

  const onSubmit = (data) => {
    createWarehouseProductMutation.mutate({ warehouseId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createWarehouseProductMutation = useMutation({
    mutationFn: createWarehouseProduct,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["warehouses"] });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id], exact: true });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id, "products"] });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id, "operations"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Produkt został dodany do magazynu." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!warehouseProductPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj produkt" leftIcon={<PackagePlus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj produkt do magazynu")}</DialogTitle>
          <DialogDescription>{warehouse.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormCombobox name="product_id" label="Produkt" options={productOptions} />
            {!warehouse.external && (
              <FormNumberField label="Ilość" placeholder="Ilość" name="quantity" positiveOnly={true} />
            )}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createWarehouseProductMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
