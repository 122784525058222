import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { getCalculators } from "@/features/calculators/api/calculatorApi";
import { useCalculatorPolicy } from "@/features/calculators/policies/useCalculatorPolicy";
import { useFilters } from "@/hooks/useFilters";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const CalculationFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);
  const calculatorPolicy = useCalculatorPolicy();
  const [calculatorOptions, setCalculatorOptions] = useState([]);

  const {
    data: calculators,
    isLoading: isLoadingCalculators,
    error: errorCalculators,
  } = useQuery({
    queryKey: ["calculators"],
    queryFn: getCalculators,
    staleTime: 1000 * 60 * 5,
    enabled: calculatorPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingCalculators && !errorCalculators && calculators) {
      setCalculatorOptions(
        calculators.data.map((calculator) => ({
          name: calculator.name,
          value: calculator.id,
        })),
      );
    }
  }, [isLoadingCalculators, errorCalculators, calculators]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      {calculatorPolicy.viewAny() && (
        <SelectFilter
          options={calculatorOptions}
          setValue={(value) => setFilter("calculator", value)}
          title="Kalkulator"
          isLoading={isLoadingCalculators}
          value={filter("calculator")}
        />
      )}
      <ColumnFilter table={table} />
    </div>
  );
};
