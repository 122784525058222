import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { EditableSelectCell } from "@/components/Table/EditableSelectCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { BillingEditDialog } from "@/features/billings/components/BillingEditDialog";
import { BillingStatus } from "@/features/billings/enums/BillingStatusEnum";
import { useBillingPolicy } from "@/features/billings/policies/useBillingPolicy";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useBillingsTableColumns = (handleDelete, handleEdit) => {
  const billingPolicy = useBillingPolicy();
  const contractPolicy = useContractPolicy();
  const { hasPermission } = useAuth();

  const columns = [
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: <span>{i18n.t("Status")}</span>,
      cell: ({ row }) => {
        const billing = row.original;
        const handleChangeStatus = (value) => {
          if (value !== billing.status) handleEdit(billing.id, { ...billing, status: value });
        };
        if (
          billingPolicy.update() &&
          !billing.billing_group &&
          BillingStatus.getValuesForEdit().includes(billing.status)
        ) {
          return (
            <div className="w-full">
              <EditableSelectCell
                className={BillingStatus[billing.status].color}
                onSave={handleChangeStatus}
                options={BillingStatus.getForEdit(hasPermission)}
                value={billing.status}
              />
            </div>
          );
        } else {
          return (
            <span className="flex flex-row gap-1 items-center font-medium">
              {BillingStatus[billing.status].icon()}
              {BillingStatus[billing.status].name}
            </span>
          );
        }
      },
    },
    {
      id: "contract",
      name: "Umowa",
      accessorKey: "contract",
      header: <span>{i18n.t("Umowa")}</span>,
      cell: ({ row }) => {
        const contract = row.original?.contract;
        if (contract) {
          if (contractPolicy.view()) {
            return (
              <Link
                className="flex flex-row gap-1 hover:underline whitespace-nowrap"
                to={`${routes.contracts}/${contract?.id}`}
              >
                <span>{i18n.t("Umowa")}</span>
                <span className="font-semibold">{contract?.identifier}</span>
              </Link>
            );
          } else {
            return (
              <>
                <span>{i18n.t("Umowa")}</span>
                <span className="font-semibold">{contract?.identifier}</span>
              </>
            );
          }
        } else {
          return i18n.t("Brak");
        }
      },
    },
    {
      id: "amount",
      name: "Kwota",
      accessorKey: "amount",
      header: <span>{i18n.t("Kwota")}</span>,
      cell: ({ row }) => {
        const billing = row.original;
        if (billing.corrected_amount && billing.corrected_amount !== billing.amount) {
          return (
            <span className="flex flex-col gap-1 items-start">
              <span className="line-through text-muted-foreground" title={formatMoney(billing.corrected_amount)}>
                {formatMoney(billing.corrected_amount)}
              </span>
              <span className="">{formatMoney(billing.amount)}</span>
              <span title={billing.correction_reason} className="text-muted-foreground truncate max-w-96">{`${i18n.t(
                "Powód zmiany",
              )}: ${billing.correction_reason}`}</span>
            </span>
          );
        } else return <span className="flex flex-row gap-1 items-center">{formatMoney(billing.amount)}</span>;
      },
    },
    {
      id: "description",
      name: "Opis",
      accessorKey: "description",
      header: <span>{i18n.t("Opis")}</span>,
      cell: ({ row }) => row.getValue("description"),
    },
    billingPolicy.viewAll()
      ? {
          id: "user_id",
          name: "Użytkownik",
          accessorKey: "user_id",
          enableSorting: false,
          header: () => <div className="px-4">{i18n.t("Użytkownik")}</div>,
          cell: ({ row }) => {
            const user = row.original.user;
            return <UserAvatarLink user={user} />;
          },
        }
      : { id: "user_id" },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: <span className="text-right"> {i18n.t("Data utworzenia")} </span>,
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const billing = row.original;
        if (!billingPolicy.destroy() && !billingPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {billingPolicy.update() && !billing.billing_group && (
                <>
                  <BillingEditDialog
                    billing={billing}
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Pencil size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Edytuj")}</span>
                      </DropdownMenuItem>
                    }
                  />
                  <DropdownMenuSeparator />
                </>
              )}
              {billingPolicy.destroy() && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(billing.id)}
                  message="Czy na pewno chcesz usunąć to rozliczenie? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
