import { Loader } from "@/components/Loader/Loader";
import { CardContent } from "@/components/ui/card";
import { ChartContainer } from "@/components/ui/chart";
import { isEmpty } from "@/helpers/isEmpty";
import { cn } from "@/lib/utils";
import { LineChart } from "lucide-react";

export const ChartContainerWrapper = ({
  config,
  className,
  data,
  children,
  title = "Brak danych do pokazania",
  isLoading = false,
}) => {
  return isLoading ? (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      <CardContent className="flex flex-col items-center text-center p-6">
        <Loader />
      </CardContent>
    </div>
  ) : data?.length === 0 || isEmpty(data) ? (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      <CardContent className="flex flex-col items-center text-center p-6">
        <LineChart className="h-12 w-12 text-muted-foreground mb-4" />
        <h3 className="text-lg font-semibold text-muted-foreground mb-2">{title}</h3>
      </CardContent>
    </div>
  ) : (
    <ChartContainer config={config} className={className}>
      {children}
    </ChartContainer>
  );
};
