import { ApplicationDetailsPage } from "@/features/applications/pages/ApplicationDetailsPage";
import { ApplicationsPage } from "@/features/applications/pages/ApplicationsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const ApplicationRoutes = () => (
  <>
    <Route exact path={routes.applications} element={<ApplicationsPage />} />
    <Route exact path={routes.application} element={<ApplicationDetailsPage />} />
  </>
);
