import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { getContracts } from "@/features/contracts/api/contractApi";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { getInstallationCrews } from "@/features/installations/api/installationApi";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { useInstallationCrewPolicy } from "@/features/installations/policies/useInstallationCrewPolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/useFilters";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";

export const InstallationFilters = ({ table }) => {
  const installationPolicy = useInstallationPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const contractPolicy = useContractPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(InstallationStatusContext);
  const { typeOptions, isLoading: isLoadingTypes } = useContext(InstallationTypeContext);
  const { filter, setFilter } = useFilters(table);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const [contractOptions, setContractOptions] = useState([]);
  const [crewOptions, setCrewOptions] = useState([]);

  const {
    isLoading: isLoadingContracts,
    data: contracts,
    error: errorContracts,
  } = useQuery({
    queryKey: ["contracts", { hasInstallations: true }],
    queryFn: () => getContracts({ hasInstallations: true }),
    staleTime: 1000 * 60 * 5,
    enabled: installationPolicy.viewAll() && contractPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingCrews,
    data: crews,
    error: errorCrews,
  } = useQuery({
    queryKey: ["installationCrews"],
    queryFn: getInstallationCrews,
    enabled: installationCrewPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingCrews && !errorCrews && crews) {
      setCrewOptions(crews.data.map((crew) => ({ name: crew.name, value: crew.id })));
    }
  }, [isLoadingCrews, errorCrews, crews]);

  useEffect(() => {
    if (!isLoadingContracts && !errorContracts && contracts) {
      setContractOptions(
        contracts.data.map((contract) => ({
          name: `${contract.name} (${contract.identifier})`,
          value: contract.id,
        })),
      );
    }
  }, [isLoadingContracts, errorContracts, contracts]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={statusOptions}
        setValue={(value) => setFilter("status", value)}
        title="Status"
        isLoading={isLoadingStatuses}
        value={filter("status")}
      />
      <MultipleSelectFilter
        options={typeOptions}
        setValue={(value) => setFilter("type", value)}
        title="Typ"
        isLoading={isLoadingTypes}
        value={filter("type")}
      />
      <VoivodeshipFilter filter={filter} setFilter={setFilter} />
      <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
      {installationPolicy.viewAll() && (
        <MultipleSelectFilter
          options={userOptions}
          setValue={(value) => setFilter("user", value)}
          title="Użytkownik"
          isLoading={isLoadingUsers}
          value={filter("user")}
        />
      )}
      {installationCrewPolicy.viewAny() && (
        <SelectFilter
          options={appendEmptyOption(crewOptions, "Brak ekipy")}
          setValue={(value) => setFilter("crew", value)}
          title="Ekipa"
          isLoading={isLoadingCrews}
          value={filter("crew")}
        />
      )}
      {installationPolicy.viewAll() && contractPolicy.viewAny() && (
        <MultipleSelectFilter
          options={contractOptions}
          setValue={(value) => setFilter("contract", value)}
          title="Umowa"
          isLoading={isLoadingContracts}
          value={filter("contract")}
        />
      )}
      <ColumnFilter table={table} />
    </div>
  );
};
