import { useAuth } from "@/features/auth/hooks/useAuth";
import { getCreditStatuses } from "@/features/credits/api/creditApi";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const CreditStatusContext = createContext();

export function CreditStatusProvider({ children }) {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const creditPolicy = useCreditPolicy();
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["creditStatuses"],
    queryFn: getCreditStatuses,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && creditPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setStatuses(response.data);
      setStatusOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <CreditStatusContext.Provider value={{ statuses, isLoading, statusOptions }}>
      {children}
    </CreditStatusContext.Provider>
  );
}
