import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/features/applications/context/ApplicationStatusContext";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { AuditStatusContext } from "@/features/audits/context/AuditStatusContext";
import { createAutomationTrigger } from "@/features/automations/api/automationApi";
import { AutomationEventType } from "@/features/automations/enums/AutomationEventTypeEnum";
import { useAutomationPolicy } from "@/features/automations/policies/useAutomationPolicy";
import { ContractStatusContext } from "@/features/contracts/context/ContractStatusContext";
import { ContractTypeContext } from "@/features/contracts/context/ContractTypeContext";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { InstallationStatusContext } from "@/features/installations/context/InstallationStatusContext";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { LeadStatusContext } from "@/features/leads/context/LeadStatusContext";
import { OrderStatus } from "@/features/orders/enums/OrderStatusEnum";
import { TaskStatus } from "@/features/tasks/enums/TaskStatusEnum";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  event_type: Yup.string().required(i18n.t("Pole jest wymagane")),
  conditions: Yup.object(),
});

export const AutomationTriggerCreateDialog = ({ automation }) => {
  const queryClient = useQueryClient();
  const automationPolicy = useAutomationPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createAutomationTriggerMutation.mutate({ automationId: automation.id, data });
  };

  const defaultValues = {
    event_type: undefined,
    conditions: {},
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });
  const { event_type } = form.watch();

  const createAutomationTriggerMutation = useMutation({
    mutationFn: createAutomationTrigger,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["automation", automation.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Wyzwalacz dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!automationPolicy.update()) return i18n.t("Brak wyzwalacza");

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj wyzwalacz" leftIcon={<Plus size={20} />} className="w-full" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj wyzwalacz")}</DialogTitle>
          <DialogDescription>{automation.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect
              label="Typ wyzwalacza"
              name="event_type"
              autoComplete="off"
              options={AutomationEventType.getValues()}
            />
            {event_type && <AutomationTriggerConditionsField eventType={event_type} />}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createAutomationTriggerMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const AutomationTriggerConditionsField = ({ eventType }) => {
  const { statusOptions: contractStatusOptions, isLoading: isLoadingContractStatuses } =
    useContext(ContractStatusContext);
  const { typeOptions: contractTypeOptions, isLoading: isLoadingContractTypes } = useContext(ContractTypeContext);
  const { statusOptions: installationStatusOptions, isLoading: isLoadingInstallationStatuses } =
    useContext(InstallationStatusContext);
  const { typeOptions: installationTypeOptions, isLoading: isLoadingInstallationTypes } =
    useContext(InstallationTypeContext);
  const { statusOptions: applicationStatusOptions, isLoading: isLoadingApplicationStatuses } =
    useContext(ApplicationStatusContext);
  const { typeOptions: applicationTypeOptions, isLoading: isLoadingApplicationTypes } =
    useContext(ApplicationTypeContext);
  const { statusOptions: auditStatusOptions, isLoading: isLoadingAuditStatuses } = useContext(AuditStatusContext);
  const { statusOptions: creditStatusOptions, isLoading: isLoadingCreditStatuses } = useContext(CreditStatusContext);
  const { statusOptions: leadStatusOptions, isLoading: isLoadingLeadStatuses } = useContext(LeadStatusContext);

  switch (eventType) {
    case AutomationEventType.ContractStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={contractTypeOptions}
            isLoading={isLoadingContractTypes}
            label="Typ umowy"
            placeholder="Dowolny typ"
            required={false}
            name="conditions.type"
          />
          <FormCombobox
            options={contractStatusOptions}
            isLoading={isLoadingContractStatuses}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox
            options={contractStatusOptions}
            isLoading={isLoadingContractStatuses}
            label="Na status"
            name="conditions.to_status"
          />
        </>
      );
    case AutomationEventType.ContractCreated.value:
    case AutomationEventType.ContractMessageCreated.value:
    case AutomationEventType.ContractPaymentCreated.value:
      return (
        <>
          <FormCombobox
            options={contractTypeOptions}
            isLoading={isLoadingContractTypes}
            label="Typ umowy"
            placeholder="Dowolny typ"
            required={false}
            name="conditions.type"
          />
        </>
      );
    case AutomationEventType.InstallationStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={installationTypeOptions}
            isLoading={isLoadingInstallationTypes}
            label="Typ montażu"
            placeholder="Dowolny typ"
            required={false}
            name="conditions.type"
          />
          <FormCombobox
            options={installationStatusOptions}
            isLoading={isLoadingInstallationStatuses}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox
            options={installationStatusOptions}
            isLoading={isLoadingInstallationStatuses}
            label="Na status"
            name="conditions.to_status"
          />
        </>
      );
    case AutomationEventType.InstallationCreated.value:
      return (
        <>
          <FormCombobox
            options={installationTypeOptions}
            isLoading={isLoadingInstallationTypes}
            label="Typ montażu"
            placeholder="Dowolny typ"
            required={false}
            name="conditions.type"
          />
        </>
      );
    case AutomationEventType.ApplicationStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={applicationTypeOptions}
            isLoading={isLoadingApplicationTypes}
            label="Typ wniosku"
            placeholder="Dowolny typ"
            required={false}
            name="conditions.type"
          />
          <FormCombobox
            options={applicationStatusOptions}
            isLoading={isLoadingApplicationStatuses}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox
            options={applicationStatusOptions}
            isLoading={isLoadingApplicationStatuses}
            label="Na status"
            name="conditions.to_status"
          />
        </>
      );
    case AutomationEventType.ApplicationCreated.value:
      return (
        <>
          <FormCombobox
            options={applicationTypeOptions}
            isLoading={isLoadingApplicationTypes}
            label="Typ wniosku"
            placeholder="Dowolny typ"
            required={false}
            name="conditions.type"
          />
        </>
      );
    case AutomationEventType.AuditStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={auditStatusOptions}
            isLoading={isLoadingAuditStatuses}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox
            options={auditStatusOptions}
            isLoading={isLoadingAuditStatuses}
            label="Na status"
            name="conditions.to_status"
          />
        </>
      );
    case AutomationEventType.CreditStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={creditStatusOptions}
            isLoading={isLoadingCreditStatuses}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox
            options={creditStatusOptions}
            isLoading={isLoadingCreditStatuses}
            label="Na status"
            name="conditions.to_status"
          />
        </>
      );
    case AutomationEventType.LeadStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={leadStatusOptions}
            isLoading={isLoadingLeadStatuses}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox
            options={leadStatusOptions}
            isLoading={isLoadingLeadStatuses}
            label="Na status"
            name="conditions.to_status"
          />
        </>
      );
    case AutomationEventType.OrderStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={OrderStatus.getValues()}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox options={OrderStatus.getValues()} label="Na status" name="conditions.to_status" />
        </>
      );
    case AutomationEventType.TaskStatusChanged.value:
      return (
        <>
          <FormCombobox
            options={TaskStatus.getValues()}
            label="Ze statusu"
            placeholder="Dowolny status"
            required={false}
            name="conditions.from_status"
          />
          <FormCombobox options={TaskStatus.getValues()} label="Na status" name="conditions.to_status" />
        </>
      );
    case AutomationEventType.OrderCreated.value:
    case AutomationEventType.AuditCreated.value:
    case AutomationEventType.CreditCreated.value:
    case AutomationEventType.InstallationServiceCreated.value:
    case AutomationEventType.TaskCreated.value:
      return null;
    default:
      return i18n.t("Niewspierany wyzwalacz, skontaktuj się z administratorem");
  }
};
