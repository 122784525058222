import { Button } from "@/components/Button";
import {
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  Pagination as ShadPagination,
} from "@/components/ui/pagination";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import i18n from "@/i18n";

export const TablePagination = ({ meta, table }) => {
  if (!meta) {
    return (
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2 flex flex-row">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {i18n.t("Wstecz")}
          </Button>
          <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {i18n.t("Dalej")}
          </Button>
        </div>
      </div>
    );
  }
  const { current_page: currentPage, last_page: lastPage, from, to, total } = meta;
  const firstPage = 1;

  const handleClick = (page) => {
    table.setPageIndex(page - 1);
  };

  const nextPage = () => {
    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const prevPage = () => {
    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };
  const pageSize = table.getState().pagination.pageSize;

  return (
    <div className="flex items-center justify-between py-2 sm:px-3 mt-2">
      {/* Mobile pagination (just arrows) */}
      {lastPage > 1 && (
        <div className="flex flex-1 justify-between sm:hidden">
          <a
            className="relative cursor-pointer inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium"
            onClick={prevPage}
          >
            {i18n.t("Poprzednia strona")}
          </a>
          <a
            className="relative cursor-pointer ml-3 inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium"
            onClick={nextPage}
          >
            {i18n.t("Następna strona")}
          </a>
        </div>
      )}
      <div className="hidden sm:flex sm:flex-row sm:flex-1 sm:items-center sm:justify-between">
        {total > 10 && (
          <div>
            <Select
              value={pageSize}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Pokaż" />
              </SelectTrigger>
              <SelectContent>
                {[10, 20, 30, 40, 50].map((limit) => (
                  <SelectItem key={limit} value={limit}>
                    {limit}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {total > 0 && (
          <div className="mr-auto">
            <p className="flex-1 text-sm text-muted-foreground ml-3">
              {i18n.t("Rekordy od ")}
              <span className="font-medium">{from}</span>
              {i18n.t(" do ")}
              <span className="font-medium">{to}</span>
              {i18n.t(" z ")}
              <span className="font-medium">{total}</span>
            </p>
          </div>
        )}
        {lastPage > 1 && (
          <ShadPagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious onClick={prevPage} disabled={!table.getCanPreviousPage()} />
              </PaginationItem>
              {/* First page */}
              {firstPage !== lastPage && firstPage !== currentPage && (
                <PaginationItem>
                  <PaginationLink onClick={() => table.firstPage()}>{firstPage}</PaginationLink>
                </PaginationItem>
              )}
              {/* Three dots */}
              {firstPage !== lastPage && currentPage !== firstPage && currentPage > firstPage + 2 && (
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>
              )}
              {/* Current page -1, current page and current page + 1 */}
              {firstPage !== lastPage && (
                <>
                  {currentPage - 1 > firstPage && (
                    <PaginationItem>
                      <PaginationLink onClick={() => handleClick(currentPage - 1)}>{currentPage - 1}</PaginationLink>
                    </PaginationItem>
                  )}

                  <PaginationItem>
                    <PaginationLink isActive>{currentPage}</PaginationLink>
                  </PaginationItem>

                  {currentPage + 1 < lastPage && (
                    <PaginationItem>
                      <PaginationLink onClick={() => handleClick(currentPage + 1)}>{currentPage + 1}</PaginationLink>
                    </PaginationItem>
                  )}
                </>
              )}
              {/* Three dots */}
              {firstPage !== lastPage && currentPage !== lastPage && currentPage < lastPage - 2 && (
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>
              )}
              {/* Last page number */}
              {firstPage !== lastPage && lastPage !== currentPage && (
                <PaginationItem>
                  <PaginationLink onClick={() => table.lastPage()}>
                    {table.getPageCount().toLocaleString()}
                  </PaginationLink>
                </PaginationItem>
              )}
              {/* Right arrow */}
              <PaginationItem>
                <PaginationNext onClick={nextPage} disabled={!table.getCanNextPage()} />
              </PaginationItem>
            </PaginationContent>
          </ShadPagination>
        )}
      </div>
    </div>
  );
};
