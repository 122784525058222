import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useApplicationPolicy } from "@/features/applications/policies/useApplicationPolicy";
import { useBillingPolicy } from "@/features/billings/policies/useBillingPolicy";
import { ContractApplicationsTab } from "@/features/contracts/components/Applications/ContractApplicationsTab";
import { ContractBillingsTable } from "@/features/contracts/components/ContractBillingsTable";
import { ContractChanges } from "@/features/contracts/components/ContractChanges";
import { ContractInformationCard } from "@/features/contracts/components/ContractInformationCard";
import { ContractNotesCard } from "@/features/contracts/components/ContractNotesCard";
import { ContractOrderTab } from "@/features/contracts/components/ContractOrderTab";
import { ContractProcessesCard } from "@/features/contracts/components/ContractProcessesCard";
import { ContractTasksTable } from "@/features/contracts/components/ContractTasksTable";
import { ContractUsersCard } from "@/features/contracts/components/ContractUsersCard";
import { ContractFilesTable } from "@/features/contracts/components/Files/ContractFilesTable";
import { ContractInstallationServicesTab } from "@/features/contracts/components/Installation/ContractInstallationServicesTab";
import { ContractInstallationsTab } from "@/features/contracts/components/Installation/ContractInstallationsTab";
import { ContractMessagesCard } from "@/features/contracts/components/Messages/ContractMessagesCard";
import { ContractPaymentInformationCard } from "@/features/contracts/components/Payments/ContractPaymentInformationCard";
import { ContractPaymentsTable } from "@/features/contracts/components/Payments/ContractPaymentsTable";
import { useContractChangePolicy } from "@/features/contracts/policies/useContractChangePolicy";
import { useContractFilePolicy } from "@/features/contracts/policies/useContractFilePolicy";
import { useContractPaymentPolicy } from "@/features/contracts/policies/useContractPaymentPolicy";
import { useInstallationPolicy } from "@/features/installations/policies/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/features/installations/policies/useInstallationServicePolicy";
import { useOrderPolicy } from "@/features/orders/policies/useOrderPolicy";
import { useTaskPolicy } from "@/features/tasks/policies/useTaskPolicy";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";

export const ContractDetails = ({ contract }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const billingPolicy = useBillingPolicy();
  const contractFilesPolicy = useContractFilePolicy();
  const contractPaymentPolicy = useContractPaymentPolicy();
  const contractChangesPolicy = useContractChangePolicy();
  const installationPolicy = useInstallationPolicy();
  const applicationPolicy = useApplicationPolicy();
  const orderPolicy = useOrderPolicy();
  const taskPolicy = useTaskPolicy();
  const installationServicePolicy = useInstallationServicePolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {contractFilesPolicy.viewAny() && (
        <TabsList className="mb-5">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>
          {contractPaymentPolicy.viewAny() && <TabsTrigger value="payments">{i18n.t("Płatności")}</TabsTrigger>}
          {applicationPolicy.viewAny() && <TabsTrigger value="applications">{i18n.t("Wnioski klienta")}</TabsTrigger>}
          {installationPolicy.viewAny() && <TabsTrigger value="installations">{i18n.t("Montaże")}</TabsTrigger>}
          {installationServicePolicy.viewAny() && (
            <TabsTrigger value="installationServices">{i18n.t("Serwisy")}</TabsTrigger>
          )}
          {orderPolicy.viewAny() && <TabsTrigger value="order">{i18n.t("Zamówienie")}</TabsTrigger>}
          {billingPolicy.viewAny() && <TabsTrigger value="billings">{i18n.t("Rozliczenia")}</TabsTrigger>}
          {taskPolicy.viewAny() && <TabsTrigger value="tasks">{i18n.t("Zadania")}</TabsTrigger>}
          {contractChangesPolicy.viewAny() && <TabsTrigger value="changes">{i18n.t("Historia zmian")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <ContractInformationCard contract={contract} />
            <ContractPaymentInformationCard contract={contract} />
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <ContractUsersCard />
            <ContractMessagesCard />
            <ContractNotesCard />
          </div>
          <ContractProcessesCard />
        </div>
      </TabsContent>
      {contractFilesPolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
      {contractPaymentPolicy.viewAny() && (
        <TabsContent value="payments">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractPaymentsTable key="payments_list" contract={contract} />
          </div>
        </TabsContent>
      )}
      {applicationPolicy.viewAny() && (
        <TabsContent value="applications">
          <ContractApplicationsTab key="applications" contract={contract} />
        </TabsContent>
      )}
      {installationPolicy.viewAny() && (
        <TabsContent value="installations">
          <ContractInstallationsTab key="installations" contract={contract} />
        </TabsContent>
      )}
      {installationServicePolicy.viewAny() && (
        <TabsContent value="installationServices">
          <ContractInstallationServicesTab key="installationServices" contract={contract} />
        </TabsContent>
      )}
      {orderPolicy.viewAny() && (
        <TabsContent value="order">
          <ContractOrderTab key="order" contract={contract} />
        </TabsContent>
      )}
      {billingPolicy.viewAny() && (
        <TabsContent value="billings">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractBillingsTable key="billings_list" contract={contract} />
          </div>
        </TabsContent>
      )}
      {taskPolicy.viewAny() && (
        <TabsContent value="tasks">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractTasksTable key="tasks_list" />
          </div>
        </TabsContent>
      )}
      {contractChangesPolicy.viewAny() && (
        <TabsContent value="changes">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractChanges key="changes" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
