import { InstallationServiceCrewCard } from "@/features/installations/components/InstallationDetails/Services/InstallationServiceCrewCard";
import { InstallationServiceInformationCard } from "@/features/installations/components/InstallationDetails/Services/InstallationServiceInformationCard";
import { InstallationServiceNotesCard } from "@/features/installations/components/InstallationDetails/Services/InstallationServiceNotesCard";

export const InstallationServiceDetails = ({ installationService }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full gap-5">
      <InstallationServiceInformationCard installationService={installationService} />
      <div className="flex flex-1 flex-col gap-5 h-fit">
        <InstallationServiceNotesCard />
        <InstallationServiceCrewCard installationService={installationService} />
      </div>
    </div>
  );
};
