import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Loader } from "@/components/Loader/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { ApplicationTypeContext } from "@/features/applications/context/ApplicationTypeContext";
import { deleteAutomationAction } from "@/features/automations/api/automationApi";
import { AutomationActionType } from "@/features/automations/enums/AutomationActionTypeEnum";
import { getMailRecipientOptions } from "@/features/automations/helpers/getMailRecipientOptions";
import { getNotificationRecipientOptions } from "@/features/automations/helpers/getNotificationRecipientOptions";
import { getSmsRecipientOptions } from "@/features/automations/helpers/getSmsRecipientOptions";
import { getTaskOwnerOptions } from "@/features/automations/helpers/getTaskOwnerOptions";
import { InstallationTypeContext } from "@/features/installations/context/InstallationTypeContext";
import { TaskDueDateUnit } from "@/features/tasks/enums/TaskDueDateUnitEnum";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { UsersContext } from "@/features/users/context/UsersContext";
import i18n from "@/i18n";
import { useSortable } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GripVertical, Trash } from "lucide-react";
import { useContext } from "react";

export const AutomationAction = ({ automation, action }) => {
  const queryClient = useQueryClient();
  const { users } = useContext(UsersContext);
  const { types: applicationTypes, isLoading: isLoadingApplicationTypes } = useContext(ApplicationTypeContext);
  const { types: installationTypes, isLoading: isLoadingInstallationTypes } = useContext(InstallationTypeContext);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: action.id });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
  };

  const deleteAutomationActionMutation = useMutation({
    mutationFn: deleteAutomationAction,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
        queryClient.invalidateQueries({ queryKey: ["automation", automation.id] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteAutomationActionMutation.mutate({ automationId: automation.id, automationActionId: action.id });
  };

  const renderActionByType = () => {
    switch (action.action_type) {
      case AutomationActionType.SendSms.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Odbiorca" />
              <ActionParameterContent>
                {action.parameters.sms_recipient === "user_id" ? (
                  <UserTooltip user={users?.find((user) => user.id === action.parameters.user_id)} />
                ) : (
                  getSmsRecipientOptions().find((recipient) => recipient.value === action.parameters.sms_recipient)
                    ?.name
                )}
              </ActionParameterContent>
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Treść" />
              <ActionParameterContent>
                &quot;
                {action.parameters.sms_content}
                &quot;
              </ActionParameterContent>
            </ActionParameter>
          </ActionParameterContainer>
        );
      case AutomationActionType.CreateTask.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Zadanie dla" />
              {action.parameters.task_owner === "user_id" ? (
                <UserTooltip user={users?.find((user) => user.id === action.parameters.user_id)} />
              ) : (
                getTaskOwnerOptions().find((owner) => owner.value === action.parameters.task_owner)?.name
              )}
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Treść zadania" />
              <ActionParameterContent>
                &quot;
                {action.parameters.task.task}
                &quot;
              </ActionParameterContent>
            </ActionParameter>
            {action.parameters.task.due_date_amount && action.parameters.task.due_date_unit && (
              <ActionParameter>
                <ActionParameterTitle title="Termin wykonania" />
                <ActionParameterContent>{`${action.parameters.task.due_date_amount} ${
                  TaskDueDateUnit[action.parameters.task.due_date_unit]?.name
                }`}</ActionParameterContent>
              </ActionParameter>
            )}
            {action.parameters.task.description && (
              <ActionParameter>
                <ActionParameterTitle title="Opis" />
                <ActionParameterContent>{action.parameters.task.description}</ActionParameterContent>
              </ActionParameter>
            )}
          </ActionParameterContainer>
        );
      case AutomationActionType.SendEmail.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Odbiorca" />
              <ActionParameterContent>
                {action.parameters.mail_recipient === "user_id" ? (
                  <UserTooltip user={users?.find((user) => user.id === action.parameters.user_id)} />
                ) : (
                  getMailRecipientOptions().find((recipient) => recipient.value === action.parameters.mail_recipient)
                    ?.name
                )}
              </ActionParameterContent>
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Tytuł" />
              <ActionParameterContent>{action.parameters.mail_subject}</ActionParameterContent>
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Treść" />
              <ActionParameterContent>
                &quot;
                {action.parameters.mail_content}
                &quot;
              </ActionParameterContent>
            </ActionParameter>
          </ActionParameterContainer>
        );
      case AutomationActionType.CreateAudit.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Użytkownik odpowiedzialny" />
              <UserTooltip
                user={users?.find((user) => user.id === action.parameters?.user_id)}
                fallback="Audyt nieprzypisany"
              />
            </ActionParameter>
            {action.parameters.audit.description && (
              <ActionParameter>
                <ActionParameterTitle title="Opis" />
                <ActionParameterContent>{action.parameters.audit.description}</ActionParameterContent>
              </ActionParameter>
            )}
          </ActionParameterContainer>
        );
      case AutomationActionType.CreateApplication.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Użytkownik odpowiedzialny" />
              <UserTooltip user={users?.find((user) => user.id === action.parameters?.user_id)} />
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Typ" />
              {renderBadge(action.parameters.application.type, applicationTypes, isLoadingApplicationTypes)}
            </ActionParameter>
            {action.parameters.application.description && (
              <ActionParameter>
                <ActionParameterTitle title="Opis" />
                <ActionParameterContent>{action.parameters.application.description}</ActionParameterContent>
              </ActionParameter>
            )}
          </ActionParameterContainer>
        );
      case AutomationActionType.CreateInstallation.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Użytkownik odpowiedzialny" />
              <UserTooltip user={users?.find((user) => user.id === action.parameters?.user_id)} />
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Nazwa" />
              <ActionParameterContent>
                {action.parameters.installation.name ?? `Wzór "Nazwa klienta - Typ montażu"`}
              </ActionParameterContent>
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Typ" />
              {renderBadge(action.parameters.installation.type, installationTypes, isLoadingInstallationTypes)}
            </ActionParameter>
            {action.parameters.installation.description && (
              <ActionParameter>
                <ActionParameterTitle title="Opis" />
                <ActionParameterContent>{action.parameters.installation.description}</ActionParameterContent>
              </ActionParameter>
            )}
          </ActionParameterContainer>
        );
      case AutomationActionType.SendNotification.value:
        return (
          <ActionParameterContainer>
            <ActionParameter>
              <ActionParameterTitle title="Odbiorca" />
              <ActionParameterContent>
                {action.parameters.notification_recipient === "user_id" ? (
                  <UserTooltip user={users?.find((user) => user.id === action.parameters.user_id)} />
                ) : (
                  getNotificationRecipientOptions().find(
                    (recipient) => recipient.value === action.parameters.notification_recipient,
                  )?.name
                )}
              </ActionParameterContent>
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Tytuł" />
              <ActionParameterContent>{action.parameters.notification_title}</ActionParameterContent>
            </ActionParameter>
            <ActionParameter>
              <ActionParameterTitle title="Treść" />
              <ActionParameterContent>
                &quot;
                {action.parameters.notification_message}
                &quot;
              </ActionParameterContent>
            </ActionParameter>
          </ActionParameterContainer>
        );
    }
  };

  const actionType = AutomationActionType[action.action_type];

  return (
    <Card className="w-full" ref={setNodeRef} style={style}>
      <CardHeader className="flex flex-row justify-between items-center">
        <CardTitle className="flex flex-row gap-1 items-center text-lg" {...attributes} {...listeners}>
          <GripVertical size={20} className="shrink-0" />
          <span className="flex flex-row gap-1 items-center">
            {<actionType.icon size={20} />}
            {actionType.name}
          </span>
        </CardTitle>
        <DeleteAlertDialog
          message="Czy na pewno chcesz usunąć tę akcję? Tej operacji nie można cofnąć"
          onConfirm={handleDelete}
          trigger={
            <Button title="Usuń" size="icon" leftIcon={<Trash size={16} />} variant="destructive" type="button" />
          }
        />
      </CardHeader>
      <CardContent>
        <div className="flex flex-col items-start gap-5">{renderActionByType()}</div>
      </CardContent>
    </Card>
  );
};

export const ActionParameterTitle = ({ title }) => {
  return <p className="text-muted-foreground font-medium text-sm">{i18n.t(title) + ":"}</p>;
};

export const ActionParameterContainer = ({ children }) => {
  return <span className="flex flex-col gap-3 text-left">{children}</span>;
};

export const ActionParameterContent = ({ children }) => {
  return <p className="text-foreground whitespace-pre-line">{children}</p>;
};

export const ActionParameter = ({ children }) => {
  return <span className="flex flex-col gap-1 w-fit">{children}</span>;
};

const renderBadge = (entityId, entities, isLoading = false) => {
  const entity = entities.find((entity) => entity.id === entityId);
  if (isLoading) return <Loader />;
  if (!entity) return i18n.t("Brak");
  return <ColorBadge color={entity.color}>{entity.name}</ColorBadge>;
};
