import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { EditableSelectCell } from "@/components/Table/EditableSelectCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { TaskRecurrenceUnit } from "@/features/tasks/enums/TaskRecurrenceUnitEnum";
import { TaskStatus } from "@/features/tasks/enums/TaskStatusEnum";
import { useTaskableTableColumn } from "@/features/tasks/hooks/useTaskableTableColum";
import { useTaskPolicy } from "@/features/tasks/policies/useTaskPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { isArchive } from "@/helpers/isArchive";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useTasksTableColumns = (handleDelete, handleEdit) => {
  const taskPolicy = useTaskPolicy();
  const { user } = useAuth();

  const columns = [
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Status")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const task = row.original;
        const handleChangeStatus = (value) => {
          if (value !== task.status) handleEdit(task.id, { status: value });
        };
        return (
          <div className="w-full">
            <EditableSelectCell onSave={handleChangeStatus} options={TaskStatus.getValues()} value={task.status} />
          </div>
        );
      },
    },
    {
      id: "task",
      name: "Treść zadania",
      accessorKey: "task",
      maxSize: 20,
      header: <span>{i18n.t("Treść zadania")}</span>,
      cell: ({ row }) => {
        const description = row.original.description;
        const task = row.original.task;
        return (
          <div className="flex flex-col gap-1">
            <span className="font-semibold">{task}</span>
            <span className="text-sm text-muted-foreground break-all">{description}</span>
          </div>
        );
      },
    },
    {
      id: "recurrence",
      name: "Powtarza się",
      accessorKey: "recurrence",
      maxSize: 20,
      header: <span className="w-1/2">{i18n.t("Powtarza się")}</span>,
      cell: ({ row }) => {
        const task = row.original;
        if (task.is_recurring) {
          return (
            <span className="font-semibold w-1/2">{`Co ${task.recurrence_interval} ${
              TaskRecurrenceUnit[task.recurrence_unit].name
            }`}</span>
          );
        } else {
          return i18n.t("-");
        }
      },
    },
    {
      id: "creator",
      name: "Dodane przez",
      accessorKey: "creator",
      header: () => <div className="px-4">{i18n.t("Dodane przez")}</div>,
      cell: ({ row }) => {
        const creator = row.original.created_by;
        return user.id !== creator.id ? <UserAvatarLink user={creator} /> : "-";
      },
    },
    {
      id: "taskable",
      name: "Dotyczy",
      accessorKey: "taskable",
      header: i18n.t("Dotyczy"),
      cell: ({ row }) => useTaskableTableColumn(row.original),
    },
    {
      id: "due_date",
      name: "Termin",
      accessorKey: "due_date",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Termin")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const task = row.original;
        return (
          <span
            className={cn(
              isArchive(task.due_date, "yyyy-MM-dd HH:mm:ss") &&
                task.status !== TaskStatus.DONE.value &&
                "text-destructive",
            )}
          >
            {task.due_date
              ? format(parse(task.due_date, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM HH:mm")
              : i18n.t("-")}
          </span>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const task = row.original;
        if (!taskPolicy.destroy(task)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {taskPolicy.destroy(task) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(task.id)}
                  message="Czy na pewno chcesz usunąć to zadanie? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
