export const USERS_ENDPOINT = "/users/";
export const LEADS_ENDPOINT = "/leads/";
export const NOTES_ENDPOINT = "/notes/";
export const ACTIONS_ENDPOINT = "/actions/";
export const STATS_ENDPOINT = "/stats/";
export const FILES_ENDPOINT = "/files/";
export const TASKS_ENDPOINT = "/tasks/";
export const TASK_USERS_ENDPOINT = "/tasks/users";
export const CONTRACTS_ENDPOINT = "/contracts/";
export const CALCULATIONS_ENDPOINT = "/calculations/";
export const CHANGES_ENDPOINT = "/changes/";
export const PAYMENTS_ENDPOINT = "/payments/";
export const NOTIFICATIONS_ENDPOINT = "/notifications/";
export const MESSAGES_ENDPOINT = "/messages/";
export const PROCESSES_ENDPOINT = "/processes/";
export const PROCESS_STEPS_ENDPOINT = "/steps/";
export const CUSTOM_FIELDS_ENDPOINT = "/customFields/";
export const COSTS_ENDPOINT = "/costs/";
export const LEVELS_ENDPOINT = "/userLevels/";
export const WAREHOUSES_ENDPOINT = "/warehouses/";
export const PRODUCTS_ENDPOINT = "/products/";
export const RELATIONS_ENDPOINT = "/relations/";
export const BUNDLES_ENDPOINT = "/bundles/";
export const OPERATIONS_ENDPOINT = "/operations/";
export const ORDERS_ENDPOINT = "/orders/";
export const ITEMS_ENDPOINT = "/items/";
export const BILLINGS_ENDPOINT = "/billings/";
export const BILLING_SETTINGS_ENDPOINT = "/billingSettings/";
export const BILLING_SETTING_RULES_ENDPOINT = "/billingSettingRules/";
export const BILLING_GROUPS_ENDPOINT = "/billingGroups/";
export const METADATA_ENDPOINT = "/metadata/";
export const CALCULATORS_ENDPOINT = "/calculators/";
export const SECTIONS_ENDPOINT = "/sections/";
export const CALCULATOR_SECTIONS_ENDPOINT = "/calculators/sections/";
export const FIELDS_ENDPOINT = "/fields/";
export const DEPENDENCIES_ENDPOINT = "/dependencies/";
export const ADDITIONAL_COSTS_ENDPOINT = "/additional-costs/";
export const CALENDAR_ENDPOINT = "/calendar/";
export const INSTALLATIONS_ENDPOINT = "/installations/";
export const INSTALLATION_SERVICES_ENDPOINT = "/installationServices/";
export const INSTALLATION_CREWS_ENDPOINT = "/installationCrews/";
export const CONTRACTS_PRODUCTS_ENDPOINT = "/contracts/products/";
export const CONTRACTS_STATUSES_ENDPOINT = "/contracts/statuses/";
export const CONTRACTS_TYPES_ENDPOINT = "/contracts/types/";
export const LEADS_STATUSES_ENDPOINT = "/leads/statuses/";
export const LEADS_SOURCES_ENDPOINT = "/leads/sources/";
export const PRODUCTS_TYPES_ENDPOINT = "/products/types/";
export const FILES_CATEGORIES_ENDPOINT = "/files/categories/";
export const INSTALLATIONS_STATUSES_ENDPOINT = "/installations/statuses/";
export const INSTALLATIONS_TYPES_ENDPOINT = "/installations/types/";
export const AUDITS_ENDPOINT = "/audits/";
export const AUDITS_STATUSES_ENDPOINT = "/audits/statuses/";
export const APPLICATIONS_ENDPOINT = "/applications/";
export const APPLICATION_STATUSES_ENDPOINT = "/applications/statuses/";
export const APPLICATION_TYPES_ENDPOINT = "/applications/types/";
export const CLIENTS_ENDPOINT = "/clients/";
export const SETTINGS_ENDPOINT = "/settings/";
export const CONTRACT_FILE_CATEGORIES_ENDPOINT = "/contractFileCategories/";
export const SURVEYS_ENDPOINT = "/surveys/";
export const QUESTIONS_ENDPOINT = "/questions/";
export const SURVEY_SECTIONS_ENDPOINT = "/surveys/sections/";
export const ENTRIES_ENDPOINT = "/entries/";
export const SURVEY_ENTRIES_ENDPOINT = "/surveyEntries/";
export const SIGNATURES_ENDPOINT = "/signatures/";
export const CONTRACT_TEMPLATES_ENDPOINT = "/contractTemplates/";
export const DISCOUNTS_ENDPOINT = "/discounts/";
export const GRANTS_ENDPOINT = "/grants/";
export const MODEL_VIEWS_ENDPOINT = "/modelViews/";
export const ROLES_ENDPOINT = "/roles/";
export const WIDGETS_ENDPOINT = "/widgets/";
export const USER_WIDGETS_ENDPOINT = "/userWidgets/";
export const CREDITS_ENDPOINT = "/credits/";
export const CREDITS_STATUSES_ENDPOINT = "/credits/statuses/";
export const AUTOMATIONS_ENDPOINT = "/automations/";
export const TRIGGERS_ENDPOINT = "/triggers/";
