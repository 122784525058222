import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteCalculatorSection, updateCalculatorFieldOrder } from "@/features/calculators/api/calculatorApi";
import { CalculatorField } from "@/features/calculators/components/Configuration/Fields/CalculatorField";
import { CalculatorFieldCreateDialog } from "@/features/calculators/components/Configuration/Fields/CalculatorFieldCreateDialog";
import { CalculatorSectionEditDialog } from "@/features/calculators/components/Configuration/Sections/CalculatorSectionEditDialog";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GripVertical, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CalculatorSection = ({ section }) => {
  const { id: calculatorId } = useParams();
  const queryClient = useQueryClient();
  const [fields, setFields] = useState(section.fields);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: section.id });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
  };

  const updateCalculatorFieldOrderMutation = useMutation({
    mutationFn: updateCalculatorFieldOrder,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteCalculatorSectionMutation = useMutation({
    mutationFn: deleteCalculatorSection,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleFieldDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = fields.findIndex((item) => item.id === active.id);
      const newIndex = fields.findIndex((item) => item.id === over.id);
      const newFields = arrayMove(fields, oldIndex, newIndex);
      setFields(newFields);
      updateCalculatorFieldOrderMutation.mutate({
        calculatorSectionId: section.id,
        data: { field_ids: newFields.map((field) => field.id) },
      });
    }
  };

  const handleDelete = () => {
    deleteCalculatorSectionMutation.mutate({ calculatorId, calculatorSectionId: section.id });
  };

  useEffect(() => {
    if (section) setFields(section.fields);
  }, [section]);

  return (
    <div ref={setNodeRef} style={style} className="flex flex-col gap-2 w-full">
      <Card className="w-full h-fit">
        <CardHeader className="flex flex-row justify-between items-start">
          <div className="flex flex-col gap-1.5 items-center">
            <CardTitle>
              <div
                className="flex flex-row gap-1 items-center cursor-move font-medium w-fit"
                {...attributes}
                {...listeners}
              >
                <GripVertical size={16} className="shrink-0" />
                {section.name}
              </div>
            </CardTitle>
            <CardDescription>{section.description}</CardDescription>
          </div>
          <div className="flex flex-row gap-2 items-start">
            <CalculatorSectionEditDialog section={section} />
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć tą sekcję? Tej operacji nie można cofnąć. Pola w tej sekcji również zostaną usunięte"
              onConfirm={handleDelete}
              trigger={
                <Button
                  variant="outline"
                  title="Usuń sekcję"
                  className=""
                  leftIcon={<Trash size={16} />}
                  isLoading={deleteCalculatorSectionMutation.isPending}
                />
              }
            />
          </div>
        </CardHeader>
        <CardContent>
          <DndContext onDragEnd={handleFieldDragEnd}>
            <SortableContext items={fields.map((field) => field.id)} strategy={verticalListSortingStrategy}>
              <div className="flex flex-col divide-y divide-y-border">
                {fields.map((field) => (
                  <CalculatorField field={field} key={field.id} />
                ))}
              </div>
            </SortableContext>
          </DndContext>
          <CalculatorFieldCreateDialog calculatorSectionId={section.id} />
        </CardContent>
      </Card>
    </div>
  );
};
