import { useAuth } from "@/features/auth/hooks/useAuth";

export const useLeadStatusPolicy = () => {
  const { hasPermission } = useAuth();

  const create = () => {
    return hasPermission("create_lead_status");
  };

  const update = () => {
    return hasPermission("update_lead_status");
  };

  const updateName = (status) => {
    return hasPermission("update_lead_status") && !status.required;
  };

  const destroy = (status) => {
    return hasPermission("delete_lead_status") && !status.required;
  };

  return {
    create,
    update,
    updateName,
    destroy,
  };
};
