import { AddressCardRow } from "@/components/Address/AddressCardRow";
import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CardRow } from "@/components/ui/CardRow";
import { toast } from "@/components/ui/use-toast";
import { updateContractsStatus } from "@/features/contracts/api/contractApi";
import { ContractStatusContext } from "@/features/contracts/context/ContractStatusContext";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { renderCustomFields } from "@/features/customFields/helpers/renderCustomFields";
import { useLeadPolicy } from "@/features/leads/policies/useLeadPolicy";
import { UserTooltip } from "@/features/users/components/UserTooltip";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const ContractInformationCard = ({ contract }) => {
  const { id: contractId } = useParams();
  const queryClient = useQueryClient();
  const leadPolicy = useLeadPolicy();
  const contractPolicy = useContractPolicy();
  const { statusOptions, isLoading } = useContext(ContractStatusContext);

  const handleStatusClick = (value) => {
    if (value) {
      const data = { contract_status_id: value };
      updateContractStatusMutation.mutate({ contractId, data });
    }
  };

  const updateContractStatusMutation = useMutation({
    mutationFn: updateContractsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      queryClient.invalidateQueries({ queryKey: ["contract", contract.id], exact: true });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      }
    },
  });

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("info")}</CardTitle>
        <CardDescription>{i18n.t("contract.contract_info")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.identifier")}</p>
          <p className="text-right">{contract.identifier}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.name")}</p>
          <p className="text-right">{contract.name}</p>
        </CardRow>
        {contract.lead && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("contract.client")}</p>
            <Link to={`${routes.clients}/${contract.lead.id}`}>
              <Button variant="link" size="xs">
                {contract.lead.name}
              </Button>
            </Link>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.created_at")}</p>
          <p title={contract.created_at}>
            {format(parse(contract.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.signed_at")}</p>
          <p className="text-right">{format(parse(contract.signed_at, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}</p>
        </CardRow>
        {leadPolicy.assignUserLeads() && contract.created_by && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("contract.created_by")}</p>
            <UserTooltip user={contract.created_by} />
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.status")}</p>
          {contractPolicy.updateStatus() || contract.amount_of_processes === 0 ? (
            <Select
              className="w-fit"
              style={{ backgroundColor: contract.status.color }}
              options={statusOptions}
              setValue={handleStatusClick}
              value={contract.status.id}
              isLoading={updateContractStatusMutation.isPending || isLoading}
            />
          ) : (
            <ColorBadge color={contract.status.color}>{contract.status.name}</ColorBadge>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.personal_identity_number")}</p>
          <p className="text-right">{contract.personal_identity_number}</p>
        </CardRow>
        <AddressCardRow model={contract} modelName="Client" />
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.phone_number")}</p>
          {contract.phone_number ? (
            <a className="font-semibold" href={`tel:${contract.phone_number}`}>
              {formatPhoneNumber(contract.phone_number)}
            </a>
          ) : (
            <p className="text-right">{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.email")}</p>
          {contract.email ? (
            <a className="font-semibold" href={`mailto:${contract.email}`}>
              {contract.email}
            </a>
          ) : (
            <p className="text-right">{i18n.t("none")}</p>
          )}
        </CardRow>
        {contract.calculation && (
          <>
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Kalkulacja")}</p>
              <Link to={`${routes.contracts}/${contract.id}/kalkulacja`} state={{ contract }}>
                <Button variant="link" size="xs">
                  {contract.calculation.name}
                </Button>
              </Link>
            </CardRow>
          </>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.products")}</p>
          <div className="flex flex-row gap-2 items-center justify-end flex-wrap">
            {contract.products.map((product) => (
              <ColorBadge key={product.id} color={product.color}>
                {product.name}
              </ColorBadge>
            ))}
          </div>
        </CardRow>
        {contract.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("contract.description")}</p>
            <p className="text-muted-foreground">{contract.description}</p>
          </CardRow>
        )}
        {renderCustomFields(contract.customFields)}
      </CardContent>
    </Card>
  );
};
