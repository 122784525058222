import mapPinAudit from "@/assets/icons/mapPin/mapPinAudit.svg";
import mapPinClient from "@/assets/icons/mapPin/mapPinClient.svg";
import mapPinClientSelected from "@/assets/icons/mapPin/mapPinClientSelected.svg";
import mapPinInstallation from "@/assets/icons/mapPin/mapPinInstallation.svg";
import mapPinInstallationService from "@/assets/icons/mapPin/mapPinInstallationService.svg";
import { Icon } from "leaflet";

export const getMapPinIconForModel = (model) => {
  switch (model) {
    case "Lead":
    case "Client":
      return new Icon({
        iconUrl: mapPinClient,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -19],
      });
    case "Installation":
      return new Icon({
        iconUrl: mapPinInstallation,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -19],
      });
    case "InstallationService":
      return new Icon({
        iconUrl: mapPinInstallationService,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -19],
      });
    case "Audit":
      return new Icon({
        iconUrl: mapPinAudit,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -19],
      });
    default:
      return null;
  }
};
export const getSelectedMapPinIconForModel = (model) => {
  switch (model) {
    case "Lead":
      return new Icon({
        iconUrl: mapPinClientSelected,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -19],
      });
    default:
      return null;
  }
};
