import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { Bar, BarChart, XAxis } from "recharts";

export const LeadProfitabilityStat = ({ dateRange, data, isLoading }) => {
  let max = { value: 0, index: 0 };
  const chartData =
    data &&
    Object.keys(data).map((source, index) => {
      const { profitability, total_cost, total_margin } = data[source];
      if (profitability > max.value) {
        max.index = index;
        max.value = profitability;
      }
      if (source === "") source = "Użytkownik";

      return {
        source,
        total_cost: total_cost.toFixed(2),
        total_margin: total_margin.toFixed(2),
        profitability: profitability.toFixed(2),
      };
    });

  const chartConfig = {
    total_cost: {
      label: "Suma kosztów kontaktów",
      color: "hsl(var(--chart-3))",
    },
    profitability: {
      label: "Zysk",
      color: "hsl(var(--chart-5))",
    },
  };

  return (
    <Card className="col-span-4 lg:col-span-2">
      <CardHeader>
        <CardTitle>{i18n.t("Opłacalność źródeł")}</CardTitle>
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent>
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="w-full max-h-[300px]"
        >
          <BarChart accessibilityLayer data={chartData}>
            <XAxis dataKey="source" tickLine={false} tickMargin={10} axisLine={false} />
            <Bar dataKey="profitability" fill="var(--color-profitability)" />
            <Bar dataKey="total_cost" fill="var(--color-total_cost)" />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  className="w-[300px]"
                  formatter={(value, name, item, index) => (
                    <>
                      <div
                        className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                        style={{
                          "--color-bg": `var(--color-${name})`,
                        }}
                      />
                      {chartConfig[name]?.label || name}
                      <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                        {formatMoney(value)}
                      </div>
                      {index === 1 && (
                        <>
                          <div className="mt-1.5 flex basis-full items-center border-t pt-1.5 text-xs font-medium text-foreground">
                            {i18n.t("Całkowita marża z umów")}
                            <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                              {formatMoney(item.payload.total_margin)}
                            </div>
                          </div>
                          {item.payload.total_cost > 0 && (
                            <div className=" flex basis-full items-center text-xs font-medium text-foreground">
                              {i18n.t("Współczynnik opłacalności")}
                              <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                                {((item.payload.profitability / item.payload.total_cost) * 100).toFixed(2) + " %"}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                />
              }
              cursor={false}
            />
          </BarChart>
        </ChartContainerWrapper>
      </CardContent>
    </Card>
  );
};
