import { EmptyState } from "@/components/EmptyState";
import { ButtonContext } from "@/context/ButtonContext";
import { ContractApplicationInformationCard } from "@/features/contracts/components/Applications/ContractApplicationInformationCard";
import { useContext, useEffect } from "react";

export const ContractApplicationsTab = ({ contract }) => {
  const { showButton, hideButton } = useContext(ButtonContext);
  const applications = contract.lead.applications;

  useEffect(() => {
    showButton("addApplication");

    return () => {
      hideButton("addApplication");
    };
  }, []);

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
      {applications?.length === 0 && <EmptyState title="Brak wniosków" />}
      {applications &&
        applications?.map((application) => (
          <ContractApplicationInformationCard key={application.id} application={application} />
        ))}
    </div>
  );
};
