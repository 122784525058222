import api from "@/api/api";
import {
  ENTRIES_ENDPOINT,
  QUESTIONS_ENDPOINT,
  SECTIONS_ENDPOINT,
  SIGNATURES_ENDPOINT,
  SURVEY_ENTRIES_ENDPOINT,
  SURVEY_SECTIONS_ENDPOINT,
  SURVEYS_ENDPOINT,
} from "@/api/endpoints";

export const getSurveys = () => api.get(SURVEYS_ENDPOINT);

export const createSurvey = ({ data }) => api.post(SURVEYS_ENDPOINT, data);

export const getSurvey = (surveyId) => api.get(SURVEYS_ENDPOINT + surveyId);

export const updateSurvey = ({ surveyId, data }) => api.put(SURVEYS_ENDPOINT + surveyId, data);

export const deleteSurvey = ({ surveyId }) => api.delete(SURVEYS_ENDPOINT + surveyId);

export const createSurveySection = ({ surveyId, data }) =>
  api.post(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT, data);

export const updateSurveySection = ({ surveyId, surveySectionId, data }) =>
  api.put(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT + surveySectionId, data);

export const updateSurveySectionOrder = ({ surveyId, data }) =>
  api.put(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT + "order", data);

export const deleteSurveySection = ({ surveyId, surveySectionId }) =>
  api.delete(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT + surveySectionId);

export const createSurveyQuestion = ({ surveySectionId, data }) =>
  api.post(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT, data);

export const updateSurveyQuestion = ({ surveySectionId, surveyQuestionId, data }) =>
  api.put(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT + surveyQuestionId, data);

export const updateSurveyQuestionOrder = ({ surveySectionId, data }) =>
  api.put(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT + "order", data);

export const deleteSurveyQuestion = ({ surveySectionId, surveyQuestionId }) =>
  api.delete(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT + surveyQuestionId);

export const createSurveyEntry = ({ surveyId, data }) => api.post(SURVEYS_ENDPOINT + surveyId + ENTRIES_ENDPOINT, data);

export const createSurveyEntrySignature = ({ surveyEntryId, data }) =>
  api.post(SURVEY_ENTRIES_ENDPOINT + surveyEntryId + SIGNATURES_ENDPOINT, data);

export const downloadSurveyEntryPdf = (surveyEntryId) =>
  api.get(SURVEY_ENTRIES_ENDPOINT + surveyEntryId + "/pdf", {}, { responseType: "blob" });
