import { useAuth } from "@/features/auth/hooks/useAuth";

export const useAuditPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_audits", "access_own_audits", "access_created_audits"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_audits");
  };

  const view = () => {
    return hasAnyPermission(["show_any_audit", "show_own_audit", "show_created_audit"]);
  };

  const create = () => {
    return hasPermission("create_audit");
  };

  const update = () => {
    return hasPermission("update_audit");
  };

  const updateStatus = () => {
    return hasPermission("update_audits_status");
  };

  const destroy = () => {
    return hasPermission("delete_audit");
  };

  const viewProcesses = () => {
    return hasPermission("access_audit_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_audit_process");
  };

  const bulkEditStatus = () => {
    return hasPermission("bulk_edit_audit_status");
  };

  const assignUserAudits = () => {
    return hasPermission("assign_user_audits");
  };

  return {
    viewAny,
    viewAll,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewProcesses,
    updateProcess,
    bulkEditStatus,
    assignUserAudits,
  };
};
