import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { FileCategoryContext } from "@/features/files/context/FileCategoryContext";
import { FileExtension } from "@/features/files/enums/FileExtensionEnum";
import { useFilters } from "@/hooks/useFilters";
import { useContext } from "react";

export const FileFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);
  const { categoryOptions, isLoading } = useContext(FileCategoryContext);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={FileExtension.getValues()}
        setValue={(value) => setFilter("extension", value)}
        title="Rozszerzenie"
        value={filter("extension")}
      />
      <SelectFilter
        options={categoryOptions}
        isLoading={isLoading}
        setValue={(value) => setFilter("category", value)}
        title="Kategoria"
        value={filter("category")}
      />
      <ColumnFilter table={table} />
    </div>
  );
};
