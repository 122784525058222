import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteSurvey } from "@/features/surveys/api/surveyApi";
import { SurveyEditDialog } from "@/features/surveys/components/SurveyEditDialog";
import { useSurveyPolicy } from "@/features/surveys/policies/useSurveyPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Cog, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const Survey = ({ survey }) => {
  useDocumentTitle(`Ankieta ${survey.name}`);
  const queryClient = useQueryClient();
  const surveyPolicy = useSurveyPolicy();

  const deleteSurveyMutation = useMutation({
    mutationFn: deleteSurvey,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["surveys"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteSurveyMutation.mutate({ surveyId: survey.id });
  };

  return (
    <Card className="w-full h-fit break-inside-avoid mb-5">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <Link to={`${routes.surveys}/${survey.id}`}>
            <CardTitle>{survey.name}</CardTitle>
            <CardDescription>{i18n.t("Ankieta")}</CardDescription>
          </Link>
        </div>
        <div className="flex flex-row gap-3 items-center">
          <Link to={`${routes.surveys}/${survey.id}`}>
            <Button variant="outline" title="Ustawienia" leftIcon={<Cog size={20} />} />
          </Link>
          <SurveyEditDialog survey={survey} />
          {surveyPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć tę ankietę? Tej operacji nie można cofnąć"
              onConfirm={handleDelete}
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń"
                  leftIcon={<Trash size={20} />}
                  isLoading={deleteSurveyMutation.isLoading}
                />
              }
            />
          )}
        </div>
      </CardHeader>
    </Card>
  );
};
