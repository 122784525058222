import { LeadSelectedProvider } from "@/features/leads/context/LeadSelectedContext";
import { LeadCreatePage } from "@/features/leads/pages/LeadCreatePage";
import { LeadDetailsPage } from "@/features/leads/pages/LeadDetailsPage";
import { LeadEditPage } from "@/features/leads/pages/LeadEditPage";
import { LeadImportPage } from "@/features/leads/pages/LeadImportPage";
import { LeadsPage } from "@/features/leads/pages/LeadsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const LeadRoutes = () => (
  <>
    <Route
      exact
      path={routes.leads}
      element={
        <LeadSelectedProvider>
          <LeadsPage />
        </LeadSelectedProvider>
      }
    />
    <Route exact path={routes.lead} element={<LeadDetailsPage />} />
    <Route exact path={routes.leadCreate} element={<LeadCreatePage />} />
    <Route exact path={routes.leadImport} element={<LeadImportPage />} />
    <Route exact path={routes.leadEdit} element={<LeadEditPage />} />
  </>
);
