import api from "@/api/api";
import {
  ACTIONS_ENDPOINT,
  CALCULATIONS_ENDPOINT,
  CONTRACTS_ENDPOINT,
  FILES_ENDPOINT,
  LEADS_ENDPOINT,
  LEADS_SOURCES_ENDPOINT,
  LEADS_STATUSES_ENDPOINT,
  NOTES_ENDPOINT,
  PROCESSES_ENDPOINT,
  USERS_ENDPOINT,
} from "@/api/endpoints";
import { transformQueryFilters } from "@/api/transformQueryFilters";
import { formatDate, formatDateTime } from "@/helpers/formatDate";

export const getLeads = (filters = null) => api.get(LEADS_ENDPOINT, transformQueryFilters(filters));

export const importLeads = ({ data }) =>
  api.post(LEADS_ENDPOINT + "import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const assignLeadsToUser = ({ userId, leadIds }) =>
  api.post(USERS_ENDPOINT + userId + "/assignLeads", { lead_ids: leadIds });

export const getLeadsStats = (filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(LEADS_ENDPOINT + "stats", queryFilters);
};

export const getLeadsSources = () => api.get(LEADS_SOURCES_ENDPOINT);

export const deleteLeadSource = ({ leadSourceId }) => api.delete(LEADS_SOURCES_ENDPOINT + leadSourceId);

export const updateLeadSource = ({ leadSourceId, data }) => api.patch(LEADS_SOURCES_ENDPOINT + leadSourceId, data);

export const createLeadSource = (data) => api.post(LEADS_SOURCES_ENDPOINT, data);

export const getLeadStatuses = () => api.get(LEADS_STATUSES_ENDPOINT);

export const updateLeadStatus = ({ leadStatusId, data }) => api.patch(LEADS_STATUSES_ENDPOINT + leadStatusId, data);

export const deleteLeadStatus = ({ leadStatusId }) => api.delete(LEADS_STATUSES_ENDPOINT + leadStatusId);

export const updateLeadStatusOrder = (data) => api.put(LEADS_STATUSES_ENDPOINT + "order", data);

export const createLeadStatus = (data) => api.post(LEADS_STATUSES_ENDPOINT, data);

export const getLead = (leadId) => api.get(LEADS_ENDPOINT + leadId);

export const createLead = ({ data }) => api.post(LEADS_ENDPOINT, data);

export const deleteLead = (leadId) => api.delete(LEADS_ENDPOINT + leadId);

export const bulkDeleteLeads = ({ leadIds }) => api.post(LEADS_ENDPOINT + "bulkDelete", { lead_ids: leadIds });

export const bulkEditStatusLeads = ({ leadIds, leadStatusId }) =>
  api.post(LEADS_ENDPOINT + "bulkEditStatus", { lead_ids: leadIds, lead_status_id: leadStatusId });

export const bulkUnassignUserLeads = ({ leadIds }) =>
  api.post(LEADS_ENDPOINT + "bulkUnassignUser", { lead_ids: leadIds });

export const updateLead = ({ id, data }) => api.patch(LEADS_ENDPOINT + id, data);

export const updateLeadsStatus = ({ id, data }) => api.put(LEADS_ENDPOINT + id + "/status", data);

export const getLeadNotes = (leadId) => api.get(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT);

export const createLeadNote = ({ leadId, note }) => api.post(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT, { note });

export const updateLeadNote = ({ leadId, noteId, note }) =>
  api.put(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT + noteId, note);

export const deleteLeadNote = ({ leadId, noteId }) => api.delete(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT + noteId);

export const getLeadActions = (leadId) => api.get(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT);

export const createLeadAction = ({ leadId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT, data);
};

export const updateLeadAction = ({ leadId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT + actionId, data);
};

export const deleteLeadAction = ({ leadId, actionId }) =>
  api.delete(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT + actionId);

export const getLeadFiles = ({ leadId, filters }) =>
  api.get(LEADS_ENDPOINT + leadId + FILES_ENDPOINT, transformQueryFilters(filters));

export const deleteLeadFile = ({ leadId, fileId }) => api.delete(LEADS_ENDPOINT + leadId + FILES_ENDPOINT + fileId);

export const createLeadFiles = ({ leadId, data }) =>
  api.post(LEADS_ENDPOINT + leadId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getLeadContracts = ({ leadId, filters = null }) =>
  api.get(LEADS_ENDPOINT + leadId + CONTRACTS_ENDPOINT, transformQueryFilters(filters));

export const createLeadCalculation = ({ leadId, data }) =>
  api.post(LEADS_ENDPOINT + leadId + CALCULATIONS_ENDPOINT, data);

export const deleteLeadCalculation = ({ leadId, calculationId }) =>
  api.delete(LEADS_ENDPOINT + leadId + CALCULATIONS_ENDPOINT + calculationId);

export const getLeadCalculations = ({ leadId, filters = null }) =>
  api.get(LEADS_ENDPOINT + leadId + CALCULATIONS_ENDPOINT, transformQueryFilters(filters));

export const getLeadProcesses = (leadId) => api.get(LEADS_ENDPOINT + leadId + PROCESSES_ENDPOINT);

export const updateLeadProcessProgress = ({ leadId, processId, data }) =>
  api.put(LEADS_ENDPOINT + leadId + PROCESSES_ENDPOINT + processId, data);
