import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { deleteAutomation } from "@/features/automations/api/automationApi";
import { AutomationEditDialog } from "@/features/automations/components/AutomationEditDialog";
import { useAutomationPolicy } from "@/features/automations/policies/useAutomationPolicy";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Cog, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const Automation = ({ automation }) => {
  const queryClient = useQueryClient();
  const automationPolicy = useAutomationPolicy();
  const navigate = useNavigate();

  const deleteAutomationMutation = useMutation({
    mutationFn: deleteAutomation,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["automations"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteAutomationMutation.mutate({ automationId: automation.id });
  };

  return (
    <Card className="w-full h-fit break-inside-avoid mb-5">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <Link to={`${routes.automations}/${automation.id}`}>
            <CardTitle>{automation.name}</CardTitle>
            <CardDescription>
              {automation.is_active ? (
                <p className="text-green-500">{i18n.t("Automatyzacja włączona")}</p>
              ) : (
                <p className="text-destructive">{i18n.t("Automatyzacja wyłączona")}</p>
              )}
            </CardDescription>
          </Link>
        </div>
        <div className="flex flex-row gap-3 items-center">
          {automationPolicy.update() && (
            <AutomationEditDialog
              trigger={<Button leftIcon={<Pencil size={20} />} variant="outline" title="Edytuj" />}
              automation={automation}
            />
          )}
          {automationPolicy.update() && (
            <Button
              leftIcon={<Cog size={20} />}
              variant="outline"
              title="Konfiguracja"
              onClick={() => navigate(`${routes.automations}/${automation.id}`)}
            />
          )}
          {automationPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć tę automatyzację? Tej operacji nie można cofnąć"
              onConfirm={handleDelete}
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń"
                  leftIcon={<Trash size={20} />}
                  isLoading={deleteAutomationMutation.isLoading}
                />
              }
            />
          )}
        </div>
      </CardHeader>
    </Card>
  );
};
