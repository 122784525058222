import { DeleteAlertDialog } from "@/components/Alert/DeleteAlertDialog";
import { Button } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useProductPolicy } from "@/features/products/policies/useProductPolicy";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, Eye, EyeOff, MoreHorizontal, PackageMinus } from "lucide-react";
import { Link } from "react-router-dom";

export const useWarehouseProductsTableColumns = (warehouse, handleDelete) => {
  const warehouseProductPolicy = useProductPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa produktu",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa produktu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (warehouseProductPolicy.view()) {
          return (
            <Link
              to={`${routes.products}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "type",
      name: "Typ",
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const product = row.original;
        return <span>{product.type.name}</span>;
      },
    },
    warehouse.external
      ? { id: "quantity" }
      : {
          id: "quantity",
          name: "Ilość produktu w magazynach",
          accessorKey: "quantity",
          header: i18n.t("Ilość"),
          cell: ({ row }) => {
            const quantity = row.original.quantity;
            return <span className={quantity === 0 && "text-destructive"}>{quantity}</span>;
          },
        },
    {
      id: "price",
      name: "Cena",
      accessorKey: "price",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Cena")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => formatMoney(row.getValue("price")),
    },
    {
      id: "available",
      name: "Produkt widoczny",
      accessorKey: "available",
      header: i18n.t("Produkt widoczny"),
      cell: ({ row }) => {
        const product = row.original;
        return product.available ? (
          <span className="flex flex-row items-center gap-2 text-green-500">
            <Eye size={16} />
            {i18n.t("Tak")}
          </span>
        ) : (
          <span className="flex flex-row items-center gap-2 text-destructive">
            <EyeOff size={16} />
            {i18n.t("Nie")}
          </span>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const product = row.original;
        if (!warehouseProductPolicy.destroy()) return null;

        return (
          <DropdownMenu modal={true}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {warehouseProductPolicy.destroy() && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(product.id)}
                  message="Czy na pewno chcesz usunąć ten produkt z tego magazynu? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <PackageMinus size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń z magazynu")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
