import { Button } from "@/components/Button";
import { ColorBadge } from "@/components/ColorBadge";
import { EmptyState } from "@/components/EmptyState";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useContractPolicy } from "@/features/contracts/policies/useContractPolicy";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { Plus } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const ClientContractsCard = ({ contracts, client }) => {
  const contractPolicy = useContractPolicy();
  const navigate = useNavigate();

  if (!contractPolicy.viewAny()) return null;

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Umowy klienta")}</CardTitle>
          <CardDescription>{i18n.t("Lista wszystkich umów tego klienta")}</CardDescription>
        </div>
        {contractPolicy.create() && (
          <Button
            leftIcon={<Plus size={20} />}
            variant="outline"
            title="Dodaj umowę"
            onClick={() => navigate(routes.contractCreate, { state: { lead: client } })}
          />
        )}
      </CardHeader>
      <ScrollArea className="w-full flex max-h-96 flex-col">
        <CardContent>
          <div className="flex flex-col gap-3 justify-center items-center">
            {contracts?.length === 0 && <EmptyState title="Brak umów" />}
            {contracts?.map((contract) => {
              return (
                <Card
                  key={contract.id}
                  className="p-4 shadow-md border-l-4 w-full flex flex-row justify-between items-start gap-3"
                  style={{ borderLeftColor: contract.status.color ?? "hsl(var(--primary))" }}
                >
                  <div className="flex flex-col justify-between items-start w-full gap-3">
                    <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                      <div className="flex flex-row justify-between items-start w-full gap-3">
                        <ColorBadge color={contract.status.color}>{contract.status.name}</ColorBadge>
                        <div className="flex flex-row flex-wrap justify-end gap-1">
                          {contract.products.map((product) => (
                            <ColorBadge key={product.id} color={product.color}>
                              {product.name}
                            </ColorBadge>
                          ))}
                        </div>
                      </div>
                      <div>
                        {contractPolicy.view() ? (
                          <Link to={`${routes.contracts}/${contract.id}`}>
                            <p className="m-0 p-0 h-fit text-lg font-semibold">{contract.identifier}</p>
                          </Link>
                        ) : (
                          <span className="font-semibold text-lg">{contract.identifier}</span>
                        )}
                        <div className="flex flex-wrap flex-row items-center text-muted-foreground font-medium text-sm">
                          {format(parse(contract.signed_at, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}
                        </div>
                      </div>
                      <div className="flex flex-wrap flex-row items-center font-medium text-sm">
                        {formatMoney(contract.gross_price)}
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
