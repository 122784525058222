import { CalendarView } from "@/features/calendar/components/CalendarView";
import { getCredits } from "@/features/credits/api/creditApi";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { CalendarClock } from "lucide-react";
import { useEffect, useState } from "react";

export const CreditsCalendar = () => {
  const [events, setEvents] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);

  const shouldFetch = dateFilters !== null;

  const filters = shouldFetch
    ? {
        columnFilters: [
          { id: "withActions", value: true },
          { id: "actions_from", value: dateFilters.startDate },
          { id: "actions_to", value: dateFilters.endDate },
        ],
      }
    : null;

  const {
    isLoading: isLoadingCredits,
    data: credits,
    error: errorCredits,
  } = useQuery({
    queryKey: ["credits", filters],
    queryFn: () => getCredits(filters),
    enabled: shouldFetch,
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoadingCredits && credits && !errorCredits) {
      if (credits?.data?.length) {
        const actions = credits.data.flatMap((credit) => credit.actions);
        setEvents(
          actions.map((action) => ({
            title: `${action.name} - ${credits.data.find((ins) => ins.id === action.actionable.id)?.lead?.name}`,
            link: `${routes.credits}/${action?.actionable?.id}`,
            icon: (props) => <CalendarClock {...props} />,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: "Działanie",
            className: "bg-yellow-600 hover:bg-yellow-600/90",
          })),
        );
      }
    }
  }, [isLoadingCredits, credits, errorCredits]);

  return <CalendarView events={events} isLoading={isLoadingCredits} setFilters={setDateFilters} />;
};
