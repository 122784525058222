import { Button } from "@/components/Button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Check, ChevronsUpDown } from "lucide-react";
import { forwardRef, useEffect, useState } from "react";

const Combobox = forwardRef(
  (
    {
      options,
      setValue,
      placeholder = "Wybierz z listy",
      description,
      required = true,
      disabled,
      value,
      leftIcon,
      variant = "outline",
      isLoading = false,
      className,
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [comboValue, setComboValue] = useState(value);

    useEffect(() => {
      setComboValue(value);
    }, [value]);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant={variant}
            role="combobox"
            aria-expanded={open}
            className={cn("w-full justify-between truncate", isLoading && "justify-start", className)}
            disabled={disabled}
            isLoading={isLoading}
            leftIcon={leftIcon}
            rightIcon={<ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />}
          >
            <span className="truncate">
              {comboValue ? options.find((option) => option.value === comboValue)?.name : placeholder}
            </span>
          </Button>
        </PopoverTrigger>
        {description && (
          <span className="text-sm text-muted-foreground">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
        <PopoverContent className="popover-content-width-same-as-its-trigger p-0">
          <Command
            filter={(v, search) => {
              const item = options.find((item) => item.name === v || item.value === v);
              if (!item) return 0;
              if (item.name.toLowerCase().includes(search.toLowerCase())) return 1;
            }}
            {...rest}
            onChange={() => {}}
          >
            <CommandInput placeholder={i18n.t("Wyszukaj...")} />
            <CommandEmpty>{i18n.t("Brak wyników.")}</CommandEmpty>
            <CommandGroup>
              <CommandList>
                {options.map((option) => (
                  <CommandItem
                    key={option.name}
                    value={option.value}
                    onSelect={(currentValue) => {
                      if (required) {
                        setValue(currentValue);
                        setComboValue(currentValue);
                      } else {
                        setComboValue(currentValue === comboValue ? "" : currentValue);
                        setValue(currentValue === comboValue ? "" : currentValue);
                      }
                      setOpen(false);
                    }}
                  >
                    <span>
                      <Check
                        className={cn("mr-2 h-4 w-4", comboValue === option.value ? "opacity-100" : "opacity-0")}
                      />
                    </span>
                    {option.content ? option.content : option.name}
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

Combobox.displayName = "Combobox";

export { Combobox };
