import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import i18n from "@/i18n";
import { format, parse } from "date-fns";
import { Bar, BarChart, CartesianGrid, Label, LabelList, ReferenceLine, XAxis } from "recharts";

export const UserCalculationsCountStat = ({ data, dateRange, isLoading }) => {
  let avg = 0;
  const chartData =
    data &&
    Object.entries(data)
      .map(([month, calculations]) => {
        avg += calculations;
        return {
          month: format(parse(month, "yyyy-MM", new Date()), "MMM yyyy"),
          calculations,
        };
      })
      .sort(
        (a, b) => parse(a.month, "MMM yyyy", new Date()).getTime() - parse(b.month, "MMM yyyy", new Date()).getTime(),
      );

  const chartConfig = {
    calculations: {
      label: "Spotkania",
      color: "hsl(var(--chart-1))",
    },
  };

  return (
    <div className="col-span-4 lg:col-span-2">
      <Card>
        <CardHeader>
          <CardTitle>{i18n.t("Ilość kalkulacji")}</CardTitle>
          <StatCardDescription dateRange={dateRange} />
        </CardHeader>
        <CardContent>
          <ChartContainerWrapper
            isLoading={isLoading}
            data={chartData}
            config={chartConfig}
            className="w-full max-h-[300px]"
          >
            <BarChart
              accessibilityLayer
              data={chartData}
              margin={{
                top: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="month"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <ChartTooltip content={<ChartTooltipContent />} />
              <Bar dataKey="calculations" fill="var(--color-calculations)">
                <LabelList offset={12} className="fill-foreground" fontSize={12} />
              </Bar>
              {chartData?.length && (
                <ReferenceLine
                  y={avg / chartData.length}
                  stroke="hsl(var(--muted-foreground))"
                  strokeDasharray="3 3"
                  strokeWidth={1}
                >
                  <Label position="insideBottomLeft" value="Średnia" offset={10} fill="hsl(var(--foreground))" />
                  <Label
                    position="insideTopLeft"
                    value={(avg / chartData.length).toFixed(0)}
                    className="text-lg"
                    fill="hsl(var(--foreground))"
                    offset={10}
                    startOffset={100}
                  />
                </ReferenceLine>
              )}
            </BarChart>
          </ChartContainerWrapper>
        </CardContent>
      </Card>
    </div>
  );
};
