import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ClientsCalendar } from "@/features/clients/components/ClientsCalendar";
import { ClientsTable } from "@/features/clients/components/ClientsTable";
import { ClientSettings } from "@/features/clients/components/Settings/ClientSettings";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { useCustomFieldPolicy } from "@/features/customFields/policies/useCustomFieldPolicy";
import { ViewMode } from "@/features/viewModes/enums/ViewModeEnum";
import { useViewModes } from "@/features/viewModes/hooks/useVIewModes";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const ClientsPage = () => {
  useDocumentTitle("Klienci");
  const navigate = useNavigate();
  const clientPolicy = useClientPolicy();
  const customFieldPolicy = useCustomFieldPolicy();
  const { activeTab, setActiveTab } = useTabs();
  const { renderModeSwitcher, viewMode } = useViewModes(ViewMode.TABLE, [ViewMode.TABLE, ViewMode.MAP], activeTab);
  const bottomRef = useRef(null);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {clientPolicy.create() && (
            <Button
              title="Dodaj klienta"
              variant="outline"
              leftIcon={<Plus size={20} />}
              onClick={() => navigate(routes.clientCreate)}
            />
          )}
          {activeTab === "table" && renderModeSwitcher()}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="mb-6">
          <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
          <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
          {customFieldPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
        </TabsList>
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientsTable viewMode={viewMode} />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <ClientsCalendar />
        </TabsContent>
        {customFieldPolicy.create() && (
          <TabsContent value="settings">
            <ClientSettings />
          </TabsContent>
        )}
      </Tabs>
      <div ref={bottomRef}></div>
    </Layout>
  );
};
