import { Button } from "@/components/Button";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { UserAvatarLink } from "@/features/users/components/UserAvatarLink";
import { routes } from "@/routes";
import { Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom";

export const CreditGroupedViewItem = ({ credit }) => {
  const creditPolicy = useCreditPolicy();
  return (
    <div
      className="flex flex-col gap-3 items-start py-2 pb-5 px-3 border rounded-md"
      style={{ borderColor: credit.status.color + "80" }}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-1/2">
          {creditPolicy.view() ? (
            <Link
              to={`${routes.credits}/${credit.id}`}
              className="flex flex-row items-center gap-3 font-semibold text-base hover:underline"
            >
              {credit.lead.name}
            </Link>
          ) : (
            <span className="font-semibold text-base">{credit.lead.name}</span>
          )}
        </div>
        <div className="w-1/2 flex flex-row justify-end px-1">
          {credit.lead.email && (
            <a href={`mailto:${credit.lead.email}`}>
              <Button size="icon" className="m-0 p-0" variant="ghost" leftIcon={<Mail size={16} />} type="button" />
            </a>
          )}
          {credit.lead.phone_number && (
            <a href={`tel:${credit.lead.phone_number}`}>
              <Button size="icon" className="m-0 p-0" variant="ghost" leftIcon={<Phone size={16} />} type="button" />
            </a>
          )}
        </div>
      </div>
      <div className="flex flex-col text-sm text-muted-foreground">
        <div>{credit.lead?.address?.street_number}</div>
        <div>{credit.lead?.address?.postal_code}</div>
        <div>{credit.lead?.address?.city}</div>
      </div>
      <div className="flex flex-col text-sm text-muted-foreground">
        <UserAvatarLink user={credit.user} fallback="Nieprzypisany" />
      </div>
    </div>
  );
};
