import { WarehouseDetailsPage } from "@/features/warehouses/pages/WarehouseDetailsPage";
import { WarehouseProductCreatePage } from "@/features/warehouses/pages/WarehouseProductCreatePage";
import { WarehousesPage } from "@/features/warehouses/pages/WarehousesPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const WarehouseRoutes = () => (
  <>
    <Route exact path={routes.warehouses} element={<WarehousesPage />} />
    <Route exact path={routes.warehouse} element={<WarehouseDetailsPage />} />
    <Route exact path={routes.warehouseProductCreate} element={<WarehouseProductCreatePage />} />
  </>
);
