import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { updateCredit } from "@/features/credits/api/creditApi";
import { CreditStatusContext } from "@/features/credits/context/CreditStatusContext";
import { useCreditPolicy } from "@/features/credits/policies/useCreditPolicy";
import { useCustomFields } from "@/features/customFields/hooks/useCustomFields";
import { UsersContext } from "@/features/users/context/UsersContext";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const CreditEditDialog = ({ trigger, credit }) => {
  const queryClient = useQueryClient();
  const creditPolicy = useCreditPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(CreditStatusContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const {
    customFields,
    renderFormCustomFields,
    customFieldValidationSchema,
    customFieldDefaultValues,
    applyCustomFieldsToForm,
  } = useCustomFields("Credit", credit);

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    user_id: Yup.string().nullable().optional(),
    credit_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
    ...customFieldValidationSchema,
  });

  const onSubmit = (data) => {
    updateCreditMutation.mutate({ creditId: credit.id, data });
  };

  const defaultValues = {
    user_id: credit?.user?.id ?? undefined,
    credit_status_id: credit?.status?.id ?? undefined,
    description: credit.description,
    ...customFieldDefaultValues,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateCreditMutation = useMutation({
    mutationFn: updateCredit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["credits"] });
      queryClient.invalidateQueries({ queryKey: ["credit", credit.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    applyCustomFieldsToForm(form);
  }, [customFields]);

  if (!creditPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja kredytu")}</DialogTitle>
          <DialogDescription>{credit.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            {creditPolicy.viewAll() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
            {(creditPolicy.updateStatus() || credit?.amount_of_processes === 0) && (
              <FormSelect
                label="Status"
                name="credit_status_id"
                options={statusOptions}
                isLoading={isLoadingStatuses}
              />
            )}
            <FormTextarea name="description" label="Opis" />
            {renderFormCustomFields()}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateCreditMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
