import { DashboardWidgetGridItem } from "@/features/dashboard/components/DashboardWidgetGridItem";
import { cn } from "@/lib/utils";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const DashboardWidgetGrid = ({ layout, onLayoutChange, editMode }) => {
  return (
    <ResponsiveGridLayout
      className={cn(editMode && "border-2 border-dashed rounded-md")}
      layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
      cols={{ lg: 12, md: 12, sm: 10, xs: 8, xxs: 4 }}
      breakpoints={{ lg: 1280, md: 1024, sm: 768, xs: 640, xxs: 475 }}
      rowHeight={180}
      preventCollision={false}
      isResizable={editMode}
      isDraggable={editMode}
      onLayoutChange={(currentLayout) => onLayoutChange(currentLayout)}
      draggableHandle=".drag-handle"
      resizeHandles={["s", "w", "e", "n"]}
    >
      {layout?.map((item) => (
        <div key={item.i} data-grid={item}>
          <DashboardWidgetGridItem item={item} editMode={editMode} />
        </div>
      ))}
    </ResponsiveGridLayout>
  );
};
