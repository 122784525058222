import { NotificationIcon } from "@/features/notifications/components/NotificationContent/DefaultNotification";

export const NotificationDropdownItem = ({ notification }) => {
  return (
    <div className="w-full flex flex-row items-center gap-3 py-3 px-3">
      <div>
        <NotificationIcon icon={notification.icon} causer={notification?.data?.causer} className="w-8 h-8" />
      </div>
      <div className="w-full">
        <div className="text-xs font-semibold flex flex-row justify-between items-center gap-3">
          <span>{notification?.data?.title}</span>
          {!notification.read_at && <div className="w-2 h-2 bg-red-600 rounded-full"></div>}
        </div>
      </div>
    </div>
  );
};
