import i18n from "@/i18n";
import { LineChart } from "lucide-react";

export const WidgetComponentWrapper = ({ data, children }) => {
  return !data || data?.length === 0 ? (
    <div className="flex flex-row gap-3 items-center">
      <LineChart className="h-12 w-12 text-muted-foreground mb-4" />
      <h3 className="text-lg font-semibold text-muted-foreground mb-2">{i18n.t("Brak danych")}</h3>
    </div>
  ) : (
    children
  );
};
