import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DateRangePicker } from "@/components/forms/DateRangePicker";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DelegatedTasksTable } from "@/features/tasks/components/DelegatedTasksTable";
import { TasksStats } from "@/features/tasks/components/Stats/TasksStats";
import { TaskCreateDialog } from "@/features/tasks/components/TaskCreateDialog";
import { TasksTable } from "@/features/tasks/components/TasksTable";
import { useTaskPolicy } from "@/features/tasks/policies/useTaskPolicy";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useState } from "react";

export const TasksPage = () => {
  useDocumentTitle("Zadania");
  const taskPolicy = useTaskPolicy();
  const { activeTab, setActiveTab } = useTabs("tasks");

  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <TaskCreateDialog buttonVariant="outline" withUser={true} />
          {activeTab === "stats" && (
            <DateRangePicker onSelect={setDateRange} selected={dateRange} placeholder="Wybierz zakres" />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {(taskPolicy.assignTask() || taskPolicy.viewStats()) && (
          <TabsList>
            <TabsTrigger value="tasks">{i18n.t("Moje zadania")}</TabsTrigger>
            <TabsTrigger value="delegated_tasks">{i18n.t("Zlecone zadania")}</TabsTrigger>
            {taskPolicy.viewStats() && <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="tasks" className="mt-5">
          <TasksTable />
        </TabsContent>
        {taskPolicy.assignTask() && (
          <TabsContent value="delegated_tasks" className="mt-5">
            <DelegatedTasksTable />
          </TabsContent>
        )}
        {taskPolicy.viewStats() && (
          <TabsContent value="stats" className="mt-5">
            <TasksStats dateRange={dateRange} />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
