import { EmptyState } from "@/components/EmptyState";
import { Badge } from "@/components/ui/badge";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";

export const LastContractsWidget = ({ data }) => {
  return (
    <div className="grid gap-5">
      {data.length > 0 ? (
        data.map((contract) => {
          return (
            <div key={contract.id} className="flex items-center gap-4">
              <UserAvatar user={contract.created_by} />
              <div className="grid gap-1">
                <p className="text-sm font-medium leading-none">{contract?.created_by?.name ?? i18n.t("Brak")}</p>
                {contract?.type?.name && <Badge className="w-fit hover:bg-primary">{contract?.type?.name}</Badge>}
              </div>
              <div className="ml-auto font-medium text-green-500 whitespace-nowrap">
                {!!contract.gross_price && formatMoney(contract.gross_price)}
              </div>
            </div>
          );
        })
      ) : (
        <EmptyState title="Brak umów" />
      )}
    </div>
  );
};
