import { Button } from "@/components/Button";
import { Text } from "@/components/Text";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { changePassword } from "@/features/auth/api/authApi";
import { useAuth } from "@/features/auth/hooks/useAuth";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { LockKeyhole } from "lucide-react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  current_password: Yup.string().required(i18n.t("Pole jest wymagane")),
  password: Yup.string()
    .required(i18n.t("Pole jest wymagane"))
    .min(8, i18n.t("Hasło musi mieć co najmniej 8 znaków!"))
    .notOneOf([Yup.ref("current_password"), null], i18n.t("Hasło nie może być takie same jak obecne!")),
  password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], i18n.t("Hasła muszą się zgadzać!")),
});

export const ChangePassword = () => {
  const { user } = useAuth();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    const response = await changePassword({ ...data, email: user.email });
    if (!response.ok) {
      if (response.status === 400) {
        toast({ title: <ErrorToast title="Obecne hasło jest nieprawidłowe" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    } else {
      form.reset();
      toast({ title: <SuccessToast title="Hasło zostało zmienione" /> });
    }
  };

  return (
    <Card className="w-1/2">
      <CardHeader>
        <CardTitle>
          <Text translate="Zmiana hasła" />
        </CardTitle>
        <CardDescription>
          <Text translate="Tu możesz zmienić swoje hasło do konta" />
        </CardDescription>
      </CardHeader>
      <Form form={form} onSubmit={onSubmit}>
        <CardContent>
          <div className="flex flex-col gap-8">
            <FormField
              type="password"
              name="current_password"
              autoComplete="current_password"
              label="Obecne hasło"
              id="current_password"
            />
            <FormField type="password" name="password" autoComplete="new-password" label="Nowe hasło" />
            <FormField
              type="password"
              name="password_confirmation"
              autoComplete="new-password"
              label="Potwierdź nowe hasło"
            />
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            leftIcon={<LockKeyhole size={20} />}
            title="Zapisz"
            isLoading={form.formState.isSubmitting}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
