import i18n from "@/i18n";
import { TrendingDown, TrendingUp } from "lucide-react";

export const ActiveLeadsWidget = ({ data }) => {
  return (
    <>
      <div className="text-2xl font-bold">{data.current_month}</div>
      {data.growth > 0 ? (
        <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
          <TrendingUp size={16} />
          {i18n.t("Wzrost o ")} {data.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
        </p>
      ) : (
        data.growth < 0 && (
          <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
            <TrendingDown size={16} />
            {i18n.t("Spadek o")} {data.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
          </p>
        )
      )}
    </>
  );
};
