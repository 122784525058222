import { ProductBundledProductsCard } from "@/features/products/components/Bundles/ProductBundledProductsCard";
import { ProductFilesCard } from "@/features/products/components/Files/ProductFilesCard";
import { ProductInformationCard } from "@/features/products/components/ProductInformationCard";
import { ProductMetadataCard } from "@/features/products/components/ProductMetadataCard";
import { ProductRelatedProductsCard } from "@/features/products/components/Relations/ProductRelatedProductsCard";

export const ProductDetails = ({ product }) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col lg:flex-row gap-5">
        <ProductInformationCard product={product} />
        <ProductMetadataCard product={product} />
      </div>
      <div className="flex flex-col lg:flex-row gap-5">
        <ProductFilesCard product={product} />
      </div>
      <div className="flex flex-col lg:flex-row gap-5">
        <ProductRelatedProductsCard product={product} />
        <ProductBundledProductsCard product={product} />
      </div>
    </div>
  );
};
