export const AutomationEventType = Object.freeze({
  ContractStatusChanged: {
    value: "ContractStatusChanged",
    name: "Zmiana statusu umowy",
  },
  ContractCreated: {
    value: "ContractCreated",
    name: "Dodanie umowy",
  },
  ContractPaymentCreated: {
    value: "ContractPaymentCreated",
    name: "Dodanie płatności do umowy",
  },
  ContractMessageCreated: {
    value: "ContractMessageCreated",
    name: "Dodanie wiadomości do umowy",
  },
  InstallationCreated: {
    value: "InstallationCreated",
    name: "Dodanie montażu",
  },
  InstallationStatusChanged: {
    value: "InstallationStatusChanged",
    name: "Zmiana statusu montażu",
  },
  InstallationServiceCreated: {
    value: "InstallationServiceCreated",
    name: "Dodanie serwisu",
  },
  ApplicationCreated: {
    value: "ApplicationCreated",
    name: "Dodanie wniosku",
  },
  ApplicationStatusChanged: {
    value: "ApplicationStatusChanged",
    name: "Zmiana statusu wniosku",
  },
  AuditCreated: {
    value: "AuditCreated",
    name: "Dodanie audytu",
  },
  AuditStatusChanged: {
    value: "AuditStatusChanged",
    name: "Zmiana statusu audytu",
  },
  CreditCreated: {
    value: "CreditCreated",
    name: "Dodanie kredytu",
  },
  CreditStatusChanged: {
    value: "CreditStatusChanged",
    name: "Zmiana statusu kredytu",
  },
  OrderCreated: {
    value: "OrderCreated",
    name: "Dodanie zamówienia",
  },
  OrderStatusChanged: {
    value: "OrderStatusChanged",
    name: "Zmiana statusu zamówienia",
  },
  LeadStatusChanged: {
    value: "LeadStatusChanged",
    name: "Zmiana statusu kontaktu",
  },
  TaskCreated: {
    value: "TaskCreated",
    name: "Dodanie zadania",
  },
  TaskStatusChanged: {
    value: "TaskStatusChanged",
    name: "Zmiana statusu zadania",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },
});
