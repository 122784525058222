import { getAudits } from "@/features/audits/api/auditApi";
import { CalendarView } from "@/features/calendar/components/CalendarView";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { CalendarClock } from "lucide-react";
import { useEffect, useState } from "react";

export const AuditsCalendar = () => {
  const [events, setEvents] = useState([]);
  const [dateFilters, setDateFilters] = useState(null);

  const shouldFetch = dateFilters !== null;

  const filters = shouldFetch
    ? {
        columnFilters: [
          { id: "withActions", value: true },
          { id: "actions_from", value: dateFilters.startDate },
          { id: "actions_to", value: dateFilters.endDate },
        ],
      }
    : null;

  const {
    isLoading: isLoadingAudits,
    data: audits,
    error: errorAudits,
  } = useQuery({
    queryKey: ["audits", filters],
    queryFn: () => getAudits(filters),
    enabled: shouldFetch,
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoadingAudits && audits && !errorAudits) {
      if (audits?.data?.length) {
        const actions = audits.data.flatMap((audit) => audit.actions);
        setEvents(
          actions.map((action) => ({
            title: `${action.name} - ${audits.data.find((ins) => ins.id === action.actionable.id)?.lead?.name}`,
            link: `${routes.audits}/${action?.actionable?.id}`,
            icon: (props) => <CalendarClock {...props} />,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: "Działanie",
            className: "bg-yellow-600 hover:bg-yellow-600/90",
          })),
        );
      }
    }
  }, [isLoadingAudits, audits, errorAudits]);

  return <CalendarView events={events} isLoading={isLoadingAudits} setFilters={setDateFilters} />;
};
