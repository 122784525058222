import { useAuth } from "@/features/auth/hooks/useAuth";

export const useInstallationPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_installations", "access_own_installations"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_installations");
  };

  const view = () => {
    return hasAnyPermission(["show_any_installation", "show_own_installation"]);
  };

  const create = () => {
    return hasPermission("create_installation");
  };

  const update = () => {
    return hasPermission("update_installation");
  };

  const updateStatus = () => {
    return hasPermission("update_installations_status");
  };

  const destroy = () => {
    return hasPermission("delete_installation");
  };

  const viewProcesses = () => {
    return hasPermission("access_installation_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_installation_process");
  };

  return {
    viewAny,
    viewAll,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewProcesses,
    updateProcess,
  };
};
