import { formatFromToDates } from "@/helpers/formatDate";

export const transformQueryFilters = (filters) => {
  if (!filters) return {};
  if (filters.pagination || filters.columnFilters || filters.sorting) {
    let queryFilters = {};
    queryFilters.page = filters?.pagination?.pageIndex ? filters?.pagination?.pageIndex + 1 : undefined;
    queryFilters.limit = filters?.pagination?.pageSize;
    filters?.columnFilters?.forEach((filter) => {
      if (typeof filter.value === "object" && !Array.isArray(filter.value) && filter.value !== null) {
        queryFilters[filter.id] = formatFromToDates(filter.value);
      } else {
        queryFilters[filter.id] = filter.value;
      }
    });
    filters.sorting?.forEach((sort) => {
      queryFilters.sort = sort.id;
      queryFilters.order = sort.desc === true ? "desc" : sort.desc === false ? "asc" : undefined;
    });
    return queryFilters;
  } else {
    return filters;
  }
};
