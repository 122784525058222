import { AuditStatusProvider } from "@/features/audits/context/AuditStatusContext";
import { AuditDetailsPage } from "@/features/audits/pages/AuditDetailsPage";
import { AuditsPage } from "@/features/audits/pages/AuditsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const AuditRoutes = () => (
  <>
    <Route
      exact
      path={routes.audits}
      element={
        <AuditStatusProvider>
          <AuditsPage />
        </AuditStatusProvider>
      }
    />
    <Route
      exact
      path={routes.audit}
      element={
        <AuditStatusProvider>
          <AuditDetailsPage />
        </AuditStatusProvider>
      }
    />
  </>
);
