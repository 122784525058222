import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { Loader } from "@/components/Loader/Loader";
import { getCustomFields } from "@/features/customFields/api/customFieldApi";
import { CustomFieldType } from "@/features/customFields/enums/CustomFieldTypeEnum";
import i18n from "@/i18n";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import * as Yup from "yup";

export const useCustomFields = (appliesTo, model = null, type = null) => {
  const [isApplied, setIsApplied] = useState(false);

  const { isLoading, data: customFields } = useQuery({
    queryKey: ["customFields", appliesTo, type],
    queryFn: () => getCustomFields({ applies_to: appliesTo, type }),
  });

  const customFieldValidationSchema = customFields?.data?.reduce((acc, customField) => {
    acc[customField.key] = customField.required
      ? Yup.string().required(i18n.t("Pole jest wymagane"))
      : Yup.string().nullable();
    return acc;
  }, {});

  const customFieldDefaultValues = customFields?.data?.reduce((acc, customField) => {
    if (model) {
      acc[customField.key] = model?.customFields?.find((field) => field.key === customField.key)?.value ?? undefined;
    } else acc[customField.key] = undefined;
    return acc;
  }, {});

  const applyCustomFieldsToForm = (form) => {
    if (!isLoading && customFields?.data?.length > 0 && !isApplied) {
      Object.keys(customFieldDefaultValues).forEach((fieldKey) => {
        form.setValue(fieldKey, customFieldDefaultValues[fieldKey]);
      });
      setIsApplied(true);
    }
  };

  const renderFormCustomFields = () => {
    return isLoading ? (
      <Loader />
    ) : (
      customFields?.data?.map((customField) => {
        switch (customField.field_type) {
          case CustomFieldType.LIST.value: {
            const options = customField?.field_options?.map((option) => ({ name: option, value: option })) ?? [];
            return (
              <FormSelect key={customField.key} name={customField.key} label={customField.name} options={options} />
            );
          }
          case CustomFieldType.TEXT.value:
            return (
              <FormField
                key={customField.key}
                name={customField.key}
                label={customField.name}
                placeholder={customField.name}
              />
            );
        }
      })
    );
  };

  return {
    customFields: customFields?.data || [],
    customFieldValidationSchema,
    customFieldDefaultValues,
    renderFormCustomFields,
    applyCustomFieldsToForm,
  };
};
