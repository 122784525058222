import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { getRoles } from "@/features/roles/api/roleApi";
import { useRolePolicy } from "@/features/roles/policies/useRolePolicy";
import { getUserLevels } from "@/features/users/api/userApi";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/useFilters";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const UserFilters = ({ table }) => {
  const rolePolicy = useRolePolicy();

  const [levelOptions, setLevelOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const { filter, setFilter } = useFilters(table);

  const {
    isLoading: isLoadingLevels,
    data: levels,
    error: errorLevels,
  } = useQuery({
    queryKey: ["userLevels"],
    queryFn: getUserLevels,
    staleTime: 1000 * 60 * 5,
  });

  const {
    isLoading: isLoadingRoles,
    data: roles,
    error: errorRoles,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
    staleTime: 1000 * 60 * 5,
    enabled: rolePolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingLevels && !errorLevels && levels) {
      setLevelOptions(levels.data.map((level) => ({ name: level.name, value: level.id })));
    }
  }, [isLoadingLevels, errorLevels, levels]);

  useEffect(() => {
    if (!isLoadingRoles && !errorRoles && roles) {
      setRoleOptions(roles.data.map((role) => ({ name: role.name, value: role.name })));
    }
  }, [isLoadingRoles, errorRoles, roles]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={roleOptions}
        setValue={(value) => setFilter("role", value)}
        title="Rola"
        value={filter("role")}
      />
      <MultipleSelectFilter
        options={appendEmptyOption(levelOptions)}
        isLoading={isLoadingLevels}
        setValue={(value) => setFilter("level", value)}
        title="Poziom"
        value={filter("level")}
      />
      <ColumnFilter table={table} />
    </div>
  );
};
