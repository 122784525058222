import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { DateRangeFilter } from "@/components/Filters/DateRangeFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { useClientPolicy } from "@/features/clients/policies/useClientPolicy";
import { LeadSourceContext } from "@/features/leads/context/LeadSourceContext";
import { useLeadSourcePolicy } from "@/features/leads/policies/useLeadSourcePolicy";
import { UsersContext } from "@/features/users/context/UsersContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/useFilters";
import { useContext } from "react";

export const ClientFilters = ({ table, withColumns = true }) => {
  const clientPolicy = useClientPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const { sourceOptions, isLoading: isLoadingSources } = useContext(LeadSourceContext);
  const { filter, setFilter } = useFilters(table);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <DateRangeFilter
        value={filter("created")}
        setValue={(value) => setFilter("created", value)}
        placeholder="Zakres utworzenia"
      />
      <VoivodeshipFilter filter={filter} setFilter={setFilter} />
      <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
      {leadSourcePolicy.viewAny() && (
        <MultipleSelectFilter
          options={sourceOptions}
          setValue={(value) => setFilter("source", value)}
          title="Źródło"
          isLoading={isLoadingSources}
          value={filter("source")}
        />
      )}
      {clientPolicy.viewUsers() && (
        <MultipleSelectFilter
          options={appendEmptyOption(userOptions, "Nieprzypisane")}
          setValue={(value) => setFilter("user", value)}
          title="Użytkownik"
          isLoading={isLoadingUsers}
          value={filter("user")}
        />
      )}
      {withColumns && <ColumnFilter table={table} />}
    </div>
  );
};
