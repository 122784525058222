import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { deleteCalculatorFieldDependency } from "@/features/calculators/api/calculatorApi";
import i18n from "@/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const CalculatorFieldDependency = ({ field, dependency }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();

  const deleteCalculatorFieldMutation = useMutation({
    mutationFn: deleteCalculatorFieldDependency,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  return (
    <div className="flex flex-row justify-between items-center p-2 border rounded-md">
      <div className="flex flex-row gap-2 items-center">
        <p className="flex flex-row w items-center font-medium">{i18n.t("Jeżeli")}</p>
        {(dependency.condition.value || dependency.condition.operator) && (
          <p className="font-medium">{i18n.t("calculatorFields." + dependency.condition.attribute).toLowerCase()}</p>
        )}
        <p className="font-medium text-primary">{dependency.depends_on_field.identifier}</p>
        {!dependency.condition.value && !dependency.condition.operator && (
          <p className="font-medium">{i18n.t("calculatorFields." + dependency.condition.attribute).toLowerCase()}</p>
        )}
        {(dependency.condition.value || dependency.condition.operator) && (
          <div className="flex flex-row gap-1 items-center">
            {dependency.condition.operator && (
              <p className="font-medium border py-1 px-2 rounded-md">{dependency.condition.operator}</p>
            )}
            {dependency.condition.value && <p className="font-medium">{dependency.condition.value}</p>}
          </div>
        )}
        <div className="flex flex-row w items-center">{i18n.t("to wtedy")}</div>
        <div className="font-medium text-primary">{i18n.t("calculatorFields." + dependency.action)}</div>
      </div>
      <div>
        <Button
          leftIcon={<Trash size={16} />}
          variant="destructive"
          type="button"
          isLoading={deleteCalculatorFieldMutation.isPending}
          onClick={() =>
            deleteCalculatorFieldMutation.mutate({
              calculatorFieldDependencyId: dependency.id,
              calculatorFieldId: field.id,
            })
          }
        />
      </div>
    </div>
  );
};
